<template>
  <div class="form_area">
    <el-form :model="wareData" :rules="rules" ref="wareForm" class="ruleForm">
      <div class="xlr_m">
        <h3 class="h3">入库信息</h3>
      </div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="客户名称" prop="companyName">
            <el-input v-model="wareData.companyName" prefix-icon="iconfont icon-qiye" placeholder="请输入客户名称" :disabled="
                (userStore.companyName && userStore.companyName !== '') ||
                pageType !== 'add'
              "></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="仓库名称" prop="depotId">
            <WareCascader v-model="wareData.depotId" :options.sync="arrOptions" :address.sync="address"
              :storageName="'depotOptions'" :holder="'请选择省/市/区/仓库名称'" @changeDepotFun="changeDepotFun" ref="wareIn">
            </WareCascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="储存天数" prop="storageDays">
            <el-input v-model="wareData.storageDays" placeholder="预计储存天数" :disabled="pageType == 'detail'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="入库需求" prop="warehousingType">
            <div class="rel auto_target">
              <el-select v-model="wareData.warehousingType" placeholder="请选择入库需求">
                <el-option v-for="item in warehousTypeArr" :key="item.value" :label="item.text" :value="item.value"
                  :disabled="pageType == 'detail'">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="预计入库时间" class="mypicker demo-input-suffix" prop="warehousingDate">
            <el-date-picker v-model="wareData.warehousingDate" type="date" placeholder="选择日期"
              :picker-options="beginDate" @change="changeDateFun" :clearable="false" style="display:inline-block">
            </el-date-picker>
            <!-- <el-form-item prop="warehousingTimes" class="rec_date" style="display:inline-block;"> -->
            <el-select style="margin-left:10px;display:inline-block" v-model="wareData.warehousingTimes">
              <el-option v-for="item in timeArray" :key="item" :label="`${item}时`" :value="item"></el-option>
            </el-select>
            <!-- </el-form-item> -->
          </el-form-item>

        </el-col>
      </el-row>
      <el-row>
        <!-- 货物选择信息 -->
        <GoodsSelection :defaultGoods="wareData.commodityJson" @getCommodityJson="getGoods" :goodHeaders="goodsheader"
          :types="'in'" :id="goodId" @getTotalData="getTotal" ref="inTable"></GoodsSelection>
        <el-col :span="8">
          <el-form-item label="入库数量（合计）" v-if="wareData.commodityJson.length !== 0">
            <el-input v-model="wareData.totalPakages" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="重量（kg）" v-if="wareData.commodityJson.length !== 0">
            <el-input v-model="wareData.totalWeight" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="体积（方）" v-if="wareData.commodityJson.length !== 0">
            <el-input v-model="wareData.totalVolume" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <h3 class="h3">备注</h3>
        <el-col :span="24">
          <el-form-item prop="remark">
            <el-input v-model="wareData.remark" type="textarea" :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="xright">
        <el-button type="primary" class="main_btn" @click="next">下一步</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { getStorage } from "@/assets/js/global";
  import moment from "moment";
  import WareCascader from "./components/wareCascader.vue";
  const API = require("/src/assets/js/apilist.js");
  import { mapState } from "vuex";
  import GoodsSelection from "./components/goodsSelection/index.vue";
  import { warehousTypeArr } from "@/assets/js/warehouse.js";
  import { warehouseRules } from "./utils/rules.js";
  import { getBeginDateAndHoursArray } from "@/assets/js/utils.js";
  import { goodsTableHead } from "@/assets/js/warehouse.js";
  export default {
    props: {
      warehouseData: {
        type: Object,
        default: () => { },
      },
      depoptions: {
        type: Array,
        default: () => { },
      },
    },
    components: {
      WareCascader,
      GoodsSelection,
    },
    data() {
      return {
        pageType: this.$store.state.wareOrder.pageType, // 判断新增、编辑、详情参数
        userStore: JSON.parse(getStorage("user")),
        address: "entryDepot", // 调用入库单仓库的地址
        commodityJson: this.warehouseData.commodityJson || [], // 存放选择商品的地方
        goodsheader: goodsTableHead,
        // goodId:'', // 可以是客户名称id也可以是仓库id
        tableChecked: [], //被选中的记录数据-----对应“批量删除”传的参数值
        ids: [], //批量删除id
        goodsInforData: [], // 选择的商品数据
        config: {
          top: "20vh",
          width: "80%",
          title: "商品选择",
          center: true,
          btnTxt: ["取消", "加入订单"],
        },

        warehousTypeArr: warehousTypeArr,
        timeArray: [],
        actType: "",
        beginDate: "",
        // 仓储订单数组
        wareData: this.warehouseData,
        rules: warehouseRules,
      };
    },
    mounted() {
      //获取时间段
      this.actType = this.$route.query.actType || "";
      this.changeDateFun();
      if (this.userStore) {
        this.wareData.companyName = this.userStore.companyName;
      }
    },
    //计算state数据
    computed: {
      arrOptions() {
        return this.depoptions
      },
      goodId() {
        return {
          corpId: this.userStore.corpId || "",
          companyName: this.wareData.companyName,
        };
      },
    },
    methods: {
      // 点击下一步
      async next() {
        const form1 = new Promise((resolve, reject) => {
          this.$refs.wareForm.validate((valid) => {
            if (valid) {
              resolve();
            } else {
              this.$message.closeAll();
              this.$message({
                message: '信息没有填写完整！',
                type: "error",
              });
            }
          });
        });
        const form2 = new Promise((resolve, reject) => {
          this.$refs.inTable.$refs.goodsForm.validate((valid) => {
            if (valid) {
              resolve();
            } else {
              this.$message.closeAll();
              this.$message({
                message: '信息没有填写完整！',
                type: "error",
              });
            }
          });
        });
        if (this.wareData.commodityJson.length !== 0) {
          Promise.all([form1, form2]).then((resolve, reject) => {
            this.saveWareData();
          });
        } else {
          Promise.all([form1]).then((resolve, reject) => {
            this.saveWareData();
          });
        }
      },
      saveWareData() {
        this.$emit("setWareData", { parmsJson: this.wareData, active: 2 });
      },
      //获取仓库
      changeDepotFun(depotArr) {
        let self = this;
        if (depotArr.lenght == 0) {
          return;
        }
        this.wareData.depotId = depotArr
      },
      // 获取货品选择组件传过来的数组
      getGoods(val) {
        this.commodityJson = val;
        this.wareData.commodityJson = this.commodityJson;
      },
      getTotal(val) {
        this.wareData.totalPakages = val.totalPakages;
        this.wareData.totalVolume = val.totalVolume;
        this.wareData.totalWeight = val.totalWeight;
      },
      //禁止选择以前的时间方法
      getBeginDateFun(date) {
        this.beginDate = {
          disabledDate(time) {
            return time.getTime() < new Date(date).getTime() - 8.64e7;
          },
        };
      },
      //选择预计入库日期
      changeDateFun() {
        let getDateAndTime = getBeginDateAndHoursArray(this.wareData.warehousingDate);
        this.timeArray = getDateAndTime.timeRang;
        this.wareData.warehousingTimes = getDateAndTime.timeRang[0];
        this.wareData.warehousingDate = getDateAndTime.beginDate; //预计入库日期
        this.$set(
          this.wareData,
          "warehousingTime",
          this.wareData.warehousingDate +
          " " +
          (this.wareData.warehousingTimes + ":00:00")
        );
        this.wareData.warehousingTime = this.wareData.warehousingDate + " " + this.wareData.warehousingTimes + ":00:00"
        this.getBeginDateFun(getDateAndTime.beginDate);
      },
    },
  };
</script>

<style scoped>
  .rec_date>>>.el-form-item__content {
    margin-top: 40px;
  }

  .iconbox,
  .iconbox .iconfont {
    font-size: 16px;
    color: #666;
  }

  .goodsDetailBtn {
    width: 180px;
    line-height: 42px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    padding: 0;
    background-color: #0a8de4;
  }
</style>