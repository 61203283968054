<template>
  <div class="addr_modal mt20">
    <el-form label-width="80px" :inline="true">
      <el-form-item label="快速查询">
        <el-input
          v-model="searchWords"
          placeholder="请输入地址/姓名模糊查找"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="tc" v-if="list.length === 0">暂无数据</div>
    <div class="tablebox" v-else>
      <div
        v-for="item in newtableList"
        class="rowlist hand flex"
        :key="item.id"
        :class="item.isCheck === false ? 'hidden-row' : ''"
        @click="() => changeSelect(item)"
      >
        <span class="mr20">
          <input
            v-if="activeType === 'radio'"
            class="flex1"
            type="radio"
            name="radio"
            :value="item.id"
            v-model="radioSelect"
          />
          <input
            v-else
            type="checkbox"
            name="checkbox"
            :value="item.id"
            :disabled="item.isDisabled"
            v-model="checkboxIdRows"
          />
        </span>
        <div>
          <span class="mr10">{{ item.contacts }}</span>
          <span class="mr10">{{ item.mobile }}</span>
          <span class="mr10">{{ item | addressFull }}</span>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer tc mt20">
      <el-button @click="goToAdd">新 建</el-button>
      <el-button @click="cancleChoose">取 消</el-button>
      <el-button type="primary" @click="handleSelect">确 定</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddressListPC",
  props: {
    addressType: {
      type: String,
      default: "deliver",
    },
    activeType: {
      type: String,
    },
    checkdId: {
      type: Array,
    },
    tableList: {
      type: Array,
    },
  },
  filters: {
    addressFull(row) {
      if (row) {
        const area1 = row.areaCode1Name || "";
        const area2 = row.areaCode2Name || "";
        const area3 = row.areaCode3Name || "";
        const area4 = row.areaCode4Name || "";
        const info = row.areaInfo || "";
        return `${area1}${area2}${area3}${area4}${info}`;
      }
    },
  },
  data() {
    return {
      newtableList: this.tableList,
      currentRow: "",
      radioSelect: "",
      checkboxIdRows: this.checkdId || [],
      searchWords: "",
    };
  },
  watch: {
    tableList(newVal){
      this.newtableList = newVal
    },
    checkdId(newVal) {
      this.checkboxIdRows = newVal;
    },
  },
  computed: {
    list() {
      let newDatas = [];
      this.tableList &&
        this.tableList.map((item) => {
          if (item.isCheck !== false) {
            newDatas.push(item);
          }
        });
      return newDatas;
    },
  },
  methods: {
    goToAdd() {
      this.$emit("closePcAdd", true);
    },
    cancleChoose() {
      this.searchWords = '';
      this.$emit("cancleChoose");
    },
    handleSelect() {
      let returnId;
      if (this.activeType === "radio") {
        returnId = this.radioSelect;
      } else {
        returnId = this.checkboxIdRows;
      }
      this.$emit("checkItem", returnId, this.addressType);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.isCheck === false) {
        return "hidden-row";
      }
      return "";
    },
    changeSelect(item) {
      if (this.activeType === "checkbox" && item.isDisabled) return; //父组件带过来已选中的不能再操作
      if (this.activeType === "radio") {
        this.radioSelect = item.id;
      } else if (this.activeType === "checkbox") {
        let isTrue = this.checkboxIdRows.indexOf(item.id) === -1;
        if (isTrue) {
          //不存在则添加
          this.checkboxIdRows.push(item.id);
        } else {
          let key = this.checkboxIdRows.indexOf(item.id);
          this.checkboxIdRows.splice(key, 1);
        }
      }
    },
    handleCancleRow() {
      this.currentRow = "";
      this.checkboxIdRows = [];
      this.handleReset();
    },
    handleReset() {
      this.searchWords = "";
      this.$emit("search", "");
    },
    // 模糊搜索
    onSearch() {
      this.$emit("search", this.searchWords);
    },
  },
};
</script>
<style scoped>
.hidden-row {
  display: none;
}
input[type="checkbox"],
input[type="radio"] {
  width: 22px;
  height: 22px;
}
.addr_modal >>> .el-form-item__label {
  line-height: 50px;
}
.addr_modal >>> .el-input__inner {
  width: 300px;
}
.addr_modal >>> .el-button {
  padding: 0 25px;
  height: 50px;
}
.tablebox {
  border: 1px solid #ddd;
  height: 400px;
  min-height: 400px;
  overflow-y: auto;
}
.rowlist {
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid #ddd;
}
</style>