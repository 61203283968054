/**MY下单专用 */
import Vue from 'vue'
import Vuex from 'vuex'
import { ORDERSTORE, BATCHORDERSTORE } from '@/assets/js/orderLibrary.js'
import wareOrder from './wareOrder/index'
import searchForm from './searchForm/index'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    monthClosing: '0', //是否月结，0为否，1为是
    stepAction: 1, //订单步骤条当前值
    extraParams: {}, //常用数据
    drawerType: 'add',
    feeList: [], //费用明细
    cargoLabelList: [], //货物规格列表
    addrList: { faAddrList: [], shouAddrList: [] }, //地址详情
    ruleForm: ORDERSTORE, //极速下单表单
    batchConForm: BATCHORDERSTORE, //批量下单表单
    byPCode: {
      //基础code
      packingVO: [], //包装方式
      zengZhiVO: [], //增值服务
      paymentTypeVO: [
        {
          fid: null,
          code: 'ZL1905201',
          name: '到付',
          pcode: 'ZL1605219',
          remark: null,
          valid: null,
          address: null,
          lstUpdTime: null,
          kdCode: null,
          logo: null
        },
        {
          fid: null,
          code: 'ZL1605220',
          name: '现付',
          pcode: 'ZL1605219',
          remark: null,
          valid: null,
          address: null,
          lstUpdTime: null,
          kdCode: null,
          logo: null
        },
        {
          fid: null,
          code: 'ZL1605223',
          name: '月结',
          pcode: 'ZL1605219',
          remark: null,
          valid: null,
          address: null,
          lstUpdTime: null,
          kdCode: null,
          logo: null
        }
      ] //付款方式
    },
    providerAll: [], //供应商列表
    couponList: [], //订单有效优惠券列表
    selectedTags: 'mr', //供应商标签筛选
    cargoTypeVO: [
      //货物类型
       // { code: 'ZL1605323', name: '颜料油漆', usedTimes: 0 },
      { code: 'ZL1807094', name: '化工用品', usedTimes: 0 },
      { code: 'ZL1605324', name: '日化用品', usedTimes: 0 },
      { code: 'ZL1605326', name: '医药', usedTimes: 57 },
      { code: 'ZL1605328', name: '机械设备', usedTimes: 4 },
      { code: 'ZL1807089', name: '家具家居', usedTimes: 0 },
      { code: 'ZL1807093', name: '家用家电', usedTimes: 0 },
      { code: 'ZL1605340', name: '建筑材料', usedTimes: 0 },
      { code: 'ZL1807087', name: '五金建材', usedTimes: 0 },
      { code: 'ZL1807085', name: '食品饮料', usedTimes: 0 },
      { code: 'ZL1807091', name: '3C电子', usedTimes: 0 },
      { code: 'ZL1605338', name: '橡胶制品', usedTimes: 0 },
      { code: 'ZL1605339', name: '皮革皮具', usedTimes: 0 }
    ],
    addedServiceList: [
      { id: 'mj', name: '木架' },
      { id: 'mtp', name: '木托盘' },
      { id: 'mx', name: '木箱' },
      { id: 'mjMtp', name: '木架+木托' },
      { id: 'mxMtp', name: '木箱+木托' },
      { id: '0', name: '暂不需要' }
    ],
    addedServiceMx: [
      { id: 'bkml', name: '薄款木箱' },
      { id: 'hkml', name: '厚款木箱' }
    ],
    servicesList: [
      { id: 'zh', name: '装货' },
      { id: 'xh', name: '卸货' }
    ],
    tagsDat: [
      { id: 'mr', name: '默认' },
      { id: 'jg', name: '价格' },
      { id: 'sx', name: '时效' }
    ],
    corpMsg: {}, //客户标签等信息
    isSvip: false //是否Svip客户
  },
  getters: {
    getCorpMsg(state) {
      return state.corpMsg
    }
  },
  mutations: {
    //设置步骤条当前值
    setStepIndex(state, index) {
      state.stepAction = index
    }
  },
  actions: {
    getStepIndex(context, index) {
      //模拟ajax请求,将返回的信息直接存储在store
      setTimeout(() => {
        context.commit('setStepIndex', index)
      }, 2000)
    }
  },
  modules: {
    wareOrder,
    searchForm
  }
})
