export default {
    module: "searchform",
    name: "searchform",
    //运输订单 
    form:{
        userId: "",
        size: 15,
        current: 1,
        status: "",
        sendTimeBegin: "",
        sendTimeEnd: "",
        sendCity: "",
        sendDist: "",
        endCity: "",
        endDist: "",
        payState: "",
        orderNo: ""
    },
    //仓储订单
    form2:{
        current: 1,
        size: 15,
        userId: '',
        outStateName: '',
        enterStateName: '',
        mobile: '',
        depotNo: '',
        createTimeBegin: '',
        endTimeEnd: ''
    }
};