import { render, staticRenderFns } from "./CargoLabelCmp.vue?vue&type=template&id=e5459232&"
import script from "./CargoLabelCmp.vue?vue&type=script&lang=js&"
export * from "./CargoLabelCmp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports