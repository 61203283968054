<template>
   <div id="WuliuStepLine" class="flex yrow">
       <div :class="index==list.length-1?'_option flex xrow':'option flex xrow'" v-for="(item,index) in list">
            <div class="l_option">
                <i :class="index==0?'iconfont icon-liuchengjiedian blue':'iconfont icon-liuchengjiedian gray'"></i>
            </div>
            <div class="r_option flex1">
                <div class="text">
                    <div :class="index==0?'text blue':'text'">{{item.message}}</div>
                    <div class="mt6">{{item.createTime}}</div>
                </div>
            </div>
       </div>
       <div v-if="nodata" class="nodata">查无数据</div>

   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
export default {

    components: {

    },

    data() {
        return {
            list:[],//轨迹文字描述
            nodata:false
        }
    },

    created() {
        this.getData();
    },


    mounted() {

    },

    methods: {
        // 获取物流信息文字描述
        getData:function(){
            const that=this;
            const params={
                orderNo:that.$route.query.orderSn
            }
            console.log(process.env.NODE_ENV)
            this.$axios('post',API.queryTbOrderNodeList,params,'data').then((res)=>{
                console.log("获取物流信息",res)
                if(res.data.list.length>0){
                    that.list=res.data.list;
                }else{
                    that.nodata=true;
                }
            }).catch((error)=>{
                console.log("获取物流信息",error)
            })
        }
    }

}

</script>
<style scoped>
    #WuliuStepLine{
        /* padding:40px;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16); */
    }
    .nodata{
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .mt6{
        margin-top: 6px;
    }
    .option{
        min-height: 150px;
        border-left: 1px dashed #727272;
    }
    ._option{
        min-height: 150px;
    }
    .l_option{
        width: 35px;
        position: relative;
    }
    .r_option{
        position: relative;
    }
    /* 未成功的icon */
    .icon-liuchengjiedian{
        font-size: 18px;
        position: absolute;
        top:0%;
        left:0%;
        transform: translate(-50%, -10%);
        
    }
    .text{
        /* position: absolute;
        top:0%; */
        /* left:0%; */
        /* transform: translateY(-50%); */
    }
    .gray{
        color: #888888;
    }
    .blue{
        color: #2F99E8;
    }

</style>
