<template>
  <div class="step_two form_area">
    <!-- <ul class="tagbox mb20" v-if="drawerType === 'add'">
      <li
        class="tagli"
        v-for="item in proTabList"
        :key="item.id"
        :class="{ checked: $store.state.selectedTags === item.id }"
        :data-id="item.id"
        @click="changeProTab"
      >
        {{ item.name }}
      </li>
    </ul> -->
    <div class="box_ul clearfix" v-if="$store.state.providerAll.length">
      <div
        class="ul_li rel"
        v-for="(item, index) in $store.state.providerAll"
        :label="item.id"
        :key="item.id"
        @click="changePriver(item)"
        :class="{ checked: item.lineId === newStepArr.lineId }"
      >
        <span class="resbox" :class="item.biaoQian != '' ? 'biaoqian' : ''"></span>
        <div class="box_li">
          <div class="head_top">
            <strong class="tipsbox" v-if="item.biaoQian">
              <!-- {{
              item.biaoQian === 'zhunShiDa'
                ? '大票快递'
                : item.biaoQian === 'jiaGeZuiDi'
                ? '大票特惠'
                : '大票特快'
            }} -->
              {{ showPdTagFun(item.biaoQian) }}
            </strong>
            <span class="pro_type">
              <i style="padding-right: 5px" v-if="item.biaoQian">| </i>
              {{ item.productTypeName }}
            </span>
          </div>
          <div class="mainbox mt10">
            <div class="new_price tc fb">
              <span
                >￥{{
                  !item.discountAmount || item.discountAmount == item.feiYongHeJi
                    ? item.feiYongHeJi
                    : item.discountAmount
                }}</span
              >
              <i>起</i>
            </div>
            <div class="old_price tc">
              <span v-if="item.discountValue">￥{{ item.feiYongHeJi }}<i>起</i></span>
            </div>
            <div class="pro_time tc mt10">预计时效 {{ item.lineHour }}小时</div>
            <div class="disc_box">
              <span v-if="item.discountValue">
                <i class="cou_box">
                  {{ item.couponTypeName }} |
                  {{ item.discountValue + (item.couponType == 'YHQLX01' ? '折' : '元') }}
                </i>
                <i class="cou_box discountbox">
                  优惠{{ item.feiYongHeJi - item.discountAmount }}元
                </i>
              </span>
            </div>
            <!-- <div
              class="f888 mt10"
              @click="(e) => isShowProvider(e, true, index)"
            >
              <i class="mr5 iconfont icon-xialajiantou"></i>查看承运商
            </div> -->
          </div>
        </div>
        <!-- <div class="providerbox" v-if="item.isShowProvider">
          <div
            class="close_icon iconfont icon-guanbi f888 hand"
            @click="(e) => isShowProvider(e, false, index)"
          ></div>
          <ul class="provider_ul">
            <li class="provider_li" v-if="item.tiHuoSupplierName">
              <i>提货服务</i>{{ item.tiHuoSupplierName }}
            </li>
            <li class="provider_li" v-if="item.zhuGanSupplierName">
              <i>运输服务</i>{{ item.zhuGanSupplierName }}
            </li>
            <li class="provider_li" v-if="item.peiSongSupplierName">
              <i>送货服务</i>{{ item.peiSongSupplierName }}
            </li>
          </ul>
          <div
            v-if="item.biaoQian && item.biaoQian == 'zhunShiDa'"
            class="tipbox"
          >
            <span class="service_tag">慢必赔</span>
            <span class="service_tag">极速提货</span>
            <span class="service_tag">优先派送</span>
            <span class="service_tag">VIP客服</span>
            <span class="service_tag">送保险</span>
          </div>
          <div v-else class="tipbox">
            <span class="service_tag">贵必赔</span>
            <span class="service_tag">慢必赔</span>
            <span class="service_tag">损必赔</span>
          </div>
        </div> -->
      </div>
    </div>
    <el-row class="xright mt30">
      <el-button type="primary" class="main_btn sec_btn" @click="prev">上一步</el-button>
      <el-button
        type="primary"
        class="main_btn"
        @click="next"
        v-if="$store.state.providerAll.length"
        >下一步</el-button
      >
    </el-row>
  </div>
</template>

<script>
import { setOrderForm, getBatchOrderForm } from '@/assets/js/storage'
import {
  calcTotal,
  providerInfoAllN,
  saveStorage,
  filterZengZhi,
  pdTagFun
} from '@/assets/js/order'
export default {
  props: {
    stepThreeArr: {
      type: Object,
      required: true
    },
    isBatch: {
      type: Boolean
    },
    faIdx: {
      type: Number
    },
    isLock: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      drawerType: this.$store.state.drawerType,
      fullscreenLoading: true,
      radioSelect: null,
      newStepArr: this.stepThreeArr
      // proTabList: [
      //   { id: 'mr', name: '默认' },
      //   { id: 'jg', name: '价格' },
      //   { id: 'sx', name: '时效' }
      // ]
    }
  },
  watch: {
    stepThreeArr(newVal, oldVal) {
      this.newStepArr = newVal
    }
  },
  methods: {
    // isShowProvider(e, isShow, index) {
    //   if (e && e.stopPropagation) {
    //     e.stopPropagation();
    //   }
    //   let newProList = [...this.$store.state.providerAll];
    //   newProList[index].isShowProvider = isShow;
    //   this.$store.state.providerAll = [...newProList];
    // },
    // changeProTab(e) {
    //   const type = e.currentTarget.getAttribute('data-id')
    //   this.$store.state.selectedTags = type
    //   let ruleForm = { ...this.newStepArr }
    //   if (this.isBatch) {
    //     let batchDatas = getBatchOrderForm()
    //     ruleForm = {
    //       ...ruleForm,
    //       ...batchDatas.fhArr
    //     }
    //   }
    //   providerInfoAllN(this, ruleForm, this.isBatch, type)
    // },
    //展示产品标识
    showPdTagFun(biaoQian) {
      return pdTagFun(biaoQian)
    },
    changePriver(item) {
      if (this.drawerType != 'add') {
        this.$message('修改订单不能更改供应商')
        return
      }
      if (item.productType == 'XP' && this.newStepArr.receiptTypeName == '纸质回单 | 普通') {
        this.$dialog
          .confirm({
            title: '温馨提示',
            message: '小票零担产品暂不享有纸质回单服务优惠',
            cancelButtonText: '选择其他产品',
            confirmButtonText: '继续下单',
            confirmButtonColor: '#007EC5'
          })
          .then(() => {
            // this.newStepArr.receiptTypeName = '无需回单'
            // this.newStepArr.receiptType = 'ZL1605215'
            this.savePriver(item)
          })
          .catch(() => {
            return
          })
      } else {
        this.savePriver(item)
      }
    },
    //存供应商信息
    savePriver(item) {
      let datas = {
        ...this.newStepArr,
        ...item
      }
      console.log('datas11', datas)
      this.newStepArr = datas
      saveStorage(this, datas, this.isBatch, this.faIdx)
    },
    changeNextData(self) {
      calcTotal(self, self.newStepArr, self.isBatch, self.faIdx, true)
      self.$emit('changeStepArr', self.newStepArr)
      if (!self.isBatch) {
        setOrderForm(self.newStepArr)
        self.$store && self.$store.state.stepAction++
      } else {
        self.$emit('faFunc', 4)
      }
    },
    prev() {
      if (!this.isBatch) {
        this.$store.state.stepAction--
      } else {
        this.$emit('faFunc', 2)
      }
    },
    next() {
      let { lineId } = this.newStepArr
      if (!lineId) {
        this.$message.error('请先选择供应商')
        return
      }
      filterZengZhi(this, this.callbackZengZhi)
    },
    callbackZengZhi(wrongArr) {
      let self = this
      //有不存在的增值服务则提示报错信息
      if (wrongArr.length) {
        let tipsTxt = '此线路不存在 ' + wrongArr.join('、') + '服务'
        self.$confirm(tipsTxt, {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success',
          showClose: false,
          closeOnClickModal: false,
          callback: action => {
            if (action === 'confirm') {
              self.changeNextData(self)
            }
          }
        })
        return
      }
      self.changeNextData(self)
    }
  }
}
</script>
<style scoped>
.tagbox {
  text-align: right;
}
.tagbox .tagli {
  display: inline-block;
  padding: 5px 15px 5px 25px;
  margin-right: 10px;
  color: #666;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  background: url('../../../assets/images/up_icon.png') no-repeat 11px center;
  background-size: 8px;
  cursor: pointer;
}
.tagbox .tagli.checked {
  color: #0a8de4;
  border: 1px solid #0a8de4;
}
.box_ul {
  max-height: 500px;
  overflow-y: auto;
}
.ul_li {
  width: 31.3%;
  float: left;
  margin: 10px 1%;
  box-sizing: border-box;
}
.box_li {
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
}
.ul_li.checked .box_li {
  border: 1px solid #098de4;
}
.head_top {
  line-height: 40px;
}
.tipsbox {
  padding: 0 15px;
  font-size: 16px;
  color: #b93a13;
  line-height: 40px;
  border-radius: 12px 0px 12px 0px;
  background: linear-gradient(180deg, #ffebd0 0%, #ffffff 100%);
  display: inline-block;
}
.resbox {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 40px;
  font-size: 0;
}
.resbox.biaoqian {
  background: url('../../../assets/images//tj_icon.png') no-repeat right top;
  background-size: cover;
}
.ul_li.checked .resbox {
  background: url('../../../assets/images/tj_sel_icon.png') no-repeat right top;
  background-size: cover;
}
.mainbox {
  padding-bottom: 13px;
}
/* .ul_li.checked .mainbox {
  padding-bottom: 10px;
} */
.ul_li.checked .head_top {
  margin-left: 1px;
}
.pro_type {
  font-size: 16px;
  display: inline-block;
  padding-left: 15px;
  color: #888;
}
.pro_time {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.disc_box {
  margin-top: 15px;
  height: 30px;
}
.cou_box {
  padding: 3px 6px 4px;
  color: #fd6c64;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #fd6c64;
}
.discountbox {
  background-color: #ffeeed;
  margin-left: 10px;
}
.new_price {
  font-size: 26px;
  color: #ff5046;
}
.new_price i {
  font-style: normal;
  font-size: 14px;
  margin-left: 5px;
}

.old_price {
  height: 20px;
}
.old_price span {
  color: #acacac;
  font-size: 12px;
  text-decoration: line-through;
}
.old_price i {
  margin-left: 3px;
}
.privider i {
  margin-right: 5px;
}
.providerbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background-color: #fff;
}
.providerbox .close_icon {
  position: absolute;
  font-size: 14px;
  right: 10px;
  top: 10px;
}
.provider_ul {
  margin-top: 40px;
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 6px;
  overflow-y: auto;
}
.provider_li {
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  margin-bottom: 10px;
  color: #666;
}
.provider_li i {
  padding-right: 8px;
  margin-right: 10px;
  line-height: 12px;
  font-size: 12px;
  font-style: normal;
  color: #acacac;
  border-right: 1px solid #acacac;
  display: inline-block;
  border-radius: 2px;
}
.tipbox {
  margin-left: 80px;
}
.tipbox .service_tag {
  padding: 4px 8px;
  border: 1px solid #21a1fc;
  color: #21a1fc;
  font-size: 10px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.mainbox,
.provider_ul {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
