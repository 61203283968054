<template>
  <div id="webfooter">
    <!-- 头部footer -->
    <div class="t_footer xycenter">
      <div class="flex box">
        <!-- 左侧内容 -->
        <div class="flex">
          <!-- 内容列 -->
          <div class="mr130" v-for="(item, index) in footer" :key="item.title">
            <!-- 标题 -->
            <div class="fw white fs16 pb12 pointer">{{ item.title }}</div>
            <!-- 横线 -->
            <div class="line"></div>
            <!-- 内容 -->
            <div class="flex yrow mt28">
              <div
                class="contentDiv pb16 fs14 white pointer"
                v-for="(item2, index) in item.content"
                :key="item2.title"
                @click="envJump(item2.path, 'href', item2.type)"
              >
                {{ item2.title }}
              </div>
            </div>
          </div>
        </div>
        <!-- 中间竖线 -->
        <div class="line2"></div>
        <!-- 右侧内容 -->
        <div class="ml90">
          <div class="ycenter">
            <i class="iconfont dianhua">&#xe69a;</i>
            <span class="fz28 white pl12">{{ dianhua }}</span>
          </div>
          <div class="flex pt30">
            <div class="flex yrow xycenter">
              <img :src="qrcode" class="qrcode" />
              <span class="codetitle fs14">关注公众号</span>
            </div>
            <div class="flex yrow xycenter ml46">
              <img :src="minicode" class="qrcode" />
              <span class="codetitle fs14">小程序下单</span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部footer -->
    <div class="b_footer xycenter fs14">
      <div class="alignCenter">
        <p>道路运输经营许可证 ｜ 营业执照</p>
        <p>Copyright © 2018-2019 一站物流商城 5656mall.com 版权所有 粤ICP备 15018368号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webfooter',
  components: {},
  data() {
    return {
      dianhua: '400-868-5656',
      qrcode: require('../../assets/images/qrcode.png'),
      minicode: require('../../assets/images/minicode.png'),
      footer: [
        {
          title: '产品服务',
          content: [
            // { title: '大票快递', path: '/html/product.html?tab=2', type: 0 },
            // { title: '大票特惠', path: '/html/product.html?tab=2', type: 0 },
            // { title: '公路整车', path: '/html/product.html?tab=2', type: 0 },
            // { title: '水路运输', path: '/html/product.html?tab=2', type: 0 },
            // { title: '航空运输', path: '/html/product.html?tab=2', type: 0 },
            // { title: '铁路运输', path: '/html/product.html?tab=2', type: 0 },
            // { title: '仓储服务', path: '/html/product.html?tab=2', type: 0 }
            { title: '运输产品', path: '/html/product.html?tab=1', type: 0 },
            { title: '仓促产品', path: '/html/product.html?tab=2', type: 0 },
            { title: '行业解决方案', path: '/html/product.html?tab=3', type: 0 }
          ]
        },
        {
          title: '寄件查询',
          content: [
            { title: '运输下单', path: '/pre_order', type: 1 },
            { title: '仓储下单', path: '/entry_order', type: 1 },
            { title: '快速询价', path: '/html/inquiry.html', type: 0 },
            { title: '货物追踪', path: '/html/trajectory.html', type: 0 },
            { title: '我的订单', path: '/p_myorder/p_transOrder', type: 1 }
          ]
        },
        {
          title: '一站动态',
          content: [
            { title: '新闻列表页', path: '/dynamic?type=0&current=1', type: 0 },
            { title: '优惠活动', path: '/dynamic?type=1&current=1', type: 0 },
            { title: '公告', path: '/dynamic?type=1&current=1', type: 0 }
          ]
        },
        {
          title: '帮助支持',
          content: [
            { title: '常见问题', path: '/html/helpAndSupport01.html', type: 0 },
            { title: '运输', path: '/html/helpAndSupport02.html', type: 0 },
            { title: '理赔', path: '/html/helpAndSupport03.html', type: 0 },
            { title: '投诉建议', path: '/html/contactUs.html', type: 0 }
          ]
        },
        {
          title: '走进一站',
          content: [
            { title: '认识一站网', path: '/html/walkInto.html', type: 0 },
            { title: '一站招聘', path: '/joinList', type: 0 },
            { title: '联系我们', path: '/html/contactUs.html', type: 0 }
          ]
        }
      ]
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.t_footer {
  background-color: #0a8de4;
  padding-top: 47px;
}
.alignCenter {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.b_footer {
  background-color: #007ec5;
  padding: 30px;
  color: #ffffff;
}

.box {
  /* width: 1256px; */
}

.contentDiv {
  display: block;
  text-align: left;
}

.mr130 {
  margin-right: 130px;
}

.line {
  width: 37px;
  border-top: 2px solid #ffffff;
}

.line2 {
  height: 174px;
  border-left: 1px solid #ffffff;
}

.mt28 {
  margin-top: 28px;
}

.pb16 {
  padding-bottom: 16px;
}

.dianhua {
  font-size: 28px;
  color: white;
}

.pl12 {
  padding-left: 12px;
}

.ml90 {
  margin-left: 90px;
}

.qrcode {
  width: 88px;
  height: 88px;
}

.codetitle {
  color: #b8d1e9;
  line-height: 0;
  padding-top: 15px;
}

.ml46 {
  margin-left: 46px;
}

.pt30 {
  padding-top: 30px;
}
</style>
