import $store from '@/store/index';
import { $axios } from "@/assets/js/global";
import { getUserStore } from "@/assets/js/storage";
import { tipsModal } from "@/assets/js/order";
const userStore = getUserStore();
export function saveAddress(self, params, callback) {
    $axios(
        "POST",
        "/portal/corpAddress/save",
        params,
        "params",
        "application/json"
    ).then((res) => {
        if (res.code === "200") {
            tipsModal(self, "success", "新增地址成功");
            self.$emit('handlePCSubmit')
            self.closeAdd && self.closeAdd();
            self.
                callback && callback(res.data || '')
        } 
        // else {
        //     tipsModal(self, "error", "新增地址失败，" + res.msg || "");
        // }
    })
}
export function fetchData(self, dataType) {
    const params = {
        corpId: userStore.corpId || "",
        userId: userStore.id || "",
    }
    $axios(
        "POST",
        "/portal/corpAddress/queryByCorpId",
        params,
        "params",
        "application/json"
    ).then((res) => {
        if (res.code === "200") {
            $store.state.addrList = res.data;
            if (dataType) {
                self[dataType] = res.data;
            }
        } 
        // else {
        //     tipsModal(self, "error", "获取地址列表失败，" + res.msg || "");
        // }

    });
}