<template>
  <div class="cargo_box_m" v-if="reFresh">
    <van-swipe
      :loop="false"
      :width="80"
      :show-indicators="false"
      class="swiper_box mt30 plr20"
      v-show="cargoLabelList.length"
    >
      <van-swipe-item
        v-for="(item, index) in cargoLabelList"
        :key="item.id"
        @click="handleSelect(item.id, index)"
      >
        <van-tag :class="item.checked === true ? 'active tag' : 'tag'">
          <template #default> + {{ item.name }} </template>
        </van-tag>
      </van-swipe-item>
    </van-swipe>
    <van-form
      @submit="handleCargoSubmit"
      class="mt30 bgW block_base"
      v-if="!isEditNew"
    >
      <van-row
        type="flex"
        class="borBt"
        justify="center"
        v-for="(item, index) in tagLine.tagLineDetail"
        :key="item.id"
      >
        <van-col span="24" class="name_box">{{ getName(item.id) }}</van-col>
        <van-col span="7" class="col">
          <van-field
            v-model="item.m"
            placeholder="包装件数"
            class="h35 borRight ctx1"
            :name="'pakages' + index"
            :rules="cargoRules.pakages"
            @input="(val) => changeIptVal(val, index, item.id)"
          />
        </van-col>
        <van-col span="7" class="col">
          <van-field
            v-model="item.t"
            placeholder="总重量"
            class="h35 borRight ctx2"
            :name="'weight' + index"
            :rules="cargoRules.weight"
            :disabled="item.id != '0'"
          />
        </van-col>
        <van-col span="7" class="col">
          <van-field
            v-model="item.m"
            class="h35 ctx3"
            placeholder="总体积"
            :name="'volume' + index"
            :rules="cargoRules.volume"
            :disabled="item.id != '0'"
          />
        </van-col>
        <van-col span="3" class="col">
          <i
            class="iconfont icon-delete1 fz40"
            @click="removeItem(item.id, index)"
          >
          </i>
        </van-col>
      </van-row>
      <div style="padding: 16px 0; text-align: center">
        <van-button
          plain
          hairline
          round
          size="mini"
          type="info"
          native-type="submit"
          class="padlr24"
          >确认</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  name: "cargoLabelM",
  props: {
    cargoLabelList: {
      type: Array,
      required: true,
    },
    tagLine: {
      type: Object,
      required: true,
    },
    reFresh: {
      type: Boolean,
      required: true,
    },
    cargoRules: {
      type: Object,
      required: true,
    },
    isShowTagDetail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    isEditNew: {
      get() {
        return this.isShowTagDetail;
      },
      set(val) {
        this.isShowTagDetail = val;
      },
    },
  },
  methods: {
    getName(cargoId) {
      let newName = "";
      this.cargoLabelList.map((tag, idx) => {
        if (cargoId == tag.id) {
          newName = tag.name;
        }
      });
      return newName;
    },
    handleSelect(id, index) {
      this.$emit("handleSelect", id, index);
    },
    handleCargoSubmit() {
      this.$emit("handleCargoSubmit");
    },
    removeItem(id, index) {
      this.$emit("removeItem", id, index);
    },
    changeIptVal(val, index, id) {
      this.$emit("changeIptVal", val, index, id);
    },
  },
};
</script>
<style lang="less" scoped>
.swiper_box ::v-deep .van-swipe-item {
  width: auto !important;
  margin-right: 10px !important;
}
.tag {
  padding: 10px 30px 10px 20px;
  border-radius: 12px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  font-family: Alibaba PuHuiTi;
  &.active {
    background-color: #1989fa;
    color: #fff;
  }
}
.name_box {
  padding: 0 30px;
  color: #1989fa;
  font-size: 28px;
}
</style>