<!-- 运输订单详情---物流信息 -->
<template>
   <div id="TransLogistics">
        <!-- 订单信息 -->
       <div class="board">
           <span class="fs26 mb20">订单号:{{detail.orderNo}}</span>
           <div class="flex mt20">
               <div class="flex1 fs34">
                    {{detail.sendersCityName+detail.sendersDistrictName}}
               </div>
               <div>
                    <img :src="arrow" class="arrow">
               </div>
               <div class="flex1 fs34 xright">
                   {{detail.recipientCityName+detail.recipientDistrictName}}
               </div>
           </div>

       </div>
       <!-- 时间轴 -->
       <StepLine class="mt50" :state="detail.state"></StepLine>
       <div class="board2 mt30">
            <WuliuStepLine></WuliuStepLine>
            <div class="btn flex xycenter" @click="skip_guiji">查看轨迹</div>
       </div>
       
       

   </div>
</template>

<script>
import StepLine from "./StepLine.vue"
import WuliuStepLine from "./WuliuStepLine.vue"
export default {

    components: {
        StepLine,
        WuliuStepLine
    },

    data() {
        return {
            arrow:require('../../assets/images/mobile/order_jt.png'),
            detail:!!localStorage.getItem('transDetail')?JSON.parse(localStorage.getItem('transDetail')):{}
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        // 查看轨迹
        skip_guiji:function(){
            const that=this;
            that.envJump('/html/trajectory.html?orderSn='+that.detail.orderNo,'href',0)
        }
    }

}

</script>
<style scoped>
    #TransLogistics{
        padding: 20px;
        flex: 1;
        overflow-y: scroll;
    }
    .btn{
        width: 340px;
        height: 90px;
        background: #007EC5;
        color: white;
        font-size: 32px;
        border-radius: 46px;
        position: absolute;
        bottom:2%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .board{
        padding:20px;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    }
    .board2{
        height: 820px;
        padding:40px;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
        position: relative;
    }
    .mt30{
        margin-top: 30px;
    }
    .fs34{
        font-size: 34px;
    }
    .fs26{
        font-size: 26px;
    }
    .mt20{
        margin-top: 20px;
    }
    .mt50{
        margin-top: 50px;
    }

</style>
