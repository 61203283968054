<template>
   <div id="p_unpaid" class="flex yrow">
        <!-- tab -->
        <div class="tablist flex">
            <div class="tab" v-for="(item,index) in tabArr" :key="item.name">
                <div :class="item.active==true?'taboption':'_taboption'">{{item.name}}({{totalnumber}})</div>
            </div>
        </div>
        <!-- 搜索条件 -->
        <div class="flex mt30 pl32" v-if="orderArr.length>0">
            <div class="l_inputrow ycenter">
                <input placeholder="请输入单号" class="input">
            </div>
            <div class="searchbtn xycenter">查询</div>
            
        </div>
        <!-- maincontent -->
        <div class="maincontent flex1 relative">
            <div class="flex xycenter">
                <div class="_width80">
                    <div class="padtb32 flex ycenter" v-if="orderArr.length>0">
                        <input class="checkall" type="checkbox" v-model="checkAll" @change="handleCheckAllChange">
                        <span class="checkalltext">全选</span>
                    </div>
                </div>
            </div>
            
            <el-checkbox-group v-model="checkList" @change="selectOrder">
                <div class="orderRow flex xycenter" v-for="(item,index) in orderArr" :key="item.orderNo">
                    <div class="orderdemo">
                        <div class="t_order flex bg_lightblue">
                            <div class="flex1">
                                <el-checkbox :label="item.id">订单号:{{item.orderNo}}</el-checkbox>
                                <!-- 订单号:{{item.orderNo}} -->
                            </div>
                            <div class="flex1 xright">下单时间:{{item.createTime}}</div>
                        </div>
                        <div class="b_order ptb30 flex fz18">
                            <div class="flex1 flex">
                                <div class="flex1 yrow xycenter">
                                    <div>{{item.sendersCityName}}</div>
                                    <div>{{item.sendersName}}</div>
                                </div>
                                <div class="flex1 yrow xycenter">
                                    <img :src="arrow" class="arrow">
                                    <span class="stateText">{{item.payStateName}}</span>
                                </div>
                                <div class="flex1 yrow xycenter">
                                    <div>{{item.recipientCityName}}</div>
                                    <div>{{item.recipientName}}</div>
                                </div>
                            </div>
                            <div class="flex1 xycenter l_border money fz20">
                                ¥{{item.payment}}
                            </div>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
            <div class="nodata" v-if="orderArr.length==0">暂无数据~</div>
            <!-- 支付 -->
            <div class="flex xycenter">
                <div class="_width80 flex xright" v-if="orderArr.length>0">
                    <div class="xycenter paybtn pointer" @click="payment">
                        支付
                    </div>
                </div>
            </div>
            <!-- 二维码弹窗 -->
            <el-dialog title="支付二维码" :visible.sync="isshowqrcode" width="30%" :close-on-click-modal="false" @close="closeDialog">
                <div class="xycenter fz18">
                    <span>支付金额:</span>
                    <span class="red">{{money}}</span>
                    <span>元</span>
                </div>
                <div class="xycenter">
                    <div class="qrcodeDialog" v-show="isshowqrcode" :style="{backgroundImage:'url('+qrcode+')'}"></div>
                </div>
                <div class="xycenter red padtb20">{{remark}}</div>
                <div class="xycenter">
                    <img :src="scanimg">
                </div>
                <div class="flex xright pt20">
                    <el-button @click="closeDialog">取消</el-button>
                    <el-button type="primary" @click="closeDialog">我已扫码支付</el-button>
                </div>
            </el-dialog>
            
        </div>
        <!-- 分页 -->
        <div class="pagerow xycenter">
            <el-pagination
            v-if="orderArr.length>0"
            background
            layout="prev, pager, next"
            :total="totalnumber"
            @current-change="changePage">
            </el-pagination>
        </div>
   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
export default {

    components: {

    },

    data() {
        return {
            fullscreenLoading: false,//全局Loading
            checkAll:false,
            remark:'备注：该二维码将在半小时后失效，失效后请重新生成',
            tabArr:[
                {name:'待支付订单',active:true},
            ],
            qrcode:"",
            isshowqrcode:false,
            arrow:require('../../../assets/images/arrow.png'),
            scanimg:require('../../../assets/images/scanimg.png'),
            orderArr:[],//订单列表
            page:1,
            totalnumber:0,//总数
            checkList:[],
            money:""
            
        }
    },

    created() {
        const that=this;
        // that.getunpaidList();
        that.getunpaidList(that.page);

    },


    mounted() {
        // const that=this;
        // that.getunpaidList();
    },

    methods: {
        // 翻页
        changePage:function(Page){
            const that=this;
            // that.page=Page;
            that.getunpaidList(Page);
            // console.log("打印当前页数",Page)
            // that.form.current=Page;
            // let params=that._filterParams(that.form);
            // console.log("打印这个管道参数",params);
            // that.updateTransOrder(params);
        },
        closeDialog:function(){
            this.isshowqrcode=false;
        },
        handleClose:function(){

        },
        payment:function(){
            const that=this;
            // that.fullscreenLoading=true;
            that.$loading({text:'待支付二维码生成中...'})
            const params={
                orderIds:that.checkList.join(),
                parametersJson:{"appId":"wx2a60d1dc5fe4b730","tradeType":"NATIVE","body":"官网1.1收款"},
                channelServiceName:'weiXinServiceImpl'
            }
            that.$axios('get',API.getPayqrcode,params,'params','application/json').then((res)=>{
                console.log("发起支付成功",res)
                if(res.isSuccess==true){
                    that.$loading().close();
                    // that.fullscreenLoading=false;
                    that.money=res.data.amount;
                    // 拼接base64
                    const base64='data:image/png;base64,'+res.data.qrcode;
                    that.qrcode=base64;
                    that.isshowqrcode=true;
                }else{
                    that.$loading().close();
                    // this.$message.error(res.msg);
                }
                


            }).catch((error)=>{
                that.$loading().close();
                console.log("发起支付失败",error)
            })
        },
        handleCheckAllChange() {
            console.log("打印checkall",this.checkAll);
            if(this.checkAll){
                this.orderArr.forEach(item=>{  //当全选被选中的时候，循环遍历源数据，把数据的每一项加入到默认选中的数组去
                this.checkList.push(item.id)
                })
            }else{
                this.checkList=[];
            }
            console.log("打印checklist",this.checkList)
        },
        // 选择订单
        selectOrder:function(obj){
            const that=this;
            console.log("打印checkList",that.checkList)
        },
        // 获取未支付订单列表
        getunpaidList:function(Page){
            const that=this;
            const userid=JSON.parse(localStorage.getItem('user')).id;
            console.log('11111111111111',userid)
            const params={
                current: Page,
                payState: "1,2",
                paymentType: "ZL1605220",
                size: "10",
                status: "3,4,5,6,7,8,9",
                userId: userid
            }
            that.$axios('post',API.unpaylist,params,'data','application/json').then((res)=>{
                console.log("获取待支付列表",res)
                that.orderArr=res.data.records;
                that.totalnumber=res.data.total;
            })
        }
    }

}

</script>
<style scoped>
    #p_unpaid{
        width: 100%;
    }
    .pointer{
        cursor: pointer;
    }
    ._width80{
        width: 80%;
    }
    .nodata{
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .padtb20{
        padding: 20px 0;
    }
    .fz18{
        font-size: 18px;
    }
    .red{
        color: red;
        font-weight: bold;
    }
    .qrcodeDialog{
        width: 200px;
        height: 200px;
        background-size: 200px 200px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .paybtn{
        width: 180px;
        height: 44px;
        color: white;
        font-size: 16px;
        background: #0A8DE4;
        border-radius: 22px;
    }
    .relative{
        position: relative;
    }
    .maincontent{
        width: 100%;
        /* position: relative; */
    }
    .pagerow{
        padding-top: 50px;
        padding-bottom: 70px;
        width: auto !important;
    }
    .checkalltext{
        color: #666666;
        font-size: 18px;
        padding-left: 10px;
    }
    .pl32{
        padding-left: 32px;
    }
    .checkall{
        width: 20px;
        height: 20px;
        border: 1px solid #bdbdbd;
    }
    .padtb32{
        padding:32px 0;
    }
    .mt30{
        margin-top: 30px;
    }
    .el-checkbox{
        display: flex;
        align-items: center;
    }
    /* checkbox label字体大小 */
    >>>.el-checkbox__label{
        font-size: 16px;
        color: #99A0A3;
    }
    >>>.el-checkbox__inner {
        width: 20px;
        height: 20px;
          
    }
    >>>.el-checkbox__inner::after{
        height: 12px;
        left: 7px;
    }
    .money{
        color:#E62019;
        font-family: Microsoft YaHei;
    }
    .l_border{
        border-left: 1px solid #CCCCCC;
    }
    .arrow{
        width: 90px;
        height: 10px;
    }
    .stateText{
        padding-top: 12px;
        color: #0A8DE4;
    }
    .ptb30{
        padding: 30px 0;
    }
    .bg_lightblue{
        background-color: #e2eff6;
    }
    .t_order{
        padding: 20px 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #99A0A3;
    }
    .orderdemo{
        width: 80%;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .l_inputrow{
        width: 252px;
        height: 40px;
        background-color: #F1F1F1;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .searchbtn{
        width: 80px;
        height: 38px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: #0A8DE4;
        color: white;
        font-size: 16px;
    }
    .input{
        width: 200px;
        font-size: 14px;
        background-color: #F1F1F1;
        border: none;
        padding-left: 16px;
        /* text-indent: 16px; */
        /* padding:14px 0; */
    }
    .tablist{
        width: 100%;
        border-bottom: 1px solid #D9D9D9;
    }
    .tab{
        padding:0 26px;
    }
    .taboption{
        padding: 24px 20px;
        border-bottom: 2px solid #007ec5;
        font-size: 18px;
    }
    ._taboption{
        padding: 24px 20px;
        font-size: 18px;
    }

</style>
