//获取前一天，后一天，当天
/**
 * 
 * @param {天数} addCount 
 * @param {返回格式} style 0：2017-07-12格式; 1：2017年7月12日; 
 * @param {是否返回年份} isYear 
 */
function getDate(addCount = 0, style = 0, isYear = true) {
    let date = new Date()
    date.setDate(date.getDate() + addCount)
    let Y = date.getFullYear() + (style == 0 ? '-' : '年');
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + (style == 0 ? '-' : '月');
    let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + (style == 0 ? '' : '日');
    let str = isYear ? (Y + M + D) : (M + D)
    return str
}
/**
 * 出入库时间点可选择范围：一天时间点的选择是9点-17点；当前时间超过1分钟时，只能选择往后2个小时的时间点；当前时间为整点时，只能选择往后1小时的时间点
 * newDate：过滤的时间，当天 new date()
 * starH：开始时间点
 * endH：结束时间点
 */
function getBeginDateAndHoursArray(date, time, hoursRangk) {
    const timeArray = hoursRangk ? hoursRangk : ['09', '10', '11', '12', '13', '14', '15', '16', '17'];
    let nowDateTime = new Date()//'2021-04-01 15:00'
    let returnDate = getDate(0)//当前日期
    if (date) {
        let pullTime = new Date(date).getTime()
        let nowTime = nowDateTime.getTime()
        if (pullTime > nowTime && !time) {
            return { beginDate: date, timeRang: timeArray, timeIndex: 0 }
        } else if (pullTime > nowTime && time) {
            let index = timeArray.findIndex(i => time === i)
            return { beginDate: date, timeRang: timeArray, timeIndex: index }
        }
    }
    let startH = parseInt(timeArray[0]) - 2
    let endH = parseInt(timeArray[timeArray.length - 1]) - 1
    let hours = 0
    let minutes = 0
    if (nowDateTime) {
        hours = nowDateTime.getHours()
        minutes = nowDateTime.getMinutes()
    }
    if (minutes == 0) {//整点
        let timeRang = timeArray
        let beginDate = returnDate
        if (hours > endH) { //超过时间点段倒数第二个时间点
            beginDate = getDate(1)//第二天日期
        }
        if (hours > startH && hours <= endH) {
            let newHours = hours + 1
            let hoursSplit = String(/^\d$/.test(newHours) ? '0' + newHours : newHours)
            let index = timeArray.findIndex(i => hoursSplit === i)
            timeRang = timeArray.slice(index, timeArray.length)
        }
        return { beginDate: beginDate, timeRang: timeRang, timeIndex: 0 }
    } else {
        let timeRang = timeArray
        let beginDate = returnDate
        if (hours >= startH && hours < endH) {
            let newHours = hours + 2
            let hoursSplit = String(/^\d$/.test(newHours) ? '0' + newHours : newHours)
            let index = timeArray.findIndex(i => hoursSplit === i)
            timeRang = timeArray.slice(index, timeArray.length)
        }
        if (hours >= endH) {
            beginDate = getDate(1)//第二天日期
        }
        return { beginDate: beginDate, timeRang: timeRang, timeIndex: 0 }
    }
}
module.exports = {
    getBeginDateAndHoursArray
}