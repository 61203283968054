<template>
   <div id="myorderPage" class="flex">
        <sidebar class="_width25"></sidebar>
        <div class="_width75">
            <slot></slot>
        </div>
   </div>
</template>

<script>
import sidebar from './sidebar.vue'
export default {
    components: {
        sidebar
    },

    data() {
        return {
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
    }

}

</script>
<style scoped>
    ._width75{
        width: 75%;
    }
    ._width25{
        width: 25%;
    }
    

</style>
