<template>
   <div id="couponPop" v-if="isShow">
        <div class="pop">
            <div class="flex xycenter rangeDesc">{{coupon.rangeDesc}}</div>
            <div class="popTitle xycenter">{{coupon.couponName}}</div>
            <div class="couponNo flex xycenter">
                <span class="symbol">{{coupon.couponTypeName}}</span>
                <span class="ml20">NO.{{coupon.couponNum}}</span>
            </div>
            <div class="detail">
                <div class="couponDetail mb20 flex">
                    <div class="flex1">有效期:</div>
                    <div class="flex1 xright">{{coupon.beginTime}}-{{coupon.endTime}}</div>
                </div>
                <view v-if='coupon.couponType=="YHQLX03"'>
                    <span>使用条件：</span>
                    <span>满{{coupon.discountValueAppend}}元可用</span>
                </view>
                <div class="couponDetail flex">
                    <span>使用说明:</span>
                    <div class="flex1 xright">{{coupon.couponDesc}}</div>
                </div>
            </div>
            <div class="flex xycenter btn" @click="_confirm">确定</div>
        </div>

   </div>
</template>

<script>
export default {

    components: {

    },
    props:[
        'coupon','isShow'
    ],

    data() {
        return {
            
        }
    },

    created() {

    },


    mounted() {
        console.log('打印isShow',this.isShow)

    },

    methods: {
        _confirm:function(){
            this.$emit('confirm',false);
        }
    }

}

</script>
<style scoped>
    #couponPop{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        border-radius: 12px;
    }
    .detail{
        padding: auto;
    }
    .ml20{
        margin-left: 20px;
    }
    .rangeDesc{
        font-size: 30px;
    }
    .popTitle{
        font-size: 50px;
    }
    .pop{
        width: 690px;
        padding-top: 60px;
        background-color: white;
        border-radius: 12px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .couponNo{
        padding: 26px 0 30px 0;
        font-size: 26px;
        border-bottom: 1px dashed #ccc;
        margin: 0 30px;
    }
    .detail,
    .detail2{
        padding: 30px;
        color: #999999;
        font-size: 28px;
    }
    .symbol{
        padding: 6px 4px;
        color: white;
        background-color: #e61d3a;
        border-radius: 8px;
    }
    .mb20{
        margin-bottom: 20px;
    }
    .btn{
        width: 100%;
        height: 88px;
        font-size: 32px;
        color: #e61d3a;
        border-top: solid #dcdcdc 1px;
        border-radius: 12px;
    }

</style>
