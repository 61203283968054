<template>
  <div class="tabItems">
    <div class="Tab_tittle_wrap" @click="tabswitch">
      <!-- :style="{ width: 100 / tabTitle.length + '%' }" -->
      <span
        v-for="(v, i) in tabTitle"
        :key="i"
        :class="isShowTab == i ? 'router-link-active' : ''"
        >{{ v }}</span
      >
    </div>
    <div class="Tab_item_wrap">
      <slot></slot>
    </div>
  </div>
</template>
 
<style lang="less" scoped>
.tabItems {
  .Tab_tittle_wrap {
    display: inline-block;
    background: #87bddc;
    border-radius: 1rem;
    margin: 50px 0;
    span {
      display: inline-block;
      text-align: center;
      padding: 0 40px;
      color: #fff;
      //   line-height: 3.75rem;
      font-size: 18px;
      line-height: 45px;
      cursor: pointer;
      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
        border-radius: 0 1rem 1rem 0;
      }
    }
    .router-link-active {
      background: #0a8de4;
    }
  }
  .Tab_item_wrap {
    width: 100%;
    z-index: 0;
    text-align: left;
    overflow-x: hidden;
  }
  .hide {
    display: none;
  }
  .showAnminous {
    display: block;
  }
}
</style>
<script>
export default {
  data() {
    return {
      tabTitle: [],
      isShowTab: 0,
    };
  },
  created: function () {
    // let is = sessionStorage.getItem("isTabShow");
    // if (is) {
    //   this.isShowTab = is;
    // } else {
    //   let URL = libUtils.GetURLParamObj();
    //   this.isShowTab = URL.isShowTab ? URL.isShowTab : "0";
    // }
  },
  mounted() {
    let slot = this.$slots.default;
    for (let i = 0; i < slot.length; i++) {
      if (slot[i].tag == "div") {
        this.tabTitle.push(slot[i].data.attrs.name);
        if (slot[i].elm) {
          slot[i].elm.className = "hide";
          if (this.isShowTab == i) {
            slot[i].elm.className = "";
          }
        }
      }
    }
  },
  methods: {
    tabswitch() {
      if (!event) return;
      let target = event.target;

      if (target.nodeName.toLowerCase() !== "span") {
        return;
      }

      let len = target.parentNode.children;
      for (let i = 0; i < len.length; i++) {
        len[i].index = i;
        len[i].removeAttribute("class");
      }
      target.setAttribute("class", "router-link-active");
      this.isShowTab = target.index;

      //tabItems
      let child = this.$el.children[1].children;
      for (let k = 0; k < child.length; k++) {
        child[k].className = "hide";
        if (k == target.index) {
          child[k].className = "showAnminous";
        }
      }
      try {
        sessionStorage.setItem("isTabShow", target.index);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>