<template>
   <div id="m_kefu">
        <headNav></headNav>
       <div class="flex xycenter yrow box">
           <img :src="phoneIcon" class="icon">
           <div class="fs36 mt30">客服电话:{{phone}}</div>
           <div>
               <div class="btn flex xycenter fz28 mt30">
                    <a :href="'tel:'+phone">拨打电话</a>
               </div>

           </div>
       </div>
   </div>
</template>

<script>
import headNav from '../../components/mobile/headNav.vue';
export default {

    components: {
        headNav

    },

    data() {
        return {
            phoneIcon:require('../../assets/images/mobile/call.png'),
            phone:'400-868-5656'
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
    }

}

</script>
<style scoped>
    .box{
        position: fixed;
        top:30%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .mt30{
        margin-top: 30px;
    }
    .icon{
        width: 204px;
        height: 204px;
    }
    .icon-dianhua1{
        font-size: 120px;
        color: white;
    }
    .fz28{
        font-size: 28px;
    }
    .fs36{
        font-size: 36px;
    }
    .btn{
        width: 480px;
        height: 90px;
        border-radius: 45px;
        font-size: 36px;
        background-color: #007EC5;
        color: white;
        /* background: -webkit-linear-gradient(bottom,#FFCC84,#FFE3B4);
        color: #B93A13; */
    }
    a:visited{
        color: white;
        text-decoration: underline; 
    }
    a:hover{
        color: white;
        text-decoration: none; 
    }
    a:actived{
        color: white;
        text-decoration: none; 
    }
    a:link{
        color: white;
        text-decoration: none; 
    }

</style>
