<template>
   <div id="sidebar">
       <div class="h136 fs24 xycenter b_border">订单查询</div>
       <div @click="skipTo(item.path)" v-for="(item,index) in routeArr" :key="item.meta.name" :class="$route.path.includes(item.path)||$route.path.includes(item.path2)?'active xycenter h96 fs22':'xycenter h96 fs22'" style="cursor: pointer;">
           {{item.meta.name}}
       </div>
        
   </div>
</template>

<script>
export default {
    name:'sidebar',
    components: {
    },

    data() {
        return {
            routeArr:[
            {
                path: 'p_transOrder',
                path2:'p_storeOrder',
                name: 'p_transOrder',
                meta:{
                type:'pc',
                name:'我的订单',
                },
            },
            {
                path: 'p_coupon',
                path2:'2222222222',
                name: 'p_coupon',
                meta:{
                type:'pc',
                name:'优惠券'
                }
            },
            {
                path: 'p_unpaid',
                path2:'222222222',
                name: 'p_unpaid',
                meta:{
                type:'pc',
                name:'待支付'
                }
            },
            ]
        }
    },

    created() {
        console.log('sidebar初始化',this.$route.path)
        this.matchRoute();
    },


    mounted() {
    },

    methods: {
        skipTo:function(route){
            console.log("路由跳转")
            this.$router.push(route)
        },
        // 配置路由
        matchRoute:function(){
            const that=this;
            // const vueRouter=that.$router.options.routes;
            // try{
            //     vueRouter.forEach((item,index)=>{
            //         if(item.name=='p_myorder'){
            //             that.routeArr=item.children;
            //             that.routeArr.pop();
            //             console.log("打印当前侧边栏",that.routeArr)
            //             throw('结束循环')
            //         }
            //     })
            // }catch(e){
            //     console.log("循环报错了",e)
            // }
            
        }
    }

}

</script>
<style scoped>
    .active{
        color: #0A8DE4;
    }
    .fs22{
        font-size: 22px;
    }
    .b_border{
        border-bottom:1px solid #d9d9d9;
    }
    .fz18{
        font-size: 18px;
    }
    .fs24{
        font-size: 24px;
    }
    #sidebar{
        width: 25%;
        min-height: 1059px;
        background-color: #e6e6e6;
    }
    .h96{
        width: 100%;
        height: 96px;
    }
    .h136{
        width: 100%;
        height: 136px;
    }

</style>
