module.exports={
    // zzx
    login:'/portal/ursMember/login',
    wxlogin:'/portal/weixin/wxLogin',//微信授权登录
    getUser:'/portal/weixin/getUser',//获取用户信息
    coupon:'/portal/ursMember/couponApply',
    pageList:'/portal/order/pageList',//我的订单-运输订单列表,
    pageOrderList:'/portal/order/pageOrderList',//运输订单列表,针对PC端分页使用
    storagePage:'/portal/depotOut/storagePage',//我的订单-仓储订单
    batchOrderDelete:'/portal/order/batchOrderDelete',//运输订单撤销接口
    getMyCoupons:'/portal/couponApply/getMyCoupons',//我的订单-优惠券列表
    unpaylist:'/portal/order/page',//我的订单-待支付列表
    orderDetail:'/portal/order/findOrderDetail',//运输订单-订单详情
    getById:'/portal/depotEnter/getById',//入库单-订单详情
    outGetById:'/portal/depotOut/getById',//出库单-订单详情
    enterPricing:'/portal/depotBilling/enterPricing',//入库单-费用详情
    selectPricing:'/portal/depotBilling/selectPricing',//出库订单-费用详情
    paycenter:'/portal/order/orderReceiptUrlNew',//支付中心
    getPayqrcode:'/portal/order/getPayOrderUrl',//获取支付二维码
    bigTicketExpress:'/portal/homePage/bigTicketExpress',
    sendIdentifyingCode:'/portal/ursMember/sendIdentifyingCode',//登录获取验证码
    getImage:'/portal/webPageBannerMaintain/page',//获取图片，banner图和广告图等等后台维护的图片
    statisticOrderState:'/portal/order/statisticOrderState',//获取订单数量,
    scanCallback:'/portal/weixin/scanCallback',
    orderRefundPage:'/portal/order/orderRefundPage',//退款列表
    unifiedOrderParameters:'/portal/order/unifiedOrderParameters',//发起微信支付
    wxLogin:'/portal/weixin/wxLogin',//微信授权登录
    queryTbOrderNodeList:'/portal/tbOrderNode/list',//轨迹信息
    inStoreCancel:'portal/depotEnter/cancel',//入库单撤销
    outStoreCancel:'portal/depotOut/changDepotOutState',//出库单撤销
    //lxj
    addGoods:'/portal/commodity/save',//入库-保存货品信息
    entryGoodList:'/portal/commodity/page',//入库-货物列表
    entryCity:'/portal/depotEnter/getAreaFilter',//入库-仓库省市区
    entryDepot:'/portal/depot/depotEnterNames',//入库-仓库
    createCustomer:'/portal/buyerCorp/createCustomer',//入库-客户不存在时，新增客户
    entrySave:'/portal/depotEnter/save',//入库单保存
    outCity:'/portal/depotOut/getAreaFilter',//出库-仓库省市区
    outDepot:'/portal/depot/page',//出库-仓库
    outGoodList:'/portal/depotStock/findDepotOutSelectStock',//出库-货物列表
    findAddedValuesInit:'/portal/depotBilling/findAddedValuesInit',//出库-增值服务
    outSave:'/portal/depotOut/save'
    
}