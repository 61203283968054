<template>
   <div class="headNav flex" v-if="!wxBrower()">
        <div class="l_headNav xycenter">
            <img :src="backicon" class="backicon" @click="goback">
        </div>
        <div class="flex1 xycenter">{{$route.meta.title}}</div>
        <div class="r_headNav flex xycenter">
            <i class="iconfont icon-shouye1" @click="skip_index"></i>
        </div>
   </div>
</template>

<script>
export default {

    components: {

    },

    data() {
        return {
            backicon:require('../../assets/images/mobile/back.png')
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        // 跳转回首页
        skip_index:function(){
            this.envJump('','href',0)
        },
        goback:function(){
            const that=this;
            history.go(-1);
            // that.$router.go(-1);
        },
        wxBrower:function(){
            let viewer = window.navigator.userAgent.toLowerCase();
            if(viewer.match(/MicroMessenger/i) == 'micromessenger'){
                return true;
            }else{
                return false;
            }
        }
    }

}

</script>
<style scoped>
    .headNav{
        width: 100%;
        height: 128px !important;
        border-bottom: 1px solid #E8E8E8;
        color:#000000;
        font-size: 36px;
        font-family: Source Han Sans CN;
        background-color: white;
    }
    .icon-shouye1{
        color: #007EC5;
        font-size: 56px;
    }
    .backicon{
        width: 18px;
        height: 34px;
    }
    .l_headNav,
    .r_headNav{
        width: 120px;
    }

</style>
