<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="1000px"
    class="border_h batch_form"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <div class="overflow_y" :style="{ maxHeight: innerHeight + 'px' }">
      <div v-show="dialogStep === 1">
        <BatchOrderStepOne
          ref="batchStepOne"
          :isBatch="true"
          :stepOneArr="newFaArr"
          @faFunc="sonFunc"
          @changeStepArr="changeStepArrSon"
          :faIdx="faIdx"
          :isLock="newFaArr.dzdState === '0'"
          :dialogVisible="dialogVisible"
        ></BatchOrderStepOne>
      </div>
      <div v-show="dialogStep === 2">
        <StepTwo
          ref="StepTwo"
          :stepTwoArr="newFaArr"
          @faFunc="sonFunc"
          @changeStepArr="changeStepArrSon"
          :isBatch="true"
          :faIdx="faIdx"
          :isLock="newFaArr.dzdState === '0'"
        ></StepTwo>
      </div>
      <div v-show="dialogStep === 3">
        <StepThree
          ref="StepThree"
          :stepThreeArr="newFaArr"
          @faFunc="sonFunc"
          @changeStepArr="changeStepArrSon"
          :isBatch="true"
          :faIdx="faIdx"
          :isLock="newFaArr.dzdState === '0'"
        ></StepThree>
      </div>
      <div v-show="dialogStep === 4">
        <StepFour
          ref="StepFour"
          :stepFourArr="newFaArr"
          @faFunc="sonFunc"
          @changeStepArr="changeStepArrSon"
          :isBatch="true"
          :faIdx="faIdx"
          :isLock="newFaArr.dzdState === '0'"
          @chagneDialogVisible="isShow"
        ></StepFour>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import BatchOrderStepOne from "./batchOrderStepOne.vue";
import StepTwo from "./orderStepTwo.vue";
import StepThree from "./orderStepThree.vue";
import StepFour from "./orderStepFour.vue";
import { getBatchOrderForm } from "@/assets/js/storage";
export default {
  props: {
    faArr: {
      type: Object,
      required: true,
    },
    faIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: true,
      dialogStep: 1,
      newFaArr: this.faArr,
      innerHeight: document.documentElement.clientHeight - 220,
    };
  },
  watch: {
    faArr(newVal, oldVal) {
      this.newFaArr = { ...newVal };
    },
  },
  components: {
    BatchOrderStepOne,
    StepTwo,
    StepThree,
    StepFour,
  },
  // zzx
  computed: {
    // 动态实时更新state数据
    updateState: function () {
      return this.$store.state.batchConForm;
    },
  },
  methods: {
    changeStepArrSon(val) {
      let batchForm = getBatchOrderForm();
      if (batchForm.shList) {
        batchForm.shList[this.faIdx] = {
          ...batchForm.shList[this.faIdx],
          ...val,
        };
        this.newFaArr = batchForm.shList[this.faIdx];
        this.$emit("changeStepArrFa", this.updateState);
      }
    },
    isShow(flag, datas) {
      this.dialogVisible = flag !== "undefined" ? flag : true;
      this.dialogStep = 1;
    },
    sonFunc(val) {
      this.dialogStep = val;
    },
  },
};
</script>
<style scoped>
/* .border_h >>> .el-dialog__header {
  border-bottom: 1px solid #f3f3f3;
} */
.border_h >>> .el-dialog__close {
  font-size: 20px;
  color: #282828;
}
.overflow_y {
  overflow-y: auto;
}
</style>