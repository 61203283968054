<template>
   <div class="tabList flex">
       <div :class="item.active?'flex1 xycenter on_bg':'flex1 xycenter off_bg'" v-for="(item,index) in tabArr" @click="select(index)">{{item.name}}</div>
   </div>
</template>

<script>
export default {
    components: {

    },
    props:[
        'type'
    ],

    data() {
        return {
            tabArr:[
                {name:'运输订单',active:false,type:0},
                {name:'仓储订单',active:false,type:1}
                
            ],
            // key:0//0是运输订单,1是仓储订单
        }
    },

    created() {
        this.renderTab();
    },


    mounted() {

    },

    methods: {
        // 根据订单列表type值动态渲染哪个tab是高亮
        renderTab:function(){
            const that=this;
            that.tabArr.forEach((item,index)=>{
                if(that.type==item.type){
                    that.tabArr[index].active=true;
                    // that.$emit('selectTab',idx)
                }else{
                    that.tabArr[index].active=false;
                }
            })
        },
        select:function(idx){
            const that=this;
            that.tabArr.forEach((item,index)=>{
                if(index==idx){
                    that.tabArr[idx].active=true;
                    that.$emit('selectTab',idx)
                }else{
                    that.tabArr[index].active=false;
                }
            })
        }
    }

}

</script>
<style scoped>
    .tabList{
        width: 100%;
        height: 78px;
    }
    .on_bg{
        background: #007EC5;
        color:white;
        font-size: 28px;
        font-weight: 500;
    }
    .off_bg{
        background: #87BCDC;
        font-size: 28px;
        color:white;
    }

</style>
