<template>
    <div id="m_inStoreDetail">
        <headNav class="headNav"></headNav>
        <!-- 订单信息 -->
        <div class="pad20 mt128">
            <div class="pt20 pb20">订单信息</div>
            <div class="board">
                <div class="row flex">
                    <div class="flex1">客户名称</div>
                    <div class="flex1 xright">{{detail.companyName}}</div>
                </div>
                <div class="row flex">
                    <div class="flex1">预计提货时间</div>
                    <div class="flex1 xright">{{detail.takeTime}}</div>
                </div>
                <div class="row flex">
                    <div class="flex1">出库方式</div>
                    <div class="flex1 xright">{{detail.deliveryTypeName}}</div>
                </div>
                <div class="row flex">
                    <div class="flex1">付款方式</div>
                    <div class="flex1 xright">{{detail.paymentTypeName}}</div>
                </div>
            </div>
            <!-- 物流信息 -->
            <div>
                <div class="pt20 pb20">物流信息</div>
                <div class="board">
                    <div class="row flex">
                        <div class="flex1">配送方式</div>
                        <div class="flex1 xright">{{detail.deliveryTypeName}}</div>
                    </div>
                    <div class="row flex">
                        <div class="flex1">提货司机</div>
                        <div class="flex1 xright">{{detail.driverName}}</div>
                    </div>
                    <div class="row flex">
                        <div class="flex1">联系方式</div>
                        <div class="flex1 xright">{{detail.driverPhone}}</div>
                    </div>
                    <div class="row flex">
                        <div class="flex1">车牌号</div>
                        <div class="flex1 xright">{{detail.licenseNumber}}</div>
                    </div>
                </div>
            </div>
            <!-- 入库清单 -->
            <div>
                <div class="pt20 pb20">商品明细</div>
                <div class="flex">
                    <div class="flex1 flex xycenter tableHeader fz28" v-for="(item,index) in tableHeader">{{item.name}}</div>
                </div>
                <div class="flex goodListRow pt20 pb20 b_border" :key="item.code" v-for="(item,index) in detail.depotStock">
                    <div class="flex1 omit">{{item.code}}</div>
                    <div class="flex1 omit">{{item.name}}</div>
                    <div class="flex1 omit">{{item.productionTime}}</div>
                    <div class="flex1 omit">{{item.enterNumber}}</div>
                </div>
            </div>
            <!-- 合计 -->
         <div class="xright fz18 padtb28 red pt20">
            合计:{{detail.totalPakages}}箱、{{detail.totalWeight}}kg、{{detail.totalVolume}}方
        </div>
        <!-- 费用明细 -->
        <div>
            <div class="pt20 pb20">费用明细</div>
            <!-- 租仓费 -->
            <div class="baserow">
                <div class="flex pad20">
                    <div class="flex1">租仓费</div>
                    <div class="flex1 xright">{{!!cost.zcfFeeList?cost.zcfFeeList:''}}元</div>
                </div>
                <div v-for="(item,index) in cost.zcfArr" :key="item.commodityCode" class="t_border">
                    <div class="flex pl20 pt20 pr20">
                        <div class="flex1">商品名称:{{item.commodityName}}</div>
                        <div class="flex1">入库日期:{{moment(item.warehousingTime).format('YYYYMMDD')}}</div>
                    </div>
                    <div class="flex pl20 pt20 pr20">
                        <div class="flex1">天数:{{item.storageDays}}</div>
                        <div class="flex1">托盘数:{{item.palletNumber}}</div>
                    </div>
                    <div class="flex pl20 pt20 pr20 pb20">
                        <div class="flex1">单价:{{item.sellRate}}</div>
                        <div class="flex1"></div>
                    </div>
                </div>
            </div>
            <!-- 装卸费 -->
            <div class="baserow">
                <div class="flex pad20">
                    <div class="flex1">装卸费</div>
                    <div class="flex1 xright">{{!!cost.ZXF?cost.ZXF:''}}元</div>
                </div>
                <div v-for="(item,index) in cost.zxfArr" :key="item.commodityCode" class="t_border">
                    <div class="flex pad20">
                        <div class="flex1">吨数:{{item.number}}吨</div>
                        <div class="flex1">单价:{{item.sellRate}}元</div>
                    </div>
                </div>
            </div>
            <!-- 合计 -->
            <div class="flex pad20 baserow">
                <div class="flex1">合计</div>
                <div class="flex1 xright red">{{fee.totalFee}}元</div>
            </div>

        </div>
         </div>
         
         
         
 
    </div>
 </template>
 
 <script>
import headNav from '../../components/mobile/headNav.vue';
 const API=require('/src/assets/js/apilist.js');
 export default {
 
     components: {
        headNav
 
     },
 
     data() {
         return {
             cost:{},
             detail:{},//详情
             fee:{},//各种费用
             tableHeader:[
                 {name:'商品编号'},
                 {name:'商品名称'},
                 {name:'生产日期'},
                 {name:'入库数量'}
             ],
             // costHeader:[
             //     {name:'商品名称'},
             //     {name:'入库日期'},
             //     {name:'天数'},
             //     {name:'托盘数'},
             //     {name:'单价'}
             // ],
             // costHeader2:[
             //     {name:'吨数'},
             //     {name:'单价'},
             // ],
             goodList:[],//商品列表
             
         }
     },
 
     created() {
         this.getOrderId();
     },
 
 
     mounted() {
         
 
     },
 
     methods: {
         // 根据feeList根据feeType进行归类拆分数据组
        feeSortOut:function(arr){
            const that=this;
            let obj={
                zxfArr:[],
                zcfArr:[],
                otherArr:[]
            }
            arr.forEach(item=>{
                switch(item.feeType){
                    case 'ZCF':
                    obj.zcfArr.push(item);
                    break;
                    case 'ZXF':
                    obj.zxfArr.push(item);
                    break;
                    default:
                    obj.otherArr.push(item); 
                    break;  
                }
            })
            console.log("构造数据组的obj",obj)
            return obj;
        },
         // 出库数量：单位为托时，转为箱:每托码货数量*出库数量
        changeUnit:function(arr){//arr为商品清单,返回以quantityArr,depotEnterNoArr,commodityIdArr的对象
            let quantityArr=[];
            let depotEnterNoArr=[];
            let commodityIdArr=[];
            let obj={};
            let newOutNumber = 0
            arr.forEach(item => {
                if (item.specifications == '2') {//出库数量：单位为托时，转为箱:每托码货数量*出库数量
                    newOutNumber = parseFloat(item.outNumber || 0) * parseFloat(item.number || 0);
                } else {
                    newOutNumber = parseFloat(item.outNumber || 0)
                }
                quantityArr.push(newOutNumber)
                depotEnterNoArr.push(item.depotEnterNo)
                commodityIdArr.push(item.commodityId)
            });
            obj.quantity=quantityArr.join();
            obj.depotEnterNo=depotEnterNoArr.join();
            obj.commodityId=commodityIdArr.join();
            return obj
        },
        getOrderId:function(){
            const that=this;
            that.$axios('get',API.outGetById+'?id='+that.$route.query.id)
            .then((res)=>{
                console.log("获取出库单详情成功",res)
                that.detail=res.data;
                console.log("打印商品列表",that.detail.depotStock)
                var goods=that.changeUnit(that.detail.depotStock);
                console.log("打印转化后的goods",goods)
                var params={
                    depotId:res.data.depotId,
                    commodityId: goods.commodityId,
                    depotEnterNo: goods.depotEnterNo,
                    quantity: goods.quantity,
                    takeTime: res.data.takeTime,
                    feeCode: 'ZCF,ZXF',
                    number: res.data.totalPakages,
                    volume: res.data.totalVolume,
                    weight: res.data.totalWeight,
                    status: 1
                }
                that.$axios('get',API.selectPricing,params,'params','application/json').then((res)=>{
                    console.log("获取出库单费用详情",res)
                    that.fee=res.data;//费用列表
                    that.cost=that.feeSortOut(res.data.feeList);//
                }).catch((error)=>{

                })
            })
            .catch((error)=>{
                console.log("获取订单详情成功",error)
            })

            
        },
 
     }
 
 }
 
 </script>
 <style scoped>
     #m_inStoreDetail{
         /* padding:20px; */
         font-size: 28px;
     }
     .headNav{
        width: 100%;
        position: fixed;
        top: 0px;
         
     }
     .mt128{
         margin-top: 128px;
     }
     .red{
         color:#B93A13;
     }
     .board{
         border-radius: 12px;
         -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
         box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
     }
     .baserow{
         margin-top: 20px;
         background-color: white;
         border-radius: 12px;
         -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
         box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
     }
     .bgwhite{
         background-color: white;
     }
     .pb20{
         padding-bottom: 20px;
     }
     .pl20{
         padding-left: 20px;
     }
     .pr20{
         padding-right: 20px;
     }
     .pt{
         padding-top: 20px;
     }
     .box{
         background-color: white;
     }
     .b_border{
         border-bottom: 1px solid #dcdcdc;
     }
     .t_border{
         border-top: 1px solid #dcdcdc;
     }
     .goodListRow{
         background-color: white;
     }
     .omit{
         /* width: 60px; */
         text-align: center;
         overflow:hidden;
         text-overflow:ellipsis;
         white-space:nowrap;
      }
     .tableHeader{
         background-color: #007EC5;
         color: white;
         padding: 10px 0;
     }
     .board{
         background-color: white;
     }
     .row{
         font-size: 28px;
         padding:20px;
     }
     .fz28{
         font-size: 28px;
     }
     .pad20{
         padding: 20px;
     }
 
 </style>
 