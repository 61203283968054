/**MY下单专用 */
import moment from 'moment'
import $store from '../../store/index'
import {
  dateRec,
  $axios,
  getStorage,
  setStorage,
  priceKG,
  priceT,
  isMobile
} from '@/assets/js/global'
import {
  getOrderForm,
  setOrderForm,
  getUserStore,
  removeOrderForm,
  setBatchOrderForm,
  getBatchOrderForm,
  getAreaFilterVersion,
  setAreaFilterVersion,
  getAreaFilterList,
  setAreaFilterList
} from '@/assets/js/storage'
import { delCookie } from '@/assets/js/cookies.js'
let userData = getUserStore() //登陆信息
/**
 * 极速下单：订单步骤条下一步数据保存
 * formName:(String) 表单ref名称
 * self：this指向
 **/
export function submitForm(formName, self) {
  self.$refs[formName].validate((valid, event) => {
    let datas = self.newStepArr || {}
    if (valid) {
      setOrderForm(datas)
      self.$emit('changeStepArr', datas)
      if ($store.state.stepAction++ > 4) return //Step调整
    } else {
      return false
    }
  })
}

/**
 * 批量下单：订单步骤条下一步数据保存
 * formName：(String) 表单ref名称
 * self：this指向
 * stepArr：(Object) 单条数据对象
 * idx：(Number) 批量下单的数据下标
 * goPage：(Number) 步骤条跳转的页码
 **/
export function submitBatchForm(self, formName, stepArr, faIdx, goPage) {
  self.$refs[formName].validate((valid, event) => {
    if (valid) {
      self.$emit('faFunc', goPage)
      saveStorage(self, stepArr, true, faIdx)
    } else {
      return false
    }
  })
}

/***
 * 根据下单类型作数据存储：
 * datas：单个订单数据；
 * isBatch：true时为批量下单，否则为极速下单；
 * faIdx：批量下单下标;
 * ****/
export function saveStorage(self, datas, isBatch, faIdx) {
  if (!isBatch) {
    //极速下单
    setOrderForm(datas)
    $store.state.ruleForm = datas
    self.$emit('changeStepArr', datas)
  } else {
    //批量下单
    let batchDatas = getBatchOrderForm()
    if (faIdx != undefined) {
      batchDatas.shList[faIdx] = {
        ...batchDatas.shList[faIdx],
        ...datas
      }
    }
    $store.state.batchConForm = batchDatas
    self.$emit('changeStepArr', batchDatas.shList[faIdx])
    setBatchOrderForm(batchDatas)
  }
}

/**
 * 获取新的增值服务string格式
 * stepArr：(Object) 单条数据
 * isBatch：(Boolean) 是否为批量下单
 * stepArr：(Object) 单条数据对象
 * idx：(Number) 批量下单的数据下标
 * goPage：(Number) 步骤条跳转的页码
 **/
export function getZengZhiStr(stepArr, isBatch, faIdx) {
  const { addedService, mtp, services, pickService } = stepArr
  let zengZhiArr = []
  if (addedService && addedService[0] && addedService[0] !== '0') {
    addedService.map(item => {
      let data = item
      if (data === 'mtp') {
        data = 'mtp,' + (mtp || 0)
      }
      zengZhiArr.push(data)
    })
  }
  zengZhiArr = [...zengZhiArr, ...services]
  if (pickService == 'shjc') {
    zengZhiArr.push('shjc')
  }
  return zengZhiArr.join('|')
}

/**
 * string格式增值服务转为数组
 * zengZhiStr：(String) 详情返回的增值服务数据
 * ziti：(Boolean||String) 是否为自提
 * deliveryService：(Boolean||String) 是否为送货服务
 **/
export function getZengZhiArr(zengZhiStr, ziti, deliveryService) {
  const newformArr = {
    addedService: [],
    mtp: '',
    services: [],
    pickService: 'ziti'
  }
  if (zengZhiStr) {
    let newArr = zengZhiStr.split('|')
    newArr.map(item => {
      if (item === 'zh' || item === 'xh') {
        //装卸货服务
        newformArr.services.push(item)
      }
      if (item === 'mj' || item === 'bkml' || item === 'hkml') {
        newformArr.addedService.push(item)
      }
      if (item.includes('mtp')) {
        let temp = item.match(/\d+(\.\d+)?/)
        newformArr.mtp = temp[0] ? temp[0] : ''
        newformArr.addedService.push('mtp')
      }
      if (item === 'shjc') {
        newformArr.pickService = item
      }
    })
  }
  if (newformArr.pickService !== 'shjc' && ziti == '0' && deliveryService == '1') {
    newformArr.pickService = 'shsm'
  }
  if (newformArr.addedService.length === 0) {
    //选中暂不需要
    newformArr.addedService.push('0')
  }
  return newformArr
}

/**
 * 获取供应商列表
 * corpId：为客户时则渲染货物规格
 **/
export function getCargoLabelList(corpId) {
  if ($store.state.cargoLabelList.length === 0) {
    $axios(
      'post',
      '/portal/specifications/queryByCorpId',
      { corpId },
      'params',
      'application/json'
    ).then(res => {
      if (res.code === '200') {
        let datas = res.data
        const addArr = { id: '0', name: '其它', unitWeight: '', unitVolume: '' }
        if (datas.length) {
          datas.push(addArr)
        }
        $store.state.cargoLabelList = datas
      }
    })
  }
}
/**
 * 获取供应商列表
 * self： this指向
 * stepArr：(Object) 单条数据对象
 * isBatch：(Boolean) 是否为批量发货
 * sortType：(String) ：排序传值：默认mr、价格jg、时效sx
 **/
export function providerInfoAllN(self, stepArr, isBatch, sortType, callback) {
  let isNext = true //是否允许下一步
  let ruleForm = { ...stepArr }
  let drawerType = stepArr.drawerType || 'edit'
  const { selectedTags } = $store.state
  let extraParams = { ...ruleForm }
  const params = {
    orderNo: ruleForm.orderSn || '',
    endDist:
      ruleForm.recipientAddress && ruleForm.recipientAddress[2] ? ruleForm.recipientAddress[2] : '',
    recipientDetailaddress: ruleForm.recipientDetailaddress,
    sendDist:
      ruleForm.sendersAddress && ruleForm.sendersAddress[2] ? ruleForm.sendersAddress[2] : '',
    sendersDetailaddress: ruleForm.sendersDetailaddress,
    sendersDate: ruleForm.sendersDate,
    sendersTime: ruleForm.sendersTime,
    weight: priceKG(ruleForm.totalWeight),
    volume: ruleForm.totalVolume,
    goodsWeight: priceKG(ruleForm.goodsWeight),
    goodsVolume: ruleForm.goodsVolume,
    mobile: ruleForm.phoneNumber,
    goodsNum: ruleForm.totalPakages,
    deliveryService: ruleForm.pickService == 'shsm' || ruleForm.pickService == 'shjc' ? '1' : '0',
    sort: sortType ? (sortType !== 'mr' ? sortType : '') : '',
    source: '0',
    enterPage: 'msfh', //统计来源
    zengZhi: getZengZhiStr(ruleForm), //增值服务
    packingName: ruleForm.packingName || '' //包装方式
  }
  // if (JSON.stringify(params) == providerParams) {
  //   return
  // }
  self.$loading({ text: '加载数据中' })
  const oldProAndTypeStr = extraParams.providerName + '' + extraParams.productType
  const oldProviderId = extraParams.lineId || ''
  let checkedArr = {},
    checkedLindIdArr = {},
    pushArr = {} //要选中合并的数据
  $axios('post', '/portal/goodsItem/listProviderInfoAllN', params).then(res => {
    if (res.code === '200') {
      // providerParams = JSON.stringify(params)
      let datas = res.data
      if (datas.length === 0) {
        isNext = false
        tipsModal(self, 'error', '亲,系统查询不到价格,您可以拨打客服热线400-868-5656向客服询价哟~')
        self.$loading().close()
        return
      }
      let bzProvider = []
      for (let i = 0; i < datas.length; i++) {
        let dataArr = datas[i]
        if (isBatch) {
          //批量下单只有零担和航空运输才有
          if (dataArr.truckType == 'ZL1605012' || dataArr.truckType == 'ZL1605014') {
            bzProvider.push(dataArr)
          }
        } else {
          bzProvider.push(dataArr)
        }
        //非新建时，判断是否存在选中(有相同lineId选中，否则有相同供应商+产品类型选中，都无则报错供应商丢失)
        if (drawerType != 'add') {
          let newProAndTypeStr = dataArr.providerName + '' + dataArr.productType
          //临时供应商只匹配供应商名称
          if (
            extraParams.biaoQian &&
            extraParams.biaoQian === 'linShi' &&
            extraParams.providerName === dataArr.providerName
          ) {
            checkedArr = dataArr
          } else {
            //是否有选中的lineId
            if (dataArr.lineId === oldProviderId) {
              checkedLindIdArr = dataArr
            } else if (oldProAndTypeStr === newProAndTypeStr) {
              checkedArr = dataArr
            }
          }
        }
      }
      if (drawerType != 'add') {
        //清空不一定都存在数据
        delete extraParams.biaoQian
        delete extraParams.peiSongSupplierName
        delete extraParams.tiHuoSupplierName
        delete extraParams.zhuGanSupplierName
        //有选中的则赋值
        if (checkedLindIdArr.lineId) {
          checkedLindIdArr.checked = true
          pushArr = checkedLindIdArr
        } else if (checkedArr.lineId) {
          checkedArr.checked = true
          pushArr = checkedArr
        } else {
          pushArr = {
            providerId: '',
            lineId: '',
            lineHour: ''
          }
          isNext = false
          tipsModal(self, 'error', '亲，订单吨方修改失败，请联系客服修改，客服热线：400-8868-5656')
        }
      }
      //默认标签排序顺序为大票快递、大票特惠、大票特快+其他
      if (selectedTags === 'mr' && bzProvider.length >= 2) {
        let one = [],
          two = [],
          three = [],
          newObj = []
        for (let j = 0; j < bzProvider.length; j++) {
          if (bzProvider[j].biaoQian === 'zhunShiDa') {
            one.push(bzProvider[j])
          } else if (bzProvider[j].biaoQian === 'jiaGeZuiDi') {
            two.push(bzProvider[j])
          } else if (bzProvider[j].biaoQian === 'shiXiaoZuiKuai') {
            three.push(bzProvider[j])
          } else {
            newObj.push(bzProvider[j])
          }
        }
        bzProvider = [...one, ...two, ...three, ...newObj]
      }
      $store.state.providerAll = bzProvider
      if (isBatch !== undefined) {
        self.newStepArr = { ...stepArr, ...pushArr }
      }
      self.$loading().close()
    } else {
      isNext = false
      self.$loading().close()
      // tipsModal(self, 'error', "获取供应商列表失败" + res.msg || "")
    }
    self.$loading().close()
    callback && callback(isNext)
  })
}

/**
 * 获取计费信息
 * self： this指向
 * stepArr：(Object) 单条数据对象
 * isBatch：(Boolean) 是否为批量发货
 * faIdx：(Number) ：默认mr、价格jg、时效sx
 * isfetchCoupons：是否调用优惠券
 **/
export function calcTotal(self, stepArr, isBatch, faIdx, isfetchCoupons) {
  let ruleForm = { ...stepArr }
  if (isBatch) {
    let batchDatas = getBatchOrderForm()
    ruleForm = {
      ...ruleForm,
      ...batchDatas.fhArr
    }
  }
  let newFormData = {
    ...ruleForm
  }
  const params = {
    sendDist:
      ruleForm.sendersAddress && ruleForm.sendersAddress[2] ? ruleForm.sendersAddress[2] : '',
    endDist:
      ruleForm.recipientAddress && ruleForm.recipientAddress[2] ? ruleForm.recipientAddress[2] : '',
    sendersDetailaddress: ruleForm.sendersDetailaddress,
    recipientDetailaddress: ruleForm.recipientDetailaddress,
    lineId: ruleForm.lineId,
    truckType: ruleForm.truckType,
    isSHF: ruleForm.pickService === 'shsm' || ruleForm.pickService === 'shjc',
    goodsNum: ruleForm.totalPakages,
    volume: ruleForm.totalVolume,
    weight: priceKG(ruleForm.totalWeight),
    goodsVolume: ruleForm.goodsVolume,
    goodsWeight: priceKG(ruleForm.goodsWeight),
    insuranceFee: ruleForm.declaredValue || 0,
    receiptType: ruleForm.receiptType,
    carLength: ruleForm.carLength,
    couponNum: ruleForm.couponNum || '',
    payType: ruleForm.paymentType,
    zengZhi: getZengZhiStr(ruleForm),
    collectFee: ruleForm.modOrderBatchFee || '',
    sendersTime: ruleForm.sendersTime,
    mobile: ruleForm.phoneNumber,
    orderNo: ruleForm.orderNo,
    productType: ruleForm.productType || ''
  }
  $axios('POST', '/portal/order/calcTotal', params).then(res => {
    if (res.code === '200') {
      const datas = res.data
      let calcCostList = datas.calcCostDtlList
      newFormData.totalPrice = datas.totalPrice
      newFormData.lineSalePrice = datas.lineSalePrice
      calcCostList.push({ feeName: '合计', cost: datas.totalPrice, price: datas.totalPrice })
      $store.state.feeList = calcCostList
      self.newStepArr = { ...newFormData }
      if (isfetchCoupons) {
        getOrderValidCoupons(self, newFormData, isBatch, faIdx)
      }
      saveStorage(self, newFormData, isBatch, faIdx)
    }
    // else {
    //   tipsModal(self, 'error', '获取计费失败，请联系管理员' + res.msg || '')
    // }
  })
}

/**
 * 获取优惠券列表
 * self： this指向
 * ruleForm：(Object) 单条数据对象
 * isBatch：(Boolean) 是否为批量发货
 * faIdx：(Number) ：默认mr、价格jg、时效sx
 **/
export function getOrderValidCoupons(self, ruleForm, isBatch, faIdx) {
  const drawerType = ruleForm.drawerType
  let UsedCoupon = [] //批量已用优惠券
  if (isBatch) {
    let getBatchDatas = getBatchOrderForm()
    getBatchDatas = getBatchDatas.shList
    getBatchDatas.map((item, index) => {
      if (index != faIdx && item.couponNum) {
        UsedCoupon.push(item.couponNum)
      }
    })
  }
  let params = {
    orderId: ruleForm.truckType || '',
    beginProvince:
      ruleForm.sendersAddress && ruleForm.sendersAddress[0] ? ruleForm.sendersAddress[0] : '',
    beginCity:
      ruleForm.sendersAddress && ruleForm.sendersAddress[1] ? ruleForm.sendersAddress[1] : '',
    beginDistrict:
      ruleForm.sendersAddress && ruleForm.sendersAddress[2] ? ruleForm.sendersAddress[2] : '',
    endProvince:
      ruleForm.recipientAddress && ruleForm.recipientAddress[0] ? ruleForm.recipientAddress[0] : '',
    endCity:
      ruleForm.recipientAddress && ruleForm.recipientAddress[1] ? ruleForm.recipientAddress[1] : '',
    endDistrict:
      ruleForm.recipientAddress && ruleForm.recipientAddress[2] ? ruleForm.recipientAddress[2] : '',
    isZsd: ruleForm.isZsd !== 'undefined' ? ruleForm.isZsd : '',
    mobile: ruleForm.phoneNumber,
    orderId: ruleForm.orderId,
    payType: ruleForm.paymentType,
    providerId: ruleForm.providerId,
    totalPakages: ruleForm.totalPakages,
    totalPrice: ruleForm.totalPrice,
    totalVolume: ruleForm.totalVolume,
    totalWeight: priceKG(ruleForm.totalWeight),
    goodsVolume: ruleForm.goodsVolume,
    goodsWeight: priceKG(ruleForm.goodsWeight),
    truckType: ruleForm.truckType
  }
  if (UsedCoupon.length) {
    params.couponNum = UsedCoupon.join(',')
  }
  $axios(
    'POST',
    '/portal/couponApply/getOrderValidCoupons',
    params,
    'data',
    'application/json'
  ).then(res => {
    if (res.code === '200') {
      let datas = res.data.coupons
      const newCou = {
        couponNum: '',
        couponName: ''
      }
      let oldCouponNum = ruleForm.couponNum
      for (let i = 0; i < datas.length; i++) {
        if (drawerType === 'add') {
          //新建并且优惠券号码为空时
          if (datas[i].isBest === '1') {
            newCou.couponNum = datas[i].couponNum
            newCou.couponName = datas[i].couponName
          }
        }

        //修改，判断已选优惠券切换支付方式后，优惠券是否在列表里面
        if (drawerType === 'edit' && oldCouponNum) {
          if (datas[i].couponNum == oldCouponNum) {
            newCou.couponNum = datas[i].couponNum
            newCou.couponName = datas[i].couponName
          }
        }
        datas[i].checked = false
      }

      ruleForm = {
        ...ruleForm,
        ...newCou
      }
      //修改，判断已选优惠券切换支付方式后，优惠券是否在列表里面
      if (drawerType === 'edit' && oldCouponNum && !newCou.couponNum) {
        tipsModal(self, 'warning', '当前修改导致优惠券条件不适用!')
      }
      calcTotal(self, ruleForm, isBatch, faIdx)

      $store.state.couponList = datas
      saveStorage(self, ruleForm, isBatch, faIdx)
    }
    // else {
    //   tipsModal(self, 'error', '获取优惠券列表失败，请联系管理员' + res.msg || '')
    // }
  })
}

/**
 * 获取极速订单详情
 * orderSn：(String) 订单号
 **/
export function getOrderDetial(self, orderSn, drawerType, callback) {
  $axios('POST', '/portal/order/findOrderDetail', { orderSn }, 'data', 'application/json').then(
    res => {
      if (res.code === '200') {
        let datas = res.data
        datas = transferOrder(datas, drawerType === 'again')
        datas.drawerType = drawerType
        callback && callback(datas)
        setOrderForm(datas)
      }
      // else {
      //   tipsModal(self, 'error', '获取订单详情失败，请联系管理员' + res.msg || '')
      // }
    }
  )
}
/**
 * 获取批量订单详情
 * orderSn：(String) 订单号
 **/
export function getBatchOrderDetial(self, newParams, callback) {
  $axios(
    'POST',
    '/portal/order/findOrdersByParentOrderNo',
    { parentOrderNo: newParams.parentOrderNo || '', orderId: newParams.orderId || '' },
    'params',
    'application/json'
  ).then(res => {
    if (res.code === '200') {
      let datas = res.data
      let newObj = { fhArr: {}, shList: [] }
      datas.map(item => {
        let newItem = {
          ...item.order_detail,
          ...item,
          batchTotalAll: item.payment
        }
        delete newItem.order_detail
        let newFaItem = {
          parentOrderNo: newItem.parentOrderNo || '',
          companyName: newItem.companyName || '',
          phoneNumber: newItem.phoneNumber || '',
          sendersAddrId: newItem.sendersAddrId || '',
          sendersAddress: [
            newItem.sendersProvince || '',
            newItem.sendersCity || '',
            newItem.sendersDistrict || ''
          ],
          sendersCityName: newItem.sendersCityName || '',
          sendersDate: newItem.sendersDate || '',
          sendersDetailaddress: newItem.sendersDetailaddress || '',
          sendersDistrictName: newItem.sendersDistrictName || '',
          sendersName: newItem.sendersName || '',
          sendersPhone: newItem.sendersPhone || '',
          sendersProvinceName: newItem.sendersProvinceName || '',
          sendersTime: newItem.sendersTime || ''
        }
        newObj.fhArr = newFaItem
        newObj.shList.push(transferOrder(newItem))
      })
      callback && callback(newObj)
      setBatchOrderForm(newObj)
    } else {
      // tipsModal(self, 'error', '获取批量订单详情失败。' + res.msg || '')
      setTimeout(() => {
        if (isMobile()) {
          self.$router.push({ path: '/m_order' }) //跳转到订单列表页
        } else {
          self.$router.push({ path: '/p_myorder/p_transOrder' }) //跳转到订单列表页
        }
      }, 1000)
    }
  })
}

/**
 * 判断是否为可叠加优惠券/折扣券/代金券
 * selectedRows：(Object) 选中的单条优惠券数据
 * type：(String) 优惠券类型
 **/
export function getMutiType(selectedRows, type) {
  let isTrue = false
  if (typeof selectedRows === 'object' && typeof type === 'string') {
    if (selectedRows.isDiejia === '1' && selectedRows.couponType === type) {
      isTrue = true
    }
  }
  return isTrue
}

/**
 * 地址识别
 * self：this指向
 * type：(String) 地址类型：发货fa、收货sh
 * text：(String) 地址要识别的内容
 * callback: 回调智能识别返回的数据
 **/
export function getAddressDetail(self, type, text, callback) {
  let newAdr = {}
  let token = getStorage('recognitionsToken')
  if (!token) {
    //如果没存储到token则重新调取
    getAddrToken()
    return
  }
  $axios('POST', '/portal/address/addressRecognition', {
    token,
    text
  }).then(data => {
    if (data.code === '200') {
      const res = data.data
      if (res.error_msg && res.error_msg == 'Access token expired') {
        //token过期则重新请求新token
        getAddrToken()
        return
      }
      if (!res.province_code) {
        tipsModal(self, 'error', '识别不出该地址')
        return
      }
      let area = [
        res.province_code ? res.province_code.substring(0, 2) : '',
        res.city_code ? res.city_code.substring(0, 4) : '',
        res.county_code || ''
      ]
      let adr = (res.town || '') + '' + (res.detail || '')
      area = getcheckAddress(self, type, area) //检查是否存在识别的省市区code
      if (res.province_code && type === 'deliver') {
        newAdr = {
          sendersAddress: area,
          sendersProvinceName: res.province || '',
          sendersCityName: res.city || '',
          sendersDistrictName: res.county || '',
          sendersDetailaddress: adr
        }
        if (res.phonenum) {
          newAdr.sendersPhone = res.phonenum
        }
        if (res.person) {
          newAdr.sendersName = res.person
        }
      } else if (res.province_code && type === 'receive') {
        newAdr = {
          recipientAddress: area,
          recipientProvinceName: res.province || '',
          recipientCityName: res.city || '',
          recipientDistrictName: res.county || '',
          recipientDetailaddress: adr
        }
        if (res.phonenum) {
          newAdr.recipientPhone = res.phonenum
        }
        if (res.person) {
          newAdr.recipientName = res.person
        }
      }
      let turnArr = {
        type: type,
        addr: newAdr
      }
      callback && callback(turnArr)
    }
    // else {
    //   tipsModal(self, 'error', "地址识别不了" + res.msg || "")
    // }
  })
  // .catch((err) => {
  //   tipsModal(self, 'error', "智能识别地址失败，" + err.msg || "")
  // });
}

/**
 * 获取地址识别的token
 * type：(String) 地址类型：发货fa、收货sh
 **/
export function getAddrToken() {
  const params = {
    grant_type: 'client_credentials',
    clientId: 'shEkgdXfjKsKujfWi9GqLujf',
    clientSecret: 'OBqUhpZmMhIK8VO05t2SbMwcde4X6oGB'
  }
  $axios('POST', '/portal/address/getRecognitionsToken', params, 'params', 'application/json').then(
    res => {
      if (res.code === '200' && res.data && res.data.access_token) {
        setStorage('recognitionsToken', res.data.access_token)
      }
    }
  )
}

/**
 * 返回增值服务部分name值
 * code：(String)  code值
 **/
export function serverName(code) {
  switch (code) {
    case 'shsm':
      return '送货上门'
    case 'shjc':
      return '送货进仓'
    case 'ziti':
      return '自提'
    case 'ziti':
      return '自提'
    case 'ZL1605211':
      return '电子回单'
    case 'ZL1605214':
      return '纸质回单 | 普通'
    case 'ZL1605216':
      return '货物清单'
    case 'ZL1605215':
      return '无需回单'
    case 'ZL2209291':
      return '纸质回单 | 快件'
    default:
  }
}

/**
 * 增值服务的中文名称
 * datas：(Object)  单条订单数据
 **/
export function getAZengZhiName(datas) {
  const { addedServiceList, servicesList } = $store.state
  let nameArr = []
  if (datas.pickService) {
    //送货服务
    nameArr.push(serverName(datas.pickService))
  }
  if (datas.receiptType) {
    //回单服务
    nameArr.push(serverName(datas.receiptType))
  }
  //包装方式
  if (datas.addedService && datas.addedService.length && addedServiceList.length) {
    datas.addedService.map(item => {
      addedServiceList.map(val => {
        if (item != '0' && item === val.id) {
          nameArr.push(val.name)
        }
      })
    })
  }
  //装卸服务
  if (datas.services && datas.services.length && servicesList.length) {
    datas.services.map(item2 => {
      servicesList.map(val2 => {
        if (item2 === val2.id) {
          nameArr.push(val2.name)
        }
      })
    })
  }
  return nameArr
}

/**
 * 获取收货人明细显示
 * itemArr：(Object)  单条订单数据
 * flag：为true时则为只显示增值服务中文明细
 **/
export function getDetailStr(itemArr, flag) {
  let wh = [],
    arr1 = []
  let zengzhiNameArr = getAZengZhiName(itemArr)
  if (!flag) {
    //不渲染吨方件等非增值服务数据
    if (itemArr.totalWeight) {
      wh.push(priceKG(itemArr.totalWeight) + '吨')
    }
    if (itemArr.totalVolume) {
      wh.push(itemArr.totalVolume + '方')
    }
    if (itemArr.totalPakages) {
      wh.push(itemArr.totalPakages + '件')
    }
    if (itemArr.cargoTypeName) {
      arr1.push(itemArr.cargoTypeName)
    }
    if (itemArr.packingName) {
      arr1.push(itemArr.packingName)
    }
    if (wh.length) {
      arr1.push(wh.join('/'))
    }
  }
  if (zengzhiNameArr.length) {
    arr1 = [...arr1, ...zengzhiNameArr]
  }
  return arr1.join('、')
}

/***
 * 过滤单条下单数据：
 * self：this指向
 * ruleForm：单个订单数据；
 * isBatch：true时为批量下单，否则为极速下单；
 * faIdx：批量下单下标;
 * flag：为true，则不计算计费，用于批量下单的详情
 * ****/
export function returnOneObj(self, ruleForm, isBatch, faIdx) {
  let tips = isBatch ? '第' + (faIdx + 1) + '条送货' : ''
  if (!ruleForm.goodsVolume || !ruleForm.goodsWeight || !ruleForm.totalPakages) {
    tipsModal(self, 'warning', '请重选' + tips + '产品')
    return false
  }
  if (!ruleForm.sendersAddress[1] || !ruleForm.sendersAddress[2]) {
    tipsModal(self, 'warning', '请重选发货省市区')
    return false
  }
  if (!ruleForm.recipientAddress[1] || !ruleForm.recipientAddress[2]) {
    tipsModal(self, 'warning', '请重选' + tips + '送货省市区')
    return false
  }
  if (!ruleForm.lineId) {
    tipsModal(self, 'warning', '请重选' + tips + '选择供应商')
    return false
  }
  if (!ruleForm.totalPrice && !ruleForm.batchTotalAll) {
    tipsModal(self, 'warning', '获取' + tips + '计费失败')
    return false
  }
  let orderDetail = {
    orderSn: ruleForm.orderNo || '',
    declaredValue: ruleForm.declaredValue || 0,
    companyName: ruleForm.companyName || '',
    paymentType: ruleForm.paymentType || '',
    paymentTypeName: ruleForm.paymentTypeName || '',
    receiptType: ruleForm.receiptType || '',
    receiptTypeName: ruleForm.receiptTypeName || '',
    packing: ruleForm.packing || '',
    packingName: ruleForm.packingName || '',
    totalPakages: ruleForm.totalPakages || '',
    totalWeight: priceKG(ruleForm.totalWeight),
    totalVolume: ruleForm.totalVolume || '',
    goodsWeight: priceKG(ruleForm.goodsWeight),
    goodsVolume: ruleForm.goodsVolume || '',
    insuredType: ruleForm.declaredValue > 0 ? 1 : 0,
    remark: ruleForm.remark || '',
    cargoType: ruleForm.cargoType || '',
    cargoTypeName: ruleForm.cargoTypeName || '',
    providerName: ruleForm.providerName || '',
    productType: ruleForm.productType || '',
    productTypeName: ruleForm.productTypeName || '',
    lineHour: ruleForm.lineHour || '',
    lineId: ruleForm.lineId || '',
    providerId: ruleForm.providerId || '',
    receiptHour: ruleForm.returnBillHour || '',
    loadServ: ruleForm.loadServ || 0,
    unloadServ: ruleForm.unloadServ || 0,
    upstairsServ: ruleForm.upstairsServ || 0,
    upstairsMode: ruleForm.upstairsMode || 1,
    upstairsLevel: ruleForm.upstairsLevel || 1,
    ziti: ruleForm.pickService === 'ziti' ? 1 : 0,
    phoneNumber: ruleForm.phoneNumber || '',
    sp: ruleForm.sp || '',
    sendersName: ruleForm.sendersName || '',
    sendersPhone: ruleForm.sendersPhone || '',
    sendersProvince: ruleForm.sendersAddress[0] || '',
    sendersProvinceName: ruleForm.sendersProvinceName || '',
    sendersCity: ruleForm.sendersAddress[1] || '',
    sendersCityName: ruleForm.sendersCityName || '',
    sendersDistrict: ruleForm.sendersAddress[2] || '',
    sendersDistrictName: ruleForm.sendersDistrictName || '',
    sendersAddrId: ruleForm.sendersAddrId || '',
    sendersDetailaddress: ruleForm.sendersDetailaddress || '',
    recipientName: ruleForm.recipientName || '',
    recipientPhone: ruleForm.recipientPhone || '',
    recipientProvince: ruleForm.recipientAddress[0] || '',
    recipientProvinceName: ruleForm.recipientProvinceName || '',
    recipientCity: ruleForm.recipientAddress[1] || '',
    recipientCityName: ruleForm.recipientCityName || '',
    recipientDistrict: ruleForm.recipientAddress[2] || '',
    recipientDistrictName: ruleForm.recipientDistrictName || '',
    recipientAddrId: ruleForm.recipientAddrId || '',
    recipientDetailaddress: ruleForm.recipientDetailaddress || '',
    sendersDate: ruleForm.sendersDate || '',
    sendersTime: ruleForm.sendersTime || '',
    deliveryService:
      ruleForm.pickService == 'shsm' || ruleForm.pickService == 'shjc' ? true : false,
    recipientTimeRange: ruleForm.recipientTimeRange || '',
    truckType: ruleForm.truckType,
    truckTypeName: ruleForm.truckTypeName,
    carLength: ruleForm.carLength || '',
    biaoQian: ruleForm.biaoQian || '',
    couponNum: ruleForm.couponNum || '',
    couponName: ruleForm.couponName || '',
    mileage: ruleForm.mileage || '',
    dzdState: ruleForm.dzdState || '',
    monthClosing: ruleForm.monthClosing || '',
    zengZhi: getZengZhiStr(ruleForm)
  }

  //回单方式为"纸质回单 |快件"时，多回单收件人，电话，地址，3个字段
  if (ruleForm.receiptType == 'ZL2209291') {
    let receiptTypeInfo = {
      receiptTypeUserName: ruleForm.receiptTypeUserName || '',
      receiptTypeUserPhone: ruleForm.receiptTypeUserPhone || '',
      receiptTypeUserAddress: ruleForm.receiptTypeUserAddress || ''
    }
    console.log('receiptTypeInfo', receiptTypeInfo)
    orderDetail = { ...orderDetail, ...receiptTypeInfo }
  }

  const allParams = {
    couponNum: ruleForm.couponNum || '',
    dataSource: '02',
    orderDetail: orderDetail,
    orderId: ruleForm.orderId || '',
    paymentType: ruleForm.paymentType || '',
    paymentTypeName: ruleForm.paymentTypeName || '',
    providerName: ruleForm.providerName || ''
  }
  if (isBatch && ruleForm.parentOrderNo) {
    allParams.parentOrderNo = ruleForm.parentOrderNo || ''
    allParams.state = ruleForm.state || ''
  }
  return allParams
}

/***
 * 选择地址簿后的数据过滤：
 * addr：选中返回的地址簿数据；
 * type: 地址簿类型：发货deliver/收货receive
 * isBatch：true时为批量下单，否则为极速下单；
 * faIdx：批量下单下标;
 * callback：回调执行方法
 * ****/
export function handleSelectAddress(self, addr, type, isBatch, faIdx) {
  if (!addr) return
  let newAddr = {}
  const areaCode = [addr.areaCode1 || '', addr.areaCode2 || '', addr.areaCode3 || '']
  if (type === 'deliver') {
    newAddr = {
      sendersAddrId: addr.id || '',
      sendersName: addr.contacts || '',
      sendersPhone: addr.mobile || '',
      sendersAddress: areaCode,
      sendersDetailaddress: addr.areaInfo || '',
      sendersProvinceName: addr.areaCode1Name || '',
      sendersCityName: addr.areaCode2Name || '',
      sendersDistrictName: addr.areaCode3Name || ''
    }
    if (isBatch) {
      self.batchDataArr.fhArr = {
        ...self.batchDataArr.fhArr,
        ...newAddr
      }
      self.batchDataArr = { ...self.batchDataArr }
      $store.state.batchConForm = { ...self.batchDataArr }
      setBatchOrderForm(self.batchDataArr)
    }
  } else if (type === 'receive') {
    newAddr = {
      recipientAddrId: addr.id || '',
      recipientName: addr.contacts || '',
      recipientPhone: addr.mobile || '',
      recipientAddress: areaCode,
      recipientDetailaddress: addr.areaInfo || '',
      recipientProvinceName: addr.areaCode1Name || '',
      recipientCityName: addr.areaCode2Name || '',
      recipientDistrictName: addr.areaCode3Name || ''
    }
    if (isBatch) {
      if (self.batchDataArr) {
        self.batchDataArr.shList[faIdx] = {
          ...self.batchDataArr.shList[faIdx],
          ...newAddr
        }
        self.batchDataArr = { ...self.batchDataArr }
      }
      saveStorage(self, newAddr, isBatch, faIdx)
    }
  }
  if (!isBatch) {
    const datas = {
      ...self.newStepArr,
      ...newAddr
    }
    // self.$emit('changeStepArr', datas)
    saveStorage(self, datas)
  }
}

/***
 * 选择地址簿后的数据过滤返回：
 * addr：选中返回的地址簿数据；
 * type: 地址簿类型：发货deliver/收货receive
 * ****/
export function newSelectAddress(addr, type) {
  if (!addr) return
  let newAddr = {}
  const areaCode = addr.areaCode1
    ? [addr.areaCode1 || '', addr.areaCode2 || '', addr.areaCode3 || '']
    : []
  if (type === 'deliver') {
    newAddr = {
      sendersAddrId: addr.id || '',
      sendersName: addr.contacts || '',
      sendersPhone: addr.mobile || '',
      sendersAddress: areaCode,
      sendersDetailaddress: addr.areaInfo || '',
      sendersProvinceName: addr.areaCode1Name || '',
      sendersCityName: addr.areaCode2Name || '',
      sendersDistrictName: addr.areaCode3Name || ''
    }
  } else if (type === 'receive') {
    newAddr = {
      recipientAddrId: addr.id || '',
      recipientName: addr.contacts || '',
      recipientPhone: addr.mobile || '',
      recipientAddress: areaCode,
      recipientDetailaddress: addr.areaInfo || '',
      recipientProvinceName: addr.areaCode1Name || '',
      recipientCityName: addr.areaCode2Name || '',
      recipientDistrictName: addr.areaCode3Name || ''
    }
  }
  return newAddr
}

/***
 * 极速/批量 触发“确定”/“下单”执行的操作：
 * self：this指向
 * ruleForm：单个订单数据；
 * type: 'toList'为跳转到订单页，'toPay'为跳转到支付页
 * isBatch：true时为批量下单，否则为极速下单；
 * faIdx：批量下单下标;
 * ****/
export function submitOrderForm(self, ruleForm, type, isBatch, faIdx) {
  const allParams = returnOneObj(self, ruleForm, isBatch, faIdx)
  if (!isBatch) {
    payMoney(self, allParams, type)
  } else {
    let getBatchDatas = getBatchOrderForm()
    costAssessment(self, getBatchDatas)
  }
}

/***
 * 批量下单提交操作：
 * self：this指向
 * lists：批量下单的数据
 * ****/
export function costAssessment(self, batchForm, callback) {
  let lists = []
  batchForm.shList &&
    batchForm.shList.some((item, index) => {
      let newObj = {
        ...item,
        ...batchForm.fhArr
      }
      let dataArr = returnOneObj(self, newObj, true, index)
      if (!dataArr) {
        return true //跳出循环
      }
      lists.push(dataArr)
    })
  $axios('POST', '/portal/order/costAssessment', { lists }, 'data', 'application/json').then(
    res => {
      let datas = []
      if (res.code === '200') {
        datas = res.data
        if (datas.length) {
          datas.map((item, idx) => {
            let arr = {
              batchTotalAll: item.fee || 0,
              collectFee: item.batchDiscount || 0,
              batchCouponMoney: item.discount || 0
            }
            batchForm.shList[idx] = {
              ...batchForm.shList[idx],
              ...arr
            }
            $store.state.batchConForm = { ...batchForm }
            setBatchOrderForm(batchForm)
          })
          callback && callback(batchForm, self)
        }
      }
      // else {
      //   tipsModal(self, 'error', "获取合计费用失败" + res.msg || "")
      // }
      setStorage('lists', datas)
    }
  )
}

/***
 * 极速下单提交操作：
 * self：this指向
 * lists：批量下单的数据
 * type: 'toList'为跳转到订单页，'toPay'为跳转到支付页
 * ****/
export function payMoney(self, params, type) {
  const allData = getOrderForm()
  if (!allData) {
    tipsModal(self, 'error', '数据已丢失，请重新刷新页面获取')
    return
  }
  if (!params.companyName) {
    //防止公司名称丢失
    params.companyName = userData.companyName
  }
  if (!params.orderDetail.nature) {
    //防止公司名称丢失
    params.orderDetail.nature = $store.state?.corpMsg?.nature || ''
  }
  const url = params.orderId ? '/portal/order/modify' : '/portal/order/creater'
  $axios('POST', url, params, 'data', 'application/json').then(res => {
    if (res.code === '200') {
      let tipTxt = '订单号：' + res.data.orderNo || ''
      if (params.paymentType !== 'ZL1605220') {
        tipsModal(self, 'success', (params.orderId ? '修改成功' : '下单成功') + '，' + tipTxt)
      }
      let idsArr = params.orderId ? params.orderId : res.data.id //获取订单id
      self.newStepArr.orderNo = ''
      $store.state.drawerType = 'add'
      // removeOrderForm('orderStore');//清空订单数据
      if (isMobile()) {
        if (type === 'toList') {
          self.$router.push({
            name: 'm_finished',
            params: { title: tipTxt, actType: params.orderId ? 'edit' : 'add', type: '0' }
          })
          // self.$router.push({ path: "/m_order" }); //跳转到订单列表页
        } else {
          self.$router.push({
            name: 'm_payment', //跳转到付款页
            params: {
              orderIds: [idsArr],
              orderNum: 1
            }
          })
        }
      } else {
        if (type === 'toList') {
          self.$router.push({ path: '/p_myorder/p_transOrder' }) //跳转到订单列表页
          $store.state.stepAction = 1 //极速下单步骤路由重置
        } else {
          self.payOrderId = idsArr
          self.ishowPayModal = true //显示支付窗口
        }
      }
    }
    // else {
    //   tipsModal(self, 'error', "提交订单失败" + res.msg || "")
    // }
  })
}

/***
 * 获取pCode数据
 * self：this指向
 * type：默认为查询支付方式
 * ****/
export function findActiveByPCode(self, code) {
  let userId = userData.id || ''
  if (!userId) return
  //付款方式
  const params = {
    pcode: code || 'ZL1605219',
    userId
  }
  $axios('POST', '/portal/baseDropdown/findActiveByPCode', params).then(res => {
    if (res.code === '200') {
      $store.state.byPCode.paymentTypeVO = res.data || []
    }
  })
}

/***
 * 获取增值服务数据
 * ****/
export function getZengZhiAll() {
  //增值服务
  $axios('POST', '/portal/product/conline/listZengZhiAll').then(res => {
    if (res.code === '200') {
      $store.state.byPCode.zengZhiVO = res.data || []
    }
  })
}
/***
 * 查询客户信optionClick息，主要包含已选货物/包装的次数排序
 * ****/
export function getByCode() {
  let corpId = userData.corpId || ''
  if (!corpId) return
  const params = {
    corpId
  }
  $axios('post', '/portal/transportationInfo/getByCorpId', params).then(res => {
    if (res.code === '200') {
      if (!res.data) return
      const dataArr = res.data
      $store.monthClosing = dataArr.monthClosing || '0'
      $store.state.byPCode.packingVO = dataArr.packingVO || []
    }
  })
}

/***
 * 查询客户信息，主要包含客户标签，货值减免
 * ****/
export function getUserByMobile() {
  let mobile = userData.mobile || ''
  if (!mobile) return
  const params = {
    mobile
  }
  $axios('post', '/portal/buyerCorp/getUserByMobile', params).then(res => {
    if (res.code === '200') {
      if (!res.data) return
      let data = res.data
      let tagName = data?.tagName || ''
      $store.state.corpMsg = data
      $store.state.isSvip = tagName.indexOf('SVIP') != -1
    }
  })
}

/***
 * 查询包装方式列表
 * ****/
export function fetchPackingList() {
  const params = { pcode: 'baozhuangmingcheng' }
  $axios(
    'post',
    '/portal/baseDropdown/findActive',
    params,
    'data',
    'application/json;charset=UTF-8'
  ).then(res => {
    if (res.isSuccess) {
      $store.state.byPCode.packingVO = res.data || []
    }
  })
}

/**移动端修改(字段映射)地址专用 start**/
export function getCreatedAddrM(addrDatas, type) {
  if (!addrDatas || !type) return
  let newAddr = {}
  if (type === 'deliver') {
    newAddr = {
      addrId: addrDatas.sendersAddrId || '',
      userName: addrDatas.sendersName || '',
      mobile: addrDatas.sendersPhone || '',
      areaCode: addrDatas.sendersAddress || '',
      addrInfo: addrDatas.sendersDetailaddress || '',
      province: addrDatas.sendersProvinceName || '',
      city: addrDatas.sendersCityName || '',
      district: addrDatas.sendersDistrictName || '',
      companyName: addrDatas.companyName || ''
    }
  } else if (type === 'receive') {
    newAddr = {
      addrId: addrDatas.recipientAddrId || '',
      userName: addrDatas.recipientName || '',
      mobile: addrDatas.recipientPhone || '',
      areaCode: addrDatas.recipientAddress || '',
      addrInfo: addrDatas.recipientDetailaddress || '',
      province: addrDatas.recipientProvinceName || '',
      city: addrDatas.recipientCityName || '',
      district: addrDatas.recipientDistrictName || ''
    }
  }
  return newAddr
}
export function returnCreatedAddrM(addrDatas, type) {
  if (!addrDatas || !type) return
  let newAddr = {}
  if (type === 'deliver') {
    newAddr = {
      sendersAddrId: addrDatas.addrId || '',
      sendersName: addrDatas.userName || '',
      sendersPhone: addrDatas.mobile || '',
      sendersAddress: addrDatas.areaCode || '',
      sendersDetailaddress: addrDatas.addrInfo || '',
      sendersProvinceName: addrDatas.province || '',
      sendersCityName: addrDatas.city || '',
      sendersDistrictName: addrDatas.district || '',
      companyName: addrDatas.companyName || ''
    }
  } else if (type === 'receive') {
    newAddr = {
      recipientAddrId: addrDatas.addrId || '',
      recipientName: addrDatas.userName || '',
      recipientPhone: addrDatas.mobile || '',
      recipientAddress: addrDatas.areaCode || '',
      recipientDetailaddress: addrDatas.addrInfo || '',
      recipientProvinceName: addrDatas.province || '',
      recipientCityName: addrDatas.city || '',
      recipientDistrictName: addrDatas.district || ''
    }
  }
  return newAddr
}
/**移动端修改地址专用 end**/

/****
 *获取零担起始地址库
 *addrCodes
 *****/
export function areaFilterVersion(self, addrCodes, callback) {
  let newCodes = (addrCodes = addrCodes ? addrCodes : 'AREA_LD_START,AREA_LD_END')
  $axios('GET', '/portal/baseArea/getFilterVersionByCode?code=' + newCodes).then(res => {
    if (res.code === '200') {
      let curVersion = res.data
      let preVersion = getAreaFilterVersion()
      let preStartVersionLists = getAreaFilterList('AREA_LD_START') //上一次开始过滤零担地址数据列表
      let preEndVersionLists = getAreaFilterList('AREA_LD_END') //上一次结束过滤零担地址数据列表
      if (
        !curVersion.AREA_LD_START ||
        !(curVersion.AREA_LD_START === preVersion.AREA_LD_START) ||
        preStartVersionLists.length === 0
      ) {
        setAreaFilterVersion(curVersion)
        areaFilterList(self, 'AREA_LD_START', callback || '')
      }
      if (
        !curVersion.AREA_LD_END ||
        !(curVersion.AREA_LD_END === preVersion.AREA_LD_END) ||
        preEndVersionLists.length === 0
      ) {
        setAreaFilterVersion(curVersion)
        areaFilterList(self, 'AREA_LD_END', callback || '')
      }
    }
    // else {
    //   tipsModal(self, 'error', res.msg || '获取过滤地址版本号失败')
    // }
  })
}

/****
 *获取零担起始地址库
 *addrCodes
 *****/
export function areaFilterList(self, addrCodes, callback) {
  addrCodes = addrCodes ? addrCodes : 'AREA_LD_START'
  $axios('GET', '/portal/baseArea/getFilterByCode?code=' + addrCodes).then(res => {
    if (res.code === '200') {
      if (addrCodes === 'AREA_LD_START') {
        self.ldStartList = res.data
      } else if (addrCodes === 'AREA_LD_END') {
        self.ldEndList = res.data
      }
      setAreaFilterList(addrCodes, res.data || [])
      callback && callback()
    }
    // else {
    //   tipsModal(self, 'error', res.msg || '获取过滤地址列表失败')
    // }
  })
}

/****
 * 计算批量预计价格和已减价格
 * self：this指向
 * type：文本类型
 * msg：报错文本
 * ** */
export function tipsModal(self, type, msg) {
  if (!isMobile()) {
    self.$message({
      type: type,
      message: msg
    })
  } else {
    if (type === 'error') {
      type = 'fail'
    }
    self.$toast({
      type: type,
      message: msg
    })
  }
}

/****
 * 计算批量预计价格和已减价格
 * shList：批量发货的数据
 * ** */
export function showBatchSubmitPrice(shList) {
  let arr = { expenses: 0, dis: 0 },
    expenses = 0,
    dis = 0
  if (shList.length) {
    shList.map(item => {
      let newDis = Number(item.batchCouponMoney || 0) + Number(item.collectFee || 0)
      expenses += Number(item.batchTotalAll || 0)
      dis += newDis
    })
    arr = { expenses: expenses.toFixed(2), dis: dis.toFixed(2) }
  }
  return arr
}

/*******
 *设置初始预计发货时间段
 * ******* */
export function initSendersDate() {
  let curDate = moment().format('YYYY-MM-DD') //能选的日期
  let rangeArr = dateRec(curDate) //能选的时间段范围
  let curHours = moment().format('HH') //当前小时
  if (curHours >= 18) {
    //过了18点只能选明天时间
    curDate = moment()
      .add(1, 'days')
      .format('YYYY-MM-DD')
    rangeArr = dateRec(curDate)
  }
  let rangeStr = rangeArr[0] || ''
  return { sendersDate: curDate, sendersTime: rangeStr }
}

/*******
 *获取发送货地址数据
 * ******* */
export function initArea(callback) {
  if (!userData.corpId) return
  let addrLists = $store.state.addrList //拿内存的数据
  let refreshAdd = {
    //需要使用默认地址的同时重置为新增,防止修改时刷新页面还存在修改订单参数
    id: '',
    orderId: '',
    couponNum: '',
    couponName: '',
    orderSn: '',
    lineId: '',
    state: '',
    drawerType: 'add'
  }
  const params = {
    corpId: userData.corpId,
    userId: userData.id
  }
  let sendsArr = {},
    receiveArr = {}
  if (addrLists.faAddrList.length !== 0 || addrLists.shouAddrList.length !== 0) {
    if (addrLists.faAddrList.length !== 0) {
      sendsArr = newSelectAddress(addrLists.faAddrList[0], 'deliver')
    }
    if (addrLists.shouAddrList.length !== 0) {
      //拿到第一条收货数据
      receiveArr = newSelectAddress(addrLists.shouAddrList[0], 'receive')
      receiveArr = {
        ...receiveArr,
        ...refreshAdd
      }
    }
    callback && callback({ sendsArr, receiveArr })
  } else {
    $axios('POST', '/portal/corpAddress/queryByCorpId', params, 'params', 'application/json').then(
      res => {
        if (res.code === '200') {
          let addrLists = res.data
          $store.state.addrList = addrLists
          if (addrLists.faAddrList && addrLists.faAddrList.length !== 0) {
            //拿到第一条发货数据
            sendsArr = newSelectAddress(addrLists.faAddrList[0], 'deliver')
          } else {
            sendsArr = newSelectAddress({ id: '' }, 'deliver')
          }
          if (addrLists.shouAddrList && addrLists.shouAddrList.length !== 0) {
            //拿到第一条收货数据
            receiveArr = newSelectAddress(addrLists.shouAddrList[0], 'receive')
          } else {
            receiveArr = newSelectAddress({ id: '' }, 'receive')
          }
          receiveArr = {
            ...receiveArr,
            ...refreshAdd
          }
          callback && callback({ sendsArr, receiveArr })
        }
      }
    )
  }
}

/****
 * 重置每条订单数据
 * reFresh: 为true代表清空【订单号、订单id、优惠券号码】
 * *****/
export function transferOrder(datas, reFresh) {
  if (!datas) return
  const newZengZhi = getZengZhiArr(datas.zengZhi, datas.ziti || 0, datas.deliveryService)
  datas.totalWeight = priceT(datas.totalWeight)
  datas.goodsWeight = priceT(datas.goodsWeight)
  datas.sendersAddress = [
    datas.sendersProvince || '',
    datas.sendersCity || '',
    datas.sendersDistrict || ''
  ]
  datas.recipientAddress = [
    datas.recipientProvince || '',
    datas.recipientCity || '',
    datas.recipientDistrict || ''
  ]
  datas.returnBillHour = datas.receiptHour || ''
  if (datas.orderNo) {
    datas.orderSn = datas.orderNo //订单详情返回的订单号字段是orderNo，提交的订单号字段是orderSn
    delete datas.orderNo
  }
  datas = {
    ...datas,
    ...newZengZhi
  }
  if (reFresh) {
    //再来一单，清空id/orderNo/couponNum等值
    let sendsDate = initSendersDate() //设置初始预计发货时间段
    datas.couponNum = ''
    datas.couponName = ''
    datas.orderSn = ''
    datas.id = ''
    datas.orderId = ''
    delete datas.state
    datas = {
      ...datas,
      ...sendsDate
    }
  }
  return datas
}

/*****
 * 提交批量下单
 * self：this指向
 * batchForm：批量下单基础数据
 * *****/
export function batchSubmit(self, batchForm) {
  let isSubmit = true
  let lists = [],
    shList = batchForm.shList
  shList.length &&
    shList.some((item, index) => {
      let newObj = {
        ...item,
        ...batchForm.fhArr
      }
      let dataArr = returnOneObj(self, newObj, true, index)
      if (!dataArr) {
        isSubmit = false
        return true //跳出循环
      }
      lists.push(dataArr)
    })
  if (!isSubmit) return
  if (!self.hasShow) {
    //没展开价格列表，则先展示
    self.hasShow = true
    return
  }
  let getPriceList = getStorage('lists')
  if (!getPriceList || JSON.parse(getPriceList).length === 0) {
    costAssessment(self, batchForm)
  } else {
    validateForm(lists, self)
  }
}

/*****
 * 提交批量下单
 * self：this指向
 * lists：批量下单处理后要提交的数据
 * *****/
export function validateForm(lists, self) {
  $axios('POST', '/portal/order/batchOrderCreat', { lists }, 'data', 'application/json').then(
    data => {
      if (data.code === '200') {
        tipsModal(self, 'success', '批量下单成功')
        removeOrderForm('batchForm') //删除订单批量数据
        $store.state.batchConForm = {}
        $store.state.drawerType = 'add'
        if (isMobile()) {
          self.$router.push({ path: '/m_order' }) //跳转到订单列表页
        } else {
          self.$router.push({ path: '/p_myorder/p_transOrder' }) //跳转到订单列表页
        }
      }
      // else {
      //   tipsModal(self, 'error', '批量下单失败。' + data.msg || "")
      // }
    }
  )
}

/*****
 * 批量收货地址或者选择地址簿确认回调
 * self：this指向
 * lists：批量下单处理后要提交的数据
 * *****/
export function confirnAddress(self, shAddr) {
  if (!self.isRadio) {
    //为添加多个发货人弹窗
    self.batchDataArr.shList = [...shAddr]
    setBatchOrderForm(self.batchDataArr)
  } else {
    //为地址簿选择弹窗
    if (self.activeName === 'deliver') {
      self.batchDataArr.fhArr = {
        ...self.batchDataArr.fhArr,
        ...shAddr
      }
    } else if (self.activeName === 'receive') {
      self.batchDataArr.shList[self.faIdx] = {
        ...self.batchDataArr.shList[self.faIdx],
        ...shAddr
      }
    }
  }
  self.batchDataArr = { ...self.batchDataArr }
  self.$store.state.batchConForm = self.batchDataArr
  setBatchOrderForm(self.batchDataArr)
}

/*****
 * 批量收货地址地址簿查重
 * self：this指向
 * obj: 选中的地址
 * addrr: 选中的地址中文
 * *****/
export function checkDuplicateAddr(self, selectedaddrId, addrr, faIdx) {
  let isNext = true
  let batchForm = getBatchOrderForm()
  batchForm = batchForm.shList
  if (batchForm.length > 1) {
    batchForm.some((item, idx) => {
      let isSel = item.recipientAddrId !== '' && item.recipientAddrId === selectedaddrId
      if (isSel) {
        if (faIdx !== idx) {
          isNext = false
          tipsModal(self, 'warning', '你已选择了：' + addrr)
          return true //跳出循环
        }
      }
    })
  }
  return isNext
}

/***
 * 检查现有的地址簿是否存在智能识别的地址code
 * self this指向
 * addrType 地址类型deliver、receive
 * codeArr 地址数组
 * ***/
export function getcheckAddress(self, addrType, codeArr) {
  let hasProvince = false,
    hasCity = false,
    hasDistrict = false
  if (!codeArr || codeArr.length === 0) return
  let addrLists = [],
    tipTxt = '该地址无法识别，请手动选择'
  if (addrType === 'receive') {
    addrLists = getAreaFilterList('AREA_LD_END')
  } else {
    addrLists = getAreaFilterList('AREA_LD_START')
  }
  if (addrLists.length === 0) {
    tipsModal(self, 'warning', tipTxt)
  }
  addrLists.some((item, index) => {
    if (item.value === codeArr[0]) {
      //是否存在省
      hasProvince = true
      if (item.children.length > 0 && !!codeArr[1]) {
        item.children.some(v => {
          if (v.value === codeArr[1]) {
            //是否存在市
            hasCity = true
            if (v.children.length > 0 && !!codeArr[2]) {
              v.children.some(j => {
                if (j.value === codeArr[2]) {
                  //是否存在市
                  hasDistrict = true
                  return true
                }
              })
            }
            return true
          }
        })
      }
      return true
    }
  })
  if (!hasDistrict) {
    //不存在市时
    tipsModal(self, 'warning', tipTxt)
    codeArr = []
  }
  return codeArr
}

//增值服务过滤
export function filterZengZhi(self, callback) {
  let { addedServiceList, servicesList } = $store.state
  let newStep = JSON.parse(JSON.stringify(self.newStepArr))
  let addedService = newStep.addedService
  let services = newStep.services
  const { pickService, lineId } = newStep
  $axios('post', '/portal/goodsItem/listZengZhiBy', {
    lineId: lineId
  }).then(res => {
    if (res.code === '200') {
      let zengzhiAll = res.data
      //保价、回单、送货服务必定有的不较验
      //是否有包装服务的
      let wrongArr = []
      if (addedService.length) {
        if (zengzhiAll.length) {
          addedService.map((item, index) => {
            let kk = zengzhiAll.findIndex(sub => {
              return sub.code == item
            })
            if (kk == -1) {
              var idx = addedServiceList.map(val => val.id).indexOf(item)
              let nameStr = addedServiceList[idx].name
              if (nameStr !== '暂不需要') {
                wrongArr.push(nameStr)
                //删除数组指定的某个元素
                let arrIdx = self.newStepArr.addedService.indexOf(item)
                if (arrIdx > -1) {
                  self.newStepArr.addedService.splice(arrIdx, 1)
                }
              }
            }
          })
        }
        if (services.length !== 0) {
          services.map(item => {
            let kk = zengzhiAll.findIndex(sub => {
              return sub.code == item
            })
            if (kk == -1) {
              let arrSerIdx = self.newStepArr.services.indexOf(item)
              let nameSerStr = servicesList[arrSerIdx].name
              wrongArr.push(nameSerStr)
              if (arrSerIdx > -1) {
                self.newStepArr.services.splice(arrSerIdx, 1)
              }
            }
          })
        }
        if (pickService) {
          let newCode = pickService
          if (pickService === 'shsm') {
            newCode = 'ZL1803887' //重新赋值送货费的code
          }
          let isTrue = JSON.stringify(zengzhiAll).indexOf(newCode)
          if (isTrue == -1) {
            self.newStepArr.pickService = 'ziti'
            if (pickService === 'ZL1803887') {
              wrongArr.push('送货上门')
            } else if (pickService === 'shjc') {
              wrongArr.push('送货进仓')
              self.newStepArr.pickService = 'shsm'
            }
          }
        }
        if (self.newStepArr.addedService.length === 0) {
          self.newStepArr.addedService = ['0']
        }
      } else {
        self.$message.error('此线路不存在增值服务')
        return
      }
      saveStorage(self, self.newStepArr, self.isBatch, self.faIdx)
      callback && callback(wrongArr)
    }
    // else {
    //   tipsModal(self, 'error', "获取供应商列表失败" + res.msg || "")
    // }
  })
}
export function removeHistoryState() {
  window.removeEventListener('popstate', () => goBack(), false)
}
//监听浏览器后退事件
export function historyState(self, url) {
  //监听浏览器后退事件
  if (window.history && window.history.pushState) {
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', () => goBack(self, url), false)
    window.addEventListener('beforeunload', e => unload(e, self))
  }
}
export function unload(e, self) {
  // e = e || window.event;
  // if (e) {
  //   e.stopPropagation && e.stopPropagation();
  //   e.cancelBubble = true
  //   return false
  // }
  let url = self.$route.name

  goBack(self, url)
}
export function goBack(self, url) {
  if (!url) return
  self.$router.push({
    name: url,
    params: {
      oneDatas: self.newStepArr,
      isBatch: self.isBatch || false,
      faIdx: self.faIdx
    }
  })
  tipsModal(self, 'warning', url + self.newStepArr.orderSn)
}

//处理1.0快速查询的跳1.1下单页的方法
export function handleInquiryData(cookieOrderStore) {
  //官网1.0快速询价下单参数
  let newCookie = {}
  cookieOrderStore = cookieOrderStore.split('&')
  cookieOrderStore.map(item => {
    let cookieArr = item.split('=')
    newCookie[cookieArr[0]] = cookieArr[1] || ''
  })
  newCookie.recipientAddress = newCookie.recipientAddress.split(',')
  newCookie.sendersAddress = newCookie.sendersAddress.split(',')
  delCookie('orderNewStore')
  return newCookie
}

//实际货物重量体积计算
export async function calculateWeight(StepArr, self, callback) {
  let newStep = JSON.parse(JSON.stringify(StepArr))
  const { totalPakages, goodsWeight, goodsVolume } = newStep
  let params = {
    goodsNum: totalPakages,
    weight: priceKG(goodsWeight),
    goodsWeight: priceKG(goodsWeight),
    volume: goodsVolume,
    goodsVolume: goodsVolume,
    zengZhi: getZengZhiStr(StepArr) //增值服务
  }
  let res = await $axios('post', '/portal/order/calculateWeight', { ...params })
  let parms = {}
  if (res && res.code == '200') {
    let datas = res?.data || {}
    parms = {
      totalVolume: datas ? datas.volume : '',
      totalWeight: datas ? priceT(datas.weight) : ''
    }
  }
  return parms
}

//产品标签展示
export function pdTagFun(biaoqianStr) {
  let text = ''
  switch (biaoqianStr) {
    case 'zhunShiDa':
      text = '大票快递'
      break
    case 'jiaGeZuiDi':
      text = '大票特惠'
      break
    case 'xiaoPiaoTeHui':
      text = '小票特惠'
      break
    case 'shuiLuTeHui':
      text = '水路特惠'
      break
    case 'tieLuTeHui':
      text = '铁路特惠'
      break
    default:
      break
  }
  return text
}

//回单方式选择“纸质回单|快件”时
export function receiptInfoFun(stepArr, isBatch) {
  let batchConForm = isBatch ? $store.state?.batchConForm?.fhArr || {} : {}
  let newStepArr = { ...stepArr, ...batchConForm }
  let newoObj = {}
  //选择的是回单方式,默认回单接受信息为发件人，电话，地址信息
  if (newStepArr.receiptType === 'ZL2209291') {
    newoObj = {
      ...stepArr,
      receiptTypeUserName: newStepArr.receiptTypeUserName || newStepArr.sendersName || '',
      receiptTypeUserPhone: newStepArr.receiptTypeUserPhone || newStepArr.sendersPhone || '',
      receiptTypeUserAddress:
        newStepArr.receiptTypeUserAddress ||
        newStepArr.sendersProvinceName +
          newStepArr.sendersCityName +
          newStepArr.sendersDistrictName +
          newStepArr.sendersDetailaddress ||
        ''
    }
  } else {
    newoObj = {
      ...stepArr,
      receiptTypeUserName: '',
      receiptTypeUserPhone: '',
      receiptTypeUserAddress: ''
    }
  }
  return newoObj
}
