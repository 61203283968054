<template>
  <div>
    <div class="bind_box mb20">
      <input
        class="bind_ipt"
        placeholder="请输入优惠券码"
        v-model="couponIpt"
      />
      <button class="bind_btn" @click="handleBind">绑定</button>
    </div>
    <div class="dialog_div" :style="{ maxHeight: innerHeight + 'px' }">
      <div class="box_ul head">
        <div class="box_li checkbox"></div>
        <div class="box_li num">优惠券号</div>
        <div class="box_li name">优惠券名称</div>
        <div class="box_li isDd">是否可叠加</div>
        <div class="box_li type">优惠类型</div>
        <div class="box_li dissipate">消费时间</div>
        <div class="box_li time">过期时间</div>
      </div>
      <div
        class="box_ul body"
        v-for="item in $store.state.couponList"
        :key="item.id"
      >
        <div class="box_li checkbox">
          <input
            class="check_ipt"
            type="checkbox"
            v-model="newCouponNum"
            :value="item.couponNum"
            @click="(e) => handleSelect(e, item)"
          />
        </div>
        <div class="box_li num">{{ item.couponNum }}</div>
        <div class="box_li name">{{ item.couponName }}</div>
        <div class="box_li isDd">
          {{ item.isDiejia === "1" ? "是" : "否" }}
        </div>
        <div class="box_li type">{{ item.couponTypeName }}</div>
        <div class="box_li dissipate">{{ item.beginTime }}</div>
        <div class="box_li time">{{ item.endTime }}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer flex_c mt20">
      <el-button @click="handleHide">取 消</el-button>
      <el-button type="primary" @click="submitBtn">确 认</el-button>
    </span>
  </div>
</template>
<script>
export default {
  name: "CouponListPC",
  props: {
    newStepArr: {
      type: Object,
      required: true,
    },
    couponNum: {
      type: Array,
      required: true,
    },
  },
  watch:{
    couponNum(newVal){
      this.newCouponNum = newVal
    }
  },
  data() {
    return {
      newCouponNum: this.couponNum,
      couponIpt: "",
      innerHeight: document.documentElement.clientHeight - 380,
    };
  },
  methods: {
    handleSelect(e, item) {
      this.$emit("handleFaSelect", e, item);
    },
    submitBtn() {
      this.$emit("faComfirmBtn");
    },
    handleBind() {
      let val = this.couponIpt;
      if (!val) {
        this.$message.info("请填写要绑定的优惠券号");
        return;
      }
      this.$emit("handleFaBind", val);
    },
    handleHide() {
      this.$emit("changeVisible", false);
    },
  },
};
</script>
<style lang="less" scoped>
.dialog_div {
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
}
.box_ul {
  line-height: 48px;
  border-bottom: 1px solid #ddd;
}
.box_ul.head {
  background-color: #e2eff6;
}
.box_ul .box_li {
  width: 12%;
  display: inline-block;
}
.box_li.checkbox {
  width: 8%;
  text-align: center;
}
.box_li.name {
  width: 25%;
}
.box_ul:last-child {
  border-bottom: none;
}

.dialog-div {
  max-height: 400px;
  overflow-y: auto;
}
.bind_box .bind_ipt {
  display: inline-block;
  width: 250px;
  line-height: 40px;
  border: 1px solid #ddd;
  padding: 0 10px;
}
.bind_box .bind_btn {
  line-height: 40px;
  margin-left: 20px;
  padding: 0 20px;
  background-color: #409eff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #fff;
  outline: none;
  cursor: pointer;
}
.dialog_div .check_ipt {
  width: 20px;
  height: 20px;
  margin-top: 8px;
}
.flex_c {
  display: flex;
  justify-content: center;
}
</style>