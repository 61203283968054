<template>
   <div id="coupons" class="flex yrow">
       <!-- tab -->
       <div class="tablist flex">
            <div class="tab" v-for="(item,index) in tabArr" :key="item.name" @click="changeTab(item,index)">
                <div :class="item.active==true?'taboption':'_taboption'">{{item.name}}</div>
            </div>
       </div>
       <!-- maincontent -->
       <div class="maincontent mt30 flex1 relative">
           <div class="conponDiv flex wrap" v-if="coupons.length>0">
               <div class="_width50 flex xycenter" v-for="(item,index) in coupons" :key="item.applyId">
                <div class="couponDemo flex mb30 ml30">
                    <div class="l_couponDemo xycenter" :style="{backgroundImage:'url('+bg+')'}">
                        <div>
                            <span class="fs60 white">{{item.discountValue}}</span>
                            <span class="white fs32">{{item.couponType=="YHQLX01"?'折':'元'}}</span>
                        </div>

                    </div>
                    <div class="r_couponDemo flex1 xcenter">
                        <div class="couponContent">
                            <div class="flex namerow  ycenter">
                                <div class="couponName fs18 flex1">{{item.couponName}}</div>
                                <div class="xright">
                                    <div class="couponType fs14 white">{{item.couponTypeName}}</div>
                                </div>
                            </div>
                            <div class="fs14 detailText">
                                <p>{{item.beginTime}}~{{item.endTime}}</p>
                                <p>使用说明:{{item.rangeDesc}}</p>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
           </div>
           <div class="nodata" v-else>暂无数据~</div>
       </div>
       <!-- 分页 -->
       <div class="pagerow xycenter" v-if="coupons.length>0">
            <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="totalnumber"
            :current-page.sync="currentPage"
            @current-change="changePage">
            </el-pagination>
        </div>
       
   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
export default {
    components: {
    },

    data() {
        return {
            tabArr:[
                {name:'有效',active:true},
                {name:'未开始',active:false},
                {name:'已过期',active:false},
                {name:'已使用',active:false}
            ],
            stateName:"有效",
            coupons:[],
            pageSize:10,
            currentPage:1,
            totalnumber:0,
            bg:require('../../../assets/images/couponbg.png')
            
        }
    },

    created() {
        this.getCouponList(this.stateName,1)
    },


    mounted() {

    },

    methods: {
        // 翻页
        changePage:function(Page){
            const that=this;
            console.log("打印当前页数",Page)
            that.currentPage=Page;
            that.getCouponList(that.stateName,Page)
        },
        // 切换tab
        changeTab:function(obj,idx){
            const that=this;
            that.tabArr.forEach((item,index)=>{
                if(idx==index){
                    that.tabArr[idx].active=true;
                    that.stateName=item.name;
                }else{
                    that.tabArr[index].active=false;
                }
            })
            that.getCouponList(obj.name,1)
            
        },
        // 获取优惠券列表
        getCouponList:function(stateName,Page){
            const that=this;
            console.log("打印缓存user",JSON.parse(localStorage.getItem('user')))
            const user=JSON.parse(localStorage.getItem('user'));
            const params={
                couponState: stateName,
                pageSize: that.pageSize,
                pageIndex: Page,
            }
            that.$axios('get',API.getMyCoupons,params,'params','application/json').then((res)=>{
                if(res.isSuccess==true){
                    console.log("获取优惠券列表",res)
                    that.coupons=res.data.records;
                    that.totalnumber=res.data.total;
                }
                
    
                
            }).catch((error)=>{

            })
        },
    }

}

</script>
<style scoped>
    #coupons{
        width: 100%;
        height: 100%;
    }
    .relative{
        position: relative;
    }
    .nodata{
        font-size: 22px;
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    ._width50{
        width: 50%;
    }
    .mt30{
        margin-top: 30px;
    }
    .fs18{
        font-size: 18px;
    }
    .pagerow{
        padding-top: 50px;
        padding-bottom: 70px;
        width: 100%;
    }
    .mt50{
        margin-top: 50px;
    }
    .mb30{
        margin-bottom: 30px;
    }
    .ml30{
        margin-left: 30px;
    }
    .detailText{
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
    }
    .couponName{
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0A8DE4;
    }
    .couponType{
        padding: 6px 16px;
        background-color: #0A8DE4;
        border-radius: 13px;
    }
    .namerow{
        padding:20px 0;
        border-bottom: 1px dashed #666666;
    }
    .fs32{
        font-size: 32px;
    }
    .fs60{
        font-size: 60px;
        font-family: Microsoft YaHei;
        font-weight: bold;

    }
    .couponContent{
        width: 298px;
        min-height: 146px;
    }
    .l_couponDemo{
        width: 200px;
        min-height: 146px;
        background-size: 200px 146px;
    }
    .r_couponDemo{
        background-color: #F0F0F0;
    }
    .couponDemo{
        width: 560px;
        min-height: 146px;
    }
    .conponDiv{
        width: 100%;
        /* max-width: 1188px; */
        /* margin: 0 auto; */
    }
    .mt58{
        margin-top: 58px;
    }
    .tablist{
        width: 100%;
        border-bottom: 1px solid #D9D9D9;
    }
    .tab{
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:0 26px;
    }
    .taboption{
        padding: 24px 20px;
        border-bottom: 4px solid #007ec5;
        color: #007ec5;
        font-size: 18px;
        cursor: pointer;
    }
    ._taboption{
        padding: 24px 20px;
        font-size: 18px;
        cursor: pointer;
    }

</style>
