<template>
  <div id="addressHtml" v-if="isM">
    <van-action-sheet
      v-model="dialogVisible"
      title="优惠券"
      :close-on-click-overlay="false"
      @close="() => changeVisible(false)"
    >
      <CouponListM
        :newStepArr="newStepArr"
        :couponNum="couponNumNew"
        @handleFaSelect="handleSelect"
        @handleFaBind="handleBind"
        @faComfirmBtn="comfirmBtn"
        @changeVisible="changeVisible"
      ></CouponListM>
    </van-action-sheet>
  </div>
  <div v-else>
    <el-dialog
      title="选择优惠券"
      width="80%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      :show-close="false"
      class="coupon_list"
      tooltip-effect="dark"
    >
      <CouponListPC
        :newStepArr="newStepArr"
        :couponNum="couponNumNew"
        @handleFaSelect="handleSelect"
        @handleFaBind="handleBind"
        @faComfirmBtn="comfirmBtn"
        @changeVisible="changeVisible"
      ></CouponListPC>
    </el-dialog>
  </div>
</template>
<script>
import { $axios, priceKG, isMobile } from '@/assets/js/global'
import { getMutiType, calcTotal, tipsModal } from '@/assets/js/order'
import CouponListM from '@/components/couponsCmp/CouponListM.vue'
import CouponListPC from '@/components/couponsCmp/CouponListPC.vue'
export default {
  components: {
    CouponListM,
    CouponListPC
  },
  props: {
    stepFourArr: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newStepArr: this.stepFourArr,
      dialogVisible: false,
      isAuto: false, //是否自动选择
      selectedRows: [],
      couponName: [],
      couponIpt: ''
    }
  },
  created() {},
  computed: {
    isM() {
      return isMobile()
    },
    couponNumNew: {
      get() {
        let newSelectedRows = this.selectedRows
        let newCouArr = [],
          newCouNameArr = []
        newSelectedRows.length &&
          newSelectedRows.map(newItem => {
            newCouArr.push(newItem.couponNum)
            newCouNameArr.push(newItem.couponName)
          })
        this.couponName = newCouNameArr
        return newCouArr
      }
    }
  },
  methods: {
    changeVisible(isShow, datas) {
      if (isShow) {
        let couponNumArr = this.stepFourArr.couponNum ? this.stepFourArr.couponNum.split(',') : []
        let couponList = this.$store.state.couponList
        let newList = []
        if (couponNumArr.length) {
          couponList.length &&
            couponList.map(item => {
              couponNumArr.map(val => {
                if (item.couponNum === val) {
                  console.log(item)
                  newList.push(item)
                }
              })
            })
        }
        this.selectedRows = newList
        if (datas) {
          this.newStepArr = datas
        }
      } else {
        this.comfirmBtn() //关闭也同时更新已选中的优惠券
        // calcTotal(this, this.stepFourArr, false, 0);
      }
      this.dialogVisible = isShow !== 'undefined' ? isShow : false
    },
    //确认选中优惠券
    comfirmBtn() {
      let lists = [...this.selectedRows]
      let numList = [],
        nameList = []
      lists.length &&
        lists.map(item => {
          if (item) {
            numList.push(item.couponNum)
            nameList.push(item.couponName)
          }
        })
      this.$emit('changeFaCoupon', numList, nameList)
      this.dialogVisible = false
      this.isAuto = false
    },
    handleSelect(e, item) {
      const { ruleForm } = this.$store.state
      if (e.target.checked) {
        let newSelectedRows = []
        let selectedRows = [...this.selectedRows]
        selectedRows.push(item)
        if (this.isAuto == true) {
          this.isAuto = false //表示是在第4步勾选的优惠券
          return
        }
        const len = selectedRows.length - 1
        if (
          selectedRows[len] &&
          selectedRows[len].couponType === 'YHQLX03' &&
          selectedRows[len].discountValueAppend >= ruleForm.totalPrice
        ) {
          this.$message.error('满减劵使用价格条件不符')
          return
        }
        if (selectedRows.length === 2) {
          //两张优惠券优惠券组合逻辑：折扣券/满减券 + 代金券
          let fCoupon = getMutiType(selectedRows[0], 'YHQLX02') //是否为可叠加的代金券
          let sCoupon = getMutiType(selectedRows[1], 'YHQLX02') //是否为可叠加的代金券
          let allDiejia = selectedRows[0].isDiejia === '1' && selectedRows[1].isDiejia === '1'
          if (allDiejia && ((fCoupon && !sCoupon) || (!fCoupon && sCoupon))) {
            if (fCoupon) {
              //第一张为可叠加代金券时
              newSelectedRows = [selectedRows[1], selectedRows[0]]
            } else {
              newSelectedRows = selectedRows
            }
          } else {
            newSelectedRows.push(selectedRows[1])
          }
        } else if (selectedRows.length === 3) {
          //最后一张为不可叠加券：只选中最后一张券
          if (selectedRows[2].isDiejia === '0') {
            newSelectedRows.push(selectedRows[2])
          } else {
            let firCoupon = getMutiType(selectedRows[0], 'YHQLX02') //第一张是否为代金券
            //最后一张为叠加券：1、同时为代金券，择替换之前的代金券，其他券同理
            if (selectedRows[2].couponType === 'YHQLX02') {
              newSelectedRows = [firCoupon ? selectedRows[1] : selectedRows[0], selectedRows[2]]
            } else {
              newSelectedRows = [selectedRows[2], firCoupon ? selectedRows[0] : selectedRows[1]]
            }
          }
        } else if (selectedRows.length > 3) {
          newSelectedRows.push(selectedRows[len])
        } else {
          //只有一张或者不选择优惠券时
          newSelectedRows.push(selectedRows)
        }
        this.selectedRows = newSelectedRows
      } else {
        this.selectedRows.push(item)
        this.selectedRows = this.selectedRows.filter(val => val.couponNum !== item.couponNum)
      }
      const newFormData = {
        ...this.newStepArr
      }
      newFormData.couponNum = this.couponNumNew.join(',')
      calcTotal(this, newFormData, false, 0)
    },
    handleBind(val) {
      if (!val) {
        this.$message.info('请填写要绑定的优惠券号')
        return
      }
      const arr = this.newStepArr
      const params = {
        beginCity: arr.sendersAddress[1] || '',
        beginDistrict: arr.sendersAddress[2] || '',
        beginProvince: arr.sendersAddress[0] || '',
        couponNum: val || '',
        endCity: arr.recipientAddress[1] || '',
        endDistrict: arr.recipientAddress[2] || '',
        endProvince: arr.recipientAddress[0] || '',
        mobile: arr.phoneNumber,
        payType: arr.paymentType,
        totalPakages: arr.totalPakages,
        totalVolume: arr.totalVolume,
        goodsPakages: arr.goodsPakages,
        goodsVolume: arr.goodsVolume,
        totalWeight: priceKG(arr.totalWeight)
      }
      $axios('POST', '/portal/couponApply/bindUserCoupon', params, 'data', 'application/json').then(
        data => {
          if (data.code === '200') {
            let datas = data.data
            let newCouponList = [...this.$store.state.couponList]
            newCouponList.push(datas)
            this.$store.state.couponList = newCouponList
            tipsModal(this, 'success', '绑定优惠券号成功。')
          }
        }
      )
    }
  }
}
</script>
<style scoped>
.coupon_list >>> .el-dialog {
  background-color: #f1f1f1;
}
</style>
