<template>
    <div class="searchBox">
        <div id="searchForm" class="xycenter" @click="skip_search">
            <i class="iconfont icon-sousuo"></i>
            <span class="prompt">{{prompt}}</span>
       </div>
    </div>
</template>

<script>
export default {

    components: {

    },
    props:['prompt','type'],

    data() {
        return {
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        skip_search:function(){
            this.$router.push({name:'m_search',params:{
                type:this.type
            }})
        }
    }

}

</script>
<style scoped>
    .searchBox{
        padding-left: 34px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    #searchForm{
        width: 382px;
        height: 64px;
        background: #F0F0F0;
        opacity: 0.7;
        border-radius: 32px;
    }
    .icon-sousuo{
        font-size: 30px;
    }
    .prompt{
        font-size: 26px;
        color: #CCCCCC;
    }

</style>
