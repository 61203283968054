<template>
    <el-dialog
        :title="title"
        :visible.sync="isShow"
        :modal="modal"
        width="30%"
        :before-close="handleClose"
        >
        <span>{{content}}</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="quxiao">取 消</el-button>
            <el-button type="primary" @click="queren">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {setCookie,getCookie,delCookie} from '../../assets/js/cookies.js'
export default {

    components: {

    },
    props:['isShow','title','content'],

    data() {
        return {
            modal:false
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        quxiao:function(){
            this.$emit('_close',false);
        },
        queren:function(){
            this.$emit('_confirm',false);
        },
        handleClose:function(){
            this.$emit('_close',false);
        }
    }

}
</script>
<style scoped>

</style>
