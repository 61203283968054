//商品规格
const specsArr = [{ name: '箱', id: '1' }, { name: '托', id: '2' }]

//入库需求
const warehousTypeArr = [{ text: '整进整出', value: '1' }, { text: '整进散出', value: '2' }]

//入库单选中的货品列表
const entryTable = [{ th: '商品编号', td: 'code' }, { th: '商品名称', td: 'name' }, { th: '生产日期', td: 'productionTime' }, { th: '入库数量', td: 'enterNumber' }] //已选商品的表头
//出库单选中的货品列表
const outTable = [{ th: '商品编号', td: 'code' }, { th: '商品名称', td: 'name' }, { th: '生产日期', td: 'productionTime' }, { th: '入库数量', td: 'outNumber' }] //已选商品的表头

//出库单付款方式
const outTypeArr = [{ text: '按箱出库', value: '1' }, { text: '按托出库', value: '2' }]

//出库单付款方式
const outPayArr = [{ text: '现付', value: 'ZL1605220' }, { text: '月结', value: 'ZL1605223' }]

//出库单配送方式
const outTalkArr = [{ text: '自提', value: 'ZT' }, { text: '一站商城配送', value: 'YZSC' }]

//入库单数据初始化
let entryInitialParms = {
  commodityJson: [], //商品传值
  companyName: "", //公司名称
  corpId: "", //公司Id
  creator: "", //创建人
  updator: "", //修改人
  userId: "", //用户ID
  mobile: "", //手机号
  depotId: "", //仓库ID
  depotName: '',//仓库名称
  remark: "", //备注
  storageDays: "", //储存天数
  totalPakages: 0, //	入库总数量
  totalVolume: 0, //入库总体积
  totalWeight: 0, //入库总重量
  warehousingTime: "", //入库时间
  warehousingType: "", //入库方式
  // state: "1", //状态
  id: "", //入库ID
  isChecked: false,//同意协议
};
//出库单数据初始化
const outInitialParms = {
  companyName: "", //公司名称
  corpId: "", //公司Id
  userId: "", //用户ID
  mobile: "", //手机号
  depotId: [], //仓库ID
  depotName: '',//仓库名称
  remark: "", //备注
  totalPakages: 0, //	入库总数量
  totalVolume: 0, //入库总体积
  totalWeight: 0, //入库总重量
  // state: "1", //状态
  id: "", //出库ID

  checkoutType: "",//出库方式
  deliveryType: "",//配送方式
  paymentType: "",//付款方式
  depotOutNo: "",//出库单号
  driverName: "",//提货司机
  driverPhone: "",//司机联系方式
  licenseNumber: "",//车牌号
  payment: 0,// 合计
  takeTime: "",//提货时间
  source: "0",//下单来源 0:小程序 1：管理后台
  valueAddedServices: [], //增值服务
  commodityJson: [],//货品信息，保存时字段要更改，保证与入库字段同步，方便货品选择字段统一 （保存时字段，depotStock）
  isChecked: false,//同意协议（保存时删掉）
};

//出入库货物选择表头共同拥有的字段
const goodsTableHead = [{
  name: '商品编号',
  val: 'code',
  width:'150'
},
{
  name: '商品名称',
  val: 'name'
},
{
  name: '商品规格',
  val: 'specifications'
},
{
  name: '每托码货数量',
  val: 'number',
  width:'150'
},
{
  name: '生产日期',
  val: 'productionTime',
  width:'150'
},
{
  name: '失效日期',
  val: 'invalidTime',
  width:'150'
},
{
  name: '单件重量(kg)',
  val: 'unitWeight',
  width:'120'
},
{
  name: '单件体积(cm³)',
  val: 'unitVolume',
  width:'120'
},
{
  name: '单件长(cm)',
  val: 'unitLong',
  width:'120'
},
{
  name: '单件宽(cm)',
  val: 'unitWide',
  width:'120'
},
{
  name: '单件高(cm)',
  val: 'unitHigh',
  width:'120'
}
] //已选商品的表头

//出库货物选择表格的表头比入库多出的字段
const outGoodsTableHead = [
  {
    name: '库存数量',
    val: 'enterNumber',
    width:''
  },
  {
    name: '入库日期',
    val: 'warehousingTime',
    width:'150'
  }
]

//计费单位转换
const rulesNameFun = function (type) {
  switch (type) {
    case '1':
      return '箱'
    case '2':
      return '托'
    case '3':
      return '方'
    case '4':
      return '吨'
    case '5':
      return '托/天'
    default:
      break;
  }
}

//增值服务根据不同单位渲染字段值
var changeUnitPriceNameFun = function (item, type) {
  switch (type) {
    case '1':
      return item.number || 0
    case '2':
      return item.palletNumber || 0
    case '3':
      return item.volume || 0
    case '4':
      return item.weight || 0
    case '5':
      return item.palletNumber || 0
    default:
      break;
  }
}

const regExpPoint = function (value, n) {
  let pows = Math.pow(10, (n || 2))
  let str = (value * pows) + '';
  let pattern = /^(\d+.{1}0*\d*[1-9]+)|(\d+.{1}0*[1-9]+\d*)$/;//小数点后的值是否大于0
  value = Math.round(value)
  if (pattern.test(str)) {
    value = Math.round(value * pows);//取整
    value = (value + 1) / pows
  }
  return value
}

//箱托计算
/*
* commodityJson:选货品的集合【数组】
* type:类型 'enterNumber':入库单 
*/
const totalFun = function (commodityJson, type) {
  let totalPakages = 0 //箱
  let totalVolume = 0 //体积
  let totalWeight = 0 //重量
  if (commodityJson.length) {
    for (let item of commodityJson) {
      let val = Number(item[type])
      if (val) {
        let pakages = item.specifications == '2' ? Number(item.number) * val : val
        totalPakages += pakages //箱
        totalVolume += Number(item.unitVolume ? item.unitVolume : 0) * val //体积
    
        totalWeight += Number(item.unitWeight ? item.unitWeight : 0) * val //重量 
      }
      
    }
   
  }
  console.log(totalVolume,'???')
  totalWeight = Math.ceil(totalWeight);//向上取整
  // totalVolume = regExpPoint(totalVolume / 1000000, 2);//保留两位小数
  totalPakages = Math.ceil(totalPakages);//向上取整
  // console.log(totalVolume,'ddd???')
  if (totalWeight == 0 && totalVolume == 0) {
    totalVolume = 0.01
  }
 
  return {
    totalPakages: totalPakages ? Number(totalPakages) : 0,
    totalVolume: totalVolume ?(Math.ceil(totalVolume / 10000) / 100).toFixed(2) : 0,
    totalWeight: totalWeight ? Math.ceil(totalWeight) : 0
  }

}
module.exports = {
  specsArr,
  warehousTypeArr,
  entryTable,
  outTable,
  outTypeArr,
  outPayArr,
  outTalkArr,
  entryInitialParms,
  outInitialParms,
  goodsTableHead,
  outGoodsTableHead,
  rulesNameFun,
  changeUnitPriceNameFun,
  totalFun
}