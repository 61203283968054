<template>
  <el-dialog
    :title="(optionType === 'add' ? '新增' : '修改') + labelName"
    :visible="isShowPcAdd"
    :close-on-click-modal="false"
    @close="closeAdd"
  >
    <el-form
      ref="addForm"
      :rules="regExp"
      :model="addrFormNew"
      label-width="80px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="姓名/公司名称"
            prop="userName"
            :rules="regExp.other"
          >
            <el-input v-model="addrFormNew.userName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="mobile" :rules="regExp.phone">
            <el-input v-model="addrFormNew.mobile"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所在地址" prop="areaCode" :rules="regExp.other">
            <el-cascader
              v-if="areaOptions.length"
              width="100%"
              placeholder="请选择省/市/区"
              ref="addressName"
              v-model="addrFormNew.areaCode"
              :options="areaOptions"
              @change="(val) => getAreaLable(val)"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="详细地址" prop="addrInfo" :rules="regExp.other">
            <el-input v-model="addrFormNew.addrInfo"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="智能录入" prop="recognitionInfo" class="rel">
            <el-input v-model="recognitionInfo" :placeholder="cpStr"></el-input>
            <span class="auto_btn" @click="getAddress">识别</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="xcenter mt20">
        <el-button type="primary" @click="onSubmit">{{
          optionType === "add" ? "新增" : "保存"
        }}</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
import { getAddressDetail, getCreatedAddrM } from "@/assets/js/order";
import { getUserStore, getAreaFilterList } from "@/assets/js/storage";
import { saveAddress } from "./addrUtils";
import { regExp } from "@/assets/js/global";
const userStore = getUserStore();
export default {
  name: "NewAddressEidtPC",
  props: {
    addrType: {
      type: String,
      default: "deliver",
    },
    isShowPcAdd: {
      type: Boolean,
    },
    optionType: {
      type: String,
    },
  },
  data() {
    return {
      regExp: {
        other: regExp("other"),
        phone: regExp("phone"),
      },
      addrFormNew: {
        addrId: "",
        userName: "",
        mobile: "",
        areaCode: [], // 选中的省市区
        province: "",
        city: "",
        district: "",
        addrInfo: "",
      },
      // addrFormNew: this.addrForm,
      show: false,
      corpId: userStore.companyName || "",
      xhrData: {},
      recognitionInfo: "",
      fieldNames: {
        text: "label",
        value: "value",
        children: "children",
      },
    };
  },
  watch: {
    addrForm(newVal) {
      this.addrFormNew = newVal;
    },
  },
  computed: {
    cpStr() {
      return this.addrType === "deliver"
        ? "试试粘贴发货人姓名、手机号、发货地址可以快速识别您的发货信息"
        : "试试粘贴收货人姓名、手机号、收货地址可以快速识别您的收货信息";
    },
    getAddr() {
      let newArr = "";
      let address = this.addrFormNew;
      newArr =
        (address.province || "") +
        "" +
        (address.city || "") +
        "" +
        (address.district || "");
      return newArr;
    },
    labelName() {
      return this.addrType === "deliver" ? "发货人" : "收货人";
    },
    areaOptions() {
      let ldStartList = getAreaFilterList("AREA_LD_START");
      let ldEndList = getAreaFilterList("AREA_LD_END");
      console.log(this.addrType === "deliver" ? ldStartList : ldEndList);
      return this.addrType === "deliver" ? ldStartList : ldEndList;
    },
  },
  methods: {
    getAddress() {
      if (!this.recognitionInfo) {
        this.$message("请填写智能录入数据");
        return;
      }
      getAddressDetail(
        this,
        this.addrType,
        this.recognitionInfo,
        this.callbackReco
      );
    },
    closeAdd() {
      this.recognitionInfo = ""
      this.$refs.addForm.resetFields();
      this.$emit("closeAdd", false);
    },
    getAreaLable(val) {
      setTimeout(() => {
        let labelVal = this.$refs["addressName"].inputValue;
        labelVal = labelVal.split(" / ");
        let arr = {
          province: labelVal[0] || "",
          city: labelVal[1] || "",
          district: labelVal[2] || "",
        };
        this.addrFormNew = {
          ...this.addrFormNew,
          ...arr,
        };
      }, 0);
    },
    callbackReco(data) {
      let address = getCreatedAddrM(data.addr, data.type);
      if (!address.mobile) {
        delete address.mobile;
      }
      if (!address.userName) {
        delete address.userName;
      }
      delete address.companyName;
      this.addrFormNew = {
        ...this.addrFormNew,
        ...address,
      };
    },
    onSubmit() {
      const res = this.addrFormNew;
      let params = {
        userId: userStore.id,
        corpId: userStore.corpId,
        areaCode1: res.areaCode[0] || "",
        areaCode2: res.areaCode[1] || "",
        areaCode3: res.areaCode[2] || "",
        areaInfo: res.addrInfo || "",
        addressType: this.addrType === "deliver" ? "2" : "1", //地址类型 1收货地址 2发货地址
        contacts: res.userName || "",
        mobile: res.mobile || "",
      };
      this.$refs["addForm"].validate((valid, event) => {
        if (valid) {
          saveAddress(this, params);
        }
      });
    },
  },
};
</script>
<style scoped>
.form {
  padding: 20px;
  color: #666;
}
.marTp30 {
  margin-top: 30px;
}
.sb_cont {
  border-radius: 12px;
}
.bg_gray,
.bg_gray ::v-deep .van-cell {
  background-color: #e8e8e8;
}
.bg_fff,
.bg_fff ::v-deep .van-cell {
  background-color: #fff;
}
.borL {
  border-left: 5px solid #f5ce98;
  padding-left: 10px;
}
.linkColor {
  color: #009ad8;
}
.list {
  width: 100%;
  height: 113px;
  margin-top: 12px;
  background-color: #fff;
}
.noData {
  padding: 30px 0;
  width: 100%;
  text-align: center;
  color: #666;
}
.sb_btn {
  width: 25%;
  line-height: 70px;
  text-align: center;
}
.c_blue {
  color: #1989fa;
}
</style>
