var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order_form"},[_c('header1'),_c('div',{staticClass:"head_tab bg_fff pt20 mb50"},[_c('div',{staticClass:"wauto tab_changeline"},[_c('span',{staticClass:"tab_li",class:_vm.fullPath.indexOf(_vm.orderNav[0].id) != -1 ||
          _vm.fullPath.indexOf(_vm.orderNav[1].id) != -1
            ? 'active'
            : '',on:{"click":function($event){return _vm.changeRoute(_vm.orderNav[0].id)}}},[_vm._v(" 运输下单 ")]),_c('span',{staticClass:"tab_li",class:_vm.fullPath.indexOf(_vm.wareNav[0].id) != -1 ||
          _vm.fullPath.indexOf(_vm.wareNav[1].id) != -1
            ? 'active'
            : '',on:{"click":function($event){return _vm.changeRoute(_vm.wareNav[0].id)}}},[_vm._v(" 仓储下单 ")])])]),_c('div',{staticClass:"pay_order",attrs:{"label":"运输下单","name":"1"}},[_c('div',{staticClass:"wauto"},[_c('div',{staticClass:"tab_changecolor mtb50"},_vm._l((_vm.fullPath === _vm.orderNav[0].id ||
          _vm.fullPath === _vm.orderNav[1].id
            ? _vm.orderNav
            : _vm.wareNav),function(item,index){return _c('span',{key:index,staticClass:"tab_li",class:_vm.fullPath.indexOf(item.id) != -1 ? 'active' : '',on:{"click":function($event){return _vm.changeRoute(item.id, true)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),(_vm.fullPath === _vm.orderNav[0].id)?_c('OrderForm'):(_vm.fullPath === _vm.orderNav[1].id)?_c('BatchOrderForm'):(_vm.fullPath.indexOf(_vm.wareNav[0].id) != -1)?_c('WareOrderForm',{key:"in"}):(_vm.fullPath.indexOf(_vm.wareNav[1].id) != -1)?_c('OutWareOrder',{key:"out"}):_vm._e()],1)]),_c('footer1')],1)}
var staticRenderFns = []

export { render, staticRenderFns }