<template>
   <div id="navBar" class="flex xrow">
       <div class="flex1 xycenter yrow" v-for="(item,index) in navArr">
            <img :src="item.route==$route.path?item.on_icon:item.icon" class="icon" @click="skip_page(item,index)">
            <span :class="item.route==$route.path?'navTitle':'_navTitle'">{{item.title}}</span>
       </div>

   </div>
</template>

<script>
export default {

    components: {

    },

    data() {
        return {
            navArr:[
                {
                    title:"首页",
                    icon:require('../../assets/images/mobile/home.png'),
                    on_icon:require('../../assets/images/mobile/home_on.png'),
                    route:''
                },
                {
                    title:"订单",
                    icon:require('../../assets/images/mobile/order.png'),
                    on_icon:require('../../assets/images/mobile/order_on.png'),
                    route:'/m_order',
                },
                {
                    title:"我的",
                    icon:require('../../assets/images/mobile/mine.png'),
                    on_icon:require('../../assets/images/mobile/mine_on.png'),
                    route:'/m_center'
                }
            ]
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        skip_page:function(obj,idx){
            if(idx==0){
                this.envJump('','href',0)
            }else{
                this.$router.replace(obj.route)
            }
        }
    }

}

</script>
<style scoped>
    #navBar{
        background-color: white;
        padding-top: 6px;
    }
    .navTitle{
        color: #B93A13;
    }
    .icon{
        width: 44px;
        height: 44px;
    }

</style>
