<template>
  <div class="goodsSelecttion">
    <h3 class="h3">货物信息</h3>
    <el-button type="primary" class="goodsDetailBtn" @click="chooseGoods('入库')">选择货品明细</el-button>
    <el-button type="primary" class="goodsDetailBtn" @click="batchDelete(tableChecked)">删除</el-button>
    <el-col :span="24">
      <el-form :rules="model.rules" :model="model" ref="goodsForm">
        <el-table class="tborder mt20 mb20" :data="model.goodsInforData" :ref="'multipleTable'+types"
          style="width: 100%;" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column type="index" width="50" label="序号" align="center">
          </el-table-column>
          <el-table-column :label="item.name" v-for="item in header" :key="item.val" align="center"
            :property="item.val" :width="item.width" :render-header="renderHeader" :fixed=" (types == 'in' && item.val==='enterNumber') || item.val==='outNumber' ? 'right':false">
            <template slot-scope="scope">
              <el-form-item v-if="types == 'in' && item.val==='enterNumber'"
                :prop="'goodsInforData.' + scope.$index + '.enterNumber'"
                :rules="model.rules.enterNumber"
                style="padding:0;">
                <el-input v-model="scope.row[scope.column.property]" :clearable="true"
                  @input="enterNumberChange($event)"></el-input>
              </el-form-item>
              <el-form-item v-else-if="types == 'out' && item.val==='outNumber'"
                :prop="'goodsInforData.' + scope.$index + '.outNumber'"
                :rules="model.rules.outNumber">
                <el-input v-model="scope.row[scope.column.property]" :clearable="true"
                  @input="enterNumberChange($event,scope.row.enterNumber)"></el-input>
              </el-form-item>
              <span v-else-if="item.val==='specifications'"> {{ scope.row[scope.column.property] | specifFormat}}</span>
              <span v-else > {{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-col>
    <Dialog ref="dialog" :config="config" :beforeClose="beforeClose" @close="resetForm">
      <GoodsDetail ref="goodsDetail" @choose="addGoods" :commodityData.sync="model.goodsInforData"
        :goodsHeader='goodHeader' :type="types" :goodsDetailId="id"></GoodsDetail>
    </Dialog>
  </div>
</template>
<script>
  import { getStorage } from "@/assets/js/global";
  import Dialog from './Dialog.vue';
  import GoodsDetail from "./goodsDetailForm.vue";
  import { totalFun } from '@/assets/js/warehouse.js'
  import { mapGetters, mapActions } from 'vuex';
  import { regExp } from "@/assets/js/global";

  let tableH = [
    {
      name: '商品编号',
      val: 'code',
      width:''
    },
    {
      name: '商品名称',
      val: 'name',
      width:'100'
    },
    {
      name: '商品规格',
      val: 'specifications',
      width:'100'
    },
    {
      name: '每托码货数量',
      val: 'number',
      width:'150'
    },
    {
      name: '生产日期',
      val: 'productionTime',
      width:'120'
    },
    {
      name: '失效日期',
      val: 'invalidTime',
      width:'120'
    },
  ]
  let inTableH = [
    {
      name: '入库数量',
      val: 'enterNumber',
      width:'150'
    },
  ]
  let outTableH = [
    {
      name: '库存数量',
      val: 'enterNumber',
      width:'150'
    },
    {
      name: '出库数量',
      val: 'outNumber',
      width:'150'
    },
  ]

  export default {
    name: 'goodsSelection',
    components: {
      GoodsDetail,
      Dialog
    },
    props: {
      // 弹窗内表格的表头
      goodHeaders: {
        type: Array,
        default: () => []
      },
      // 判断是出库还是入库，入库为字符串’1‘，出库为字符串’2‘
      types: {
        type: String,
        default: ''
      },
      // 详情页默认选中的商品列表值
      defaultGoods: {
        type: Array,
        default: () => []
      },
      id: {
        type: Object,
        default: () => { return {} }
      }
    },
    filters: {
      // 格式化规格
      specifFormat: function (val) {
        return val == 1 ? '箱' : val == 2 ? '托' : '';
      },
    },
    data() {
      let self = this
      const checkOut= (rule, value, callback) => {
        console.log(self.enterNumber);
      if (value > self.enterNumber) {
        return callback(new Error('大于库存数量！'));
      } else {
        return callback();
      }
     };
      return {
        enterNumber:'',
        config: {
          top: '20vh',
          width: '80%',
          title: '商品选择',
          center: true,
          btnTxt: ['取消', '加入订单'],
        },
        userStore: JSON.parse(getStorage("user")),

        header: this.types == 'in' ? tableH.concat(inTableH) : tableH.concat(outTableH),
        address: this.address,
        goodHeader: this.goodHeaders,
        type: this.types,
        tableChecked: [],//被选中的记录数据-----对应“批量删除”传的参数值
        ids: [],//批量删除id
        model: {
          goodsInforData: [], // 选择的商品数据
          rules: {
            enterNumber:regExp('integer'),
            outNumber: regExp('integer').concat([{ validator: checkOut}])
          },
        }
      }
    },
    watch:{
      // 因为需求清空数组所以在子组件加个监听赋值
      'defaultGoods': {
          handler: function (newValue,oldValue) {
            this.model.goodsInforData = newValue;
            this.$set(this.model.goodsInforData)
          },
          // 开启深度监听：只要defaultGoods中的任何一个属性发生改变，都会触发相应的代码
          deep: true
        }
    },
    mounted() {
      const unwatch = this.$watch(
      'defaultGoods',
      function (newValue, oldValue) {
        if (newValue && newValue.length > 0) {
          this.model.goodsInforData = newValue;
          this.$set(this.model.goodsInforData)
          if (unwatch) {
            unwatch();
          }
        }
      },
      { deep: true },
    );
    },
    methods: {
      ...mapActions('wareOrder', ['getCorpId']),
      beforeClose() {
        console.log('关闭前');
      },
      resetForm() {
        // 这里可以写重置表单的实现
      },
      addGoods(val) {
         this.model.goodsInforData = val
           this.model.goodsInforData.forEach(item => {
             this.$set(item)
         })
        // 传值给父组件
        this.$emit('getCommodityJson', this.model.goodsInforData)
      },
      chooseGoods(type) {
        //出库单
        if (this.types === 'out') {
          if (!this.id.depotId) {
            this.$message({
              message: '请选择仓库！',
              type: 'error'
            })
            return
          }
        }

        //入库单
        if (this.types === 'in') {

          if (!this.id.corpId && !this.id.companyName) {
            this.$message({
              message: '请填写客户名称',
              type: 'error'
            })
            return
          }

          if (!this.userStore.mobile) {
            this.$message({
              message: '客户手机号失效，请重新登录，或者联系管理员！',
              type: 'error'
            })
            return
          }
          if (!this.userStore.companyName && !this.userStore.corpId) {
            let data = {
              companyName: this.id.companyName,
              mobile: this.userStore.mobile
            }
            this.getCorpId(data);
            this.id.corpId = this.userStore.corpId
          }
        }
        // 弹窗确定按钮点击事件
        this.$refs.dialog.open(confirm => {
           this.$refs.goodsDetail.chooseGood();
          if(this.model.goodsInforData.length==0){
            this.$message({
              message: '至少选择一个货品！',
              type: "error",
            });
            return
          }else{
               this.$refs.dialog.cancel();
          }
       
        })

      },
      // 货品选择事件
      handleSelectionChange(val) {
        this.tableChecked = val
      },
      //批量删除事件
      batchDelete(rows) {
        let _this = this;
        if(rows.length==0){
            this.$message({
              message: '至少选择一个货品！',
              type: "error",
            });
            return
        }else{
          _this.$confirm('是否确认删除货品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          rows.forEach(item => {
            for (let i = this.model.goodsInforData.length - 1; i >= 0; i--) {
              if (this.model.goodsInforData[i].id == item.id) {
                this.model.goodsInforData.splice(i, 1);
              }

            }
          });
        });
        }
      },
      enterNumberChange(val,number) {
        let typeNum = this.type == 'in' ? 'enterNumber' : 'outNumber'
        let data = totalFun(this.model.goodsInforData, typeNum)
        // 将统计的总数传给父组件
        this.$emit('getTotalData', data)
        // 出库时用来判断库存参数
        if(this.types== 'out'){
         this.enterNumber = number
        }
      },
      renderHeader(h,obj){
        if(this.types=='in'){
         if (obj.column.label == '入库数量') {
           return h("div", {
              class: 'cell',
              domProps: {
                 innerHTML: '<span style="color:#F56C6C;">*&nbsp;</span>'+obj.column.label
              }
            })
         } else {
            return h("span", {}, obj.column.label)
          }
        }else{
          if (obj.column.label == '出库数量') {
           return h("div", {
              class: 'cell',
              domProps: {
                 innerHTML: '<span style="color:#F56C6C;">*&nbsp;</span>'+obj.column.label
              }
            })
         } else {
            return h("span", {}, obj.column.label)
          }
        }
      }
    }
  }
</script>
<style scoped>
  .goodsDetailBtn {
    width: 180px;
    line-height: 42px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    padding: 0;
    background-color: #0A8DE4;
  }
 .goodsSelecttion /deep/.el-input__inner{
  border-radius: 5px;
  border: 1px solid #dcdfe6;
  box-shadow: none;
  margin-top: 30px;
}
 .goodsSelecttion /deep/.el-table td.el-table__cell{
  padding: 0;
}
.goodsSelecttion /deep/.el-table .el-input__suffix{
 margin-top: 28px;
}
</style>