<template>
  <div class="form_area">
    <el-form :model="newStepArr" :rules="rules" ref="ruleForm" class="ruleForm">
      <h3 class="h3">货物信息</h3>
      <el-row>
        <CargoLabelCmp
          v-if="this.$store.state.cargoLabelList.length"
          :newStepArr="newStepArr"
          :sp="newStepArr.sp || ''"
          @changeSp="changeSp"
          :typeRef="'cargo' + faIdx"
          :dialogVisible="dialogVisible"
        ></CargoLabelCmp>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="货物类型" prop="cargoType">
            <el-select v-model="newStepArr.cargoType">
              <el-option
                v-for="item in this.$store.state.cargoTypeVO"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                @click.native="optionClick(item.name, 'cargoTypeName')"
                :disabled="drawerType == 'detail'"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="包装方式" prop="packing">
            <el-select v-model="newStepArr.packing">
              <el-option
                v-for="item in this.$store.state.byPCode.packingVO"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                @click.native="optionClick(item.name, 'packingName')"
                :disabled="drawerType == 'detail'"
              >
              </el-option>
            </el-select>
            <div
              v-if="newStepArr.packing == 'LZ'"
              style="position:absolute;top:32px;left: 0;font-size: 12px;color: #0081EB;"
            >
              注：小票零担产品无法承运裸装
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="件数（件）" prop="totalPakages">
            <el-input
              required
              v-model="newStepArr.totalPakages"
              placeholder="请输入件数"
              :disabled="drawerType == 'detail' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="重量（kg）" prop="goodsWeight">
            <el-input
              required
              v-model="newStepArr.goodsWeight"
              placeholder="请输入重量"
              :disabled="drawerType == 'detail' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="体积（方）" prop="goodsVolume">
            <el-input
              required
              v-model="newStepArr.goodsVolume"
              placeholder="请输入体积"
              :disabled="drawerType == 'detail' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="xright">
        <el-button type="primary" class="main_btn" @click="next()">下一步</el-button>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { regExp } from '@/assets/js/global'
import { submitBatchForm, saveStorage } from '@/assets/js/order'
import CargoLabelCmp from '@/components/cargoLabelCmp/CargoLabelCmp.vue' //引入货物规格
import { getUserStore } from '@/assets/js/storage'
const userData = getUserStore()
export default {
  props: {
    stepOneArr: {
      type: Object,
      required: true
    },
    isBatch: {
      type: Boolean
    },
    faIdx: {
      type: Number,
      required: true
    },
    dialogVisible: {
      //用于触发货物规格组件
      type: Boolean
    }
  },
  data() {
    return {
      drawerType: this.$store.state.drawerType,
      isLock: this.stepOneArr.dzdState === '0',
      userStore: userData,
      newStepArr: this.stepOneArr,
      rules: {
        cargoType: regExp('other', true, '请选择'),
        packing: regExp('other', true, '请选择'),
        goodsWeight: regExp('oneDec'),
        goodsVolume: regExp('sixDec'),
        totalPakages: regExp('integer')
      }
    }
  },
  components: {
    CargoLabelCmp
  },
  watch: {
    stepOneArr(newVal, oldVal) {
      this.newStepArr = { ...newVal }
    }
  },
  methods: {
    changeSp(item) {
      this.newStepArr = {
        ...this.newStepArr,
        ...item
      }
      saveStorage(this, this.newStepArr, this.isBatch, this.faIdx)
    },
    optionClick(name, field) {
      if (this.newStepArr[field] === name) return
      this.newStepArr[field] = name
    },
    next() {
      this.$emit('changeStepArr', this.newStepArr)
      submitBatchForm(this, 'ruleForm', this.newStepArr, this.faIdx, 2)
    }
  }
}
</script>
<style scoped></style>
