<template>
  <div class="order_form">
    <el-steps :active="$store.state.stepAction">
      <el-step title="Step 1" description="收发货信息、重量、体积"></el-step>
      <el-step title="Step 2" description="增值服务"></el-step>
      <el-step title="Step 3" description="选择产品"></el-step>
      <el-step title="Step 4" description="完成下单"></el-step>
    </el-steps>
    <div v-show="$store.state.stepAction === 1">
      <StepOne
        ref="StepOne"
        :stepOneArr="allFormData"
        :isBatch="false"
        :faIdx="-1"
        :isLock="allFormData.dzdState === '0'"
        @changeStepArr="changeStepArrTwo"
      ></StepOne>
    </div>
    <div v-show="$store.state.stepAction === 2">
      <StepTwo
        ref="StepTwo"
        :stepTwoArr="allFormData"
        :isBatch="false"
        :faIdx="-1"
        :isLock="allFormData.dzdState === '0'"
        @changeStepArr="changeStepArrTwo"
      ></StepTwo>
    </div>
    <div v-show="$store.state.stepAction === 3">
      <StepThree
        ref="StepThree"
        :stepThreeArr="allFormData"
        :isBatch="false"
        :faIdx="-1"
        :isLock="allFormData.dzdState === '0'"
        @changeStepArr="changeStepArrTwo"
      ></StepThree>
    </div>
    <div v-show="$store.state.stepAction === 4">
      <StepFour
        ref="StepFour"
        :stepFourArr="allFormData"
        :isBatch="false"
        :faIdx="-1"
        :isLock="allFormData.dzdState === '0'"
        @changeStepArr="changeStepArrTwo"
      ></StepFour>
    </div>
  </div>
</template>
<script>
import StepOne from './orderStepOne.vue'
import StepTwo from './orderStepTwo.vue'
import StepThree from './orderStepThree.vue'
import StepFour from './orderStepFour.vue'
import { getOrderForm, setOrderForm } from '@/assets/js/storage'
import { ORDERSTORE } from '@/assets/js/orderLibrary'
import {
  initSendersDate,
  initArea,
  getOrderDetial,
  handleInquiryData,
  getUserByMobile
} from '@/assets/js/order'
import { getCookie } from '@/assets/js/cookies.js'
export default {
  name: 'orderForm',
  data() {
    return {
      allFormData: this.$store.state.ruleForm //获取极速下单Form数据
    }
  },
  created() {
    getUserByMobile(this) //获取客户标签和货值减免
    let cookieOrderStore = getCookie('orderNewStore') //询价页面带来的数据
    //获取极速下单Form数据
    let routeParams = this.$route.params
    if (!!routeParams.orderNo) {
      let drawerType = routeParams.drawerType || 'edit'
      //从订单列表来-修改或者再来一单
      getOrderDetial(this, routeParams.orderNo, drawerType, this.callbackDetail) //获取订单详情
    } else {
      //新增
      let orderStore = getOrderForm()
      if (!orderStore) {
        orderStore = ORDERSTORE
        setOrderForm(orderStore)
      }
      if (cookieOrderStore) {
        //官网1.0快速询价下单参数
        let newCookie = handleInquiryData(cookieOrderStore)
        orderStore = {
          ...ORDERSTORE,
          ...newCookie
        }
      }
      //设置初始预计发货时间段
      let sendsDate = initSendersDate()
      orderStore = {
        ...orderStore,
        ...sendsDate
      }
      this.allFormData = orderStore
      //收发地址簿有地址则重置新增的收发货地址
      if (!cookieOrderStore) {
        initArea(this.areaCallback)
      }
    }
  },
  methods: {
    callbackDetail(res) {
      this.allFormData = res
    },
    changeStepArrTwo(val) {
      this.allFormData = { ...val }
      setOrderForm(val)
    },
    areaCallback(dataArr) {
      this.allFormData = {
        ...this.allFormData,
        ...dataArr.sendsArr,
        ...dataArr.receiveArr
      }
      this.allFormData = { ...this.allFormData }
      this.$store.state.ruleForm = this.allFormData
      setOrderForm(this.allFormData)
    }
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour
  }
}
</script>
<style scoped>
.order_form >>> .el-step__head {
  text-align: left;
}
.order_form >>> .el-step__title.is-finish,
.order_form >>> .el-step__description.is-finish {
  color: #0a8de4;
}
.order_form >>> .el-step__head.is-wait,
.order_form >>> .el-step__head.is-process {
  color: #666;
  border-color: #bdbdbd;
}
.order_form >>> .el-step__title {
  font-size: 20px !important;
  line-height: 2em !important;
}
.order_form >>> .el-step__description {
  font-size: 18px !important;
  line-height: 2em !important;
}
.order_form >>> .el-step__title.is-wait,
.order_form >>> .el-step__title.is-process {
  font-weight: normal;
  color: #666;
}
.order_form >>> .el-step__description.is-wait,
.order_form >>> .el-step__description.is-process {
  color: #666;
}
.order_form >>> .el-step.is-horizontal .el-step__line {
  top: 15px;
}
.order_form >>> .el-step__icon {
  width: 30px;
  height: 30px;
}
.order_form >>> .el-step__icon.is-text {
  border-radius: 0;
  border: none;
  border-color: transparent;
  background: url('../../../assets/images/step_icon.png') no-repeat -30px 0;
  background-size: 60px;
}
.order_form >>> .is-finish .el-step__icon.is-text {
  background-position: 0 0;
}
.order_form >>> .el-step__icon-inner {
  font-size: 0;
}
</style>
