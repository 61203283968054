<template>
  <div id="p_login" :style="{ backgroundImage: 'url(' + bg + ')' }">
    <div class="ycenter box">
      <img :src="logo" class="logo" />
      <img :src="codeimg" class="codeimg" />
    </div>
    <div class="loginbox">
      <div class="xycenter title">
        货主登录
      </div>
      <!-- tab -->
      <div class="flex">
        <div
          :class="index == 1 ? 'tab' : '_tab'"
          v-for="(item, index) in tabArr"
          @click="changeTab(item, index)"
        >
          <span :class="item.active ? 'active' : '_active'">
            {{ item.label }}
          </span>
        </div>
      </div>
      <div v-show="type == 0">
        <!-- 短信验证表单 -->
        <div class="flex row mt30">
          <input
            v-model="phone"
            type="text"
            maxlength="11"
            class="input mt30"
            placeholder="请输入手机号/账号"
          />
          <!-- <div>111111111</div> -->
        </div>
        <!-- 验证码表单 -->
        <div class="flex row mt30">
          <input
            v-model="code"
            type="text"
            maxlength="6"
            class="input flex1"
            placeholder="请输入验证码"
          />
          <div class="btn white fz18 xycenter" @click="getcode">
            <span v-show="show" @click="getcode">获取验证码</span>
            <span v-show="!show">{{ count }} s</span>
          </div>
        </div>
        <div class="loginbtn xycenter white fz18" @click="login">登录</div>
      </div>
      <!-- 微信登录二维码 -->
      <div id="wx_qrcode_login" class="xycenter" v-show="type == 1"></div>
    </div>
  </div>
</template>

<script>
const API = require('/src/assets/js/apilist.js')
import { setCookie, getCookie, delCookie } from '../../assets/js/cookies.js'
export default {
  name: 'p_login',
  components: {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log('打印vue实例', vm)
      // if(vm.isMobile()){
      //   vm.$router.replace('/m_login')
      // }
      // 通过 `vm` 访问组件实例
    })
  },
  data() {
    return {
      bg: require('/src/assets/images/bg.png'),
      codeimg: require('/src/assets/images/codeimg.png'),
      logo: require('/src/assets/images/logo.png'),
      tabArr: [
        { label: '短信验证登录', name: 'shortMsg', type: 0, active: true },
        { label: '微信扫码登录', name: 'scanLogin', type: 1, active: false }
      ],
      phone: '', //手机号
      code: '', //验证码
      show: true,
      count: '',
      timer: null,
      type: 0, //默认是账号登录
      activeName: 'shortMsg'
    }
  },
  created() {
    const that = this
    console.log(window.location.origin + '/portal/weixin/scanCallback')
    console.log('111111111111123', window.location)
    console.log('打印接口名称', API.bigticket)
    // console.log("2222223",this.isMobile())
    // that.$axios('post','/api'+API.bigticket,obj)
  },
  mounted() {
    // this.creatLoginCode()
    console.log('打印这个window.location.host', window.location.host)
    console.log('打印环境接口地址配置', process.env.VUE_APP_URL)
    console.log('打印环境', process.env.NODE_ENV)
  },
  methods: {
    // isMobile:function(){
    //   var flag=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    //   return flag;
    // },
    // envsocket
    envsocket: function() {
      switch (process.env.NODE_ENV) {
        case 'development':
          return 'mall-fat.yizhan56.cn'
          break
        case '85test':
          return 'mall-fat.yizhan56.cn'
          break
        case '86test':
          return 'mall-uat.yizhan56.cn'
          break
        case 'production':
          return window.location.origin
      }
    },
    // 生成微信扫码登录二维码
    creatLoginCode() {
      const that = this
      console.log(that.envsocket() + '/portal/weixin/scanCallback')
      this.timeStamp = new Date().getTime() //生成时间戳
      console.log('打印这个时间戳', this.timeStamp)
      // console.log("生成二维码的回调地址","http://m-test.yizhan56.cn:11195/portal/weixin/scanCallback")
      const obj = new WxLogin({
        self_redirect: true,
        id: 'wx_qrcode_login', // 需要显示的容器id
        appid: 'wx1f38304a3d87b4a6', // 公众号appid wx*******
        scope: 'snsapi_login', // 网页默认即可
        redirect_uri: encodeURIComponent(that.envsocket() + '/portal/weixin/scanCallback'), // 授权成功后回调的
        state: this.timeStamp, // 可设置为简单的随机数加session用来校验
        style: 'black', // 提供"black"、"white"可选。二维码的样式
        href: '' // 外部css文件url，需要https
      })
      if (this.type == 1) {
        //创建websocket
        var wsUri = ''
        switch (process.env.NODE_ENV) {
          case 'development':
            wsUri = 'ws://' + 'mall-fat.yizhan56.cn' + '/ws/websocket/' + this.timeStamp
            break
          case '85test':
            wsUri = 'ws://' + 'mall-fat.yizhan56.cn' + '/ws/websocket/' + this.timeStamp
            break
          case '86test':
            wsUri = 'ws://' + 'mall-uat.yizhan56.cn' + '/ws/websocket/' + this.timeStamp
            break
          case 'production':
            wsUri = 'wss://' + window.location.host + '/ws/websocket/' + this.timeStamp
            break
        }
        console.log('打印socket地址', wsUri)
        var websocket = new WebSocket(wsUri)
        websocket.onopen = function(evt) {
          console.log('socket连接成功', evt)
        }
        websocket.onerror = function(evt) {
          console.log('socket连接失败', evt)
        }
        websocket.onmessage = function(evt) {
          // var res=JSON.parse(evt.data.data);
          console.log('socket发送数据', JSON.parse(evt.data))
          var res = JSON.parse(evt.data)
          if (res.isSuccess == true) {
            that.creatLoginCode()
            console.log('执行socket成功时的回调', res)
            console.log(res.data.accessToken)
            console.log(res.data.member)
            //更新缓存token,user
            localStorage.setItem('token', res.data.accessToken)
            localStorage.setItem('user', JSON.stringify(res.data.member))
            setCookie('mobile', res.data.member.mobile, 1, '', '.yizhan56.cn')
            setTimeout(() => {
              websocket.close()
            }, 10000)
            // websocket.close() //关闭socket
            if (!!that.$route.query.orderno) {
              that.envJump('/html/trajectory.html?orderSn=' + that.$route.query.orderno, 'href', 0)
            } else {
              that.envJump('', 'href', 0)
            }
          }
        }
      }
    },
    jumpweb: function() {
      if (process.env.NODE_ENV == 'development') {
        window.location.href = 'http://192.168.100.85:8098/'
      } else {
        window.location.href = 'http://mall.yizhan56.cn/'
      }
    },
    getcode:function(){
      const that=this;
      var myreg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;//手机正则
      if(that.phone!=""&&myreg.test(that.phone)){//手机号不为空,并且是正确格式
        const _time = 60;
        const params={
          mobile:that.phone
        }
        if (!that.timer) {
          that.count = _time
          that.show = false
          // that.getData2()
          that.$axios('post', API.sendIdentifyingCode, params, 'data')
          // that.$axiosUrl('post',API.sendIdentifyingCode,params,'params')
          that.timer = setInterval(() => {
            if (that.count > 0 && that.count <= _time) {
              that.count--
            } else {
              that.show = true
              clearInterval(that.timer)
              that.timer = null
            }
          }, 1000)
        }
      } else {
        that.$message.error('请输入正确手机号')
      }
    },
    login:function(){
      const that=this;
      var myreg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;//手机正则
      if(that.type==0){//type:0短信登录,type:1扫码登录
        if(that.phone==""||!myreg.test(that.phone)){
          that.$message.error("请输入正确手机号");
        }else if(that.code==""){
          that.$message.error("请输入验证码");

        }else{
          let params={
            captcha: that.code,
            loginType: 1,
            mobile: that.phone,
            password: that.code,
            loginSource: 'officialWeb'
          }
          that
            .$axios('post', API.login, params, 'data', 'application/json')
            .then(res => {
              console.log('登录成功', res)
              if (res.isSuccess) {
                localStorage.setItem('token', res.data.accessToken) //设置token缓存
                localStorage.setItem('user', JSON.stringify(res.data.member)) //缓存用户信息
                setCookie('mobile', res.data.member.mobile, 1, '', '.yizhan56.cn')
                that.$message({
                  message: res.msg,
                  type: 'success'
                })
                if (!!that.$route.query.orderno) {
                  console.log('打印这个orderno', that.$route.query.orderno)
                  that.envJump(
                    '/html/trajectory.html?orderSn=' + that.$route.query.orderno,
                    'href',
                    0
                  )
                } else {
                  that.envJump('', 'href', 0)
                }
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch(error => {
              console.log('登录失败', error)
            })
        }
      } else {
        console.log('连接socket')
      }
    },
    getData2: function() {
      const that = this
      localStorage.setItem(
        'token',
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ7XCJidXNpbmVzc0xpY2Vuc2VcIjpcIlwiLFwiYnVzaW5lc3NMaWNlbnNlTnVtXCI6XCJcIixcImNsaWVudENvZGVcIjpcIlNDS0gyMDIxMDUwMjNcIixcImNvbXBhbnlBZGRyZXNzXCI6XCJcIixcImNvbXBhbnlOYW1lXCI6XCIxMTExXCIsXCJjb3JwSWRcIjoxNDQ4OTQxNTc2NDE4NDEwNDk3LFwiaWRcIjpcIjE0NDg5NDE1Njg1MTYzNDE3NjFcIixcImxhc3RMb2dpbklwXCI6XCIxMC4xLjQuMTA5XCIsXCJsYXN0TG9naW5UaW1lXCI6MTYzNDI4OTU2NTAwMCxcImxhc3RNb2RpZnlUaW1lXCI6MTYzNTIzNTc0NTg1NSxcImxlZ2FsUGVyc29uXCI6XCJcIixcImxvZ2luTnVtXCI6MSxcIm1vYmlsZVwiOlwiMTg2NjU1MDQ5MzZcIixcIm5ld1VzZXJGbGFnXCI6ZmFsc2UsXCJyZWdpc3RlclBhdGhcIjpcIjFcIixcInJlZ2lzdGVyVGltZVwiOjE2MzQyODk1NjUwMDAsXCJyb2xlQ29kZVwiOlwiYWRtaW5cIixcInNldHRsZUN5Y2xlXCI6XCJcIixcInNldHRsZVR5cGVcIjpcIlwiLFwidXNlclN0YXRlXCI6MSxcInVzZXJUeXBlXCI6XCIxXCIsXCJ4Y3hJbmZvXCI6XCJ7XFxcInBob25lTnVtYmVyXFxcIjpcXFwiMTg2NjU1MDQ5MzZcXFwiLFxcXCJ1bmlvbklkXFxcIjpcXFwib3ZlNk8wcExmQ2VPZXZhdU90VEZ0LXJTcnhsY1xcXCIsXFxcInNlc3Npb25LZXlcXFwiOlxcXCJkWFd3Rk4xaXFpc1pneTVRd29pRy9nPT1cXFwiLFxcXCJwdXJlUGhvbmVOdW1iZXJcXFwiOlxcXCIxODY2NTUwNDkzNlxcXCIsXFxcImNvdW50cnlDb2RlXFxcIjpcXFwiODZcXFwiLFxcXCJvcGVuSWRcXFwiOlxcXCJvTmJHOTR0dDFEYklnSGo3NXFIYWFwRkl1RHFjXFxcIixcXFwiYXBwaWRcXFwiOlxcXCJ3eDJhNjBkMWRjNWZlNGI3MzBcXFwiLFxcXCJ0aW1lc3RhbXBcXFwiOjE2MzQyODk3MTB9XCIsXCJ4Y3hPcGVuaWRcIjpcIm9OYkc5NHR0MURiSWdIajc1cUhhYXBGSXVEcWNcIn0iLCJjdXJyZW50VXNlciI6MTQ0ODk0MTU2ODUxNjM0MTc2MSwiZXhwIjoxNjM3ODI3NzQ1LCJpYXQiOjE2MzUyMzU3NDV9.7Zsp-zO051kz_6n9tt-t6HPRpvIpoW1emiaDdwxggf8'
      )
      that
        .axios({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ7XCJidXNpbmVzc0xpY2Vuc2VcIjpcIlwiLFwiYnVzaW5lc3NMaWNlbnNlTnVtXCI6XCJcIixcImNsaWVudENvZGVcIjpcIlNDS0gyMDIxMDUwMjNcIixcImNvbXBhbnlBZGRyZXNzXCI6XCJcIixcImNvbXBhbnlOYW1lXCI6XCIxMTExXCIsXCJjb3JwSWRcIjoxNDQ4OTQxNTc2NDE4NDEwNDk3LFwiaWRcIjpcIjE0NDg5NDE1Njg1MTYzNDE3NjFcIixcImxhc3RMb2dpbklwXCI6XCIxMC4xLjQuMTA5XCIsXCJsYXN0TG9naW5UaW1lXCI6MTYzNTI5NjU3MzM3NyxcImxhc3RNb2RpZnlUaW1lXCI6MTYzNTIzNTc0NjAwMCxcImxlZ2FsUGVyc29uXCI6XCJcIixcImxvZ2luTnVtXCI6MixcIm1vYmlsZVwiOlwiMTg2NjU1MDQ5MzZcIixcIm5ld1VzZXJGbGFnXCI6ZmFsc2UsXCJyZWdpc3RlclBhdGhcIjpcIjFcIixcInJlZ2lzdGVyVGltZVwiOjE2MzQyODk1NjUwMDAsXCJyb2xlQ29kZVwiOlwiYWRtaW5cIixcInNldHRsZUN5Y2xlXCI6XCJcIixcInNldHRsZVR5cGVcIjpcIlwiLFwidXNlclN0YXRlXCI6MSxcInVzZXJUeXBlXCI6XCIxXCIsXCJ4Y3hJbmZvXCI6XCJ7XFxcInBob25lTnVtYmVyXFxcIjpcXFwiMTg2NjU1MDQ5MzZcXFwiLFxcXCJ1bmlvbklkXFxcIjpcXFwib3ZlNk8wcExmQ2VPZXZhdU90VEZ0LXJTcnhsY1xcXCIsXFxcInNlc3Npb25LZXlcXFwiOlxcXCJkWFd3Rk4xaXFpc1pneTVRd29pRy9nPT1cXFwiLFxcXCJwdXJlUGhvbmVOdW1iZXJcXFwiOlxcXCIxODY2NTUwNDkzNlxcXCIsXFxcImNvdW50cnlDb2RlXFxcIjpcXFwiODZcXFwiLFxcXCJvcGVuSWRcXFwiOlxcXCJvTmJHOTR0dDFEYklnSGo3NXFIYWFwRkl1RHFjXFxcIixcXFwiYXBwaWRcXFwiOlxcXCJ3eDJhNjBkMWRjNWZlNGI3MzBcXFwiLFxcXCJ0aW1lc3RhbXBcXFwiOjE2MzQyODk3MTB9XCIsXCJ4Y3hPcGVuaWRcIjpcIm9OYkc5NHR0MURiSWdIajc1cUhhYXBGSXVEcWNcIn0iLCJjdXJyZW50VXNlciI6IjE0NDg5NDE1Njg1MTYzNDE3NjEiLCJleHAiOjE2Mzc4ODg1NzMsImlhdCI6MTYzNTI5NjU3M30.WFvyF_n-SVrr9NCdnlxOqlrRWm3M7zVSWjrCy7tkHzc'
          },
          url: process.env.VUE_APP_URL + API.sendIdentifyingCode,
          params: {
            mobile: that.phone
          }
        })
        .then(function(res) {
          console.log('请求成功', res)
        })
        .catch(function(e) {
          console.log('请求失败', e)
        })
    },
    changeTab: function(obj, idx) {
      const that = this
      that.tabArr.forEach((item, index) => {
        if (index == idx) {
          that.tabArr[idx].active = true
          that.type = item.type
          if (that.type == 1) {
            that.creatLoginCode()
          }
        } else {
          that.tabArr[index].active = false
        }
        console.log(item)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#p_login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.logo {
  width: 218px;
  height: 67px;
}
.codeimg {
  width: 225px;
  height: 123px;
  margin-left: 88px;
}
.box {
  margin-left: 114px;
  margin-top: 59px;
}
.loginbox {
  width: 414px;
  min-height: 488px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 23px;
  position: fixed;
  top: 50%;
  left: 77%;
  transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
}
.title {
  font-family: 'MicrosoftYaHei';
  font-size: 30px;
  color: #2b2b2b;
  padding: 32px 0;
  border-bottom: 1px solid #d9d9d9;
}
.tab {
  display: flex;
  flex: 1;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border-left: 1px solid #D9D9D9; */
}
._tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.active {
  padding: 14px;
  color: #0a8de4;
  border-bottom: 2px solid #0a8de4;
  font-family: 'MicrosoftYaHei';
}
._active {
  padding: 14px;
  font-family: 'MicrosoftYaHei';
}
.padtb13 {
  padding: 13px 0;
}
.el-tabs >>> .el-tabs__item {
  font-size: 20px;
  color: #2b2b2b;
}
>>> .el-tabs__nav-wrap::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: white;
  border: none;
  z-index: 1;
}
.input {
  width: 100%;
  height: 53px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 18px;
  text-indent: 20px;
}
.mt30 {
  margin-top: 30px;
}
.btn {
  width: 114px;
  background: #0a8de4;
  border-radius: 5px;
  cursor: pointer;
}
.fz18 {
  font-size: 18px;
}
.row {
  width: 100%;
}
.loginbtn {
  height: 53px;
  background: #0a8de4;
  border-radius: 5px;
  margin-top: 56px;
  cursor: pointer;
}
/* .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: white !important;
    z-index: 1;
} */

/* ::v-deep.el-tabs__item{
  font-size:18px;
  color: red;
} */
</style>
