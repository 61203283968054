<template>
   <div id="TransList" class="flex1 flex yrow">
       <div class="box flex yrow">
            <van-tabs v-model="tabValue" animated swipeable @change="renderlist">
                <van-tab v-for="(item,index) in tabArr" :title="item.title" :key="item.title"></van-tab>
            </van-tabs>
            <van-list class="vanlist" id="vanlist" ref="vanlist" :offset="offset" v-model="loading" :finished="finished" :finished-text="finishText" @load="loadMore">
                <!-- 运输订单demo -->
                <div class="demo" v-for="(item,index) in orderArr">
                    <div class="flex">
                        <div class="flex1">
                            共<span class="blue">{{item.parentOrderNoSize}}</span>票货物
                        </div>
                        <div class="flex1 flex xright fz28 blue">
                            <span v-if="item.allcancel" @click.stop="allCancel(item.list)">全部取消</span>
                            <span v-if="item.allcancel&&item.isShowButton==1"class="line"></span>
                            <span v-if="item.isShowButton==1" @click="addChildOrder(item,'add')">添加子订单</span>
                        </div>
                    </div>
                    <div v-for="(item2,index2) in item.list" class="orderDemo" @click="skip_transDetail(item2)">
                        <div class="flex fz28 b_border">
                            <div class="flex1 flex pad20 ycenter">
                                <div>单号:{{item2.orderNo}}</div>
                                <i class="iconfont icon-fuzhi ml20" @click.stop="againOrder(item2,'again')"></i>
                            </div>
                            <div class="flex1 flex pad20 xright fs24">
                                <div v-if="item2.state==3||item2.state==4||item2.state==5" class="symbol flex xycenter">下单成功</div>
                                <div v-if="item2.state==6||item2.state==7" class="symbol2 flex xycenter">运输中</div>
                                <div v-if="item2.state==8||item2.state==9" class="symbol3 flex xycenter">已签收</div>
                                <div v-if="item2.state==0" class="symbol4 flex xycenter">撤销</div>
                            </div>
                        </div>
                        <div class="flex pad20 fz28">
                            <div class="flex1 xleft fs34">
                                {{item2.sendersCityName}}{{item2.sendersDistrictName}}
                            </div>
                            <div>
                                <img :src="arrow" class="arrow">
                            </div>
                            <div class="flex1 xright fs34">
                                {{item2.recipientCityName}}{{item2.recipientDistrictName}}
                            </div>
                        </div>
                        <div class="flex pl20 pr20 fz28">
                            <div class="flex1">
                                <div>结算方式:{{item2.paymentTypeName}}</div>
                                <div class="pt20">待付:{{item2.surplusMoney}}</div>
                            </div>
                            <div class="flex1">
                                <div>总费用:{{item2.payment}}</div>
                                <div class="pt20">已付:{{item2.paidMoney}}</div>
                            </div>
                        </div>
                        <div class="fz28 pl20 pt20 pb20">下单时间:{{item2.createTime}}</div>
                        <div class="flex pr20 xright pb20">
                            <div class="btn" v-if="item2.state==3" @click.stop="cancel(item2,index,index2,item.list)">撤销</div>
                            <div class="btn" v-if="item2.state==9&&!item2.score" @click.stop="pay(item2)">支付</div>
                            <div class="btn" v-if="item2.state == 3 && item2.paidMoney == 0&&item2.chargingMode!=2" @click.stop="changeOrder(item2,'edit')">订单修改</div>
                            <div class="btn share">分享订单</div>
                        </div>

                    </div>
                </div>
            </van-list>
        </div>
   </div>
</template>

<script>
    const API=require('/src/assets/js/apilist.js');
export default {

    components: {

    },

    data() {
        return {
            arrow:require('../../assets/images/mobile/order_jt.png'),
            tabValue:"",
            tabArr:[
               { title:"全部",status:""},
               { title:"下单成功",status:"3,4,5"},
               { title:"运输中",status:"6,7"},
               { title:"已签收",status:"8,9"},
               { title:"撤销",status:"0"}
            ],
            form:{
                // size: 15,
                // userId: "",
                // status: "",
                // current: 1,
                // sendTimeBegin: "",
                // sendTimeEnd: "",
                // sendCity: "",
                // sendDist: "",
                // endCity: "",
                // endDist: "",
                // payState: "",
                // orderNo: ""
            },
            orderArr:[],//订单列表
            page:1,
            offset:5,
            finishText:'没有更多了',
            loading: false,
            finished: false
            
        }
    },

    created() {
        this.form=this.$store.state.searchForm.form;
        console.log("运输订单列表组件初始化form数据",this.form);
        // if(!!this.$route.params.form){
        //     that.form
        // }
        const params=this.filterParams(this.form)
        this.updateTransOrder(params);
        

    },


    mounted() {
        // this.isALLCancel()

    },

    methods: {
        // 支付
        pay:function(obj){
            const that=this;
            console.log("打印这个obj",obj)
            that.$router.push({
                name:'m_payment',
                params:{
                    orderIds:[obj.id],
                    orderNum:1,
                    amount:Number(obj.surplusMoney),
                    pageName:0,//运输订单
                }
            })
        },
        // 全部取消
        allCancel:function(arr){
            const that=this;
            this.$dialog.confirm({
                title: '温馨提示',
                message: '你确定要取消批量下单中的所有订单吗?',
                cancelButtonText:'再考虑下',
                confirmButtonText:'确认',
                confirmButtonColor:'#007EC5'
                })
                .then((res) => {
                    var idArr=[];
                    arr.forEach(item => {
                        idArr.push(item.id)
                    });
                    // on confirm
                    var params={
                        orderIds:idArr.join()
                    }
                    that.$axios('post',API.batchOrderDelete,params,'data')
                    .then((res)=>{
                        if(res.isSuccess==true){
                            that.$toast('撤销订单成功')
                            this.form.current=1;
                            that.orderArr=[]
                            const params=this.filterParams(this.form)
                            this.updateTransOrder(params);

                        }else{

                        }
                    })
                    .catch((error)=>{

                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 判断是否显示-全部取消按钮
        isALLCancel:function(arr){
            const that=this;
            var arr=[1,2,3,4,5];
            var a=arr.find((item,index,arr)=>{
                return item!=3;
            })
            console.log("打印a",a)
        },
        // 撤销订单
        cancel:function(obj,idx,idx2,list){
            const that=this;
            console.log("1111111111111111111111")
            this.$dialog.confirm({
                title: '温馨提示',
                message: list.length>1?'该订单使用了批量下单优惠，取消后该批订单的优惠金额将少减免¥40元，您继续取消订单吗？':'您确定要撤销此订单吗？',
                cancelButtonText:'再考虑下',
                confirmButtonText:'确认',
                confirmButtonColor:'#007EC5'
                })
                .then((res) => {
                    console.log("打印这个obj",obj)
                    // on confirm
                    var params={
                        orderIds:obj.id
                    }
                    that.$axios('post',API.batchOrderDelete,params,'data')
                    .then((res)=>{
                        if(res.isSuccess==true){
                            that.$toast('撤销订单成功')
                            that.orderArr[idx].list[idx2].state=0;

                            // const params=this.filterParams(this.form)
                            // this.updateTransOrder(params);

                        }else{

                        }
                    })
                    .catch((error)=>{

                    })
                })
                .catch(() => {
                    // on cancel
                });
            },
        // 修改订单
        changeOrder:function(obj,state){
            console.log("打印参数obj",obj)
            console.log("打印参数state",state)
            console.log("修改打印$store",this.$store.state.ruleForm)
            this.$router.push({
                name:'m_payorder',
                params:{
                    orderNo:obj.orderNo,
                    drawerType:state
                }
            })
        },
        // 添加子订单
        addChildOrder:function(obj,state){
            console.log("打印参数obj",obj)
            console.log("打印参数state",state)
                this.$router.push({
                    name:'m_batchorder',
                    params:{
                        parentNo:obj.parentOrderNo,
                        drawerType:state,
                        orderId:obj.parentOrderNo!=''?'':obj.list[0].id
                    }
                })
            
            
        },
        skip_transDetail:function(obj){
            const that=this;
            console.log("打印这个obj",obj)
            that.$router.push({path:'/m_transDetail',query:{
                orderSn:obj.orderNo
            }})
        },
        // 再下一单
        againOrder:function(obj,state){//idx是第一层数组下标,idx2数组元素中list的下标
            const that=this;
            this.$router.push({
                name:'m_payorder',
                params:{
                    orderNo:obj.orderNo,
                    drawerType:state
                }
            })


        },
        // 滑动切换tab
        renderlist:function(idx,title){
            const that=this;
            console.log("111111111",that.$refs.vanlist);//滚动条回到顶部
            document.body.scrollTop = 0;
            document.getElementById('vanlist').scrollTop = 0;
            that.form.current=1;
            that.orderArr=[];
            console.log(idx,title)
            that.form.status=that.tabArr[idx].status;
            that.updateTransOrder(that.form);
        },
        // 获取运输订单列表
        updateTransOrder:function(Params){
            const that=this;
            // this.$toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            //     duration:800
            // });
            that.loading=true;
            that.finished=false;
            // that.$loading({text:'加载数据中...'})
            let params=that.filterParams(Params)
            that.$axios('post',API.pageList,params).then((res)=>{
                console.log("获取订单列表",res)
                if(res.data.length>0){
                    that.orderArr=that.orderArr.concat(res.data);
                    that.orderArr.forEach(item => {
                        item.allcancel=true;
                        item.list.forEach((item2)=>{
                            if(item2.state!=3){
                                item.allcancel=false;
                            }
                        })
                    });
                }else{
                    that.finished=true;
                }
            }).catch((error)=>{

            })
            that.loading=false;
        },
        // 加载更多
        loadMore:function(){
            const that=this;
            console.log("触发了Loadmore")
            that.form.current=that.form.current+1;
            // this.$toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            //     duration: 2
            // });
            const params=this.filterParams(this.form)
            this.updateTransOrder(params);
        },
        // 获取运输列表数据
        getData:function(){
            const that=this;
            that.$axios('')

        },
        selectTab:function(value){
            const that=this;
            console.log("打印子组件传过来的参数",value);
            that.type=value;
            if(value==0){
                that.prompt="请输入订单编号查询"
            }else{
                that.prompt="请输入出入库单号查询"
            }
        },
        // 跳转客服
        skip_kefu:function(){
            const that=this;
            that.$router.push('/m_kefu')
            
        }
    }

}

</script>
<style scoped>
    #TransList{
        flex:1;
        position: relative;
        background-color: #F5F5F5;
    }
    .fs24{
        font-size: 24px;
    }
    .pt20{
        padding-top: 20px;
    }
    .box{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    #m_order{
        /* width: 100%;
        min-height: 100vh;
        overflow: hidden; */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(245, 245, 245);
    }
    .phoneDiv{
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 120px;
        right: 30px;
    }
    .icon-fuzhi{
        font-size: 20px;
    }
    .icon-dianhua3{
        color: white;
        font-size: 50px;
    }
    .share{
        border: 1px solid #007EC5 !important;
        color: #007EC5;
    }
    .flowhidden{
        overflow: hidden;
    }
    .relative{
        position: relative;
    }
    
    .scrollY{
        overflow-y: scroll;
    }
    >>>.van-tabs__content{
        flex: 1;
        /* overflow: hidden; */
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        
    }
    >>>.van-tab--active {
        color: #007EC5;
        font-weight: 500;
    }
    >>>.van-tabs__line {
        position: absolute;
        bottom: 15px;
        left: 0px;
        z-index: 1;
        width: 40px;
        height: 3px;
        background-color: #007EC5;
        border-radius: 3px;
    }
    .vanlist{
        flex: 1;
        /* height: 100%; */
        /* overflow: hidden; */
        overflow-y: scroll;
        background-color: rgb(245, 245, 245);
       
    }
    .blue{
        color: #2A97E8;
    }
    .line{
        height: 35px;
        border-right: 2px solid #707070;
        margin-left: 20px;
        margin-right: 20px;
    }
    .btn{
        padding: 20px 20px;
        border-radius: 45px;
        border: 1px solid #DBDCDB;
        border-radius: 50px;
        font-size: 28px;
        margin-left: 12px;
    }
    .pb20{
        padding-bottom: 20px;
    }
    .pl20{
        padding-left: 20px;
    }
    .pr20{
        padding-right: 20px;
    }
    .b_border{
        border-bottom: 1px solid #DBDCDB;
    }
    .pad20{
        padding: 20px;
    }
    .fs34{
        font-size: 34px;
    }
    .fz28{
        font-size: 28px;
    }
    .orderDemo{
        background-color: white;
        margin-top: 20px;
        border-radius: 24px;
    }
    .arrow{
        width: 60px;
        height: auto;
    }
    .symbol{
        background-color: #007EC5;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    .symbol2{
        background-color: #007EC5;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    .symbol3{
        background-color: #76B040;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    .symbol4{
        background-color: #D3D3D3;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    .demo{
        background-color: #E7F0F2;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 24px;
    }

</style>
