<template>
  <div class="m_addr p20">
    <van-row>
      <van-col span="20">
        <van-field
          class="box_ipt"
          v-model="searchWords"
          label-width="60px"
          placeholder="请输入地址/姓名模糊查找"
        />
      </van-col>
      <van-col span="4">
        <van-button type="info" size="small" @click="onSearch">
          查询
        </van-button>
      </van-col>
    </van-row>
    <div class="mt20">
      <div
        class="rowlist flex_m"
        v-for="item in tableList"
        :key="item.id"
        :class="item.isCheck === false ? 'hidden-row' : ''"
        @click="() => changeSelect(item)"
      >
        <div class="mr20">
          <input
            v-if="activeType === 'radio'"
            class="flex1"
            type="radio"
            name="radio"
            :value="item.id"
            v-model="radioSelect"
          />
          <input
            v-else
            type="checkbox"
            name="checkbox"
            :value="item.id"
            :disabled="item.isDisabled"
            v-model="checkboxIdRows"
          />
        </div>
        <div>
          <div>
            <span class="mr20 fz28">{{ item.contacts }}</span>
            <span class="mr20 fz28">{{ item.mobile }}</span>
          </div>
          <div class="mt10 fz28">{{ renderInfo(item) }}</div>
        </div>
      </div>
      <div class="box_bottom tc">
        <button class="btn cancel mr20" @click="goToAdd">新增</button>
        <template v-if="activeType === 'checkbox'">
          <button class="btn cancel mr20" @click="cancleChoose">取消</button>
          <button class="btn mr20" @click="handleSelect">确定</button>
        </template>
      </div>
    </div>
    <van-empty v-if="list.length == 0" description="暂无数据" />
  </div>
</template>
<script>
import Vue from "vue";
import { AddressList } from "vant";

Vue.use(AddressList);
export default {
  name: "AddressListM",
  props: {
    addressType: {
      type: String,
      default: "deliver",
    },
    activeType: {
      type: String,
    },
    checkdId: {
      type: Array,
    },
    tableList: {
      type: Array,
    },
  },
  data() {
    return {
      radioSelect: "",
      checkboxIdRows: this.checkdId || [],
      searchWords: "",
    };
  },
  watch: {
    checkdId(newVal) {
      this.checkboxIdRows = newVal;
    },
  },
  computed: {
    list() {
      let newDatas = [];
      this.tableList &&
        this.tableList.map((item) => {
          if (item.isCheck !== false) {
            newDatas.push(item);
          }
        });
      return newDatas;
    },
  },
  methods: {
    renderInfo(item) {
      const area1 = item.areaCode1Name || "";
      const area2 = item.areaCode2Name || "";
      const area3 = item.areaCode3Name || "";
      const area4 = item.areaCode4Name || "";
      const info = item.areaInfo || "";
      return `${area1}${area2}${area3}${area4}${info}`;
    },
    changeSelect(item) {
      if (this.activeType === "checkbox" && item.isDisabled) return; //父组件带过来已选中的不能再操作
      if (this.activeType === "radio") {
        this.radioSelect = item.id;
        this.$emit("checkItemM", item.id, this.addressType);
      } else if (this.activeType === "checkbox") {
        let isTrue = this.checkboxIdRows.indexOf(item.id) === -1;
        if (isTrue) {
          //不存在则添加
          this.checkboxIdRows.push(item.id);
        } else {
          let key = this.checkboxIdRows.indexOf(item.id);
          this.checkboxIdRows.splice(key, 1);
        }
      }
    },
    handleSelect() {
      let returnId;
      if (this.activeType === "radio") {
        returnId = this.radioSelect;
      } else {
        returnId = this.checkboxIdRows;
      }
      this.$emit("checkItemM", returnId, this.addressType);
    },
    // 清空搜索框
    handleReset() {
      this.searchWords = "";
      this.$emit("search", "");
    },
    cancleChoose() {
      this.$emit("cancleChoose");
    },
    // 模糊搜索
    onSearch() {
      this.$emit("search", this.searchWords);
    },
    goToAdd() {
      let path = this.$route.path;
      if (path === "/addressManage") {
        //在新增页面时
        this.$emit("changeAddOneAddr", { isShow: false, type: "add" });
      } else {
        this.$router.push({
          name: "addressManage",
          params: { path, type: this.addressType },
        });
      }
    },
  },
};
</script>
<style scoped>
.m_addr {
  background: #f3f3f3;
  padding-bottom: 110px;
}
.box_ipt {
  padding: 8px;
  border-radius: 8px 0 0 8px;
}
input[type="checkbox"] {
  width: 36px;
  height: 36px;
}
.rowlist {
  padding: 15px 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.box_bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 0;
  background-color: #fff;
}
.box_bottom .btn {
  width: 150px;
  margin: 0 20px;
  line-height: 70px;
  color: #fff;
  background-color: #1989fa;
  border: 1px solid transparent;
  border-radius: 8px;
}
.box_bottom .btn.cancel {
  color: #1989fa;
  background-color: #fff;
  border-color: #dbdbdb;
}
.hidden-row {
  display: none;
}
</style>