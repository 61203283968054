<template>
   <div id="m_order" class="flex yrow">
        <SearchInput :prompt="prompt" :type="type"></SearchInput>
        <orderTab @selectTab="selectTab" :type="type"></orderTab>
        <TransLIst v-show="type==0"></TransLIst>
        <StoreList v-show="type==1"></StoreList>
        <div class="phoneDiv flex xycenter" @click="skip_kefu">
            <i class="iconfont icon-dianhua3"></i>
        </div>
       <navBar class="nav"></navBar>
   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
import navBar from '../../components/mobile/navBar.vue'
import SearchInput from '../../components/mobile/SearchInput.vue'
import orderTab from '../../components/mobile/OrderListTab.vue'
import TransLIst from '../../components/mobile/TransLIst.vue'
import StoreList from '../../components/mobile/StoreList.vue'

// import test from '../../components/mobile/test.vue'
export default {
    components: {
        navBar,
        SearchInput,
        orderTab,
        TransLIst,
        StoreList
        // test
    },
    // inject: ['reload'],
    // 导航守卫
    beforeRouteEnter (to, from, next) {
        if(from.name=='m_search'){
            to.meta.keepAlive = false;
            next(vm => {
                vm.$forceUpdate();
            })
        }else{
            to.meta.keepAlive = true;
            next();
        }
        
    },
    data() {
        return {
            prompt:"请输入订单编号查询",//请输入出入库单号查询
            type:0,//0:运输订单,1:仓储订单
        }
    },

    created() {
        const that=this;
        console.log("打印vuex数据",this.$store.state.searchForm)
        if(!!this.$route.params.type){
            console.log("打印params",this.$route.params)
            this.type=this.$route.params.type;
        }
        if(!!this.$route.params.form){
            console.log("打印params",this.$route.params)
            const searchform=this.$route.params.form;
            switch(this.$route.params.type){
                case 0:
                    that.$store.state.searchForm.form.current=1;
                    that.$store.state.searchForm.form.orderNo=searchform.orderNo;
                    that.$store.state.searchForm.form.sendTimeBegin=searchform.startTime;
                    that.$store.state.searchForm.form.sendTimeEnd=searchform.endTime;
                    that.$store.state.searchForm.form.sendCity=searchform.sendCity;
                    that.$store.state.searchForm.form.sendDist=searchform.sendCity;
                    that.$store.state.searchForm.form.endCity=searchform.endCity;
                    that.$store.state.searchForm.form.endDist=searchform.endDist;
                    that.$store.state.searchForm.form.payState=searchform.stateText=="未结清"?'1,2':searchform.stateText=="已结清"?'3':''
                    break;
                case 1:
                    that.$store.state.searchForm.form2.current=1;
                    that.$store.state.searchForm.form2.depotNo=searchform.orderNo;
                    that.$store.state.searchForm.form2.createTimeBegin=searchform.startTime;
                    that.$store.state.searchForm.form2.endTimeEnd=searchform.endTime;
            }
        }
    },
    mounted() {

    },
    methods: {
        // 运输订单,仓库订单切换
        selectTab:function(value){
            const that=this;
            console.log("打印子组件传过来的参数",value);
            that.type=value;
            if(value==0){
                that.prompt="请输入订单编号查询"
            }else{
                that.prompt="请输入出入库单号查询"
            }
        },
        // 跳转客服
        skip_kefu:function(){
            const that=this;
            that.$router.push('/m_kefu');
        }
    }

}

</script>
<style scoped>
    #m_order{
        /* width: 100%;
        min-height: 100vh;
        overflow: hidden; */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(245, 245, 245);
    }
    .phoneDiv{
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 120px;
        right: 30px;
    }
    .icon-dianhua3{
        color: white;
        font-size: 50px;
    }
    .share{
        border: 1px solid #B93A13 !important;
        color: #B93A13;
    }
    .flowhidden{
        overflow: hidden;
    }
    .relative{
        position: relative;
    }
    
    .scrollY{
        overflow-y: scroll;
    }
    >>>.van-tabs__content{
        flex: 1;
        /* overflow: hidden; */
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        
    }
    .vanlist{
        flex: 1;
        height: auto;
        /* overflow: hidden; */
        overflow-y: scroll;
        background-color: rgb(245, 245, 245);
       
    }
    .blue{
        color: #2A97E8;
    }
    .line{
        height: 35px;
        border-right: 2px solid #707070;
        margin-left: 20px;
        margin-right: 20px;
    }
    .btn{
        padding: 20px 30px;
        border-radius: 45px;
        border: 1px solid #DBDCDB;
        border-radius: 50px;
        font-size: 28px;
        margin-left: 12px;
    }
    .pb20{
        padding-bottom: 20px;
    }
    .pl20{
        padding-left: 20px;
    }
    .pr20{
        padding-right: 20px;
    }
    .b_border{
        border-bottom: 1px solid #DBDCDB;
    }
    .pad20{
        padding: 20px;
    }
    .fs34{
        font-size: 34px;
    }
    .fz28{
        font-size: 28px;
    }
    .orderDemo{
        background-color: white;
        margin-top: 20px;
        border-radius: 24px;
    }
    .arrow{
        width: 60px;
        height: auto;
    }
    .symbol{
        background-color: #FF9D55;
        color: white;
        width: 126px;
        height: 40px;
        border-radius: 10px;
    }
    .symbol2{
        background-color: #2A97E8;
        color: white;
        width: 126px;
        height: 40px;
        border-radius: 10px;
    }
    .symbol3{
        background-color: #FF9D55;
        color: white;
        width: 126px;
        height: 40px;
        border-radius: 10px;
    }
    .symbol4{
        background-color: #93A1A7;
        color: white;
        width: 126px;
        height: 40px;
        border-radius: 10px;
    }
    .demo{
        background-color: #E7F0F2;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 24px;
    }
    .nav{
        /* width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 999; */
    }
</style>
