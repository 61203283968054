<template>
    <div id="TransList" class="flex1 flex yrow">
        <div class="box flex yrow">
             <van-tabs v-model="tabValue" animated swipeable @change="renderlist">
                 <van-tab v-for="(item,index) in tabArr" :title="item.name" :key="item.name"></van-tab>
             </van-tabs>
             <van-list class="vanlist" id="vanlist" ref="vanlist" :offset="offset" v-model="loading" :finished="finished" :finished-text="finishText" @load="loadMore">
                 <!-- 仓库订单demo -->
                    <div class="orderDemo fz28" v-for="(item,index) in orderArr" @click="to_detail(item)">
                    <div class="flex fz28 b_border">
                        <div class="flex1 flex pad20 ycenter">
                            <div>单号:{{item.depotNo}}</div>
                            <i class="ml20 iconfont icon-fuzhi" @click.stop="againOrder(item,'add')"></i>
                            <span :class="item.type==0?'ml20 storeType':'ml20 storeType2'">{{item.type==0?'入库单':'出库单'}}</span>
                        </div>
                        <div class="flex pad20 xright fs24">
                            <div v-if="item.state=='待处理'" class="symbol flex xycenter">待处理</div>
                            <div v-if="item.state=='待确认'" class="symbol2 flex xycenter">待确认</div>
                            <div v-if="item.state=='待出库'" class="symbol3 flex xycenter">待出库</div>
                            <div v-if="item.state=='待入库'" class="symbol3 flex xycenter">待入库</div>
                            <div v-if="item.state=='已入库'" class="symbol3 flex xycenter">已入库</div>
                            <div v-if="item.state=='已取消'" class="symbol4 flex xycenter">已取消</div>
                        </div>
                    </div>
                    <div class="flex pad20 fs34">
                        {{item.depotName}}
                    </div>
                    <div class="flex pl20 pr20 pt20">
                        <div class="flex1" v-if="item.type==0">
                                预计储存天数：{{item.storageDays}}
                        </div>
                        <div class="flex1 xright">
                            {{item.type=='0'?'入库需求：':'出库方式：'}}{{item.accessType}}
                        </div>
                    </div>
                    <div class="pl20 pr20 pt20">
                        {{item.type=='0'?'入库':'出库'}}合计：数量{{item.totalPakages}}箱、重量{{item.totalWeight}}公斤、体积{{item.totalVolume}}方
                    </div>
                    <div class="pad20">下单时间：{{item.createTime}}</div>
                    <div class="flex pl20 pr20">
                        <div class="flex1">
                            总费用:<span class="red">{{item.payment}}元</span>
                        </div>
                        <div class="flex1 xright">
                            已付:<span class="red">{{item.paidMoney}}元</span>
                        </div>
                    </div>
                        <div class="flex pr20 pt20 xright pb20">
                            <div class="btn" v-if="item.state=='待处理'" @click.stop="cancel(item,index)">撤销</div>
                            <div class="btn" v-if="(item.state=='待处理'||item.state=='待确认')&&item.paidMoney!=item.payment&&item.payment" @click.stop="pay(item)">确定并支付</div>
                            <div class="btn" v-if="item.state=='待处理'" @click.stop="changeOrder(item,'edit')">订单修改</div>
                            <div class="btn share">分享订单</div>
                        </div>
                    </div>
             </van-list>
         </div>
    </div>
 </template>
 
 <script>
 const API=require('/src/assets/js/apilist.js');
 export default {
     components: {
 
     },
     data() {
         return {
            arrow:require('../../assets/images/mobile/order_jt.png'),
            tabValue:"",
            tabArr:[
                {name:'全部',active:true,outStateName:'',enterStateName:''},
                {name:'待处理',active:false,outStateName:1,enterStateName:1},
                {name:'待确认',active:false,outStateName:5,enterStateName:10},
                {name:'待入/出库',active:false,outStateName:3,enterStateName:2},
                {name:'已入/出库',active:false,outStateName:6,enterStateName:4},
                {name:'撤销',active:false,outStateName:0,enterStateName:0}
            ],
            form:{
                // outStateName: '',
                // enterStateName: '',
                // current: 1,
                // size: 15,
                // userId: '',
                // mobile: '',
                // depotNo: '',
                // createTimeBegin: '',
                // endTimeEnd: ''
            },
            orderArr:[],//订单列表
            page:1,
            offset:5,
            finishText:'没有更多了',
            loading: false,
            finished: false
             
         }
     },
 
     created() {
        this.form=this.$store.state.searchForm.form2;
        console.log("打印params传来的form",this.$route.params)
        const params=this.filterParams2(this.form);
        this.getStoreOrder(params);
 
     },
 
 
     mounted() {
 
     },
 
     methods: {
        //  确定并支付
        pay:function(obj){
            const that=this;
            console.log("打印这个obj",obj)
            that.$router.push({
                name:'m_payment',
                params:{
                    orderIds:[obj.id],
                    orderNum:1,
                    amount:Number(obj.payment)-Number(obj.paidMoney),
                    pageName:1,//仓库列表
                }
            })
        },
        // 撤销订单
        cancel:function(obj,idx){
            const that=this;
            this.$dialog.confirm({
                title: '温馨提示',
                message: '确定要撤销该订单么？',
                confirmButtonColor:'#007EC5'
                })
                .then(() => {
                    if(obj.type==0){//如果是入库单
                        let params={
                            id:obj.id
                        }
                        that.$axios('get',API.inStoreCancel,params,'params','application/json')
                        .then((res)=>{
                            console.log("入库单撤销成功",res)
                            if(res.isSuccess){
                                that.$toast('撤销成功');
                                that.orderArr[idx].state='已取消'
                                // this.form.current=1;
                                // that.orderArr=[];
                                // const params=that.filterParams2(this.form);
                                // setTimeout(function(){
                                //     that.getStoreOrder(params);
                                // },1000)
                                
                            }

                        })
                        .catch((error)=>{
                            console.log("入库单撤销失败",error)

                        })

                    }else{//如果是出库单
                        let params={
                            depotOutId:obj.id,
                            state:0
                        }
                        that.$axios('get',API.outStoreCancel,params,'params','application/json')
                        .then((res)=>{
                            console.log("出库单撤销成功",res)
                            if(res.isSuccess){
                                that.$toast('撤销成功');
                                that.orderArr[idx].state='已取消';
                                // this.form.current=1;
                                // that.orderArr=[];
                                // const params=that.filterParams2(this.form);
                                // setTimeout(function(){
                                //     that.getStoreOrder(params);
                                // },1000)
                            }
                            
                        })
                        .catch((error)=>{
                            console.log("出库单撤销失败",error)

                        })

                    }
                })
                .catch(() => {

                });

        },
        // 修改订单
        changeOrder:function(obj,state){
            const that=this;
            if(obj.type==0){
                that.$router.push({
                    path:'/m_entry',
                    query:{
                        id:obj.id,
                        actType:state
                    }
                })
            }else{
                that.$router.push({
                    path:'/m_out',
                    query:{
                        id:obj.id,
                        actType:state
                    }
                })
            }

        },
        againOrder:function(obj,state){//idx是第一层数组下标,idx2数组元素中list的下标
            const that=this;
            if(obj.type==0){
                that.$router.push({
                    path:'/m_entry',
                    query:{
                        id:obj.id,
                        actType:state
                    }
                })
            }else{
                that.$router.push({
                    path:'/m_out',
                    query:{
                        id:obj.id,
                        actType:state
                    }
                })
            }
        },
        // 滑动切换tab
        renderlist:function(idx,title){
            const that=this;
            console.log("111111111",that.$refs.vanlist);//滚动条回到顶部
            document.getElementById('vanlist').scrollTop = 0;
            that.form.outStateName=that.tabArr[idx].outStateName;
            that.form.enterStateName=that.tabArr[idx].enterStateName;
            that.form.current=1;
            that.orderArr=[];
            const params=that.filterParams2(that.form);
            that.getStoreOrder(params);
        },
        // 获取仓库订单列表
        getStoreOrder:function(Params){
            const that=this;
            // this.$toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            //     duration:800
            // });
            that.loading=true;
            that.finished=false;
            that.$axios('get',API.storagePage,Params,'params','application/json').then((res)=>{
                console.log("获取仓库订单列表",res.data)
                if(res.data.records.length>0){
                    for(var i=0;i<res.data.records.length;i++){
                        if(res.data.records[i].paidMoney==''){
                            res.data.records[i].paidMoney='0.00';
                        }
                        if(res.data.records[i].payment==''){
                            res.data.records[i].payment='0.00';
                        }
                    }
                    that.orderArr=that.orderArr.concat(res.data.records);
                }else{
                    that.finished=true;
                }
                
            }).catch((error)=>{

            })
            that.loading=false;

        },
        // 加载更多
        loadMore:function(){
            const that=this;
            console.log("触发了Loadmore")
            // this.$toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            //     duration: 2
            // });
            that.form.current++;
            const params=this.filterParams2(this.form)
            this.getStoreOrder(params);
        },
        // 获取运输列表数据
        getData:function(){
            const that=this;
            that.$axios('')

        },
        selectTab:function(value){
            const that=this;
            console.log("打印子组件传过来的参数",value);
            that.type=value;
            if(value==0){
                that.prompt="请输入订单编号查询"
            }else{
                that.prompt="请输入出入库单号查询"
            }
        },
        // 跳转客服
        skip_kefu:function(){
            const that=this;
            that.$router.push('/m_kefu')
        },
        // 跳转到仓库详情
        to_detail:function(obj){
            const that=this;
            if(obj.type==0){
                that.$router.push({path:'/m_inStoreDetail',query:{
                    id:obj.id
                }})
            }else{
                that.$router.push({path:'/m_outStoreDetail',query:{
                    id:obj.id
                }})
            }
            

        }
     }
 
 }
 
 </script>
 <style scoped>
    #TransList{
        flex:1;
        position: relative;
    }
    .fs24{
        font-size: 24px;
    }
    .icon-fuzhi{
        font-size: 20px;
    }
    .red{
        color:#F71111;
    }
    .pt20{
        padding-top: 20px;
    }
    .storeType{
    border: 1px solid #21A1FC;
    color: #21A1FC;
    border-radius: 10px;
    font-size: 24px;
    padding:2px 4px;
    }
    .storeType2{
    border: 1px solid #27BA81;
    color: #27BA81;
    border-radius: 10px;
    font-size: 24px;
    padding:2px 4px;
    }
    .fs34{
        font-size: 34px;
    }
    .box{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    #m_order{
        /* width: 100%;
        min-height: 100vh;
        overflow: hidden; */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(245, 245, 245);
    }
    .phoneDiv{
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 120px;
        right: 30px;
    }
    .icon-dianhua3{
        color: white;
        font-size: 50px;
    }
    .share{
        border: 1px solid #007EC5 !important;
        color: #007EC5;
    }
    .flowhidden{
        overflow: hidden;
    }
    .relative{
        position: relative;
    }
    
    .scrollY{
        overflow-y: scroll;
    }
    >>>.van-tabs__nav--line.van-tabs__nav--complete {
    padding-right: 0px;
    padding-left: 0px;
    }
    >>>.van-tabs__wrap--scrollable .van-tab {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 0;
    }
    >>>.van-tabs__wrap--scrollable .van-tab {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    /* padding: 0 12px; */
    }
    >>>.van-tabs__content{
        flex: 1;
        /* overflow: hidden; */
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        
    }
    >>>.van-tab--active {
        color: #007EC5;
        font-weight: 500;
    }
    >>>.van-tabs__line {
        position: absolute;
        bottom: 15px;
        left: 0px;
        z-index: 1;
        width: 40px;
        height: 3px;
        background-color: #007EC5;
        border-radius: 3px;
    }
    .vanlist{
        flex: 1;
        padding: 20px;
        /* height: 100%; */
        /* overflow: hidden; */
        overflow-y: scroll;
        background-color: rgb(245, 245, 245);
    
    }
    .blue{
        color: #2A97E8;
    }
    .line{
        height: 35px;
        border-right: 2px solid #707070;
        margin-left: 20px;
        margin-right: 20px;
    }
    .btn{
        padding: 20px 20px;
        border-radius: 45px;
        border: 1px solid #DBDCDB;
        border-radius: 50px;
        font-size: 28px;
        margin-left: 12px;
    }
    .pb20{
        padding-bottom: 20px;
    }
    .pl20{
        padding-left: 20px;
    }
    .pr20{
        padding-right: 20px;
    }
    .b_border{
        border-bottom: 1px solid #DBDCDB;
    }
    .pad20{
        padding: 20px;
    }
    .fs34{
        font-size: 34px;
    }
    .fz28{
        font-size: 28px;
    }
    .orderDemo{
        background-color: white;
        margin-top: 20px;
        border-radius: 24px;
    }
    .arrow{
        width: 60px;
        height: auto;
    }
    /* 待处理 */
    .symbol{
        background-color: #F5B968;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    /*  */
    .symbol2{
        background-color: #2A97E8;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    .symbol3{
        background-color: #76B040;
        color: white;
        width: 106px;
        height: 38px;
        border-radius: 6px;
    }
    .symbol4{
        background-color: #D3D3D3;
        color: white;
        width: 110px;
        height: 40px;
        border-radius: 10px;
    }
    .demo{
        background-color: #E7F0F2;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 24px;
    }
 </style>
 