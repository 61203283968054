<template>
  <div class="order_form">
    <el-form :rules="rules" ref="ruleForm" class="ruleForm" :model="parmsJson">
      <div class="xlr_m">
        <h3 class="h3">出库信息</h3>
      </div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="我的企业名称" prop="companyName">
            <el-input v-model="parmsJson.companyName" :disabled="true" prefix-icon="iconfont icon-qiye"
              placeholder="请输入">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="仓库名称" prop="depotId">
            <WareCascader v-model="parmsJson.depotId" :options.sync="arrOption" :address.sync="address"
              :storageName="'outDepotOptions'" :holder="'请选择省/市/区/仓库名称'" @changeDepotFun="changeDepotFun" ref="outWare">
            </WareCascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="出库方式" prop="checkoutType">
            <el-select v-model="parmsJson.checkoutType" class="select-box wp100" placeholder="请选择">
              <el-option v-for="item in outTypeArr" :key="item.value" :label="item.text" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="预计提货时间" class="mypicker demo-input-suffix">
            <el-date-picker v-model="takeDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
              :picker-options="beginDate" @change="changeDateFun">
            </el-date-picker>
            <el-select class="select-box" v-model="takeTime">
              <el-option v-for="item in timeArray" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="xlr_m">
        <h3 class="h3">配送方式</h3>
      </div>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="deliveryType">
            <el-radio-group v-model="parmsJson.deliveryType">
              <!-- <el-radio-button v-for="(item,index) in outTalkArr" :label="item.value" 
                                class="mr20 border">{{item.text}}</el-radio-button> -->
              <el-radio v-for="item in outTalkArr" :label="item.value" :key="item.value">{{ item.text }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="提货司机">
            <el-input v-model="parmsJson.driverName" placeholder="请输入">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="提货司机联系方式">
            <el-input v-model="parmsJson.driverPhone" placeholder="请输入">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车牌号">
            <el-input v-model="parmsJson.licenseNumber" placeholder="请输入">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div>
        <!-- 货物选择信息 -->
        <!-- <GoodsSelection :defaultGoods='parmsJson.commodityJson' @getCommodityJson='getGoods'
                    :goodHeaders='tableHead' :goodaddress="'outGoodList'" :types="'out'" :id="goodId">
                </GoodsSelection> -->
        <el-row>
          <GoodsSelection :defaultGoods="parmsJson.commodityJson" @getCommodityJson="getGoods" :goodHeaders="tableHead"
            :types="'out'" :id="goodId" @getTotalData="getTotal" ref="tableSelect">
          </GoodsSelection>
          <el-col :span="8">
            <el-form-item label="出库数量（合计）" v-if="parmsJson.commodityJson.length !== 0">
              <el-input v-model="parmsJson.totalPakages" placeholder="请输入" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="重量（kg）" v-if="parmsJson.commodityJson.length !== 0">
              <el-input v-model="parmsJson.totalWeight" placeholder="请输入" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="体积（方）" v-if="parmsJson.commodityJson.length !== 0">
              <el-input v-model="parmsJson.totalVolume" placeholder="请输入" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <template v-if="plainOptions && plainOptions.length">
        <div class="xlr_m">
          <h3 class="h3">增值服务</h3>
        </div>
        <div class="added_services">
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <el-checkbox-group v-model="parmsJson.valueAddedServices">
                  <el-checkbox v-for="item in plainOptions" :label="item.feeType" :key="item.feeType" border>
                    {{ item.feeTypeName }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </template>
      <div class="xlr_m">
        <h3 class="h3">备注</h3>
      </div>
      <div>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="parmsJson.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="xright mt30 mb30">
        <el-button type="primary" class="main_btn" @click="nextFun">下一步</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import GoodsSelection from "./components/goodsSelection/index.vue";
  import WareCascader from "./components/wareCascader.vue";
  import moment from "moment";
  import {
    outTypeArr,
    outPayArr,
    outTalkArr,
    totalFun,
    goodsTableHead,
    outGoodsTableHead,
  } from "@/assets/js/warehouse.js";
  import { getBeginDateAndHoursArray } from "@/assets/js/utils.js";
  import { regExp } from "@/assets/js/global";
  // 接口参数名称
  const API = require("/src/assets/js/apilist.js");
  //获取用户信息
  let userJson = JSON.parse(localStorage.getItem("user"));
  export default {
    props: {
      parmsJsonProps: {
        type: Object,
        default: () => {
          return {};
        },
      },
      outOptions: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      WareCascader,
      GoodsSelection,
    },
    data() {
      return {
        rules: {
          companyName: regExp("other", true, "请填写"),
          depotId: regExp("other", true, "请选择"),
          checkoutType: regExp("other", true, "请选择"),
          takeTime: regExp("other", true, "请选择"),
          deliveryType: regExp("other", true, "请选择"),
        },
        // parmsJson: {},
        parmsJson: {
          ...this.parmsJsonProps,
          companyName: userJson.companyName || '', //公司名称
          corpId: userJson.corpId || '', //公司Id
          userId: userJson.id || '', //用户ID
          mobile: userJson.mobile || '', //手机号
        },
        address: "outDepot", //仓库接口地址
        outTypeArr, //出库方式
        outPayArr, //支付方式
        outTalkArr, //配送方式
        beginDate: "", //日期开始选择
        takeDate: new Date(), //提货日期
        takeTime: "", //提货小时
        timeArray: [], //时间数组
        plainOptions: [], //增值服务集合
        tableHead: goodsTableHead.concat(outGoodsTableHead), //表头
        actType: "", //add:增加  edit:编辑
      };
    },
    watch: {
      parmsJsonProps: {
        //监听的对象
        deep: true, //深度监听设置为 true
        handler: function (newV, oldV) {
          this.parmsJson = newV;
          if (this.actType == "edit") {
            //编辑时保留下单时的预计发货时间段，其他时间默认是当前时间
            this.takeDate = moment(newV.takeTime).format("YYYY-MM-DD");
            this.takeTime = moment(newV.takeTime).format("hh");
          }
        },
      },
    },
    //计算state数据
    computed: {
      arrOption() {
        return this.outOptions;
      },
      goodId:{
        get(){
          let depotArr = this.parmsJson.depotId
          ? JSON.parse(JSON.stringify(this.parmsJson.depotId))
          : [];
        let depotId = depotArr[depotArr.length - 1];
        return {
          depotId: depotId || "",
          commodityName: "",
        };
        },
        set(){

        }
      },
    },
    mounted() {
      this.actType = this.$route.query.actType || "";
      //获取时间段
      this.changeDateFun();
      let depotId = this.parmsJson.depotId.length
        ? this.parmsJson.depotId[(this.parmsJsonProps.depotId, length - 1)]
        : "";
      if (depotId) {
        this.getSddServices(depotId);
      }
    },
    methods: {
      //点击下一步
      nextFun() {
        let self = this;
        let commodityJson = this.parmsJson.commodityJson || [];
        if (commodityJson.length == 0) {
          self.$message.error("请至少选择一个货品");
          return;
        }
        const form1 = new Promise((resolve, reject) => {
          this.$refs["ruleForm"].validate((valid) => {
            if (valid) {
              resolve();
            } else {
              this.$message.closeAll();
              this.$message({
                message: '信息没有填写完整！',
                type: "error",
              });
            }
          });
        });
        const form2 = new Promise((resolve, reject) => {
          this.$refs["tableSelect"].$refs["goodsForm"].validate((valid) => {
            if (valid) {
              resolve();
            } else {
              this.$message.closeAll();
              this.$message({
                message: '信息没有填写完整！',
                type: "error",
              });
            }
          });
        });
        Promise.all([form1, form2]).then((resolve, reject) => {
          self.$emit("getParmsJson", { parmsJson: this.parmsJson, active: 2 });
        });
        // self.$refs["ruleForm"].validate((valid) => {
        //   if (valid) {
        //     self.$emit("getParmsJson", { parmsJson: this.parmsJson, active: 2 });
        //   }
        // });
      },
      //获取选中的货品
      getGoods(data) {
        this.$set(this.parmsJson, "commodityJson", data);
      },
      //获取汇总
      getTotal(val) {
        this.parmsJson.totalPakages = val.totalPakages;
        this.parmsJson.totalVolume = val.totalVolume;
        this.parmsJson.totalWeight = val.totalWeight;
      },
      //获取仓库
      changeDepotFun(depotArr) {
        let self = this;
        if (depotArr.lenght == 0) {
          return;
        }
        let depotId = depotArr[depotArr.length - 1];
        this.getSddServices(depotId);
        this.$set(this.parmsJson, 'commodityJson', [])
        this.goodId = []
      },
      //获取增值服务
      getSddServices(depotId) {
        let self = this;
        let parms = { depotId: depotId, state: "1" };

        //获取增值服务
        self.$axios("post", API.findAddedValuesInit, parms).then((res) => {
          if (res && res.code == "200") {
            let data = res.data;
            self.plainOptions = data;
          } else {
            self.$message.error("获取增值服务出错了！");
          }
        });
      },
      //禁止选择以前的时间方法
      getBeginDateFun(date) {
        this.beginDate = {
          disabledDate(time) {
            return time.getTime() < new Date(date).getTime() - 8.64e7;
          },
        };
      },
      //选择提货日期
      changeDateFun() {
        let getDateAndTime = getBeginDateAndHoursArray(this.takeDate);
        this.timeArray = getDateAndTime.timeRang;
        this.takeTime = getDateAndTime.timeRang[0];
        this.takeDate = getDateAndTime.beginDate; //提货日期
        this.$set(
          this.parmsJson,
          "takeTime",
          this.takeDate + " " + (this.takeTime + ":00:00")
        );
        this.getBeginDateFun(getDateAndTime.beginDate);
      },
    },
  };
</script>
<style scoped>
  .select-box {
    display: inline-block;
    margin-left: 20px;
  }

  .wp100 {
    margin: 0;
    width: 100%;
  }

  .border>>>.el-radio-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 2px;
  }

  .added_services>>>.el-checkbox {
    margin-right: 10px;
  }

  .added_services>>>.el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
    margin-right: 10px;
  }
</style>