// PC路由
import footer from '../components/pc/footer.vue'
import header from '../components/pc/header.vue'
import p_login from '../views/pc/p_login.vue'
import p_myorder from '../views/pc/p_myorder/p_myorder'
import p_transOrder from '../views/pc/p_myorder/p_transOrder.vue'
import p_storeOrder from '../views/pc/p_myorder/p_storeOrder.vue'
import p_coupon from '../views/pc/p_myorder/p_coupon.vue'
import p_unpaid from '../views/pc/p_myorder/p_unpaid.vue'
import p_orderDetail from '../views/pc/p_myorder/p_orderDetail.vue'
import p_order from '../views/pc/pre_order.vue'
// 路由重定向:m_index为手机端首页，p_index为移动端首页
const realPath = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'm_login' : 'p_login'
const pc_routes = [
  //   {
  //     path: '/about',
  //     name: 'About',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     // 路由懒加载
  //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   },
  {
    path: '/p_login',
    name: 'p_login',
    component: p_login,
    // redirect:realPath2,
    meta: {
      type: 'pc'
    }
  },
  {
    path: '/pre_order',
    name: 'pre_order',
    component: p_order,
    meta: {
      type: 'pc',
      name: '极速发货',
    },
  },
  {
    path: '/batch_order',
    name: 'batch_order',
    component: p_order,
    meta: {
      type: 'pc',
      name: '批量发货',
    },
  },
  {
    path: '/entry_order',
    name: 'entry_order',
    component: p_order,
    meta: {
      type: 'pc',
      name: '入库单',
    },
  },
  {
    path: '/out_order',
    name: 'out_order',
    component: p_order,
    meta: {
      type: 'pc',
      name: '出库单',
    },
  },
  // zzx:订单模块
  {
    name: 'p_myorder',
    path: '/p_myorder',
    component: p_myorder,
    children: [
      {
        path: 'p_transOrder',
        name: 'p_transOrder',
        component: p_transOrder,
        meta: {
          type: 'pc',
          name: '我的订单',
        },
      },
      {
        path: 'p_storeOrder',
        name: 'p_storeOrder',
        component: p_storeOrder,
        meta: {
          type: 'pc',
          name: '我的订单',
        },
      },
      {
        path: 'p_coupon',
        name: 'p_coupon',
        component: p_coupon,
        meta: {
          type: 'pc',
          name: '优惠券'
        }
      },
      {
        path: 'p_unpaid',
        name: 'p_unpaid',
        component: p_unpaid,
        meta: {
          type: 'pc',
          name: '待支付'
        }
      },
      {
        path: 'p_orderDetail',
        name: 'p_orderDetail',
        component: p_orderDetail,
        meta: {
          type: 'pc',
          name: '订单详情'
        }
      }
    ]
  },

]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

export default pc_routes
