<template>
   <div id="m_payment" class="flex yrow">
        <headNav></headNav>
        <div class="flex1 xycenter yrow">
            <div class="fs34 xycenter mt20" v-if="qrcode">支付金额:<span class="money">{{money}}</span>元</div>
            <div class="flex xycenter yrow" v-if="qrcode">
                <div class="qrcode" :style="{backgroundImage:'url('+qrcode+')'}"></div>
                <div class="prompt xycenter">温馨提示:微信付款码将在两小时后失效，请及时支付，失效后请点击支付重新生成付款码。</div>
                <div>
                    <div class="btn xycenter mt20" @click="finishPay">我已扫码支付</div>
                </div>
            </div>
            
            <div class="flex xycenter yrow">
                <i class="iconfont icon-weixin1 mt20"></i>
                <span class="fs30 mt20">总计支付{{$route.params.orderNum}}张订单</span>
                <span class="fs34 mt20">合计:<span class="money">{{$route.params.amount||money}}</span>元</span>
                <div>
                    <div class="btn xycenter mt20" @click="pay">微信支付</div>
                </div>
            </div>

        </div>
        
   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
import headNav from '../../components/mobile/headNav.vue'
export default {    
    components: {
        headNav
    },
    // beforeRouteEnter (to, from, next) {
    //     console.log('from',from);
    //     console.log('to',to)
    //     next(vm => {
    //         // 通过 `vm` 访问组件实例
    //     })
    //  },

    data() {
        return {
            money:0,
            qrcode:"",
            orderNum:""
        }
    },

    created() {
        console.log("$route",this.$route);
        console.log("$router",this.$router)
        this.orderNum=this.$route.params.orderNum;
        console.log("打印params参数",this.$route.params);
        if(this.$route.params.pageName!=1){
            this.getQrcode();
        }
        // if(!!this.$route.params.amount){
        //     that.money=this.$route.params.amount;
        // }
        
    },


    mounted() {

    },

    methods: {
        pay:function(){
            this.throttle(this.wxpay,4);
        },
        // 微信支付
        wxpay:function(){
            const that=this;
            const user=JSON.parse(localStorage.getItem('user'))
            console.log("获取用户缓存数据",user)
            if(!user.unionId){
                that.$toast("当前账号尚未关联商城小程序,请在小程序上进行微信授权登录");
            }else if(!user.wxOpenid){
                that.$toast("未获取到当前账号的openid,请进行微信授权登录后再支付");
            }else{
                that.$toast("正在发起微信支付...")
                var _tradeType="";
                if(that.wxBrower()){
                    _tradeType="JSAPI";
                }else{
                    _tradeType="MWEB";
                }
                var json={
                    appId:"wx4dc34538a5e3f39d",
                    tradeType:_tradeType,
                    body:"一站商城web微信支付",
                    openid:user.wxOpenid
                }
                const params={
                    channelServiceName:'weiXinServiceImpl',
                    orderIds:that.$route.params.orderIds.join(),
                    parametersJson: JSON.stringify(json)
                }
                that.$axios('post',API.unifiedOrderParameters,params,'data','application/json').then((res)=>{
                    if(res.code==200){
                        let resultMap=res.data.resultMap;
                        console.log("打印支付接口返回参数",resultMap)
                        if(that.wxBrower()){
                            WeixinJSBridge.invoke(
                                'getBrandWCPayRequest', {
                                    "appId": 'wx4dc34538a5e3f39d', //公众号名称，由商户传入
                                    "timeStamp": resultMap.timeStamp, //时间戳，自1970年以来的秒数
                                    "nonceStr": resultMap.nonceStr, //随机串
                                    "package": resultMap.packageInfo,
                                    "signType": resultMap.signType, //微信签名方式：
                                    "paySign": resultMap.paySign //微信签名
                                },
                                function(res){
                                    if(res.err_msg == "get_brand_wcpay_request:ok" ){
                                        alert('支付成功！');
                                        that.$router.push('/m_paySuccess');//跳转到支付成功页面
                                        
                                    }else{
                                        console.log("报错时的res",res)
                                        // alert(res.err_msg)
                                    }
                                }
                            )
                        }else{//如果是外部浏览器
                            window.location.href=resultMap.mwebUrl;
                        }
                        
                    } 
                })

            }
        },
        finishPay:function(){
            const that=this;
            that.$router.replace('/m_order')


        },
        getQrcode:function(){
            const that=this;
            this.$toast.loading({
                message: '生成支付二维码...',
                forbidClick: true,
                duration:800
            });
            const params={
                channelServiceName:'weiXinServiceImpl',
                orderIds:that.$route.params.orderIds.join(),
                parametersJson: {"appId":"wx2a60d1dc5fe4b730","tradeType":"NATIVE","body":"一站商城web收款"},
            }
            console.log('打印这个params参数',params)
            that.$axios('get',API.getPayqrcode,params,'params','application/json')
            .then((res)=>{
                console.log("获取二维码成功",res)
                that.money=res.data.amount;
                const base64='data:image/png;base64,'+res.data.qrcode;
                that.qrcode=base64;
            })
            .catch((error)=>{

            })
        }
    }

}

</script>
<style scoped>
    .icon-weixin1{
        font-size: 160px;
        color: #F5CE98;
    }
    .mt20{
        margin-top: 20px;
    }
    .fs30{
        font-size: 30px;
    }
    .btn{
        min-width: 400px;
        height: 90px;
        border-radius: 45px;
        border: 1px solid #007EC5;
        color: #007EC5;
        font-size: 40px;
    }
    .fs34{
        font-size: 34px;
    }
    #m_payment{
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
        background-color: white;
    }
    .prompt{
        max-width: 50%;
        color:#007EC5;
        font-size: 18px;
    }
    .mt20{
        margin-top: 20px;
    }
    .qrcode{
        width: 400px;
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 400px 400px;
    }
    .fs34{
        font-size: 34px;
    }
    .money{
        font-size: 40px;
        color: #e61d3a;
    }

</style>
