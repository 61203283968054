<template>
  <div>
    <div id="addressHtml" v-if="isM">
      <van-action-sheet
        v-model="isShowAddrModalNew"
        title="地址簿"
        :close-on-click-overlay="false"
        @close="() => cancleChoose()"
      >
        <div class="tc pb30 b-line">
          <div class="tab_small_changeline big">
            <span
              class="tab_li"
              v-for="item in tabArrOpt"
              :key="item.id"
              :type="item.addressType"
              :class="{ active: item.addressType === activeNameNew }"
              @click="() => changeActive(item.addressType)"
            >
              {{ item.tabTitle }}
            </span>
          </div>
        </div>
        <AddressListM
          ref="cmpRefM"
          :addressType="activeNameNew"
          :checkdId="checkId"
          :tableList="newTableList"
          :activeType="activeType"
          @checkItemM="checkItem"
          @search="onSearch"
          @changeAddOneAddr="changeAddOneAddr"
          @cancleChoose="cancleChoose"
        ></AddressListM>
      </van-action-sheet>
    </div>
    <div v-else>
      <el-dialog
        title="地址簿"
        :close-on-click-modal="false"
        :visible="isShowAddrModalNew"
        @close="cancleChoose"
      >
        <div class="tc pb20 b-line" v-if="isRadio">
          <div class="tab_small_changeline">
            <span
              class="tab_li"
              v-for="item in tabArrOpt"
              :key="item.id"
              :type="item.addressType"
              :class="{ active: item.addressType === activeNameNew }"
              @click="() => changeActive(item.addressType)"
            >
              {{ item.tabTitle }}
            </span>
          </div>
        </div>
        <AddressListPC
          ref="cmpRef"
          :addressType="activeNameNew"
          :checkdId="checkId"
          :tableList="newTableList"
          :activeType="activeType"
          @checkItem="checkItem"
          @search="onSearch"
          @changeAddOneAddr="changeAddOneAddr"
          @cancleChoose="cancleChoose"
          @closePcAdd="closePcAdd"
        ></AddressListPC>
      </el-dialog>
      <NewAddressEidtPC
        :addrType="activeNameNew"
        optionType="add"
        :isShowPcAdd="isShowPcAdd"
        @closeAdd="() => closePcAdd(false)"
        @handlePCSubmit="handlePCSubmit"
      ></NewAddressEidtPC>
      <!-- <AddressManage
        v-if="!isM"
        :isShowPcAdd="isShowPcAdd"
        @closeAdd="() => closePcAdd(false)"
        :addActiveName="activeNameNew"
      ></AddressManage> -->
    </div>
  </div>
</template>
<script>
import { isMobile } from "@/assets/js/global";
import AddressListPC from "@/components/addressListCmp/AddressListPC.vue";
import AddressListM from "@/components/addressListCmp/AddressListM.vue";
import {
  checkDuplicateAddr,
  newSelectAddress,
  getcheckAddress,
} from "@/assets/js/order";
// import AddressManage from "@/components/addressManage/AddressManage.vue";
import NewAddressEidtPC from "@/components/addressManage/NewAddressEidtPC.vue";
import { SHOUHUOARR } from "@/assets/js/orderLibrary";

export default {
  name: "AddressListCmp",
  components: { AddressListPC, AddressListM, NewAddressEidtPC },
  props: {
    isShowAddrModal: {
      type: Boolean,
    },
    selectedAddr: {
      type: Array,
    },
    activeName: {
      type: String | Number,
      default: "deliver",
    },
    xhrParams: {
      type: Object,
      default: () => {},
    },
    isBatch: {
      type: Boolean,
    },
    isRadio: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeNameNew: this.activeName,
      isShow: false, //显示列表渲染
      checkId: null, //"1427076991458283521",
      tableList: [],
      isShowPcAdd: false, //是否显示Pc的新增地址簿弹窗
      tabArrOpt: [
        //tab配置对象
        {
          key: 1,
          tabTitle: "发货地址",
          addressType: "deliver",
        },
        {
          key: 2,
          tabTitle: "收货地址",
          addressType: "receive",
        },
      ],
      isShowAddrModalNew: false,
      reFresh: true,
    };
  },
  created() {},
  watch: {
    isShowAddrModal(newVal) {
      this.isShowAddrModalNew = newVal;
      if (newVal) {
        this.activeNameNew = this.activeName;
        let addrLists = JSON.parse(JSON.stringify(this.$store.state.addrList)); //拿内存的数据

        if (
          addrLists.faAddrList.length !== 0 ||
          addrLists.shouAddrList.length !== 0
        ) {
          this.tableList = addrLists;
          if (this.selectedAddr.length) {
            this.renderTable(this.selectedAddr);
          }
        } else {
          this.fetchData();
        }
      } else {
        this.checkId = null;
        this.tableList = JSON.parse(JSON.stringify(this.$store.state.addrList)); //重置数据
      }
    },
  },
  computed: {
    isM() {
      return isMobile();
    },
    activeType() {
      return this.isRadio ? "radio" : "checkbox";
    },
    dataIndex() {
      const mapList = { deliver: "faAddrList", receive: "shouAddrList" };
      return mapList[this.activeNameNew];
    },
    newTableList: {
      get() {
        return this.tableList[this.dataIndex];
      },
      set(val) {
        this.tableList[this.dataIndex] = val;
      },
    },
  },
  methods: {
    handlePCSubmit(res) {
      this.fetchData();
    },
    closePcAdd(flag) {
      this.isShowPcAdd = flag || false;
    },
    renderTable(selectedId) {
      if (
        !!selectedId &&
        selectedId.length > 0 &&
        this.tableList.shouAddrList.length > 0
      ) {
        this.checkId =
          selectedId.length && selectedId.map((item) => item.recipientAddrId);
        let newLists = [];
        this.tableList.shouAddrList.map((item) => {
          item.isDisabled = false;
          selectedId.map((val) => {
            if (item.id === val.recipientAddrId && val.orderSn !== "") {
              item.isDisabled = true;
            }
          });
          newLists.push(item);
        });
        this.tableList.shouAddrList = newLists;
        this.tableList = { ...this.tableList };
      }
    },
    fetchData() {
      const params = this.xhrParams;
      this.$axios(
        "POST",
        "/portal/corpAddress/queryByCorpId",
        params,
        "params",
        "application/json"
      ).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            this.tableList = res.data;
            this.$store.state.addrList = res.data;
            if (this.selectedAddr.length) {
              this.renderTable(this.selectedAddr);
            }
          }
        }
      });
    },
    checkItem(ids) {
      let newArr = null,
        isNext = true;
      if (this.isRadio) {
        //单选框传回来id字符串
        let obj;
        this.newTableList.some((v) => {
          if (v.id === ids) {
            newArr = newSelectAddress(v, this.activeName);
            obj = v;
            return true;
          }
        });
        if (obj) {
          isNext = this.confirmChoose(obj);
        }
        if (!isNext) return;
      } else {
        //单选框传回来地址id的数组
        newArr = this.mapAddrDetail(ids);
      }
      this.$emit("changeAddrModal", false);
      this.cancleChoose();
      this.$emit("confirm", { activeName: this.activeNameNew, obj: newArr });
    },
    mapAddrDetail(idArr) {
      let newData = [],
        allPushData = [];
      idArr.length &&
        idArr.map((t) => {
          this.newTableList.map((v) => {
            if (t === v.id) {
              let newV = newSelectAddress(v, this.activeName);
              newData.push(newV);
            }
          });
        });
      newData.length &&
        newData.map((f) => {
          let tmepStr1 =
            f.recipientName +
            f.recipientPhone +
            f.recipientAddress +
            f.recipientDetailaddress;
          this.selectedAddr.map((g) => {
            let tmepStr2 =
              g.recipientName +
              g.recipientPhone +
              g.recipientAddress +
              g.recipientDetailaddress;
            if (tmepStr1 === tmepStr2) {
              f = {
                ...g,
                ...f,
              };
            }
          });
          f = {
            ...SHOUHUOARR,
            ...f,
          };
          f.recipientAddress = getcheckAddress(
            this,
            this.activeName,
            f.recipientAddress
          );
          allPushData.push(f);
        });
      return allPushData;
    },
    confirmChoose(obj) {
      if (!obj) return;
      let isNext = true;
      if (this.isRadio && obj !== null && this.isBatch) {
        //批量下单的地址簿选择，要查重
        let addrrName =
          (obj.areaCode1Name || "") +
          "" +
          (obj.areaCode2Name || "") +
          "" +
          (obj.areaCode3Name || "") +
          "" +
          (obj.areaCode4Name || "") +
          "" +
          (obj.areaInfo || "");
        isNext = checkDuplicateAddr(this, obj.id, addrrName);
      }
      return isNext;
    },
    cancleChoose() {
      this.checkId = null;
      this.$emit("changeAddrModal", false);
    },
    onSearch(value) {
      let newList = [];
      if (value) {
        this.newTableList.map((item) => {
          const addrStr =
            (item["areaCode1Name"] || "") +
            "" +
            (item["areaCode2Name"] || "") +
            "" +
            (item["areaCode3Name"] || "") +
            "" +
            (item["areaCode4Name"] || "") +
            "" +
            (item["areaInfo"] || "");
          const addrInfo = addrStr.indexOf(value) != -1;
          const name =
            item["contacts"] && item["contacts"].indexOf(value) != -1;
          if (addrInfo || name) {
            item["isCheck"] = true;
          } else {
            item["isCheck"] = false;
          }
          newList.push(item);
        });
      } else {
        this.newTableList.map((item) => {
          item["isCheck"] = true;
          newList.push(item);
        });
      }
      this.newTableList = newList;
    },
    changeActive(type) {
      this.activeNameNew = type;
    },
    changeAddOneAddr(arr) {
      //父子方法，用于操作地址簿新增
      arr.activeName = this.activeNameNew;
      this.$emit("changeFaAddOneAddr", arr);
    },
  },
};
</script>
<style scoped>
::v-deep .el-input__inner {
  border: 1px solid #dcdfe6;
  box-shadow: none;
  margin: 0;
}
/*****tab 切换样式，改变下划线的tab 以下******/
.tab_small_changeline .tab_li {
  padding: 15px 20px;
  font-size: 20px;
  color: #0a8de4;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.tab_small_changeline .tab_li.active {
  border-color: #0a8de4;
}
.tab_small_changeline.big .tab_li {
  padding: 15px 20px;
  font-size: 30px;
}
.van_footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 30px;
}
.van_footer :v-deep .van-button--normal {
  background-color: #1989fa;
}
</style>