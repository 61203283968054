//获取cookie、
export function getCookie(name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
     return (arr[2]);
    else
     return null;
   }
    
   //设置cookie,增加到vue实例方便全局调用
//    name, value, day, path, domain
   export function setCookie (name, value, day,path, domain) {
    day = day || 30;
	path = path || '/';
	var str = name + '=' + value + '; ';
	// if(day) str += 'expires=' + new Date(Date.now() + day * 24 * 3600 * 1000).toGMTString() + '; ';
  if(day) str += 'expires=' + new Date(Date.now() + day * 2 * 3600 * 1000).toGMTString() + '; ';//设置cookies过期时间为2小时
	if(path) str += 'path=' + path + '; ';
	if(domain) str += 'domain=' + domain;
	document.cookie = str;

    // var exdate = new Date();
    // exdate.setDate(exdate.getDate() + expiredays);
    // document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
   };
    
   //删除cookie
   export function delCookie (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
     document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
   };