<template>
   <div id="paySuccess">
       <div class="flex xycenter icondiv">
            <img :src="paysuccess" class="paysuccess">
       </div>
       <div class="flex xycenter pt50 paystate">支付成功</div>
       <!-- <div class="orderNo flex xycenter pt16">单号:11111111111111111</div> -->
       <div class="flex xycenter">
            <div class="text pt45">
                一站物流商城客服将在30分钟内，与您核对订单信息，请您保持通讯畅通。
            </div>
       </div>
       <div class="flex xycenter mt430">
           <div class="btn flex xycenter" @click="goback">返回订单列表</div>
       </div>
       
   </div>
</template>

<script>
export default {

    components: {

    },

    data() {
        return {
            paysuccess:require('../../assets/images/mobile/paysuccess.png')
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        goback:function(){
            this.$router.go(-2);
        }
    }

}

</script>
<style scoped>
    .paysuccess{
        width: 129px;
        height: 129px;
    }
    .mt430{
        margin-top: 430px;
    }
    .btn{
        width: 340px;
        height: 90px;
        background: #FFFFFF;
        border: 2px solid #007EC5;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
        opacity: 1;
        border-radius: 45px;
        color:#007EC5;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
    }
    .pt16{
        padding-top: 16px;
    }
    .pt45{
        padding-top: 45px;
    }
    .paystate{
        font-size: 39px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
    }
    .pt50{
        padding-top: 50px;
    }
    .icondiv{
        padding-top: 148px;
    }
    .text{
        max-width: 482px;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #A59C99;
        text-align: center;
    }
    .orderNo{
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 31px;
        color: #666666;
        opacity: 1;
    }

</style>
