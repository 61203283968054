<template>
    <div id="p_orderDetail">
        <transDetail v-if="$route.query.detail==1"></transDetail>
        <inStoreDetail v-if="$route.query.detail==2&&$route.query.type==0"></inStoreDetail>
        <outStoreDetail v-if="$route.query.detail==2&&$route.query.type==1"></outStoreDetail>
    </div>
 </template>
 
 <script>
// 运输订单详情
import transDetail from '../../../components/pc/transDetail.vue'
// 入库单详情
import inStoreDetail from '../../../components/pc/inStoreDetail.vue'
// 出库单详情
import outStoreDetail from '../../../components/pc/outStoreDetail.vue'

 const API=require('/src/assets/js/apilist.js');
 export default {
 
     components: {
        transDetail,
        inStoreDetail,
        outStoreDetail
     },
 
     data() {
         return {
             detail:{}
         }
     },
 
     created() {
     },
 
 
     mounted() {
 
     },
 
     methods: {

     }
 
 }
 
 </script>
 <style scoped>
    #p_orderDetail{
        padding: 0 36px;
    }
     
 
 </style>
 