<template>
   <div id="p_myorder">
       <header1></header1>
        <myorderPage>
            <router-view></router-view>
        </myorderPage>
       <footer1></footer1>
   </div>
</template>

<script>
import myorderPage from '../../../components/pc/myorderPage.vue'
import header1 from '../../../components/pc/header.vue'
import footer1 from '../../../components/pc/footer.vue'
export default {

    components: {
        myorderPage,
        header1,
        footer1
    },

    data() {
        return {
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
    }

}

</script>
<style scoped>

</style>
