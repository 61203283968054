<template>
  <div class="order_form">
    <el-steps :active="active">
      <el-step title="Step 1" description="入库信息填写"></el-step>
      <el-step title="Step 2" description="完成下单"></el-step>
    </el-steps>
    <div v-show="active === 1">
      <StepOne
        ref="StepOne"
        :warehouseData="wareParams"
        @setWareData="getWareData"
        :depoptions="options"
      ></StepOne>
    </div>
    <div v-if="active === 2">
      <StepTwo
        ref="StepTwo"
        :type="'in'"
        :parmsJsonProps="wareParams"
        @getParmsJson="getWareData"
      ></StepTwo>
    </div>
  </div>
</template>
<script>
import StepOne from "./wareOrderStepOne.vue";
import StepTwo from "./wareOrderStepTwo.vue";
// 接口参数名称
const API = require('/src/assets/js/apilist.js');
//获取用户信息
let userJson = JSON.parse(localStorage.getItem('user'));
export default {
  data() {
    return {
      active: 1,
      wareParams: {
        commodityJson: [
          //   {
          //   code: "xinzeng",
          //   corpId: "1458693669739978753",
          //   createTime: "2021-11-18 09:24:12",
          //   creator: 1458693661875658800,
          //   id: "1461143164125913090",
          //   invalidTime: "20211218",
          //   name: "新增商品",
          //   number: "2",
          //   productionTime: "20211118",
          //   specifications: "2",
          //   unitHigh: 2,
          //   unitLong: 2,
          //   unitVolume: 2,
          //   unitWeight: 2,
          //   unitWide: 2,
          //   updateTime: "2021-11-18 09:24:12",
          //   updator: 1458693661875658800,
          //   valid: "1",
          //   version: "1",
          // }
        ], //商品传值
        companyName: '', //公司名称
        corpId: '', //公司Id
        creator: '', //创建人
        updator: '', //修改人
        userId: '', //用户ID
        mobile: '', //手机号
        // depotId: [ "44", "4403", "440303", "1372498284580450305" ], //仓库ID 1372515543852003330 [ "33", "3301", "330102" ]
        depotId: [],
        remark: "", //备注
        storageDays: "", //储存天数
        totalPakages: '', //	入库总数量
        totalVolume: '', //入库总体积
        totalWeight: '', //入库总重量
        warehousingTime: '', //入库时间
        warehousingTimes: '', //入库时间
        warehousingDate: new Date(),
        warehousingType: '', //入库方式
        state: '1', //状态
        id: '', //入库ID
      }, //保存的集合
      parmsJson: {},
      options: []
    };
  },
  created() {
    let id = this.$route.query.id || ''
    if (id) {
      this.getDetail(id)
    }else{
      this.getEntryCityList()
    }
    //更新
    this.userMsgFun()
  },
  methods: {
    getWareData({ parmsJson, active }) {
      this.wareParams = parmsJson;
      this.active = active
    },
    getDetail(id) {
      let self = this
      this.$axios('get', API.getById + '?id=' + id).then(res => {
        if (res && res.code == '200' && res.data) {
          let parmsJson = self.wareParams
          let data = res.data
          Object.keys(parmsJson).map(function (key) {
            data[key] && (parmsJson[key] = data[key])
          })
          parmsJson.commodityJson = data.commodityJson ? JSON.parse(data.commodityJson) : []
          parmsJson.depotId = (data.province && data.city && data.district && data.depotId) ? [data.province, data.city, data.district, data.depotId] : []
        
          parmsJson.warehousingDate = parmsJson.warehousingTime.substring(0, 10);
          parmsJson.warehousingTimes = parmsJson.warehousingTime.substring(11);
          parmsJson.warehousingTimes = parmsJson.warehousingTimes.substring(0, 2);
          self.wareParams = parmsJson
          this.getDetailEntryCityList(self.wareParams.depotId)
        } else {
          self.$message.error("获取详情出错了！");
        }
      })
    },
    //补充客户信息
    userMsgFun() {
      this.wareParams = {
        ...this.wareParams,
        companyName: userJson.companyName || '', //公司名称
        corpId: userJson.corpId || '', //公司Id
        userId: userJson.id || '', //用户ID
        mobile: userJson.mobile || '', //手机号
      }
    },
    // 存储入库省市区编辑详情初始数据的方法
    getDetailEntryCityList(value) {
      // 调用仓库接口的参数    
      let params = {
        current: 1,
        size: 30,
        name: '',
        contractName: '',
        province: value[0],
        city: value[1],
        district: value[2],
      }
      let data = []
      this.$axios('post', API.entryCity, {}).then((res) => {
        data = res.data;
        if (res.code == '200') {
          this.$axios('post', API.entryDepot, params).then((res) => {
            let result = res.data.records;
             data.forEach(item => {
              if (item.value === value[0]) {
                item.children.forEach(item2 => {
                  if (item2.value === value[1]) {
                    item2.children.forEach(item3 => {
                      if (item3.value === value[2]) {
                        item3.children = [];
                        result.map(item4 => {
                          if (item4.id === value[3]) {
                            item3.children.push(
                              Object.assign({ 'value': item4.id, 'label': item4.name, leaf: true })
                            )
                          } else {
                            item3.children.push(
                              Object.assign({ 'value': item4.id, 'label': item4.name, leaf: 4 })
                            )
                          }
                        });
                      }
                    })
                  }
                })
              }
            });
            this.options = data
          }).catch((error) => {
            console.log(error)
          })
        }

      }).catch((error) => {

      })

    },
    //存储入库省市区信息方法
    getEntryCityList() {
    this.$axios('post', API.entryCity, {}).then((res) => {
      this.options = res.data;
    }).catch((error) => {

    })
  },
    },
   
  components: {
    StepOne,
    StepTwo,
  },
};
</script>
<style scoped>
.order_form >>> .el-step__head {
  text-align: left;
}
.order_form >>> .el-step__title.is-finish,
.order_form >>> .el-step__description.is-finish {
  color: #0a8de4;
}
.order_form >>> .el-step__head.is-wait,
.order_form >>> .el-step__head.is-process {
  color: #666;
  border-color: #bdbdbd;
}
.order_form >>> .el-step__title {
  font-size: 20px !important;
  line-height: 2em !important;
}
.order_form >>> .el-step__description {
  font-size: 18px !important;
  line-height: 2em !important;
}
.order_form >>> .el-step__title.is-wait,
.order_form >>> .el-step__title.is-process {
  font-weight: normal;
  color: #666;
}
.order_form >>> .el-step__description.is-wait,
.order_form >>> .el-step__description.is-process {
  color: #666;
}
.order_form >>> .el-step.is-horizontal {
  flex-basis: 50% !important;
}
.order_form >>> .el-step.is-horizontal .el-step__line {
  top: 15px;
}
.order_form >>> .el-step__icon {
  width: 30px;
  height: 30px;
}
.order_form >>> .el-step__icon.is-text {
  border-radius: 0;
  border: none;
  border-color: transparent;
  background: url("../../../assets/images/step_icon.png") no-repeat -30px 0;
  background-size: 60px;
}
.order_form >>> .is-finish .el-step__icon.is-text {
  background-position: 0 0;
}
.order_form >>> .el-step__icon-inner {
  font-size: 0;
}
</style>