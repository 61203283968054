<template>
   <div id="p_transOrder" class="flex yrow">
       <!-- tab -->
       <div class="tabbox b_border flex">
            <div class="plr30" v-for="(item,index) in tabArr">
                <div :class="item.active?'activetab pointer':'_activetab pointer'" @click="changeTab(item.route)">{{item.name}}</div>
            </div>
       </div>
       <!-- 筛选条件 -->
       <div class="flex h74 ycenter">
        <div class="flex pl32">
            <div :class="item.active==true?'blue mr60 fs18 pointer':'mr60 fs18 pointer'" @click="changeStatus(item,index)" v-for="(item,index) in statusList" :key="item.name">
                <span>{{item.name}}</span>
                <!-- <span>({{item.number}})</span> -->
            </div>
        </div>
        <!-- 搜索条件 -->
        <div class="flex ycenter">
            <div class="l_inputrow ycenter">
                <input placeholder="请输入单号" v-model="form.orderNo" class="input">
            </div>
            <div class="searchbtn xycenter pointer" @click="search">查询</div> 
        </div>
        <div class="reset xycenter pointer" @click="reset">重置</div>
        <div class="fz18 blue">
            <span class="pl22 minw110 pointer" @click="moreSearch">更多查询条件</span>
        </div>
       </div>
        <!-- 查询条件 -->
        <div>
            <div class="flex searchrow fz16" v-show="show">
                <!-- 发货地 -->
                <div class="flex yrow">
                    <span class="padtb12">发货地</span>
                    <el-cascader
                        :value="sendAddress"
                        placeholder="请选择发货地"
                        :options="cityOption"
                        :props="cityOpitonProps"
                        filterable
                        @change="startAddress"
                    ></el-cascader>
                </div>
                <!-- 收货地 -->
                <div class="flex yrow">
                    <span class="padtb12">收货地</span>
                    <el-cascader
                        :value="receiptAddress"
                        placeholder="请选择收货地"
                        :options="cityOption"
                        :props="cityOpitonProps"
                        filterable
                        @change="endAddress"
                    ></el-cascader>
                </div>
                <!-- 下单日期 -->
                <div class="flex yrow">
                    <span class="padtb12">下单日期</span>
                    <el-date-picker
                    v-model="time"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions2"
                    @change="selectTime">
                </el-date-picker>
                </div>
                <!-- 支付状态 -->
                <div class="flex yrow">
                    <span class="padtb12">支付状态</span>
                    <el-select v-model="form.payState" placeholder="选择支付状态" @change="selectpayState">
                        <el-option
                        v-for="item in payStateArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>

            </div>
        </div>
       <div class="maincontent flex1 relative">
            <!-- 列表 -->
            <div class="orderRow flex xycenter" v-for="(item,index) in orderArr">
                <!--  -->
                <div class="orderDiv">
                    <div class="flex">
                        <div class="fs20 flex1 ycenter pt10 pb10">
                            共<span class="blue">{{item.parentOrderNoSize}}</span>票货物
                        </div>
                        <div class="flex1 xright">
                            <div class="add btn pointer" v-if="item.isShowButton==1" @click.stop="addChildOrder(item,'add')">添加子订单</div>
                        </div>
                    </div>
                    <div v-for="(item2,index2) in item.list" class="orderdemo" @click="toDetail(item2)">
                        <div class="t_order flex bg_lightblue">
                            <div class="flex1 flex ycenter">
                                <div>订单号:{{item2.orderNo}}</div>
                                <div v-if="item2.state==3||item2.state==4||item2.state==5" class="symbol flex xycenter">下单成功</div>
                                <div v-if="item2.state==6||item2.state==7" class="symbol2 flex xycenter">运输中</div>
                                <div v-if="item2.state==8||item2.state==9" class="symbol3 flex xycenter">已签收</div>
                                <div v-if="item2.state==0" class="symbol4 flex xycenter">撤销</div>
                            </div>
                            <div class="flex1 xright ycenter">下单时间:{{item2.createTime}}</div>
                        </div>
                        <div class="b_order ptb30 flex fz18">
                            <div class="flex1 flex">
                                <div class="flex1 yrow xycenter">
                                    <div>{{item2.sendersCityName}}</div>
                                    <div>{{item2.sendersName}}</div>
                                </div>
                                <div class="flex1 yrow xycenter">
                                    <img :src="arrow" class="arrow">
                                    <span class="stateText">{{item2.payStateName}}</span>
                                </div>
                                <div class="flex1 yrow xycenter">
                                    <div>{{item2.recipientCityName}}</div>
                                    <div>{{item2.recipientName}}</div>
                                </div>
                            </div>
                            <div class="flex1 xycenter l_border money fs20">
                                ¥{{item2.payment|empty}}
                            </div>
                        </div>
                        <div class="flex xright t_border">
                            <div class="delete btn pointer" v-if="item2.state==3" @click.stop.self="cancel(item2,item.list)">撤销</div>
                            <div class="edit btn pointer" v-if="item2.state==3&&item2.paidMoney==0&&item2.chargingMode!=2" @click.stop.self="skip_preOrder(item2,'edit')">修改</div>
                        </div>
                    </div>
                </div>
                <!--  -->
            </div>
            <div class="nodata" v-if="orderArr.length==0">暂无数据~</div>
       </div>
       <!-- 分页 -->
       <div class="pagerow xycenter" v-show="!without">
            <el-pagination
            background
            layout="prev, pager, next"
            :total="totalnumber"
            :page-size="pageSize"
            :current-page.sync="form.current"
            @current-change="changePage">
            </el-pagination>
        </div>
   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
const city=require('../../../utlis/chinaCity.js');
export default {

    components: {
        
    },
    filters:{
        // 金额为空时自动改为0,
        empty:function(data){
            if(data==''){
                return '0.00'
            }else{
                return data;
            }
        },
    },
    data() {
        return {
            arrow:require('../../../assets/images/arrow.png'),
            tabArr:[
                {name:'运输订单',active:true,route:'p_transOrder'},
                {name:'仓储订单',active:false,route:'p_storeOrder'}
            ],
            without:false,
            show:false,
            tab:0,//0:运输订单,1:仓储订单
            statusList:[
                {name:'全部订单',active:true,status:''},
                {name:'下单成功',active:false,status:'3,4,5'},
                {name:'运输中',active:false,status:'6,7'},
                {name:'已签收',active:false,status:'8,9'},
                {name:'已撤销',active:false,status:'0'},
            ],
            totalnumber:0,//总数量
            orderArr:[],//订单列表
            // 订单查询接口参数form
            pageSize:5,
            form:{
                size: 5,
                userId: "",
                status: "",
                current: 1,
                sendTimeBegin: "",
                sendTimeEnd: "",
                sendCity: "",
                sendDist: "",
                endCity: "",
                endDist: "",
                payState: "",
                orderNo: ""
            },
            cityOption:city.chinaCity,//城市列表
            cityOpitonProps:{
                value:'value',
                label:'label',
                children:'children'
            },
            // size:5,
            // status:'',
            time:'',
            sendAddress:[],
            receiptAddress:[],
            payStateArr:[//支付状态选项
                {
                    value:'',
                    label:'全部'
                },
                {
                    value:'1,2',
                    label:'未结清'
                },
                {
                    value:'3',
                    label:'已结清'
                },
            ],
            pickerOptions2: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },

    created() {
        console.log("打印city",city);
        const params=this._filterParams(this.form)
        this.updateTransOrder(params);
        // this.updateTransOrder(this.form)
        

    },


    mounted() {
        const that=this;
        // that.updateTransOrder(this.form)
    },

    methods: {
        // 重置
        reset:function(){
            this.statusList.forEach((item)=>{
                item.active=false;
            })
            this.statusList[0].active=true;
            this.time="";
            this.sendAddress=[];
            this.receiptAddress=[];
            this.form={
                size: 5,
                userId: "",
                status: "",
                current: 1,
                sendTimeBegin: "",
                sendTimeEnd: "",
                sendCity: "",
                sendDist: "",
                endCity: "",
                endDist: "",
                payState: "",
                orderNo: ""
            }
            const params=this._filterParams(this.form)
            this.updateTransOrder(params);

        },
        // 入库单获取费用
        // 撤销订单
        cancel:function(obj,list){
            const that=this;
            console.log("1111111111111111111111")
            this.$dialog.confirm({
                title: '温馨提示',
                message: list.length>1?'该订单使用了批量下单优惠，取消后该批订单的优惠金额将少减免¥40元，您继续取消订单吗？':'您确定要撤销此订单吗？',
                cancelButtonText:'再考虑下',
                confirmButtonText:'确认',
                confirmButtonColor:'#007EC5'
                })
                .then((res) => {
                    console.log("打印这个obj",obj)
                    // on confirm
                    var params={
                        orderIds:obj.id
                    }
                    that.$axios('post',API.batchOrderDelete,params,'data')
                    .then((res)=>{
                        if(res.isSuccess==true){
                            that.$toast('撤销订单成功')
                            const params=this._filterParams(this.form)
                            this.updateTransOrder(params);

                        }else{

                        }
                    })
                    .catch((error)=>{

                    })
                })
                .catch(() => {
                    // on cancel
                });
            },
        // 能否显示撤销按钮,返回布尔值
        canCancel:function(obj){
            return(obj.state == 3&&((obj.order_detail.isDaiXiaDan&&obj.order_detail.isDaiXiaDan==1&&!isDaiXiaDan)||isDaiXiaDan))
        },
        // 能否显示修改按钮,返回布尔值
        canEdit:function(obj){
         return (obj.state == 3 && obj.paidMoney == 0&&obj.chargingMode!=2)
        },
        // 添加子订单
        addChildOrder:function(obj,state){
            console.log("打印参数obj",obj)
            console.log("打印参数state",state)
            this.$store.state.drawerType='add';
            this.$router.push({
                name:'batch_order',
                params:{
                        parentNo:obj.parentOrderNo,
                        drawerType:state,
                        orderId:obj.parentOrderNo!=''?'':obj.list[0].id
                    }
            })
        },
        // 修改
        skip_preOrder:function(obj,state){
            console.log("打印参数obj",obj)
            console.log("打印参数state",state)
            console.log("修改打印$store",this.$store.state.ruleForm)
            this.$store.state.ruleForm.orderNo=obj.orderNo;
            this.$store.state.drawerType=state;
            this.$router.push({
                name:'pre_order',
                params:{
                    orderNo:obj.orderNo,
                    drawerType:state
                }
            })
        },
        toDetail:function(obj){
            const that=this;
            console.log("跳转详情的obj",obj)
            that.$router.push({
                path:'p_orderDetail',
                query:{
                    type:obj.type,
                    detail:1,
                    orderSn:obj.orderNo,
                    state:obj.state,
                    accessType:obj.accessType
                }
            })
        },
        // 查询
        search:function(){
            const that=this;
            that.form.current=1;
            console.log("打印form",that.form);
            const params=that._filterParams(that.form);
            that.updateTransOrder(params);
        },
        // 翻页
        changePage:function(Page){
            const that=this;
            console.log("打印当前页数",Page)
            that.form.current=Page;
            let params=that._filterParams(that.form);
            console.log("打印这个管道参数",params);
            that.updateTransOrder(params);
        },
        // 选择支付状态
        selectpayState:function(value){
            const that=this;
            console.log("选择支付状态",value);
            that.form.payState=value;
        },
        // 选择发货地址
        startAddress:function(obj){
            const that=this;
            if(obj.length>0){
                that.form.sendCity=obj[1];
                that.form.sendDist=obj[2]
            }
            console.log("更新form",that.form)

        },
        // 选择收货地址
        endAddress:function(obj){
            const that=this;
            if(obj.length>0){
                that.form.endCity=obj[1];
                that.form.endDist=obj[2]
            }
            console.log("更新form",that.form)
        },
        // 选择下单时间
        selectTime:function(time){
            const that=this;
            if(time!=null){
                that.form.sendTimeBegin=that.moment(time[0]).format("YYYY-MM-DD");
                that.form.sendTimeEnd=that.moment(time[1]).format("YYYY-MM-DD");
            }else{
                that.form.sendTimeBegin="";
                that.form.sendTimeEnd="";
            }
            
        },
        // 切换状态
        changeStatus:function(item,idx){
            const that=this;
            that.statusList.forEach((item,index)=>{
                if(idx==index){
                    that.statusList[idx].active=true;
                }else{
                    that.statusList[index].active=false;
                }
            })
            that.form.status=item.status;
            that.form.current=1;
            console.log("打印当前form",that.form)
            const params=that._filterParams(that.form);
            console.log("打印过滤后的参数",params);
            that.updateTransOrder(params);
            
        },
        changeTab:function(route){
            this.$router.push(route)
        },
        // 更多查询条件
        moreSearch:function(){
            const that=this;
            that.show=!that.show
        },
        // 获取运输订单列表
        updateTransOrder:function(Params){
            const that=this;
            that.$loading({text:'加载数据中...'})
            let params=that._filterParams(Params)
            that.$axios('post',API.pageOrderList,params,'data','application/json').then((res)=>{
                console.log("获取订单列表",res)
                if(res.data.records.length>0){
                    that.$loading().close()
                    that.without=false;
                    that.orderArr=res.data.records;
                    that.totalnumber=res.data.total;
                }else{
                    that.$loading().close();
                    that.without=true;
                }
            }).catch((error)=>{
                that.$loading().close();

            })
        },

    }

}

</script>
<style scoped>
    #p_transOrder{
        width: 100%;
    }
    .nodata{
        font-size: 22px;
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .fs20{
        font-size: 20px;
    }
    .relative{
        position: relative;
    }
    .symbol{
        background-color: #007EC5;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
    }
    .pt10{
        padding-top: 10px;
    }
    .pb{
        padding-bottom: 10px;
    }
    .symbol2{
        background-color: #007EC5;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
        
    }
    .symbol3{
        background-color: #76B040;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
    }
    .symbol4{
        background-color: #D3D3D3;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
    }
    .fs18{
        font-size: 18px;
    }
    .orderDiv{
        width: 80%;
        margin-top: 15px;
        background-color: #E7F0F2;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
    }
    /* ._orderDiv{
        width: 80%;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    } */
    .ptb10{
        padding: 10px 0;
    }
    .btn{
        padding: 10px 20px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .add,
    .edit{
        background-color: #0A8DE4;
        color: white;
    }
    .delete{
        background-color: #d43e3e;
        color: white;
    }
    /* .edit{
        padding: 10px 20px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
    }
    .delete{
        padding: 10px 20px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
    } */
    .t_border{
        border-top: 1px solid #d9d9d9;
    }
    .without{
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
        font-size: 18px;
    }
    .listbox{
        min-height: 600px;
        position: relative;
    }
    .activetab{
        padding: 24px 20px;
        /* height: 84px; */
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 4px solid #0A8DE4;
    }
    ._activetab{
        padding: 24px 20px;
        /* height: 84px; */
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pointer{
        cursor: pointer;
    }
    .padtb12{
        padding: 12px 0;
    }
    .searchrow{
        justify-content: space-around;
    }
    >>>.el-input__inner{
        background-color: #F1F1F1 !important;
    }
    >>>.el-range-editor .el-range-input{
        background-color: #F1F1F1 !important;
    }
    >>>.el-date-editor .el-range-separator{
        display: flex;
        align-items: center;
    }
    .bggray{
        background-color: #F1F1F1;
    }
    .pagerow{
        padding-top: 50px;
        padding-bottom: 70px;
    }
    .stateText{
        padding-top: 12px;
        color: #0A8DE4;
    }
    .blue{
        color: #0A8DE4;
    }
    .minw110{
        min-width: 110px;
    }
    .pb30{
        padding-bottom: 30px;
    }
    .mt50{
        margin-top: 50px;
    }
    .orderdemo{
        /* width: 1450px; */
        width: 100%;
        background-color: white;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .money{
        color:#E62019;
        font-family: Microsoft YaHei;
    }
    .l_border{
        border-left: 1px solid #CCCCCC;
    }
    .arrow{
        width: 90px;
        height: 10px;
    }
    .ptb30{
        padding: 30px 0;
    }
    .ptb10{
        padding: 10px 0;
    }
    .bg_lightblue{
        background-color: #e2eff6;
    }
    .t_order{
        padding: 20px 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #99A0A3;
    }
    .maincontent{
        width: 100%;
    }
    .pl22{
        padding-left: 22px;
    }
    .blue{
        color: #0A8DE4;
    }
    .l_inputrow{
        width: 252px;
        height: 40px;
        background-color: #F1F1F1;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .h74{
        height: 74px;
    }
    .searchbtn{
        width: 80px;
        height: 38px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: #0A8DE4;
        color: white;
        font-size: 16px;
    }
    .reset{
        width: 80px;
        height: 38px;
        margin-left: 8px;
        border-radius: 6px;
        background-color: #d43e3e;
        color: white;
        font-size: 16px;
    }
    .input{
        width: 200px;
        font-size: 14px;
        background-color: #F1F1F1;
        border: none;
        padding-left: 16px;
        /* text-indent: 16px; */
        /* padding:14px 0; */
    }
    .pl32{
        padding-left: 32px;
    }
    .padtb30{
        padding:30px 0;
        /* padding-left: 32px; */
    }
    .mr60{
        margin-right: 60px;
    }
    .fz18{
        font-size: 18px;
    }
    .b_border{
        border-bottom: 1px solid #d9d9d9;
    }
    .plr30{
        padding: 0 30px;
    }
    .plr24{
        padding: 0 24px;
    }
    .h84{
        height: 84px;
    }

</style>
