<!-- 运输订单详情----订单信息 -->
<template>
  <div id="TransOrderMsg">
    <!-- 订单信息 -->
    <div class="box">
      <div class="flex">
        <div class="flex1 flex ycenter title pb20">订单信息</div>
        <div class="flex1 xright pr20 title pb20">
          <div class="flex xycenter btn" wx:if="detail.state>3" @click="to_ebill(detail.orderNo)">
            电子运单
          </div>
        </div>
      </div>
      <div class="content">
        <div>下单时间:{{ detail.createTime }}</div>
        <div>订单单号:{{ detail.orderNo }}</div>
        <div>
          客户单号:{{
            !!detail.clientNumber && detail.clientNumber != ''
              ? detail.clientNumber
              : detail.orderNo
          }}
        </div>
        <div class="flex">
          <div class="flex1">
            结算方式:{{ detail.paymentTypeName ? detail.paymentTypeName : '--' }}
          </div>
          <div class="flex1">结算状态:{{ detail.payStateName }}</div>
        </div>
        <div class="flex">
          <div>待付:{{ detail.surplusMoney ? detail.surplusMoney : '--' }}</div>
          <div>已付:{{ detail.paidMoney ? detail.surplusMoney : '--' }}</div>
        </div>
      </div>
    </div>
    <!-- 发货地址 -->
    <div class="box">
      <div class="title pb20">发货地址</div>
      <div class="content">
        <div class="flex">
          <div class="flex1">联系人:{{ detail.sendersName }}</div>
          <div class="flex1">电话号码:{{ detail.sendersPhone }}</div>
        </div>
        <div>
          所在地:{{
            detail.sendersProvinceName +
              detail.sendersCityName +
              detail.sendersDistrictName +
              detail.sendersDetailaddress
          }}
        </div>
      </div>
    </div>
    <!-- 收货地址 -->
    <div class="box">
      <div class="title pb20">收货地址</div>
      <div class="content">
        <div class="flex">
          <div class="flex1">联系人:{{ detail.recipientName }}</div>
          <div class="flex1">电话号码:{{ detail.recipientPhone }}</div>
        </div>
        <div>
          所在地:{{
            detail.recipientProvinceName +
              detail.recipientCityName +
              detail.recipientDistrictName +
              (detail.recipientDetailaddress == '请填写详情地址'
                ? ''
                : detail.recipientDetailaddress)
          }}
        </div>
      </div>
    </div>
    <!-- 时间要求 -->
    <div class="box">
      <div class="title pb20">时间要求</div>
      <div class="content">
        <div>发货时间:{{ detail.sendersDate }} {{ detail.sendersTime }}</div>
        <div>时效:{{ detail.lineHour }}</div>
        <div>预计送达时间:{{ detail.recipientTimeRange2 }}</div>
      </div>
    </div>
    <!-- 货物信息 -->
    <div class="box">
      <div class="title pb20">货物信息</div>
      <div class="content">
        <div>货物类型:{{ detail.cargoTypeName }}</div>
        <div class="flex">
          <div class="flex1">包装方式:{{ detail.packingName }}</div>
          <div class="flex1">件数:{{ detail.totalPakages }}</div>
        </div>
        <div class="flex">
          <div class="flex1">原始重量(吨):{{ detail.oldWeight }}</div>
          <div class="flex1">原始体积(方):{{ detail.totalWeight }}</div>
        </div>
        <div class="flex">
          <div class="flex1">重量(吨):{{ detail.oldVolume }}</div>
          <div class="flex1">体积(方):{{ detail.totalVolume }}</div>
        </div>
      </div>
    </div>
    <!-- 服务信息 -->
    <div class="box">
      <div class="title pb20">服务信息</div>
      <div class="content"></div>
    </div>
    <!-- 费用信息 -->
    <div class="box">
      <div class="title pb20">费用信息</div>
      <div class="content">
        <div class="flex wrap">
          <div class="wrapOption" :key="item.name" v-for="(item, index) in detail.feeMergeList">
            {{ item.name }}:{{ item.cost | pricefilter }}
          </div>
          <div class="wrapOption">总运费(元):{{ detail.payment | pricefilter }}</div>
        </div>
      </div>
    </div>
    <!-- 托运备注 -->
    <div class="box">
      <div class="title pb20">托运备注</div>
      <div class="content">
        {{ detail.remark ? detail.remark : '无' }}
      </div>
    </div>
  </div>
</template>

<script>
const API = require('/src/assets/js/apilist.js')
export default {
  components: {},

  data() {
    return {
      detail: {}
    }
  },
  filters: {
    // 金额过滤器,保留小数点后2位,没有小数则自动补零
    pricefilter: function(data) {
      return parseFloat(data).toFixed(2)
    }
  },

  created() {
    this.getOrderDetail()
  },

  mounted() {},

  methods: {
    // 跳转电子运单
    to_ebill: function(orderSn) {
      const that = this
      this.envJump(
        '/business/faceSheet?waybillNo=' + orderSn + '-G1&type=3&time=' + new Date().getTime(),
        'href',
        0
      )
    },
    getOrderDetail: function() {
      const that = this
      const params = {
        orderSn: that.$route.query.orderSn
      }
      that
        .$axios('post', API.orderDetail, params, 'data', 'application/json')
        .then(res => {
          console.log('获取订单详情成功', res)
          if (res.data.recipientTimeRange) {
            res.data.recipientTimeRange2 = res.data.recipientTimeRange.split(' ')[0]
            that.detail = res.data
            localStorage.setItem('transDetail', JSON.stringify(that.detail)) //将订单详情信息缓存下来,给物流信息使用
          }
        })
        .catch(error => {
          console.log('获取订单详情成功', error)
        })
    }
  }
}
</script>
<style scoped>
#TransOrderMsg {
  padding: 20px;
  flex: 1;
  overflow-y: scroll;
  position: relative;
}
.btn {
  width: 140px;
  height: 50px;
  border: 1px solid #666666;
  border-radius: 6px;
}
.wrapOption {
  width: 50%;
}
.wrap {
  flex-wrap: wrap;
}
.box {
  border-radius: 12px;
  background-color: #ffffff;
  padding: 20px;
  font-size: 28px;
  margin-top: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}
.content {
  padding-top: 20px;
  color: #999999;
}
.title {
  border-bottom: 1px solid #dcdcdc;
  font-size: 28px;
}
.pt20 {
  padding-top: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.fz28 {
  font-size: 28px;
}
</style>
