<template>
  <div class="batch_form">
    <el-form
      :model="batchDataArr"
      :rules="expRules"
      ref="batchFormRef"
      class="batchForm"
    >
      <div class="fa_box rel">
        <div class="abs_box fabg"></div>
        <div
          class="address_box hand"
          @click="showAddressM('deliver', true)"
          v-if="!batchDataArr.shList[0].orderSn"
        >
          <i class="iconfont icon-diliweizhi mr5"></i>地址簿选择
        </div>
        <div class="smt">
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="发货人"
                prop="fhArr.sendersName"
                :rules="expRules.other"
              >
                <el-input
                  v-model="batchDataArr.fhArr.sendersName"
                  prefix-icon="iconfont icon-yonghu"
                  placeholder="请输入姓名"
                  :disabled="!!batchDataArr.shList[0].orderSn"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="发货人联系电话"
                prop="fhArr.sendersPhone"
                :rules="expRules.mobilePhone"
              >
                <el-input
                  v-model="batchDataArr.fhArr.sendersPhone"
                  prefix-icon="iconfont icon-dianhua"
                  placeholder="请输入联系人电话/座机"
                  :disabled="!!batchDataArr.shList[0].orderSn"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="我的企业名称"
                prop="fhArr.companyName"
                :rules="expRules.other"
              >
                <el-input
                  v-model="batchDataArr.fhArr.companyName"
                  prefix-icon="iconfont icon-qiye"
                  placeholder="请输入公司名称"
                  :disabled="
                    userStore.companyName != '' ||
                    drawerType !== 'add' ||
                    isLock
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="发货省市区"
                prop="fhArr.sendersAddress"
                :rules="expRules.otherSel"
              >
                <el-cascader
                  class="mycascader"
                  width="100%"
                  placeholder="请选择省/市/区"
                  ref="sendersAddressName"
                  v-model="batchDataArr.fhArr.sendersAddress"
                  :options="ldStartList"
                  :disabled="!!batchDataArr.shList[0].orderSn"
                  @change="(val) => getAreaLable(val, 'sendersAddressName')"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="发货详情地址"
                prop="fhArr.sendersDetailaddress"
                :rules="expRules.otherSel"
              >
                <el-input
                  v-model="batchDataArr.fhArr.sendersDetailaddress"
                  prefix-icon="iconfont icon-diliweizhi"
                  placeholder="请输入详细地址"
                  :disabled="!!batchDataArr.shList[0].orderSn"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="!batchDataArr.shList[0].orderSn">
              <el-form-item label="智能录入">
                <div class="rel auto_target">
                  <input
                    class="auto_ipt el-input__inner"
                    placeholder="复制发货信息，快速识别"
                    ref="deliver"
                  />
                  <span class="auto_btn" @click="() => getAddress('deliver')"
                    >识别</span
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-col :span="12">
                <el-form-item
                  label="预计发货时间"
                  prop="fhArr.sendersDate"
                  :rules="expRules.otherSel"
                  class="mypicker"
                >
                  <el-date-picker
                    style="width: 100%"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    v-model="batchDataArr.fhArr.sendersDate"
                    :picker-options="pickerOptions"
                    :clearable="false"
                    :disabled="!!batchDataArr.fhArr.parentOrderNo"
                    @change="changeDate"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  class="rec_date"
                  label="预计发货时间段"
                  prop="fhArr.sendersTime"
                  :rules="expRules.otherSel"
                >
                  <el-select
                    class="myselect"
                    v-model="batchDataArr.fhArr.sendersTime"
                    :disabled="!!batchDataArr.fhArr.parentOrderNo"
                    @visible-change="handleRecDate($event)"
                  >
                    <el-option
                      v-for="item in sendersTimeOtp"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </div>
      <div
        class="sh_box rel"
        v-for="(item, index) in batchDataArr.shList"
        :key="index"
      >
        <div class="abs_box shbg"></div>
        <div
          v-if="!item.orderId"
          class="address_box hand"
          @click="showAddressM('receive', true, index)"
        >
          <i class="iconfont icon-diliweizhi mr5"></i>地址簿选择
        </div>
        <div
          v-if="!item.orderId"
          class="iconfont icon-delete1 ml20 del_btn hand"
          @click="handleDelectItem(index)"
        ></div>
        <div class="smt">
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="收货人"
                :prop="'shList.' + index + '.recipientName'"
                :rules="expRules.other"
              >
                <el-input
                  v-model="item.recipientName"
                  prefix-icon="iconfont icon-yonghu"
                  placeholder="请输入姓名"
                  :disabled="!!item.orderId"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-col :span="12">
                <el-form-item
                  label="收货人联系电话"
                  :prop="'shList.' + index + '.recipientPhone'"
                  :rules="expRules.mobilePhone"
                >
                  <el-input
                    v-model="item.recipientPhone"
                    prefix-icon="iconfont icon-dianhua"
                    placeholder="请输入联系人电话/座机"
                    :disabled="!!item.orderId"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="收货省市区"
                :prop="'shList.' + index + '.recipientAddress'"
                :rules="expRules.otherSel"
              >
                <el-cascader
                  class="mycascader"
                  width="100%"
                  placeholder="请选择省/市/区"
                  v-model="item.recipientAddress"
                  :options="ldEndList"
                  :ref="'recipientAddressName' + index"
                  :disabled="!!item.orderId"
                  @change="
                    (val) =>
                      getAreaLable(val, 'recipientAddressName' + index, index)
                  "
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="收货详情地址"
                :prop="'shList.' + index + '.recipientDetailaddress'"
                :rules="expRules.other"
              >
                <el-input
                  v-model="item.recipientDetailaddress"
                  prefix-icon="iconfont icon-diliweizhi"
                  placeholder="请输入详细地址"
                  :disabled="!!item.orderId"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="!item.orderId">
              <el-form-item label="智能录入">
                <div class="rel auto_target">
                  <input
                    class="auto_ipt el-input__inner"
                    placeholder="复制收货信息，快速识别"
                    :ref="'receive' + index"
                  />
                  <span
                    class="auto_btn"
                    @click="() => getAddress('receive', index)"
                    >识别</span
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="hand" v-if="item.totalVolume">
            <div class="xlr_m" @click="handleSonFunc(item, index)">
              <span class="fz16">{{ getString(item) }}</span>
              <i class="iconfont icon-xiangyou fz18"></i>
            </div>
          </el-row>
          <el-row v-else>
            <div class="select_btn" @click="handleSonFunc(item, index)">
              选择产品
            </div>
          </el-row>
        </div>
      </div>
      <el-row
        ><div class="hand add_item" @click="handleAddItem">
          <i class="iconfont icon-jiahao mr10 fz20"></i>添加收货人
        </div></el-row
      >
      <el-row>
        <div class="xlr_m mt30">
          <div class="fz28 f2b">
            <span>预估价格</span>
            <span class="min_btn ml20 tc hand" @click="changeShow"
              >{{ hasShow ? "折叠" : "展开"
              }}<i
                class="iconfont ml5 fz18"
                :class="hasShow ? 'icon-xiangshang' : 'icon-xiala'"
              ></i
            ></span>
          </div>
          <div class="plr20 tr fz20">
            <div>
              预计
              <span class="red">￥{{ batchDisAll.expenses || "--" }}元</span>
            </div>
            <div>
              已减
              <span class="red">{{ batchDisAll.dis || "0" }}</span>
              元
            </div>
          </div>
        </div>
        <div v-show="hasShow">
          <div class="fz20 f2b mt10">共{{ batchDataArr.shList.length }}票</div>
          <div v-show="batchDataArr.shList.length">
            <ul
              class="price_box"
              v-for="(item, index) in this.$store.state.batchConForm.shList"
              :key="index"
            >
              <li class="head ellipsis">
                {{ priceFaAddr }}-
                {{
                  item.recipientDistrictName
                    ? item.recipientProvinceName +
                      "" +
                      item.recipientCityName +
                      "" +
                      item.recipientDistrictName
                    : ""
                }}
              </li>
              <li>
                {{ item.totalWeight || "-" }}kg/{{ item.totalVolume || "-" }}m³
              </li>
              <li class="xlr_m">
                <span>优惠券</span>
                <span class="pri_span">{{
                  item.batchCouponMoney ? "-￥" + item.batchCouponMoney : "￥0"
                }}</span>
              </li>
              <li class="xlr_m">
                <span>批量下单优惠</span>
                <span class="pri_span">{{
                  item.collectFee ? "-￥" + item.collectFee : "￥0"
                }}</span>
              </li>
              <li class="xlr_m">
                <span>费用预估</span>
                <span>{{
                  item.batchTotalAll ? "￥" + item.batchTotalAll : "-"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </el-row>
      <el-row class="tr mt30">
        <el-button class="main_btn" type="primary" @click="submitBatch"
          >提交</el-button
        >
      </el-row>
      <BatchDialogForm
        ref="batchDialogRef"
        v-if="isShow"
        :isShow="isShow"
        :faArr="allDataArr"
        :faIdx="faIdx"
        @changeStepArrFa="changeStepArrOne"
      ></BatchDialogForm>
    </el-form>
    <AddressListCmp
      ref="addressRef"
      :isRadio="isRadio"
      :activeName="activeName"
      :xhrParams="xhrData"
      :isBatch="true"
      :isShowAddrModal="isShowAddrModal"
      :selectedAddr="selectedAddr"
      @changeAddrModal="changeAddrModal"
      @confirm="confirm"
    ></AddressListCmp>
  </div>
</template>
<script>
import { regExp, dateRec } from "@/assets/js/global";
import {
  setBatchOrderForm,
  getBatchOrderForm,
  getAreaFilterList,
} from "@/assets/js/storage";
import { SHOUHUOARR, BATCHORDERSTORE } from "@/assets/js/orderLibrary.js";
import {
  getDetailStr,
  costAssessment,
  areaFilterVersion,
  showBatchSubmitPrice,
  initSendersDate,
  initArea,
  getBatchOrderDetial,
  batchSubmit,
  confirnAddress,
  getAddressDetail,
} from "@/assets/js/order";
import moment from "moment";
import BatchDialogForm from "./batchDialogForm"; //引入选择产品弹窗
import AddressListCmp from "@/components/addressListCmp/AddressListCmp.vue";
import { getUserStore } from "@/assets/js/storage";
const userData = getUserStore();
export default {
  data() {
    return {
      userStore: userData,
      batchDataArr: this.$store.state.batchConForm,
      allDataArr: {}, //传值给子组件的对象数据
      faIdx: 0, //传给子组件的对象下标
      global,
      value: [],
      hasShow: false, //是否展开价格列表
      sendersTimeOtp: [],
      isShow: false, //是否下单步骤显示组件
      activeName: "deliver", //当前地址簿选择的类型deliver,receive
      isRadio: true, //是否为单选框
      ldStartList: getAreaFilterList("AREA_LD_START"),
      ldEndList: getAreaFilterList("AREA_LD_END"),
      xhrData: {
        corpId: userData.corpId || "",
        userId: userData.id || "",
      },
      selectedAddr: [],
      isRadio: true, //true为地址簿选择弹窗，false为添加多发货人弹窗
      isShowAddrModal: false, //是否显示地址簿弹窗
      pickerOptions: {
        disabledDate(time) {
          let h = Number(moment().format("HH")); //当前小时
          if (h >= 18) {
            return time.getTime() < Date.now(); //18：00后只能选明天之后
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      },
      expRules: {
        other: regExp("other"),
        mobilePhone: regExp("mobilePhone"),
        otherSel: regExp("other", true, "请选择"),
      },
    };
  },
  components: {
    BatchDialogForm,
    AddressListCmp,
  },
  created() {
    areaFilterVersion(this); //查询地址版本
    let routeParams = this.$route.params;
    if (!!routeParams.parentNo || !!routeParams.orderId) {
      //从订单列表来
      let newP = {
        parentOrderNo: routeParams.parentNo || "",
        orderId: routeParams.orderId || "",
      };
      getBatchOrderDetial(this, newP, this.callbackBatch);
    } else {
      //新增
      let newBatchForm = BATCHORDERSTORE;
      //设置初始预计发货时间段
      let sendsDate = initSendersDate();
      newBatchForm.fhArr = {
        ...newBatchForm.fhArr,
        ...sendsDate,
      };
      this.batchDataArr = newBatchForm;
      //收发地址簿有地址则重置新增的收发货地址
      initArea(this.areaCallback);
    }
  },
  computed: {
    priceFaAddr() {
      let fa = this.$store.state.batchConForm.fhArr;
      fa =
        (fa.sendersProvinceName || "") +
        "" +
        (fa.sendersCityName || "") +
        "" +
        (fa.sendersDistrictName || "");
      return fa;
    },
    batchDisAll() {
      if (this.$store.state.batchConForm.shList.length === 0) return;
      return showBatchSubmitPrice(this.$store.state.batchConForm.shList || {});
    },
  },
  methods: {
    changeAddrModal(flag) {
      this.isShowAddrModal = flag || false;
    },
    callbackBatch(res) {
      this.batchDataArr = {
        ...res,
      };
      costAssessment(this, res);
      this.$store.state.batchConForm = { ...res };
    },
    areaCallback(dataArr) {
      this.batchDataArr.fhArr = {
        ...this.batchDataArr.fhArr,
        ...dataArr.sendsArr,
      };
      this.batchDataArr.shList[0] = {
        ...this.batchDataArr.shList[0],
        ...dataArr.receiveArr,
      };
      this.batchDataArr = { ...this.batchDataArr };
      this.$store.state.batchConForm = this.batchDataArr;
      setBatchOrderForm(this.batchDataArr);
    },
    changeStepArrOne(val) {
      this.batchDataArr = val;
      setBatchOrderForm(val);
    },
    getString(newItem) {
      return getDetailStr(newItem);
    },
    changeDate() {
      if (this.batchDataArr.fhArr.sendersTime) {
        //已选有时间段则清空
        this.batchDataArr.fhArr.sendersTime = "";
      }
    },
    //地址识别
    getAddress(type, faIdx) {
      let refName = type;
      let text = "";
      if (type === "receive") {
        refName = type + "" + faIdx;
        text = this.$refs[refName][0].value;
      } else {
        text = this.$refs[refName].value;
      }
      if (!text) {
        this.$message.info(
          "请填写要识别" + (type === "deliver" ? "发货" : "收货") + "信息"
        );
        return;
      }
      if (faIdx !== undefined) {
        this.faIdx = faIdx;
      }
      getAddressDetail(this, type, text, this.callbackReco);
    },
    callbackReco(data) {
      let type = data.type || "deliver";
      let addr = data.addr;
      if (type === "deliver") {
        this.batchDataArr.fhArr = {
          ...this.batchDataArr.fhArr,
          ...addr,
        };
      } else if (type === "receive") {
        this.batchDataArr.shList[this.faIdx] = {
          ...this.batchDataArr.shList[this.faIdx],
          ...addr,
        };
      }
      this.batchDataArr = { ...this.batchDataArr };
      setBatchOrderForm(this.batchDataArr);
    },
    handleRecDate(isShow) {
      if (isShow) {
        if (this.batchDataArr.fhArr.sendersDate) {
          this.sendersTimeOtp = dateRec(this.batchDataArr.fhArr.sendersDate);
        } else {
          this.$message.error("请先选择发货时间");
          return;
        }
      }
    },
    showAddressM(type, isRadio, index) {
      this.activeName = type;
      this.isRadio = isRadio;
      if (index != undefined) {
        this.faIdx = index;
      }
      //筛选已选中的地址id数据
      // let selectedAddrId = [];
      // this.batchDataArr.shList.map((item) => {
      //   if (item.recipientAddrId) {
      //     selectedAddrId.push({
      //       id: item.recipientAddrId,
      //       orderSn: item.orderSn || "",
      //     });
      //   }
      // });
      this.selectedAddr = this.batchDataArr.shList;
      this.isShowAddrModal = true;
    },
    confirm(res) {
      if (res.obj === null) return;
      const addr = res.obj;
      confirnAddress(this, addr);
    },
    handleSonFunc(item, index) {
      if (item.orderId) {
        this.$message.warning("已下单的发货地址在批量操作中不能修改");
        return;
      }
      this.$refs["batchFormRef"].validate((valid, event) => {
        if (valid) {
          this.allDataArr = { ...this.batchDataArr.shList[index] };
          this.faIdx = index;
          this.isShow = true;
          if (this.$refs.batchDialogRef) {
            setBatchOrderForm(this.batchDataArr);
            this.$refs.batchDialogRef.isShow(true);
          } else {
            return false;
          }
        }
      });
    },
    handleRules(type) {
      regExp(type);
    },
    getAreaLable(val, type, faIdx) {
      const self = this;
      let oldAreaCode = getBatchOrderForm();
      oldAreaCode = oldAreaCode.fhArr.sendersAddress;
      if (
        type === "sendersAddressName" &&
        self.batchDataArr.shList.length > 0 &&
        !self.batchDataArr.fhArr.parentOrderNo
      ) {
        let index = self.batchDataArr.shList.findIndex((item) => item.lineId);
        if (index !== -1) {
          self.$confirm("修改发货地址会删除已选择好产品的收货人", {
            title: "温馨提示",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            showClose: false,
            closeOnClickModal: false,
            callback: (action) => {
              if (action === "confirm") {
                self.getChangeArea(self, true, type, faIdx);
              } else {
                //取消则赋原地址值
                self.batchDataArr.fhArr.sendersAddress = oldAreaCode;
                return;
              }
            },
          });
        }
      } else {
        self.getChangeArea(self, false, type, faIdx);
      }
    },
    getChangeArea(self, flag, type, faIdx) {
      //type为sendersAddressName，并且flag为true，重置收货人，flase则忽略
      setTimeout(() => {
        let labelVal = "",
          arr = {};
        if (type === "sendersAddressName") {
          labelVal = self.$refs[type].inputValue.split(" / ");
          arr = {
            sendersProvinceName: labelVal[0] || "",
            sendersCityName: labelVal[1] || "",
            sendersDistrictName: labelVal[2] || "",
          };
          self.batchDataArr.fhArr = {
            ...self.batchDataArr.fhArr,
            arr,
          };
          if (flag) {
            self.batchDataArr.shList = [SHOUHUOARR];
          }
        } else {
          labelVal = self.$refs[type][0].inputValue.split(" / ");
          arr = {
            recipientProvinceName: labelVal[0] || "",
            recipientCityName: labelVal[1] || "",
            recipientDistrictName: labelVal[2] || "",
          };
          self.batchDataArr.shList[faIdx] = {
            ...self.batchDataArr.shList[faIdx],
            arr,
          };
        }
      }, 0);
    },
    handleDelectItem(index) {
      let shList = [...this.batchDataArr.shList];
      if (shList.length === 1) {
        this.$message.warning("最少保留一条收货信息", 5000);
        return;
      }
      let self = this;
      self.$confirm("确认本次不给我发货了吗", {
        title: "温馨提示",
        confirmButtonText: "确定",
        cancelButtonText: "再考虑下",
        type: "success",
        showClose: false,
        closeOnClickModal: false,
        callback: (action) => {
          if (action === "confirm") {
            shList.splice(index, 1);
            self.batchDataArr.shList = shList;
            setBatchOrderForm(shList);
            costAssessment(self, self.batchDataArr);
          }
        },
      });
    },
    handleAddItem() {
      this.$refs["batchFormRef"].validate((valid, event) => {
        if (valid) {
          this.activeName = "receive";
          this.showAddressM("receive", false);
        } else {
          return false;
        }
      });
    },
    changeShow() {
      this.hasShow = !this.hasShow;
    },
    submitBatch() {
      const self = this;
      self.$refs["batchFormRef"].validate((valid, event) => {
        if (valid) {
          batchSubmit(this, this.batchDataArr);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.batch_form {
  padding-bottom: 70px;
}
.fa_box,
.sh_box {
  background-color: #fff;
  margin-bottom: 50px;
}
.fabg {
  background: url("../../../assets/images/fa_flag.png") no-repeat;
}
.shbg {
  background: url("../../../assets/images/sh_flag.png") no-repeat;
}
.abs_box {
  position: absolute;
  width: 49px;
  height: 60px;
  top: 0;
  left: 20px;
  background-size: cover;
}
.address_box {
  position: absolute;
  top: 25px;
  right: 80px;
}
.smt {
  padding: 70px 50px 60px 90px;
}
.rec_date >>> .el-form-item__label {
  font-size: 0;
}
.del_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 50px;
  color: #888;
}
.add_item {
  width: 50%;
  height: 70px;
  line-height: 70px;
  margin: 0 auto;
  text-align: center;
  background-color: #87bddc;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
}
.min_btn {
  width: 120px;
  line-height: 38px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #87bddc;
}
.batch_form >>> .el-dialog__body{
  padding-top: 0;
}
</style>