<template>
  <div class="addGoodsForm">
    <el-form :rules="model.rules" :model="model" ref="addGoodsForm">
    <el-table
      class="tborder"
      ref="multipleTable"
      :data="model.goodsDetailsData"
      style="width: 100%; margin-top: 10px; margin-bottom: 20px;box-shadow:none;"
    >
      <el-table-column
        :label="item.name"
        v-for="item in header"
        :key="item.val"
        align="center"
        :property="item.val"
        width="200"
        :render-header="renderHeader"
      >
        <template slot-scope="scope">
         <el-form-item :prop="'goodsDetailsData.' + scope.$index + `.${item.val}`"
         :rules="model.rules[item.val]">
          <el-select
            v-if="item.val === 'specifications'"
            v-model="scope.row[scope.column.property]"
            placeholder="请选择商品规格"
          >
            <el-option
              v-for="item in specsArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="block" v-else-if="item.val === 'productionTime' || item.val === 'invalidTime'">
            <el-date-picker
              v-model="scope.row[scope.column.property]"
              align="right"
              type="date"
              :placeholder="item.holder"
              value-format="yyyyMMdd"
              :picker-options="pickerOptions"
              style="width:100%"
            >
            </el-date-picker>
          </div>
          <el-input
            v-else
            v-model="scope.row[scope.column.property]"
            :placeholder="item.holder"
            :clearable="true"
          ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    </el-form>
  </div>
</template>
<script>
import { specsArr } from '@/assets/js/warehouse.js'
import { getStorage } from "@/assets/js/global";
const userStore = JSON.parse(getStorage("user"))
export default {
  data() {
     const checkNumber = (rule, value, callback) => {
        if(value != ""){
             if((/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(value) == false){
                 callback(new Error("请填写数字！"));
             }else{
                 callback();
             }
         }else{
             callback();
         }
     }
    return {
       pickerOptions: {
          // disabledDate(time) {
          //   return time.getTime() > Date.now();
          // },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
      specsArr: specsArr,
      header: [{
        name: '商品编号',
        val: 'code',
        holder: '请输入商品编号'
      },
      {
        name: '商品名称',
        val: 'name',
        holder: '请输入商品名称'
      },
      {
        name: '商品规格',
        val: 'specifications',
        holder: '请输入商品规格'
      },
      {
        name: '每托码货数量',
        val: 'number',
        holder: '请输入每托码货数量'
      },
      {
        name: '生产日期(批次)',
        val: 'productionTime',
        holder: '请输入生产日期'
      },
      {
        name: '失效日期',
        val: 'invalidTime',
        holder: '请输入失效日期'
      },
      {
        name: '单件重量(kg)',
        val: 'unitWeight',
        holder: '请输入单件重量'
      },
      {
        name: '单件体积(cm3)',
        val: 'unitVolume',
        holder: '请输入单件体积'
      },
      {
        name: '单件长(cm)',
        val: 'unitLong',
        holder: '请输入单件长'
      },
      {
        name: '单件宽(cm)',
        val: 'unitWide',
        holder: '请输入单件宽'
      },
      {
        name: '单件高(cm)',
        val: 'unitHigh',
        holder: '请输入单件高'
      }
      ],
      model:{
       goodsDetailsData: [{
        code: '',
        name: '',
        number: '',
        unitWeight: '',
        unitVolume: '',
        unitLong: '',
        unitWide: '',
        unitHigh: '',
        specifications: '',
        productionTime: '',
        invalidTime: '',
        corpId: userStore.corpId,
        creator: userStore.id,
        updator: userStore.id
      }],
      rules: {
            code: [
                 { required: true, message: "请输入商品编号", trigger: "blur" },
             ],
            name: [
                 { required: true, message: "请输入商品名称", trigger: "blur" },
             ],
            number: [
                 { required: true, message: "请输入每托码货数量", trigger: "blur" },
                 { validator: checkNumber, trigger: 'blur'}
             ],
            unitWeight: [
                 { validator: checkNumber, trigger: 'blur'}
             ],
            unitVolume: [
                 { validator: checkNumber, trigger: 'blur'}
             ],
            unitLong: [
                 { validator: checkNumber, trigger: 'blur'}
             ],
            unitWide: [
                 { validator: checkNumber, trigger: 'blur'}
             ],
            unitHigh: [
                 { validator: checkNumber, trigger: 'blur'}
             ],
            specifications: [
                 { required: true, message: "请输入商品规格", trigger: "change" }
             ],
            productionTime: [
                 { required: true, message: "请输入生产日期(批次)", trigger: "blur" }
             ],
            invalidTime: [
                 { required: true, message: "请输入失效日期", trigger: "blur" }
             ],
            },
      },
       
    }
  },
  methods: {
    chooseGood() {
      console.log(this.model.goodsDetailsData, 'dddd')
      console.log(this.userStore, 'fdfdd')
      this.$emit('chooseForm', this.model.goodsDetailsData)
    },
    renderHeader(h,obj){
       if (obj.column.label == '商品编号' || obj.column.label == '商品名称'
       || obj.column.label == '商品规格'|| obj.column.label == '每托码货数量'
       || obj.column.label == '生产日期(批次)'|| obj.column.label == '失效日期') {
           return h("div", {
              class: 'cell',
              domProps: {
                 innerHTML: '<span style="color:#F56C6C;">*&nbsp;</span>'+obj.column.label
              }
            })
         } else {
            return h("span", {}, obj.column.label)
          }
    }
  }
}
</script>
<style  scoped>
 /deep/.el-input__inner{
  box-shadow: none !important;
  border: 1px solid #dcdfe6;
}
</style>