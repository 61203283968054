import moment from 'moment'
import axios from 'axios'
import qs from 'qs'
import vueobj from '../../main'
import store from '../../store'
const API = require('/src/assets/js/apilist.js')
const url85 = 'http://192.168.100.85:8098' //85环境
// const url86='http://www.yizhan56.cn:8098';//带cookies指向86环境
const url86 = 'http://192.168.100.86:8098' //无cookies的86环境
const urlzs = 'http://yizhan56.cn' //正式环境
let Time = 0
// 判断设备是否为移动端
export var isMobile = function() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  // console.log('打印当前平台', flag)
  return flag
}
// zzx axios接口请求封装
export function $axios(
  method,
  _url,
  _params = {},
  data = 'data',
  contentType = 'application/x-www-form-urlencoded'
) {
  // console.log('打印当前data', data)
  const that = this
  axios.interceptors.response.use(
    res => {
      // console.log("打印响应拦截",res)
      if (res.data.isSuccess) {
        return Promise.resolve(res)
      } else {
        // console.log('isSuccess为false', res)
        if (res.data.code == 510 || res.data.code == 530) {
          if (isMobile()) {
            vueobj.$toast('请登录!')
            setTimeout(function() {
              vueobj.$router.replace('/m_login')
            }, 1500)
          } else {
            vueobj.$message({
              type: 'error',
              message: '请登录'
            })
            setTimeout(function() {
              vueobj.$router.replace('/p_login')
            }, 1500)
          }
        } else {
          if (isMobile()) {
            vueobj.$toast(res.data.msg)
          } else {
            vueobj.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        }
        return Promise.resolve(res)
      }
    },
    error => {
      console.log('拦截器打印这个error', error)
      vueobj.$toast('出错了，请联系管理员！')
      return Promise.reject(error)
    }
  )
  return (
    axios({
      method,
      headers: {
        'Content-Type': contentType,
        Authentication: localStorage.getItem('token')
      },
      // url: process.env.VUE_APP_URL + _url,
      // 如果是开发环境,则使用开发环境的配置,如果是其他环境,则直接拿域名的origin拼接接口名
      url:
        process.env.NODE_ENV == 'development'
          ? process.env.VUE_APP_URL + _url
          : window.location.origin + _url,
      [data]: contentType == 'application/x-www-form-urlencoded' ? qs.stringify(_params) : _params
    })
      // .interceptors.response.use(function (response) {
      //   // 对响应数据做点什么
      //   return response;
      // }, function (error) {
      //   // 对响应错误做点什么
      //   return Promise.reject(error);
      // });
      .then(res => {
        return Promise.resolve(res.data)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  )
}

// 获取验证码
export function getcodeAPI(phone) {
  //phone:手机号
  const params = {
    mobile: phone
  }
  $axios('post', API.sendIdentifyingCode, params, 'params')
}

/**
 * 正则共用开始
 **/
export function regExp(type, flag, tips) {
  let tipsName
  const rules = typeof flag !== 'undefined' ? flag : true
  switch (type) {
    case 'integer':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /^([1-9]{1}\d*)?$/,
          message: tips || '填写正整数'
        }
      ]
      break
    case 'roundNumber':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /^([0-9]{1}\d*)?$/,
          message: tips || '填写整数'
        }
      ]
      break
    case 'oneDec':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[1-9](\d{1,8})?$)|(^\d{1,9}\.\d{1}$)/,
          message: tips || '非0且最多保留1位小数'
        }
      ]
      break
    case 'oneDec-0':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[0-9](\d{1,8})?$)|(^\d{1,9}\.\d{1}$)/,
          message: tips || '最多保留1位小数'
        }
      ]
      break
    case 'twoDec':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
          message: tips || '非0且最多保留2位小数'
        }
      ]
      break
    case 'twoDec-0':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[0-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
          message: tips || '最多保留2位小数'
        }
      ]
      break
    case 'threeDec':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[1-9](\d{1,5})?$)|(^\d{1,6}\.\d{1,3}$)/,
          message: tips || '非0且最多保留3位小数'
        }
      ]
      break
    case 'threeDec-0':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[0-9](\d{1,5})?$)|(^\d{1,6}\.\d{1,3}$)/,
          message: tips || '最多保留3位小数'
        }
      ]
      break
    case 'fourDec':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[1-9](\d{1,5})?$)|(^\d{1,6}\.\d{1,4}$)/,
          message: tips || '非0且最多保留4位小数'
        }
      ]
      break
    case 'sixDec':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^[1-9](\d{1,5})?$)|(^\d{1,6}\.\d{1,6}$)/,
          message: tips || '非0且最多保留6位小数'
        }
      ]
      break
    case 'mobilePhone':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^1\d{10}$)|(^0\d{2,3}-?\d{7,8}$)/,
          message: tips || '手机号或座机号（例：区号-号码）格式有误'
        }
      ]
      break
    case 'phone':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /^1\d{10}$/,
          message: tips || '手机号格式有误'
        }
      ]
      break
    case 'tel':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /^0\d{2,3}-?\d{7,8}$/,
          message: tips || '座机号（例：区号-号码）格式有误'
        }
      ]
      break
    case 'email':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
          message: tips || '邮箱地址格式有误'
        }
      ]
      break
    case 'idCard':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/,
          message: tips || '身份证号码格式有误'
        }
      ]
      break
    case 'percent':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /(^([1-9]?\d)(\.\d{1,2})?$)|(^100$)/,
          message: tips || '百分率格式有误，最多2位小数'
        }
      ]
      break
    case 'bankNo':
      tipsName = [
        {
          required: rules,
          message: '请填写'
        },
        {
          pattern: /^[0-9]{5,30}$/,
          message: tips || '银行卡号格式有误'
        }
      ]
      break
    case 'other':
      tipsName = [
        {
          required: rules,
          message: tips || '请填写'
        }
      ]
      break
    default:
  }
  return tipsName
}
export function dateRec(selDate) {
  let selDateStamp = moment(selDate).format('YYYY-MM-DD HH:mm:ss')
  let newDateStamp = moment()
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss')
  var diffDay = moment(selDateStamp).diff(newDateStamp, 'days') //发货日期和当前日期相差多少天
  console.log(diffDay, selDateStamp, newDateStamp)
  let allTimeArr = renderTimePeriod() //
  console.log('allTimeArr====', allTimeArr)
  if (diffDay === 0) {
    //同一天时
    let h = Number(moment().format('HH')) //当前小时
    let s = Number(moment().format('mm')) //当前分钟
    let timeStr = '',
      curIndx
    if (s >= 30) {
      timeStr = h + 1 + ':30' + '-' + (h + 2) + ':00'
    } else {
      timeStr = h + 1 + ':00' + '-' + (h + 1) + ':30'
    }
    for (let i = 0; i < allTimeArr.length; i++) {
      if (allTimeArr[i] === timeStr) {
        curIndx = i
      }
    }
    allTimeArr = allTimeArr.slice(curIndx, allTimeArr.length)
  }
  return allTimeArr
}
//根据发货时间渲染可选时间段
export function renderTimePeriod(hours, minute) {
  const isKACustomer = store.getters.getCorpMsg.isKACustomer || ''
  let arr = []
  let i = isKACustomer === '1' ? 8 : 10
  for (i; i < 20; i++) {
    const iStr = (i + '').padStart(2, '0')
    //渲染全部时间段
    arr.push(iStr + ':00' + '-' + iStr + ':30')
    let startNo2 = iStr + ':30' + '-' + ((i + 1) + '').padStart(2, '0') + ':00'
    arr.push(startNo2)
  }
  return arr
}
//本地存储
export function setStorage(field, datas) {
  let newDatas = datas
  if (typeof datas === 'object') {
    newDatas = JSON.stringify(newDatas)
  }
  localStorage.setItem(field, newDatas)
}
export function getStorage(field) {
  const newDatas = localStorage.getItem(field)
  return newDatas
}
export function removeStorage(field) {
  localStorage.removeItem(field)
}
//封装公斤换算成吨-->价格(val/1000)
export function priceKG(arg1, arg2 = 1000) {
  if (arg1 == undefined) return
  if (arg2 == 0) return 0
  let t1 = 0,
    t2 = 0,
    r1,
    r2,
    point = 0,
    num = 0
  try {
    t1 = arg1.toString().split('.')[1].length
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {}
  r1 = Number(arg1.toString().replace('.', ''))
  r2 = Number(arg2.toString().replace('.', ''))
  point = t1 > t2 ? t1 : t2
  num = ((r1 / r2) * Math.pow(10, t2 - t1)).toFixed(point + 3)
  const regexp = /(?:\.0*|(\.\d+?)0+)$/
  return num.replace(regexp, '$1')
}

//封装吨换算成公斤--》单位(val*1000)
export function priceT(arg1, arg2 = 1000) {
  if (arg1 == undefined) return
  let r1 = 0,
    r2 = 0,
    m,
    point = 0,
    num = 0
  try {
    //取小数位长度
    r1 = arg1.toString().split('.')[1].length
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {}
  point = r1 > r2 ? r1 : r2
  m = Math.pow(10, Math.max(r1, r2)) //计算因子
  num = ((arg1 * m * (arg2 * m)) / (m * m)).toFixed(point)
  const regexp = /(?:\.0*|(\.\d+?)0+)$/
  return num.replace(regexp, '$1')
}
//zzx-运输订单条件查询参数过滤函数:
export function filterParams(params) {
  let json = {}
  let user = JSON.parse(localStorage.getItem('user'))
  // console.log("打印缓存user",user)
  // console.log("打印用户id",user.id)
  Object.keys(params).forEach(key => {
    json[key] = params[key]
    if (key == 'userId' && !!user) {
      json[key] = user.id
    }
    if (key == 'sendCity' && params[key] == '') {
      delete json[key]
    }
    if (key == 'sendDist' && params[key] == '') {
      delete json[key]
    }
    if (key == 'endCity' && params[key] == '') {
      delete json[key]
    }
    if (key == 'endDist' && params[key] == '') {
      delete json[key]
    }
    if (key == 'sendTimeBegin' && params[key] != '') {
      json[key] = params[key] + ' 00:00:00'
    }
    if (key == 'sendTimeEnd' && params[key] != '') {
      json[key] = params[key] + ' 23:59:59'
    }
  })
  return json
}
// PC运输订单接口专用--zzx-运输订单条件查询参数过滤函数
export function _filterParams(params) {
  let json = {}
  let user = JSON.parse(localStorage.getItem('user'))
  // console.log("打印缓存user",user)
  // console.log("打印用户id",user.id)
  Object.keys(params).forEach(key => {
    json[key] = params[key]
    if (key == 'userId' && !!user) {
      json[key] = user.id
    }
    // if (key == 'sendCity' && params[key] == '') {
    //   delete json[key]
    // }
    // if (key == 'sendDist' && params[key] == '') {
    //   delete json[key]
    // }
    // if (key == 'endCity' && params[key] == '') {
    //   delete json[key]
    // }
    // if (key == 'endDist' && params[key] == '') {
    //   delete json[key]
    // }
    if (key == 'sendTimeBegin' && params[key] != '') {
      json[key] = params[key] + ' 00:00:00'
    }
    if (key == 'sendTimeEnd' && params[key] != '') {
      json[key] = params[key] + ' 23:59:59'
    }
  })
  return json
}
//zzx-仓储订单接口查询参数过滤函数:
export function filterParams2(params) {
  let json = {}
  let user = JSON.parse(localStorage.getItem('user'))
  Object.keys(params).forEach(key => {
    json[key] = params[key]
    if (key == 'userId' && !!user) {
      json[key] = user.id
    }
    if (key == 'mobile' && !!user) {
      json[key] = user.mobile
    }
    if (key == 'createTimeBegin' && params[key] != '') {
      json[key] = params[key] + ' 00:00:00'
    }
    if (key == 'endTimeEnd' && params[key] != '') {
      json[key] = params[key] + ' 23:59:59'
    }
  })
  return json
}
/* 乘法 */
export function multiply(a, b) {
  let m = 0,
    c = a.toString(),
    d = b.toString()
  try {
    m += c.split('.')[1].length
  } catch (e) {}
  try {
    m += d.split('.')[1].length
  } catch (e) {}
  return (Number(c.replace('.', '')) * Number(d.replace('.', ''))) / Math.pow(10, m)
}
/*数字四舍五入（保留n位小数）*/
export function getFloat(number, n) {
  n = n ? parseInt(n) : 0
  if (n <= 0) return Math.round(number)
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n)
  return number
}
// 根据85,86,正式环境跳转1.0官网不同页面
export function envJump(url, way, type) {
  //url跳转地址,way跳转方式,有href,replace,type:0,是跳转1.0;type:1,跳转1.1;
  console.log('执行跳转')
  const origin = window.location.origin
  if (type == 0) {
    switch (
      origin //判断环境
    ) {
      case 'http://localhost:8080':
        window.location[way] = url85 + url
        break
      case 'http://192.168.100.85':
        window.location[way] = url85 + url
        break
      case 'http://m-test.yizhan56.cn':
        window.location[way] = url86 + url
        break
      case 'https://m.yizhan56.cn':
        window.location[way] = urlzs + url
        break
    }
  } else {
    this.$router.push(url)
  }
}
// 判断是微信内部浏览器还是外部浏览器
export function wxBrower() {
  let viewer = window.navigator.userAgent.toLowerCase()
  if (viewer.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
//
// 节流函数
export function throttle(func, waitTime) {
  //func:节流作用的函数对象,waitTime:等待时间
  const now = Date.now()
  if (Time == 0 || now - Time > waitTime * 1000) {
    //如果是第一次触发或者触发的间隔时间大于等待时间
    Time = now
    console.log('打印这个全局时间Time', Time)
    func()
  } else {
    vueobj.$toast('请不要连续点击!')
  }
}
