<template>
  <div class="step_three form_area">
    <el-form :model="newStepArr" :rules="rulesTwo" ref="ruleFormTwo" class="ruleForm">
      <h3 class="h3 bjbox">保价运输</h3>
      <el-row>
        <el-col :span="8">
          <el-form-item label="输入货值" prop="declaredValue">
            <el-input
              v-model="newStepArr.declaredValue"
              prefix-icon="iconfont icon-money"
              placeholder="保价千分之二，最低一票5元"
              :disabled="isLock"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <h3 class="h3">送货服务</h3>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="pickService">
            <el-radio-group v-model="newStepArr.pickService" :disabled="isLock">
              <el-radio label="shsm">送货上门</el-radio>
              <el-radio label="shjc">送货进仓</el-radio>
              <el-radio label="ziti">自提</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <h3 class="h3">回单服务</h3>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="receiptType" class="mb0">
            <el-radio-group v-model="newStepArr.receiptType" :disabled="isLock">
              <el-radio label="ZL1605215" @click.native="optionClick('无需回单', 'receiptTypeName')"
                >无需回单</el-radio
              >
              <el-radio label="ZL1605211" @click.native="optionClick('电子回单', 'receiptTypeName')"
                >电子回单</el-radio
              >
              <el-radio
                label="ZL1605214"
                @click.native="optionClick('纸质回单 | 普通', 'receiptTypeName')"
                >纸质回单 | 普通</el-radio
              >
              <el-radio
                label="ZL2209291"
                @click.native="optionClick('纸质回单 | 快件', 'receiptTypeName')"
                >纸质回单 | 快件</el-radio
              >
              <!-- <el-radio label="ZL1605216" @click.native="optionClick('货物清单', 'receiptTypeName')"
                >货物清单</el-radio
              > -->
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col
          v-if="newStepArr.receiptTypeName == '纸质回单 | 普通' && isSvip"
          :span="24"
          class="f999"
          ><span class="tipTag">惠</span>回单费减免15元，注：小票零担产品不参与</el-col
        >
      </el-row>
      <!-- 回单方式为“纸质回单 | 快件”才展示 -->
      <template v-if="newStepArr.receiptType == 'ZL2209291'">
        <h3 class="h3">回单接受信息</h3>
        <el-row>
          <el-col :span="8">
            <el-form-item class="mtp_ipt" label="接收人" prop="receiptTypeUserName">
              <el-input
                v-model="newStepArr.receiptTypeUserName"
                placeholder="请输入接收人"
                :disabled="isLock"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="mtp_ipt" label="接收电话" prop="receiptTypeUserPhone">
              <el-input
                v-model="newStepArr.receiptTypeUserPhone"
                placeholder="请输入接收电话"
                :disabled="isLock"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="mtp_ipt" label="接收地址" prop="receiptTypeUserAddress ">
              <el-input
                v-model="newStepArr.receiptTypeUserAddress"
                placeholder="请输入接收地址"
                :disabled="isLock"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <h3 class="h3">包装方式</h3>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="addedService">
            <el-radio-group v-model="strAddedService" @change="handleChecked" :disabled="isLock">
              <el-radio
                v-for="item in $store.state.addedServiceList"
                :label="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8" v-if="isShowMtpFun()">
          <el-form-item class="mtp_ipt" prop="mtp">
            <el-input v-model="newStepArr.mtp" placeholder="请输入托盘个数" :disabled="isLock">
              <template slot="prepend">木托盘：</template>
              <template slot="append">个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="isShowMxFun()">
          <el-form-item class="mx_select">
            <span>木箱厚度：</span>
            <el-select v-model="mxType" class="myselect" :disabled="isLock" @change="handleSelete">
              <el-option
                class="myselect"
                v-for="item in $store.state.addedServiceMx"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <h3 class="h3">装卸服务</h3>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="services">
            <el-checkbox-group v-model="newStepArr.services" :disabled="isLock">
              <el-checkbox
                v-for="item in $store.state.servicesList"
                :label="item.id"
                :key="item.id"
                border
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="newStepArr.remark"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="xright mt30">
        <el-button type="primary" class="main_btn sec_btn" @click="prev">上一步</el-button>
        <el-button type="primary" class="main_btn" @click="next">下一步</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { regExp } from '@/assets/js/global'
import { getBatchOrderForm } from '@/assets/js/storage'
import {
  submitForm,
  providerInfoAllN,
  submitBatchForm,
  calculateWeight,
  receiptInfoFun
} from '@/assets/js/order'
export default {
  name: 'orderFormTwo',
  props: {
    stepTwoArr: {
      type: Object,
      required: true
    },
    isBatch: {
      type: Boolean
    },
    faIdx: {
      type: Number,
      required: true
    },
    isLock: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      drawerType: this.$store.state.drawerType,
      newStepArr: this.stepTwoArr,
      mxType: '', //木箱类型
      strAddedService: '0', //单选的增值服
      rulesTwo: {
        declaredValue: regExp('twoDec-0', false),
        pickService: regExp('other'),
        addedService: regExp('other'),
        receiptType: regExp('other'),
        services: regExp('other', false),
        remark: regExp('other', false),
        mtp: regExp('twoDec'),
        receiptTypeUserPhone: regExp('mobilePhone')
      }
    }
  },
  computed: {
    //客户是不是svip
    isSvip() {
      let isSvip = this.$store.state.isSvip
      return isSvip
    }
  },
  watch: {
    stepTwoArr(newVal, oldVal) {
      this.newStepArr = newVal
      this.addedServiceFun() //重组包装方式
    },
    'newStepArr.receiptType'(newVal, oldVal) {
      if (newVal) {
        let newStepArr = receiptInfoFun(this.newStepArr, this.isBatch)
        this.newStepArr = newStepArr
      }
    }
  },
  module() {
    let newStepArr = receiptInfoFun(this.newStepArr, this.isBatch)
    this.newStepArr = newStepArr
  },
  methods: {
    //包装方式服务回选处理
    addedServiceFun() {
      let addedServiceArr = this.newStepArr.addedService
      let hasMtp = addedServiceArr.includes('mtp') || ''
      let hasMj = addedServiceArr.includes('mj') || ''
      let hasMx = ['bkml', 'hkml'].filter(item => addedServiceArr.indexOf(item) > -1)
      let mxType = hasMx.length > 0 ? hasMx.join('') : ''
      if (hasMtp && hasMj) {
        this.strAddedService = 'mjMtp'
        this.mxType = mxType
      } else if (hasMtp && mxType) {
        this.strAddedService = 'mxMtp'
        this.mxType = mxType
      } else if (mxType) {
        this.strAddedService = 'mx'
        this.mxType = mxType
      } else {
        this.strAddedService = addedServiceArr.join()
      }
    },
    //展示木托盘数量输入框
    isShowMtpFun() {
      let str = this.strAddedService
      return str.includes('mtp') || str.includes('mjMtp') || str.includes('mxMtp') ? true : false
    },
    //展示木箱选择
    isShowMxFun() {
      let str = this.strAddedService
      return str.includes('mx') || str.includes('mxMtp') ? true : false
    },
    //选择木箱
    handleSelete(e) {
      this.mxType = e
      this.arrAddedServiceFun()
    },
    //格式化
    arrAddedServiceFun() {
      let str = this.strAddedService
      let mx = this.mxType
      let arr = []
      if (str == 'mjMtp') {
        arr = ['mj', 'mtp']
      } else if (str == 'mxMtp') {
        arr = ['mtp', mx]
      } else if (str == 'mx') {
        arr = [mx]
      } else {
        arr = [str]
      }
      this.newStepArr.addedService = arr
    },
    optionClick(name, field) {
      if (this.newStepArr[field] === name) return
      this.newStepArr[field] = name
    },
    getMtp(service) {
      let rex = service.includes('mtp')
      let num = ''
      if (service && rex) {
        let temp = service.match(/\d+(\.\d+)?/)
        num = temp[0] ? temp[0] : ''
        if (num) {
          service = service.replace(',' + num, '')
        }
        this.setState({
          mtp: num
        })
      }
      return service
    },
    // getMtpStr(selAddedService, mtp) {
    //   let newSelAddedService = selAddedService.join('|')
    //   if (selAddedService.includes('mtp')) {
    //     newSelAddedService = newSelAddedService.replace('mtp', 'mtp,' + mtp)
    //   }
    //   return newSelAddedService
    // },
    handleChecked(e) {
      this.strAddedService = e
      this.arrAddedServiceFun()
    },
    prev() {
      if (!this.isBatch) {
        this.$store.state.stepAction--
      } else {
        this.$emit('faFunc', 1)
      }
    },
    async next() {
      let weightAndVolume = await calculateWeight(this.newStepArr, this)
      let ruleForm = { ...this.newStepArr, ...weightAndVolume }
      if (this.isBatch) {
        let batchDatas = getBatchOrderForm()
        ruleForm = {
          ...ruleForm,
          ...batchDatas.fhArr
        }
      }
      providerInfoAllN(this, ruleForm, this.isBatch, 'mr', this.callbackProvider)
    },
    callbackProvider(isNext) {
      if (isNext !== true) return
      if (!this.isBatch) {
        submitForm('ruleFormTwo', this)
      } else {
        submitBatchForm(this, 'ruleFormTwo', this.newStepArr, this.faIdx, 3)
      }
    }
  }
}
</script>
<style scoped>
.mtp_ipt >>> .el-input__inner,
.mx_select >>> .el-input__inner {
  box-shadow: none;
  margin-bottom: 0;
  border: 1px solid #dcdfe6;
}
.mx_select {
  padding: 0;
  background-color: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.mx_select span {
  color: #909399;
  display: inline-block;
  width: 120px;
  vertical-align: middle;
  border-right: 1px solid #dcdfe6;
  text-align: center;
  line-height: 36px;
}
.mx_select >>> .el-form-item__content {
  margin: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: 36px;
}
.mx_select >>> .el-input__inner {
  flex: 1;
  border: none;
  line-height: 28px;
}
/* .batch_form .bjbox {
  display: none;
} */
.tipTag {
  display: inline-block;
  margin-right: 0.2rem;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #ff6648;
  border-radius: 4px;
  color: #ffffff;
}
.mb0 {
  margin-bottom: 0;
}
</style>
