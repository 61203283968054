<template>
   <div id="m_transDetail">
       <!-- <headNav></headNav> -->
        <van-tabs v-model="active" animated swipeable @change="changeTab">
            <van-tab v-for="(item,index) in tabArr" :key="item.transType" :title="item.title">
                <TransOrderMsg ref="TransOrderMsg" v-show="active==0"></TransOrderMsg>
                <TransLogistics v-show="active==1"></TransLogistics>
            </van-tab>
        </van-tabs>
        <!-- 支付 -->
        <div v-show="active==0" class="btn flex xycenter" @click="pay">支付</div>
    </div>
</template>

<script>
import headNav from '../../components/mobile/headNav.vue';
import TransLogistics from "../../components/mobile/TransLogistics.vue";//运输订单详情--物流信息组件
import TransOrderMsg from "../../components/mobile/TransOrderMsg.vue";//运输订单详情--订单信息组件
export default {

    components: {
        TransLogistics,
        TransOrderMsg,
        headNav
    },

    data() {
        return {
            active:"",
            tabArr:[
                {title:'订单信息',transType:0},
                {title:'物流信息',transType:1}
            ]
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        pay:function(){
            const that=this;
            console.log("发起支付")
            const detail=JSON.parse(localStorage.getItem('transDetail'));
            if(detail.surplusMoney>0){
                that.$router.push({
                    name:'m_payment',
                    params:{
                        orderIds:[detail.id],
                        orderNum:1,
                        amount:detail.surplusMoney
                    }
                })
            }else{
                this.$toast.fail('当前支付金额为0')
            }
            
        },
        changeTab:function(idx,title){
            this.active=idx;
        }
    }

}

</script>
<style scoped>
    #m_transDetail{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
    }
    .btn{
        width: 340px;
        height: 90px;
        background: #007EC5;
        color: white;
        font-size: 32px;
        border-radius: 46px;
        position: fixed;
        bottom:2%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .vanTab{
        /* position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; */
    }
    >>>.van-tabs{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
    }
    >>>.van-tabs__nav{
        background-color: rgba(0, 0, 0, 0);
        justify-content: center;
    }
    >>>.van-tab{
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        flex: unset;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 12px;
        color: #646566;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
    }
    >>>.van-tabs__line{
        transform: translateX(156px) translateX(-50%);
        transition-duration: 0.3s;
        position: absolute;
        bottom: 15px;
        left: 0;
        z-index: 1;
        width: 40px;
        height: 3px;
        background-color: #007EC5;
        border-radius: 3px;
    }
    >>>.van-tab--active{
        color: #007EC5;
    }
    >>>.van-tabs__content{
        /* padding:20px; */
        flex: 1;
        overflow-y: scroll;
    }

</style>
