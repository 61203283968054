const getUserStore = () => {
  let user = localStorage.getItem('user');
  user = user ? JSON.parse(user) : {}
  return user
}
const setOrderForm = (options) => {
  if (typeof options === 'object') {
    options = JSON.stringify(options);
  }
  let userData = getUserStore();
  let minuseId = userData.id || ''
  let mincorpId = userData.corpId || ''
  let id = mincorpId ? ('B' + mincorpId) : ('A' + minuseId)
  localStorage.setItem("orderStore_" + id, options)
}
const getOrderForm = () => {
  let userData = getUserStore();
  let minuseId = userData.id || ''
  let mincorpId = userData.corpId || ''
  let id = mincorpId ? ('B' + mincorpId) : ('A' + minuseId)
  let store = localStorage.getItem("orderStore_" + id);
  store = store ? JSON.parse(store) : '';
  return store
}
const removeOrderForm = (type) => {
  let userData = getUserStore();
  let minuseId = userData.id || ''
  let mincorpId = userData.corpId || ''
  let id = mincorpId ? ('B' + mincorpId) : ('A' + minuseId)
  localStorage.removeItem(type + "_" + id);
}
const setBatchOrderForm = (options) => {
  if (typeof options === 'object') {
    options = JSON.stringify(options);
  }
  let userData = getUserStore();
  let minuseId = userData.id || ''
  let mincorpId = userData.corpId || ''
  let id = mincorpId ? ('B' + mincorpId) : ('A' + minuseId)
  localStorage.setItem("batchForm_" + id, options)
}
const getBatchOrderForm = () => {
  let userData = getUserStore();
  let minuseId = userData.id || ''
  let mincorpId = userData.corpId || ''
  let id = mincorpId ? ('B' + mincorpId) : ('A' + minuseId)
  let store = localStorage.getItem("batchForm_" + id);
  store = store ? JSON.parse(store) : '';
  return store
}
const getAreaFilterVersion = () => {
  let version = localStorage.getItem('area_filter_version');
  version = version ? JSON.parse(version) : {}
  return version
}
const setAreaFilterVersion = (data) => {
  if (typeof data === 'object') {
    data = JSON.stringify(data);
  }
  localStorage.setItem("area_filter_version", data)
}
/**
 * 是否更新地址库
 * @param {*} name 
 */
const getAreaFilterList = (name) => {
  let version = localStorage.getItem(name);
  version = version ? JSON.parse(version) : []
  return version
}
const setAreaFilterList = (name, data) => {
  if (typeof data === 'object') {
    data = JSON.stringify(data);
  }
  localStorage.setItem(name, data)
}
/**
 * 是否已阅读同意《运输条款》和《隐私政策》
 * @param {*} options 
 */
const setAgreementState = (options) => {
  let userData = getUserStore();
  let useId = userData.id || ''
  localStorage.setItem("yzky_agreement_state" + useId, options)
}
const getAgreementState = () => {
  let userData = getUserStore();
  let useId = userData.id || ''
  let options = localStorage.getItem("yzky_agreement_state" + useId)
  return options ? options : 0
}
module.exports = {
  getUserStore,
  setOrderForm,
  removeOrderForm,
  getOrderForm,
  setBatchOrderForm,
  getBatchOrderForm,
  AREA_LD_START: 'AREA_LD_START', // 公路零担起始
  AREA_LD_END: 'AREA_LD_END', // // 公路零担结束
  getAreaFilterVersion,
  setAreaFilterVersion,
  getAreaFilterList,
  setAreaFilterList,
  setAgreementState,
  getAgreementState
}