<template>
   <div id="m_inStoreDetail">
        <headNav class="headNav"></headNav>
        <!-- 订单信息 -->
        <div class="maincontent">
            <div class="pt20 pb20">订单信息</div>
            <div class="board">
                <div class="row flex">
                    <div class="flex1">仓库信息</div>
                    <div class="flex1 xright">{{detail.depotName}}</div>
                </div>
                <div class="row flex">
                    <div class="flex1">预计入库时间</div>
                    <div class="flex1 xright">{{detail.warehousingTime}}</div>
                </div>
                <div class="row flex">
                    <div class="flex1">储存天数</div>
                    <div class="flex1 xright">{{detail.storageDays}}</div>
                </div>
                <div class="row flex">
                    <div class="flex1">入库需求</div>
                    <div class="flex1 xright">{{detail.warehousingType==1?'整进整出':'整进散出'}}</div>
                </div>
            </div>
        </div>
        <!-- 入库清单 -->
        <div class="inbill">
            <div class="pt20 pb20">入库清单</div>
            <div class="flex">
                <div class="flex1 flex xycenter tableHeader fz28" v-for="(item,index) in tableHeader">{{item.name}}</div>
            </div>
            <div class="flex goodListRow pt20 pb20 b_border" :key="item.code" v-for="(item,index) in goodList">
                <div class="flex1 omit">{{item.code}}</div>
                <div class="flex1 omit">{{item.name}}</div>
                <div class="flex1 omit">{{item.productionTime}}</div>
                <div class="flex1 omit">{{item.enterNumber}}</div>
            </div>
        </div>
        <!-- 合计 -->
        <div class="xright fz28 padtb28 pt20 pl20 pr20">
            合计:<span class="red">{{detail.totalPakages}}箱、{{detail.totalWeight}}kg、{{detail.totalVolume}}方</span>
        </div>
        <!-- 费用明细 -->
        <div>
            <div class="pt20 pb20 pl20 pr20">费用明细</div>
            <!-- 租仓费 -->
            <div class="baserow ml20 mr20">
                <div class="flex pad20">
                    <div class="flex1">租仓费</div>
                    <div class="flex1 xright">{{!!cost.zcfFeeList?cost.zcfFeeList:''}}元</div>
                </div>
                <div v-for="(item,index) in cost.feeList" :key="item.commodityCode" class="t_border">
                    <div class="flex pl20 pt20 pr20">
                        <div class="flex1">商品名称:{{item.commodityName}}</div>
                        <div class="flex1">入库日期:{{moment(item.warehousingTime).format('YYYYMMDD')}}</div>
                    </div>
                    <div class="flex pl20 pt20 pr20">
                        <div class="flex1">天数:{{item.storageDays}}</div>
                        <div class="flex1">托盘数:{{item.palletNumber}}</div>
                    </div>
                    <div class="flex pl20 pt20 pr20 pb20">
                        <div class="flex1">单价:{{item.sellRate}}</div>
                        <div class="flex1"></div>
                    </div>
                </div>
            </div>
            <!-- 装卸费 -->
            <div class="baserow ml20 mr20">
                <div class="flex pad20">
                    <div class="flex1">装卸费</div>
                    <div class="flex1 xright">{{!!cost.ZXF?cost.ZXF:''}}元</div>
                </div>
                <div v-for="(item,index) in cost.zxfList" :key="item.commodityCode" class="t_border">
                    <div class="flex pad20">
                        <div class="flex1">吨数:{{item.number}}吨</div>
                        <div class="flex1">单价:{{item.sellRate}}元</div>
                    </div>
                </div>
            </div>
            <!-- 合计 -->
            <div class="flex pad20 baserow ml20 mr20">
                <div class="flex1">合计</div>
                <div class="flex1 xright red">{{cost.totalFee}}元</div>
            </div>

        </div>

   </div>
</template>

<script>
import headNav from '../../components/mobile/headNav.vue';
const API=require('/src/assets/js/apilist.js');
export default {

    components: {
        headNav

    },

    data() {
        return {
            detail:{},//详情
            cost:{},//各种费用
            tableHeader:[
                {name:'商品编号'},
                {name:'商品名称'},
                {name:'生产日期'},
                {name:'入库数量'}
            ],
            // costHeader:[
            //     {name:'商品名称'},
            //     {name:'入库日期'},
            //     {name:'天数'},
            //     {name:'托盘数'},
            //     {name:'单价'}
            // ],
            // costHeader2:[
            //     {name:'吨数'},
            //     {name:'单价'},
            // ],
            goodList:[],//商品列表
            
        }
    },

    created() {
        this.getDetail();
    },


    mounted() {
        

    },

    methods: {
        // 出库数量：单位为托时，转为箱:每托码货数量*出库数量
        changeUnit:function(arr){//arr为商品清单,返回以quantityArr,depotEnterNoArr,commodityIdArr的对象
            let quantityArr=[];
            let depotEnterNoArr=[];
            let commodityIdArr=[];
            let obj={};
            let newInNumber = 0
            arr.forEach(item => {
                if(item.id && item.enterNumber){//入库单才有的逻辑
                    commodityIdArr.push(item.id)
                }
                if (item.specifications == '2') {//出库数量：单位为托时，转为箱:每托码货数量*出库数量
                    newInNumber = parseFloat(item.enterNumber || 0) * parseFloat(item.number || 0);
                } else {
                    newInNumber = parseFloat(item.enterNumber || 0)
                }
                quantityArr.push(newInNumber)
                depotEnterNoArr.push(item.depotEnterNo)
                // commodityIdArr.push(item.commodityId)
            });
            obj.quantity=quantityArr.join();
            obj.depotEnterNo=depotEnterNoArr.join();
            obj.commodityId=commodityIdArr.join();
            return obj
        },
        getDetail:function(){
            const that=this;
            that.$axios('get',API.getById+'?id='+that.$route.query.id)
            .then((res)=>{
                console.log("获取仓库订单详情成功",res)
                that.detail=res.data;
                that.goodList=JSON.parse(res.data.commodityJson)
                var goods=that.changeUnit(that.goodList);
                console.log("打印commodityJson",that.goodList)
                console.log("打印这个goods",goods);
                var params={
                    depotId:res.data.depotId,
                    feeCode:'ZCF,ZXF',
                    number:res.data.totalPakages,
                    volume:res.data.totalVolume,
                    weight:res.data.totalWeight,
                    warehousingTime:res.data.warehousingTime,
                    storageDays:res.data.storageDays,
                    status:1,
                    commodityId:goods.commodityId,
                    quantity:goods.quantity
                }
                console.log("打印入库请求费用接口参数",params)
                that.$axios('get',API.enterPricing,params,'params','application/json').then((res)=>{
                    console.log("获取入库费用详情",res)
                    that.cost=res.data;//费用列表
                    // if(res.cost.feeList.length>0){
                    //     res.cost.feeList.forEach(item=>{
                    //         // item.date=that.moment(item.warehousingTime).format('YYYYMMDD')
                    //     })
                    // }
                }).catch((error)=>{

                })
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }

    }

}

</script>
<style scoped>
    #m_inStoreDetail{
        /* padding:20px; */
        font-size: 28px;
    }
    .headNav{
        width: 100%;
        position: fixed;
        top: 0;
    }
    .mr20{
        margin-right: 20px;
    }
    .ml20{
        margin-left: 20px;
    }
    .inbill{
        padding: 20px;
    }
    .maincontent{
        padding: 20px;
        margin-top: 128px;
    }
    .red{
        color:#B93A13;
    }
    .board{
        border-radius: 12px;
        -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
    }
    .baserow{
        margin-top: 20px;
        background-color: white;
        border-radius: 12px;
        -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
    }
    .bgwhite{
        background-color: white;
    }
    .pb20{
        padding-bottom: 20px;
    }
    .pl20{
        padding-left: 20px;
    }
    .pr20{
        padding-right: 20px;
    }
    .pt{
        padding-top: 20px;
    }
    .box{
        background-color: white;
    }
    .b_border{
        border-bottom: 1px solid #dcdcdc;
    }
    .t_border{
        border-top: 1px solid #dcdcdc;
    }
    .goodListRow{
        background-color: white;
    }
    .omit{
        /* width: 60px; */
        text-align: center;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
     }
    .tableHeader{
        background-color: #007EC5;
        color: white;
        padding: 10px 0;
    }
    .board{
        background-color: white;
    }
    .row{
        font-size: 28px;
        padding:20px;
    }
    .fz28{
        font-size: 28px;
    }
    .pad20{
        padding: 20px;
    }

</style>
