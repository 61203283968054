<template>
    <div id="p_orderDetail">
        <div class="maincontent">
            <!-- 头部 -->
            <div class="flex ycenter ptb40">
            <div class="flex1">
                <span class="fz18" @click="goback"><<返回订单列表</span>
            </div>
            <div class="flex1 xright">
            </div>
            </div>
            <!-- 待入库 -->
            <div class="pad22 card">
                <div class="flex">
                    <div class="flex1 card_t">{{detail.stateName}}</div>
                    <div class="flex1 xright ycenter orderno">订单号:{{detail.depotEnterNo}}</div>
                </div>
                <div class="mt30">用户下单时间:{{detail.createTime}}</div>

            </div>
            <!-- 收费明细 -->
            <div class="pad22 card">
                <div class="flex1 card_t">收费明细</div>
                <div class="flex fz18">
                    <div class="flex1 yrow">
                        <div class="pt20 pb10 flex1">仓租费:{{!!cost.zcfFeeList?cost.zcfFeeList:''}}元</div>
                        <!-- 租仓费表 -->
                        <div class="l_border r_border t_border">
                            <div class="flex headbg2" v-if="cost.feeList.length>0">
                                <div class="flex1 xrow xycenter" v-for="(item,index) in costHeader" :key="item.name">{{item.name}}</div>
                            </div>
                            <div class="flex ptb10 b_border" v-for="(item,index) in cost.feeList" :key="item.commodityCode">
                                <div class="flex1 xycenter">{{item.commodityName}}</div>
                                <div class="flex1 xycenter">{{moment(item.warehousingTime).format('YYYYMMDD')}}</div>
                                <div class="flex1 xycenter">{{item.storageDays}}</div>
                                <div class="flex1 xycenter">{{item.palletNumber}}</div>
                                <div class="flex1 xycenter">{{item.sellRate}}元</div>
                            </div>
                        </div>
                    </div>
                    <div class="line l_border"></div>
                    <div class="flex1 fz18">
                        <div class="pl22 flex1 yrow">
                            <div class="pt20 pb10">装卸费:{{!!cost.ZXF?cost.ZXF:''}}元</div>
                            <!-- 装卸费表 -->
                            <div class="l_border r_border t_border">
                                <div class="flex headbg2" v-if="cost.zxfList.length>0">
                                    <div class="flex1 xrow xycenter" v-for="(item,index) in costHeader2" :key="item.name">{{item.name}}</div>
                                </div>
                                <div class="flex ptb10 b_border" v-for="(item,index) in cost.zxfList" :key="item.commodityCode">
                                    <div class="flex1 xycenter">{{item.number}}吨</div>
                                    <div class="flex1 xycenter">{{item.sellRate}}元</div>

                                </div>
                            </div>
                            <!-- <div class="mt20 flex1">单价:{{!!cost.ZXF?cost.ZXF:''}}</div> -->
                        </div>
                    </div>
                </div>
                <!-- 合计 -->
                <div class="flex xright fz20">合计:<span class="red">{{cost.totalFee}}元</span></div>
            </div>
                <!-- 发收货信息 -->
            <div class="card">
                <div class="flex1 card_t padlrt22">发/收货信息</div>
                <div class="flex plr22 ycenter">
                    <div class="flex1">
                        <div class="xycenter">
                            <div :class="$route.query.type==0?'bg_blue xycenter circle':'bg_green xycenter circle'">{{$route.query.type==0?'入':'出'}}</div>
                        </div>
                        <div class="pl18 fz18">
                            <p>{{detail.companyName}}/{{detail.mobile}}</p>
                            <p>{{detail.depotName}}</p>
                        </div>
                    </div>
                </div>
                <div class="t_border pad22 fz18">
                    <!-- <div>货物信息</div> -->
                    <!-- 商品列表 -->
                    <div class="l_border r_border t_border">
                        <div class="flex">
                            <div class="flex1 xycenter headbg" v-for="(item,index) in tableHeader" :key="item.name">{{item.name}}</div>
                        </div>
                        <div class="flex b_border" v-for="(item,index) in goodList">
                            <div class="flex1 xycenter ptb24">{{item.code}}</div>
                            <div class="flex1 xycenter ptb24">{{item.name}}</div>
                            <div class="flex1 xycenter ptb24">{{item.productionTime}}</div>
                            <div class="flex1 xycenter ptb24">{{$route.query.type==0?item.enterNumber:item.outNumber}}</div>
                        </div>
                    </div>
                    <!-- 合计 -->
                    <div class="xright fz18 padtb28">
                        合计:{{detail.totalPakages}}箱、{{detail.totalWeight}}kg、{{detail.totalVolume}}方
                    </div>
                    
                    <!-- <span class="ml82">{{detail.cargoTypeName}},{{detail.packingName}},{{detail.totalWeight}}kg,{{detail.totalVolume}}方,{{detail.totalPakages}}{{detail.packingName}}</span> -->
                </div>
            </div>
            <!-- 服务信息 -->
            <div class="pad22 card">
                <div class="flex1 card_t">服务信息</div>
                <!-- 入库单服务信息 -->
                <div class="flex ycenter fz18">
                    <div class="flex1 yrow">
                        <div class="mt20">储存天数:{{detail.storageDays}}天</div>
                        <div class="mt20">预计入库时间:{{detail.warehousingTime}}</div>
                        <div class="mt20">备注:{{detail.remark}}</div>
                    </div>
                    <div class="line l_border"></div>
                    <div class="flex1 ycenter">
                        <div class="pl22">
                            <div class="">入库需求:{{detail.warehousingType==1?'整进整出':'整进散出'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </template>
 
 <script>
 const API=require('/src/assets/js/apilist.js');
 export default {
    components: {

    },

    data() {
        return {
            detail:{},//详情
            cost:{},//各种费用
        //  tableList:[],//货物清单
            costHeader:[
                {name:'商品名称'},
                {name:'入库日期'},
                {name:'天数'},
                {name:'托盘数'},
                {name:'单价'}
            ],
            costHeader2:[
                {name:'吨数'},
                {name:'单价'},
            ],
            tableHeader:[
            {name:'商品编号'},
            {name:'商品名称'},
            {name:'生产日期'},
            {name:'入库数量'},
            ],
            goodList:[],//商品列表
        //  totalproperty:{},//合计:总箱,总kg,总放
        }
    },

    created() {
        this.getOrderId()
    },


    mounted() {

    },
 
    methods: {
        // 出库数量：单位为托时，转为箱:每托码货数量*出库数量
        changeUnit:function(arr){//arr为商品清单,返回以quantityArr,depotEnterNoArr,commodityIdArr的对象
            let quantityArr=[];
            let depotEnterNoArr=[];
            let commodityIdArr=[];
            let obj={};
            let newInNumber = 0
            arr.forEach(item => {
                if(item.id && item.enterNumber){//入库单才有的逻辑
                    commodityIdArr.push(item.id)
                }
                if (item.specifications == '2') {//出库数量：单位为托时，转为箱:每托码货数量*出库数量
                    newInNumber = parseFloat(item.enterNumber || 0) * parseFloat(item.number || 0);
                } else {
                    newInNumber = parseFloat(item.enterNumber || 0)
                }
                quantityArr.push(newInNumber)
                depotEnterNoArr.push(item.depotEnterNo)
                // commodityIdArr.push(item.commodityId)
            });
            obj.quantity=quantityArr.join();
            obj.depotEnterNo=depotEnterNoArr.join();
            obj.commodityId=commodityIdArr.join();
            return obj

            // return quantityArr
        },
        getOrderId:function(){
        const that=this;
        that.$axios('get',API.getById+'?id='+that.$route.query.id)
        .then((res)=>{
            console.log("获取仓库订单详情成功",res)
            that.detail=res.data;
            if(res.data.commodityJson){
                that.goodList=JSON.parse(res.data.commodityJson)
            }
            var goods=that.changeUnit(that.goodList);
            console.log("打印commodityJson",that.goodList)
            console.log("打印这个goods",goods)
            //  that.totalproperty={
            //     totalPakages:res.data.
            //  }
            var params={
                depotId:res.data.depotId,
                feeCode:'ZCF,ZXF',
                number:res.data.totalPakages,
                volume:res.data.totalVolume,
                weight:res.data.totalWeight,
                warehousingTime:res.data.warehousingTime,
                storageDays:res.data.storageDays,
                status:1,
                commodityId:goods.commodityId,
                quantity:goods.quantity
                // depotEnterNo:goods.depotEnterNo
            }
            console.log("打印入库请求费用接口参数",params)
            that.$axios('get',API.enterPricing,params,'params','application/json').then((res)=>{
                console.log("获取入库费用详情",res)
                that.cost=res.data;//费用列表
                // if(res.cost.feeList.length>0){
                //     res.cost.feeList.forEach(item=>{
                //         // item.date=that.moment(item.warehousingTime).format('YYYYMMDD')
                //     })
                // }
            }).catch((error)=>{

            })
        })
        .catch((error)=>{
            console.log(error)
        })

            
        },
        goback:function(){
        const that=this;
        that.$router.go(-1);

        }
    }
 
 }
 
 </script>
 <style scoped>
     #p_orderDetail{
        padding: 0 36px;
     }
     .fz20{
         font-size: 20px;
     }
     .red{
         color: #B93A13;
     }
     .ptb20{
         padding: 20px 0;
     }
     .pt20{
         padding-top: 20px;
     }
     .pb10{
         padding-bottom: 10px;
     }
     .headbg2{
        background-color: #E2EFF6;
     }
     .ptb10{
        padding: 10px 0;
     }
    .l_border{
        border-left: 1px solid #D9D9D9;
     }
    .r_border{
        border-right: 1px solid #D9D9D9;
    }
    .t_border{
        border-top:1px solid #D9D9D9;
    }
     .padtb28{
         padding: 28px 0;
     }
     .b_border{
         border-bottom: 1px solid #D9D9D9;
     }
     .headbg{
         background-color: #E2EFF6;
         padding: 20px 0;
     }
     .padlrt22{
         padding-left: 22px;
         padding-right: 22px;
         padding-top: 22px;
     }
     .mt20{
         margin-top: 20px;
     }
     .line{
         width: 1px;
         margin: 0 10px;
         height: auto;
     }
     .ml82{
         margin-left: 82px;
     }
     .ptb24{
         padding: 24px 0;
     }
     .plr22{
         padding: 0 22px;
     }
     .t_border{
         border: 1px solid #D9D9D9;
     }
     .fz18{
         font-size: 18px;
         color: #666666;
     }
     .pl18{
         padding-left: 18px;
     }
     .circle{
         width: 44px;
         height: 44px;
         font-size: 16px;
         font-family: Microsoft YaHei;
         font-weight: bold;
         color: #FFFFFF;
         border-radius: 50%;
     }
     .bg_blue{
         background-color: #0A8DE4;
     }
     .bg_green{
         background-color: #76B042;
     }
     .l_border{
         border-left: 1px solid #CCCCCC;
     }
     .mt30{
         margin-top: 30px;
         font-size: 18px;
         color: #666666;
     }
     .orderno{
         font-size: 16px;
         color:#666666;
     }
     .card{
         background-color: #F7F7F7;
         border: 1px solid #CCCCCC;
         border-radius: 5px;
         margin-bottom: 30px;
     }
     .card_t{
         color: #666666;
         font-size: 20px;
         font-weight: bold;
     }
     .pad22{
         padding: 22px;
     }
     .pl22{
         padding-left: 22px;
     }
     .blue{
         color: #0A8DE4;
     }
     .ptb40{
         padding: 40px 0;
     }
     .mr30{
         margin-right: 30px;
     }
     .maincontent{
         width: 1150px;
     }
     .bluebtn{
         width: 155px;
         height: 38px;
         background: #0A8DE4;
         border-radius: 5px;
         color: white;
         font-size: 16px;
     }
 
 </style>
 