<template>
  <div class="step_three form_area">
    <el-form :model="parmsJson" :rules="rulesTwo" ref="ruleFormTwo" class="ruleForm">
      <h3 class="h3" v-if="type!=='in'">付款方式</h3>
      <el-row>
        <el-form-item prop="paymentType" v-if="type!=='in'">
          <el-radio v-model="parmsJson.paymentType" :label="item.value" :key="item.value" v-for="item in outPayArr">
            {{item.text}}
          </el-radio>
        </el-form-item>
      </el-row>
      <h3 class="h3">费用明细</h3>
      <!--租仓费-->
      <div class="block_base mt30">
        <div class="flex_m plt25 lh60 bg-e pl20 pr20">
          <div>租仓费</div>
          <div v-if="servicesPrice.ZCFPrice>0" class="flex_autosize ml20 flex_end ellipsis">
            {{servicesPrice.ZCFPrice}}元</div>
          <div v-if="servicesPrice2 && servicesPrice2.feeValueZCF" class="flex_autosize ml20 flex_end ellipsis">
            单价：{{servicesPrice2.sellRateZCF}}元/{{unitFun(servicesPrice2.unitZCF) || ''}}</div>
        </div>
        <div v-if="servicesPrice.ZCFPrice>0">
          <template v-for="(item,index) in servicesPrice.ZCF">
            <div :key="'zc'+index" class="flex_m plt25 border_t lh60 pl20 pr20 b_line">
              <div class="flex1 ellipsis mr20">
                商品名称：{{item.commodityName}}
              </div>
              <div class="flex1 ellipsis">
                入库日期：{{moment(item.warehousingTime).format('YYYYMMDD')}}
              </div>
              <div v-if="type == 'out'" class="flex1 ellipsis mr20">
                出库日期：{{moment(item.takeTime).format('YYYYMMDD')}}
              </div>
              <div class="flex1 ellipsis">
                天数：{{item.storageDays}}
              </div>
              <div class="flex1 ellipsis  mr20">
                托盘数：{{item.palletNumber}}
              </div>
              <div class="flex1 ellipsis">
                单价：{{item.sellRate}}元
              </div>
            </div>
          </template>
        </div>

      </div>
      <!--装卸费-->
      <div class="block_base mt30 ">
        <div class="flex_m plt25 lh60 bg-e pl20 pr20">
          <div>装卸费</div>
          <div v-if="servicesPrice.ZXFPrice>0" class="flex_autosize ml20 flex_end ellipsis">
            {{servicesPrice.ZXFPrice}}元</div>
          <div v-if="servicesPrice2 && servicesPrice2.feeValueZXF" class="flex_autosize ml20 flex_end ellipsis">
            单价：{{servicesPrice2.sellRateZXF}}元/{{unitFun(servicesPrice2.unitZXF)||''}}</div>
          <template v-for="(item,index) in servicesPrice.ZXF">
            <view :key="'zx'+index" class="flex_m pl20 pr20 border_t lh60">
              <view class="ellipsis mr20">
                {{ unitFun(item.unit) || ''}}数：{{item.number}}
              </view>
              <view class="ellipsis">
                单价：{{item.sellRate}}元
              </view>
            </view>
          </template>
        </div>
        <div v-if="servicesPrice.ZXFPrice > 0">
          <template v-for="(item,index) in servicesPrice.ZXF">
            <div :key="'zx2'+index" class="flex_m pl20 pr20 border_t lh60">
              <div class="flex1 ellipsis mr20">
                {{ unitFun(item.unit) || ''}}数：{{item.number}}
              </div>
              <div class="flex1 ellipsis">
                单价：{{item.sellRate}}元
              </div>
            </div>
          </template>
        </div>
      </div>
      <template v-if="type == 'out' && servicesPrice.ZZFWPrice>0">
        <div class="block_base mt30 ">
          <div class="flex_m pl20 pr20 lh60 bg-e pl20 pr20">
            <div>增值服务费</div>
            <div v-if="servicesPrice.ZXFPrice>0" class="flex_autosize ml20 flex_end ellipsis">
              {{servicesPrice.ZZFWPrice}}元</div>
          </div>
          <template v-for="(item,index) in servicesPrice.ZZFW">
            <div :key="'zz'+index" class="flex_m pl20 pr20 border_t lh60">
              <div class="flex1 ellipsis mr20">
                {{item.feeValue}}：{{item.price}}元
              </div>
              <div class="flex1 ellipsis">
                {{ unitFun(item.unit) || ''}}数：{{changeUnitPriceNameFunC(item,item.unit)}}
              </div>
            </div>
            <div class="flex_m pl20 pr20 lh60 b_line">
              <div class="flex1 ellipsis">
                单价：￥{{item.sellRate}}
              </div>
            </div>
          </template>
        </div>
      </template>
      <div v-if="servicesPrice.total" class="block_base mt30">
        <div class="flex_m pl20 pr20 lh60 bg-e pl20 pr20">合计：<span class="red">{{servicesPrice.total}}</span>元</div>
      </div>
      <el-row class="xright mt30">
        <el-button type="primary" class="main_btn" @click="prevFun">上一步</el-button>
        <el-button type="primary" class="main_btn" @click="saveFun">确认下单</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { outPayArr, rulesNameFun, changeUnitPriceNameFun } from '@/assets/js/warehouse.js'
  import { regExp } from '@/assets/js/global'
  // 接口参数名称
  const API = require('/src/assets/js/apilist.js');
  export default {
    props: {
      parmsJsonProps: {
        type: Object,
        default: () => { return {} }
      },
      type: {
        type: String,
        default: ''
      },
      active: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        rulesTwo: {
          paymentType: regExp('other', true, '请填写'),
        },
        outPayArr,
        parmsJson: this.parmsJsonProps || {},
        servicesPrice: {
          ZCFPrice: 0, //租仓费价格
          ZXFPrice: 0, //装卸费价格
          ZZFWPrice: 0,//其他增值服务合计
          total: 0, //总费用
          ZCF: [],//租仓费
          ZXF: [],//装卸费
          ZZFW: [],//其他增值服务
        }, //费用明细
        servicesPrice2: {},//没有明细时的计费,展示的是单价
      };
    },
    mounted() {
      this.actType = this.$route.query.actType || ''
      this.getEnterPricFun()//获取计费
    },
    methods: {
      prevFun() {
        this.$emit('getParmsJson', { parmsJson: this.parmsJson, active: 1 })
      },
      //保存
      saveFun() {
        let self = this
        this.saveDisabled = true
        let parm = {} //保存的参数
        let api = null //保持接口地址
        let title = ''//成功后传的提示语
        let { commodityJson, depotName, isChecked, depotId, ...other } = this.parmsJson

        if (this.type === 'out' && !other.paymentType) {
          self.$message.error("请选择付款方式");
          return
        }
        other.depotId = depotId.length ? depotId[depotId.length - 1] : ''//PC仓库选择是个数组


        if (!this.actType || this.actType == 'add') {//新增的单或者是再来一单，清空id，只有编辑的时候保存保留id
          other.id = ''
        }
        if (this.type == 'in') {//入库单
          parm = {
            ...other,
            commodityJson: commodityJson.length ? JSON.stringify(commodityJson) : []
          }
          if (this.actType == 'edit') { //修改
            parm.creator = ''
          }
          api = API.entrySave
          title = '入库单号：'
        } else if (this.type == 'out') {//出库单
          other.valueAddedServices = other['valueAddedServices'] ? other['valueAddedServices'].join(',') : ''
          other.payment = this.servicesPrice.total
          delete other.creator
          delete other.updator
          parm = {
            depotOut: { ...other },
            depotStock: commodityJson
          }
          api = API.outSave
          title = '出库单号：'
        }

        let contentType = this.type == 'in' ? 'application/x-www-form-urlencoded' : 'application/json'
        this.$axios('post', api, parm, 'data', contentType).then(res => {
          if (res && res.code == '200') {
            let inNo = res && res.data && res.data.depotEnterNo ? res.data.depotEnterNo : ''
            let outNo = res && res.msg ? res.msg : ''
            let orderNo = self.type == 'in' ? inNo : outNo
            self.$message.success("创建成功！单号：" + orderNo);
            self.$router.push({ name: 'p_storeOrder', params: { type: '1' } });
          } else {

          }
        })
      },
      //单位
      unitFun(num) {
        return rulesNameFun(num)
      },
      //增值服务根据不同单位渲染字段值
      changeUnitPriceNameFunC(item, type) {
        return changeUnitPriceNameFun(item, type)
      },
      //获取计费
      getEnterPricFun() {
        let self = this
        let newParms = JSON.parse(JSON.stringify(this.parmsJson))
        let commodityId = []//货品id
        let quantity = []//货品数量
        let depotEnterNo = [] //货品入库号（出库单用）
        let tableData = newParms.commodityJson || []
        if (tableData && tableData.length) {
          for (let item of tableData) {
            if (item.id && (item.enterNumber || item.outNumber)) {
              commodityId.push(this.type == 'in' ? item.id : item.commodityId) //入库取‘id’ 出库取‘commodityId’
              let number = item.specifications == '2' ? (this.type == 'in' ? item.enterNumber : item.outNumber) * item.number : (this.type == 'in' ? item.enterNumber : item.outNumber) //托要乘以单托件数
              quantity.push(number)
              if (this.type == 'out') {
                depotEnterNo.push(item.depotEnterNo)
              }
            }
          }
        }
        let depotArr = newParms.depotId || []
        let obj = {
          depotId: depotArr[depotArr.length - 1] || '',
          number: newParms.totalPakages || '',
          volume: newParms.totalVolume || '',
          weight: newParms.totalWeight || '',
          status: 1,//已启用的增值服务
          commodityId: commodityId.length ? commodityId.join(',') : '',
          quantity: quantity.length ? quantity.join(',') : '',
        }
        let inParams = {//入库费用调用不一样的参数
          warehousingTime: newParms.warehousingTime || '',
          storageDays: newParms.storageDays || '',
          feeCode: 'ZCF,ZXF',
        }
        let outParams = {//出库费用调用不一样的参数
          depotEnterNo: depotEnterNo.length ? depotEnterNo.join(',') : '',
          takeTime: newParms.takeTime || '',
          feeCode: 'ZCF,ZXF' + (newParms.valueAddedServices && newParms.valueAddedServices.length ? ',' + newParms.valueAddedServices.join(',') : ''),
        }
        let api = this.type == 'in' ? API.enterPricing : API.selectPricing
        let params = this.type == 'in' ? { ...obj, ...inParams } : { ...obj, ...outParams }
        self.$axios('post', api, params, 'params', 'application/json').then((res) => {
          if (res && res.code == '200') {
            let data = res.data
            if (data.zcfFeeList || data.feeList) {
              let ZCFArr = [],
                ZXFArr = [],
                zzArr = [];
              for (let i = 0, arr = data.feeList; i < arr.length; i++) {
                if (arr[i].feeType === 'ZCF') {
                  ZCFArr.push(arr[i]);
                } else if (arr[i].feeType === 'ZXF') {
                  ZXFArr.push(arr[i]);
                } else {
                  zzArr.push(arr[i]);
                }
              }
              let servicesPrice = {
                ZCFPrice: data.zcfFeeList || 0,//租仓费价格
                ZXFPrice: data.ZXF || 0,//装卸费价格
                ZZFWPrice: data.totalAccretionFee || 0,//其他增值服务合计
                total: data.totalFee.toFixed(2),//总费用
                ZCF: self.type == 'in' ? data.feeList : ZCFArr,//租仓费
                ZXF: self.type == 'in' ? data.zxfList : ZXFArr,//装卸费
                ZZFW: zzArr,//其他增值服务
              }//费用明细
              self.servicesPrice = { ...servicesPrice }
            } else {
              self.servicesPrice2 = data
            }
          } else {
            self.$toast2.show('出错了' + (res && res.msg ? res.msg : ''), 'error')
          }
        })

      },
    },
  };
</script>
<style scoped>
  .mtp_ipt>>>.el-input__inner {
    box-shadow: none;
    margin-bottom: 0;
    border: 1px solid #dcdfe6;
  }

  .block_base {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
  }

  .flex_m {
    /* 垂直居中 */
    display: flex;
    align-items: center;
  }

  .lh60 {
    height: 60px;
  }

  .flex_end {
    display: flex;
    justify-content: flex-end;
  }

  .flex_autosize {
    /* 子元素自动宽度 */
    flex-grow: 1;
  }

  .bg-e {
    background: #E2EFF6;
  }

  .b_line {
    border-bottom: 1px solid #ccc;
  }

  .red {
    color: red;
  }
</style>