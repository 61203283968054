<!-- 订单搜索的支付状态弹窗 -->
<template>
   <div id="PayStateDialog" v-show="isShow">
       <div class="dialog">
           <div class="flex xycenter fz28">支付状态</div>
           <div class="row b_border flex">
               <div :class="item.active==true?'option on':'option off'" v-for="(item,index) in stateArr" :key="item.state" @click="select(index)">{{item.name}}</div>
           </div>
           <div class="flex xycenter pt20">
                <div class="btn2 flex xycenter" @click="confirm">确定</div>
           </div>

       </div>
   </div>
</template>

<script>
export default {

    components: {

    },
    props:[
        'isShow'
    ],

    data() {
        return {
            stateArr:[
                {name:'未结清',state:0,active:false},
                {name:'已结清',state:1,active:false}
            ],
            stateName:""
            
        }
    },
    created() {

    },


    mounted() {

    },

    methods: {
        confirm:function(){
            const that=this;
            this.$emit('hide',{stateName:that.stateName,isshow:false,value:that.stateName})
        },
        // 选择支付状态
        select:function(idx){
            const that=this;
            console.log("选择支付状态",idx);
            that.stateArr.forEach((item,index)=>{
                if(idx==index){
                    item.active=true;
                    that.stateName=item.name
                }else{
                    item.active=false;
                }
            })
            
            console.log("打印这个选择后的stateArr",that.stateArr)
        }
    }

}

</script>
<style scoped>
    #PayStateDialog{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        
    }
    .pt20{
        padding-top: 20px;
    }
    .fz28{
        font-size: 28px;
    }
    .btn2{
        width: 380px;
        font-size: 36px;
        height: 90px;
        border-radius: 45px;
        border: 1px solid #B93A13;
        background-color: #fff;
        color: #B93A13;
    }
    .option{
        font-size: 28px;
        padding:10px 20px;
        border-radius: 10px;
        margin-right: 16px;
    }
    .on{
        color: #B93A13;
        background-color: #facfc2;
        border: 2px solid #B93A13;
    }
    .off{
        color: #999999;
        background-color:white;
        border: 2px solid #999999;
    }
    .dialog{
        width: 620px;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        position: fixed;
        top:30%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);

    }
    .row{
        padding: 20px 0;
    }
    .b_border{
        border-bottom: 1px solid #dcdcdc;
    }

</style>
