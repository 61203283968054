import Vue from 'vue'
import VueRouter from 'vue-router'
import pc_routes from '../router/pc_index.js'
import Home from '../views/Home.vue'
import m_index from '../views/mobile/m_index.vue'
import p_index from '../views/pc/p_index.vue'
import m_login from '../views/mobile/m_login.vue'
import m_center from '../views/mobile/m_center.vue'
import m_order from '../views/mobile/m_order.vue'
import m_coupon from '../views/mobile/m_coupon.vue'
import m_unpaid from '../views/mobile/m_unpaid.vue'
import m_payment from '../views/mobile/m_payment.vue'
import m_refund from '../views/mobile/m_refund.vue'
import m_search from '../views/mobile/m_search.vue'
import m_kefu from '../views/mobile/m_kefu.vue'
import m_inStoreDetail from '../views/mobile/m_inStoreDetail.vue'
import m_outStoreDetail from '../views/mobile/m_outStoreDetail.vue'
import m_transDetail from '../views/mobile/m_transDetail.vue'
import m_paySuccess from '../views/mobile/m_paySuccess.vue'


Vue.use(VueRouter)

// 路由重定向:m_index为手机端首页，p_index为移动端首页
const realPath = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'm_login' : 'p_login';
const isMobile = function () {
  var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  // return flag;
  if (flag == null) {
    return false;
  } else {
    return true;
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect:realPath
  },
  {
    path: '/m_login',
    name: 'm_login',
    component: m_login,
    meta: {
      type: 'mobile'
    }
  },
  {
    path: '/m_index',
    name: 'm_index',
    component: m_index,
    redirect: realPath,
    meta: {
      type: 'mobile'
    }
  },
  {//入库单
    path: '/m_entry',
    name: 'm_entry',
    meta: {
      type: 'mobile',
      title: '仓储下单',
    },
    component: () => import('../views/mobile/warehouse/m_entry_warehouse.vue')
  },
  {//出库单
    path: '/m_out',
    name: 'm_out',
    meta: {
      type: 'mobile',
      title: '仓储下单',
    },
    component: () => import('../views/mobile/warehouse/m_out_warehouse.vue')
  },
  {//货品明细列表
    path: '/m_goods_list/:type/:id',
    name: 'm_goods_list',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/mobile/warehouse/m_goods_list.vue')
  },
  {//仓库详情
    path: '/m_warehouse_detail',
    name: 'm_warehouse_detail',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/mobile/warehouse/m_warehouse_detail.vue')
  },
  {//下单成功的页面
    path: '/m_finished',
    name: 'm_finished',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/mobile/m_finished.vue')
  },
  {//仓库收货、收费标准
    path: '/m_standards',
    name: 'm_standards',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/standards.vue')
  },
  {//仓库条款
    path: '/m_agreement',
    name: 'm_agreement',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/agreement.vue')
  },
  {//极速下单
    path: '/m_payorder',
    name: 'm_payorder',
    meta: {
      type: 'mobile',
      title: '运输下单',
      // keepAlive:true,
    },
    component: () => import('../views/mobile/payOrder/payStepOne.vue')
  },
  {//批量下单-货物信息
    path: '/m_batchOne',
    name: 'm_batchOne',
    meta: {
      type: 'mobile',
    },
    component: () => import('../views/mobile/payOrder/batchOne.vue')
  },
  {//批量下单
    path: '/m_batchorder',
    name: 'm_batchorder',
    meta: {
      type: 'mobile',
      title: '运输下单',
      // keepAlive:true,
    },
    component: () => import('../views/mobile/payOrder/batchOrderForm.vue')
  },
  {//极速下单-选择地址
    path: '/addressManage',
    name: 'addressManage',
    meta: {
      type: 'mobile'
    },
    component: () => import('../components/addressManage/AddressManage')
  },
  {//极速下单-选择供应商
    path: '/lineGoods',
    name: 'lineGoods',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/mobile/payOrder/lineGoods.vue')
  },
  {//极速下单-计费确认页
    path: '/confirmOrder',
    name: 'confirmOrder',
    meta: {
      type: 'mobile'
    },
    component: () => import('../views/mobile/payOrder/confirmOrder.vue')
  },
  {
    path: '/p_index',
    name: 'p_index',
    component: p_index,
    meta: {
      type: 'pc'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/m_center',
    name: 'm_center',
    component: m_center,
    meta: {
      type: 'mobile',
      title: '个人中心'
    }
  },
  {
    path: '/m_order',
    name: 'm_order',
    component: m_order,
    meta: {
      type: 'mobile',
      title: '订单列表',
      keepAlive: true,//缓存路由
    }
  },
  {
    path: '/m_coupon',
    name: 'm_coupon',
    component: m_coupon,
    meta: {
      type: 'mobile',
      title: '我的优惠券'
    }
  },
  {
    path: '/m_unpaid',
    name: 'm_unpaid',
    component: m_unpaid,
    meta: {
      type: 'mobile',
      title: '待付款列表'
    }
  },
  {
    path: '/m_payment',
    name: 'm_payment',
    component: m_payment,
    meta: {
      type: 'mobile',
      title: '订单支付'
    }
  },
  {
    path: '/m_refund',
    name: 'm_refund',
    component: m_refund,
    meta: {
      type: 'mobile',
      title: '退款列表'
    }
  },
  {
    path: '/m_search',
    name: 'm_search',
    component: m_search,
    meta: {
      type: 'mobile',
      title: '订单搜索'
    }
  },
  {
    path: '/m_kefu',
    name: 'm_kefu',
    component: m_kefu,
    meta: {
      type: 'mobile',
      title: '客服'
    }
  },
  {
    path: '/m_inStoreDetail',
    name: 'm_inStoreDetail',
    component: m_inStoreDetail,
    meta: {
      type: 'mobile',
      title: '入库单详情'
    }
  },
  {
    path: '/m_outStoreDetail',
    name: 'm_outStoreDetail',
    component: m_outStoreDetail,
    meta: {
      type: 'mobile',
      title: '出库单详情'
    }
  },
  {
    path: '/m_transDetail',
    name: 'm_transDetail',
    component: m_transDetail,
    meta: {
      type: 'mobile',
      title: '运输单详情'
    }
  },
  {
    path: '/m_paySuccess',
    name: 'm_paySuccess',
    component: m_paySuccess,
    meta: {
      type: 'mobile',
      title: '支付状态'
    }
  },
  {
    path: '/m_orderagreement',
    name: 'm_orderagreement',
    
    component: () => import('../components/articleText/agreement.vue'),
    meta: {
      type: 'mobile',
      title: '运输条款'
    }
  },
  ...pc_routes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
