<template>
   <div id="Home"></div>
</template>

<script>
export default {

    components: {

    },

    data() {
        return {
            
        }
    },

    created() {
      this.envJump('','href',0)
    },


    mounted() {

    },

    methods: {
    }

}

</script>
<style scoped>

</style>

