<template>
  <div id="m_login">
    <!-- 返回按钮 -->
    <div class="flex xright mt37">
      <div class="backbtn xycenter" @click="returnback">
        <img :src="goback" class="goback" />
        <span class="ml22">返回</span>
      </div>
    </div>
    <div class="xycenter mt71">
      <img :src="logo" class="logo" />
    </div>
    <div class="xycenter txt">
      <span class="fz28">一站式物流服务管家</span>
    </div>
    <!-- 手机号表单 -->
    <div class="inputbg mt106 ycenter">
      <i class="iconfont icon-shouji"></i>
      <input type="text" v-model="phone" maxlength="11" class="input ml25" />
    </div>
    <!-- 验证码 -->
    <div class="flex mt27">
      <div class="inputrow flex ycenter">
        <i class="iconfont icon-duanxinyoujian ml40"></i>
        <input type="text" v-model="code" maxlength="6" class="input2 ml25" />
      </div>
      <div class="flex1 xright">
        <div class="btn xycenter">
          <span v-show="show" @click="getcode">获取验证码</span>
          <span v-show="!show">{{ count }} s</span>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <div class="login xycenter mt60" @click="login">登录</div>
    <div class="xycenter mt112 prompt">或用以下方式登录</div>
    <div class="wxbtn xycenter mt40" @click="wxlogin">
      <i class="iconfont icon-gongzhonghao"></i>
      <span class="wxtext ml15">微信登录</span>
    </div>
    <div class="xycenter yrow fz16 mt100">
      <span>道路运输经营许可证 ｜ 营业执照</span>
      <span>Copyright © 2018-2019 一站物流商城 5656mall.com 版权所有 粤ICP备 15018368号</span>
    </div>
  </div>
</template>

<script>
const API = require('/src/assets/js/apilist.js')
import { setCookie, getCookie, delCookie } from '../../assets/js/cookies.js'
// import {setCookie,getCookie,delCookie} from '../../assets/js/cookies.js'
export default {
  name: 'index',
  components: {},
  data() {
    return {
      logo: require('/src/assets/images/m_logo.png'),
      goback: require('/src/assets/images/goback.png'),
      phone: '',
      code: '',
      show: true,
      count: '',
      timer: null
    }
  },
  created() {},
  methods: {
    // 返回键
    returnback: function() {
      this.envJump('', 'href', 0)
    },
    // 微信登录
    wxlogin: function() {
      const that = this
      if (that.wxBrower()) {
        var params = {
          state: new Date().getTime()
        }
        that
          .$axios('get', API.wxlogin, params, 'params', 'application/json')
          .then(res => {
            console.log('微信授权登录成功', res)
            window.location.replace(res.data)
          })
          .catch(error => {
            console.log('微信授权登录失败', error)
          })
      } else {
        that.$toast('请在微信浏览器中打开')
      }
    },
    // 短信验证登录
    login: function() {
      const that = this
      var myreg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/ //手机正则
      if (that.phone == '' || !myreg.test(that.phone)) {
        that.$toast('请输入正确手机号')
      } else if (that.code == '') {
        that.$toast('请输入验证码')
      } else {
        let params = {
          captcha: that.code,
          loginType: 1,
          mobile: that.phone,
          password: that.code,
          loginSource: 'officialWeb'
        }
        that
          .$axios('post', API.login, params, 'data', 'application/json')
          .then(res => {
            console.log('登录成功', res)
            if (res.isSuccess) {
              localStorage.setItem('token', res.data.accessToken) //设置token缓存
              localStorage.setItem('user', JSON.stringify(res.data.member)) //缓存用户信息
              setCookie('mobile', res.data.member.mobile, 1, '', '.yizhan56.cn')
              // setCookie('user',JSON.stringify(res.data.member));//设置cookies
              that.$toast({
                message: res.msg,
                type: 'success'
              })
              if (!!that.$route.query.orderno) {
                that.envJump(
                  '/html/trajectory.html?orderSn=' + that.$route.query.orderno,
                  'href',
                  0
                )
              } else {
                that.envJump('', 'href', 0)
              }
              // that.jumpweb();//跳转1.0
            } else {
              that.$toast.error(res.msg)
            }
          })
          .catch(error => {
            console.log('登录失败', error)
          })
      }
    },
    getcode: function() {
      const that = this
      var myreg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/ //手机正则
      if (that.phone != '' && myreg.test(that.phone)) {
        //手机号不为空,并且是正确格式
        const _time = 60
        const params = {
          mobile: that.phone
        }
        if (!that.timer) {
          that.count = _time
          that.show = false
          // that.getcodeAPI(that.phone)
          that.$axios('post', API.sendIdentifyingCode, params, 'data')
          that.timer = setInterval(() => {
            if (that.count > 0 && that.count <= _time) {
              that.count--
            } else {
              that.show = true
              clearInterval(that.timer)
              that.timer = null
            }
          }, 1000)
        }
      } else {
        that.$toast('请输入正确手机号')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mt100 {
  margin-top: 160px;
}
.fz16 {
  font-size: 12px;
}
.ml15 {
  margin-left: 15px;
}
.mt40 {
  margin-top: 40px;
}
.wxbtn {
  padding: 30px 0;
  width: 100%;
  border: 1px solid #0a8de4;
  border-radius: 10px;
}
.wxtext {
  color: #0a8de4;
  font-size: 28px;
}
.prompt {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #a2a2a2;
}
.icon-gongzhonghao {
  font-size: 44px;
  color: #0a8de4;
}
.mt112 {
  margin-top: 112px;
}
.ml40 {
  margin-left: 40px;
}
.input2 {
  width: 150px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2b2b2b;
  border: none;
}
.input {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2b2b2b;
  border: none;
}
.ml25 {
  margin-left: 25px;
}
.icon-shouji {
  font-size: 50px;
  color: #0a8de4;
}
.icon-duanxinyoujian {
  font-size: 40px;
  color: #0a8de4;
}
.mt60 {
  margin-top: 60px;
}
.login {
  width: 100%;
  height: 87px;
  background: #0a8de4;
  border-radius: 10px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.mt106 {
  margin-top: 106px;
}
.mt27 {
  margin-top: 27px;
}
.btn {
  width: 267px;
  height: 87px;
  background: #0a8de4;
  border-radius: 10px;
  font-size: 28px;
  font-family: PingFang SC;
  color: #ffffff;
}
.inputrow {
  width: 366px;
  height: 87px;
  background-color: white;
}
.goback {
  width: 26px;
  height: 26px;
}
.ml22 {
  margin-left: 22px;
}
.mt37 {
  margin-top: 37px;
}
.backbtn {
  width: 188px;
  height: 66px;
  border: 2px solid #d2d1d1;
  border-radius: 30px;
  font-size: 30px;
  color: #666666;
}
#m_login {
  /* width: 100%; */
  padding: 0 45px;
  height: 100vh;
  overflow: auto;
  background-color: #f0f0f0;
}
.logo {
  width: 210px;
  height: 63px;
}
.fz28 {
  font-size: 28px;
  color: #666666;
}
.txt {
  margin-top: 37px;
  font-family: 'PingFang SC';
  letter-spacing: 6px;
}
.mt71 {
  margin-top: 71px;
}
.inputbg {
  /* width: 100%; */
  padding: 0 44px;
  height: 87px;
  background: #ffffff;
  border-radius: 10px;
}
</style>
