<template>
    <div class="toast flex xycenter" v-show="isShow">
        <div id="toast" class="flex yrow">
            <div class="flex xycenter">
                <img v-show="type!=''" :src="type=='success'?success_icon:type=='error'?error_icon:warn_icon" class="icon">
            </div>
            <div class="text flex xycenter">{{content}}</div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },

    data() {
        return {
            success_icon:require('./img/success.png'),
            warn_icon:require('./img/warn.png'),
            error_icon:require('./img/error.png'),
            isShow: false,
            content: "",
            times: null,
            type:"",//显示的图片类型
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        show(text = "操作成功",type="",duraction = 2) {//type:'success','warn','error'
            this.content = text;
            this.type=type;
            this.isShow = true;
            if (this.times) clearTimeout(this.times);
            this.times = setTimeout(() => {
                // this.content = "";
                this.isShow = false;
            }, duraction * 1000);
        }

    }

}

</script>
<style scoped>
    .toast{
        /* position:absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; */
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
    }
    #toast{
        /* position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); */
        z-index: 999;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        min-width:50%;
        max-width: 100%;
        height: auto;
        padding: 20px 20px;
        box-sizing: border-box;
        color: #fff;
    }
    .icon{
        width: 50px;
        /* height: 60px; */
    }
    .text{
        padding:30px 0;
        font-size: 28px;
        color: white;
        line-height: 2em;
        /* word-wrap: break-word;
        word-break: break-all;
        overflow: hidden; */
    }

</style>
