<template>
    <el-dialog :append-to-body="appendbody" title="支付二维码" :visible="isshowqrcode" width="30%" :close-on-click-modal="false" @close="closeDialog">
        <div class="xycenter fz18">
            <span>支付金额:</span>
            <span class="red">{{amount}}</span>
            <span>元</span>
        </div>
        <div class="xycenter">
            <div class="qrcodeDialog" v-show="isshowqrcode" :style="{backgroundImage:'url('+qrcode+')'}"></div>
        </div>
        <div class="xycenter red padtb20">{{remark}}</div>
        <div class="xycenter">
            <img :src="scanimg">
        </div>
        <div class="flex xright pt20">
            <el-button @click="closeDialog">取消</el-button>
            <el-button type="primary" @click="closeDialog">我已扫码支付</el-button>
        </div>
    </el-dialog>
</template>

<script>
    // 支付弹窗组件
const API=require('../../assets/js/apilist.js');
export default {
    components: {
    },
    props:[
        'isShow',
        'amount',
        'orderId'
    ],
    data() {
        return {
            scanimg:require('../../assets/images/scanimg.png'),
            remark:'备注：该二维码将在半小时后失效，失效后请重新生成',
            qrcode:"",
            appendbody:true,
            isshowqrcode:false
        }
    },
    watch:{
        isShow(newvalue,oldvalue){
            if(newvalue==true){
                this.payment();
            }else{
                this.isshowqrcode=false;
            }
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        closeDialog:function(){
            this.$emit('close',false)
        },
        handleClose:function(){

        },
        payment:function(){
            const that=this;
            // that.fullscreenLoading=true;
            that.$loading({text:'待支付二维码生成中...'})
            const params={
                orderIds:that.orderId,
                parametersJson:{"appId":"wx2a60d1dc5fe4b730","tradeType":"NATIVE","body":"官网1.1收款"},
                channelServiceName:'weiXinServiceImpl'
            }
            that.$axios('get',API.getPayqrcode,params,'params','application/json').then((res)=>{
                console.log("发起支付成功",res)
                if(res.isSuccess==true){
                    that.$loading().close();
                    // that.fullscreenLoading=false;
                    that.money=res.data.amount;
                    // 拼接base64
                    const base64='data:image/png;base64,'+res.data.qrcode;
                    that.qrcode=base64;
                    that.isshowqrcode=true;
                }else{
                    that.$loading().close();
                    this.$message.error(res.msg);
                }
                


            }).catch((error)=>{
                console.log("发起支付失败",error)
            })
        }
    }

}

</script>
<style scoped>
    #p_unpaid{
        width: 100%;
    }
    .pt20{
        padding-top: 20px;
    }
    .nodata{
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .padtb20{
        padding: 20px 0;
    }
    .fz18{
        font-size: 18px;
    }
    .red{
        color: red;
        font-weight: bold;
    }
    .qrcodeDialog{
        width: 200px;
        height: 200px;
        background-size: 200px 200px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .paybtn{
        width: 180px;
        height: 44px;
        color: white;
        font-size: 16px;
        background: #0A8DE4;
        border-radius: 22px;
    }
    .maincontent{
        width: 1156px;
        /* position: relative; */
    }
    .pagerow{
        padding-top: 50px;
        padding-bottom: 70px;
        width: auto !important;
    }
    .checkalltext{
        color: #666666;
        font-size: 18px;
        padding-left: 10px;
    }
    .pl32{
        padding-left: 32px;
    }
    .checkall{
        width: 20px;
        height: 20px;
        border: 1px solid #bdbdbd;
    }
    .padtb32{
        padding:32px 0;
    }
    .mt58{
        margin-top: 58px;
    }
    .el-checkbox{
        display: flex;
        align-items: center;
    }
    /* checkbox label字体大小 */
    >>>.el-checkbox__label{
        font-size: 16px;
        color: #99A0A3;
    }
    >>>.el-checkbox__inner {
        width: 20px;
        height: 20px;
          
    }
    >>>.el-checkbox__inner::after{
        height: 12px;
        left: 7px;
    }
    .money{
        color:#E62019;
        font-family: Microsoft YaHei;
    }
    .l_border{
        border-left: 1px solid #CCCCCC;
    }
    .arrow{
        width: 90px;
        height: 10px;
    }
    .stateText{
        padding-top: 12px;
        color: #0A8DE4;
    }
    .ptb30{
        padding: 30px 0;
    }
    .bg_lightblue{
        background-color: #e2eff6;
    }
    .t_order{
        padding: 20px 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #99A0A3;
    }
    .orderdemo{
        width: 1150px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .l_inputrow{
        width: 252px;
        height: 40px;
        background-color: #F1F1F1;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .searchbtn{
        width: 80px;
        height: 38px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: #0A8DE4;
        color: white;
        font-size: 16px;
    }
    .input{
        width: 200px;
        font-size: 14px;
        background-color: #F1F1F1;
        border: none;
        padding-left: 16px;
        /* text-indent: 16px; */
        /* padding:14px 0; */
    }
    .tablist{
        width: 100%;
        border-bottom: 1px solid #D9D9D9;
    }
    .tab{
        padding:0 26px;
    }
    .taboption{
        padding: 24px 20px;
        border-bottom: 2px solid #007ec5;
        font-size: 18px;
    }
    ._taboption{
        padding: 24px 20px;
        font-size: 18px;
    }

</style>
