<template>
 <div class="goodsForm">
    <el-button type="primary" class="goodsDetailBtn" @click="addGoods()" v-if="type==='in'">新增商品</el-button>
  
    <el-form label-width="80px" :inline="true" class="search">      
            <el-form-item>
                <el-input v-model="searchWords" placeholder="模糊查找" clearable @input="watchSearchWords"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
            <el-table
          class="tborder"
          ref="multipleTable"
          :data="goodsDetailsData"
          @selection-change="handleSelectionChange" 
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px;margin-bottom:20px;"
          :row-key="getRowKey"
        >
         <el-table-column
          type="selection"
          width="55"
          :reserve-selection="true">
         </el-table-column>
          <el-table-column
             type="index"
             width="50"
             label="序号"
             align="center" >
          </el-table-column>
          <el-table-column :label="item.name" v-for="(item) in header" :key="item.val"  align="center" :property="item.val" :width="item.width">
            <template slot-scope="scope"> 
                <span v-if="item.val==='specifications'"> {{ scope.row[scope.column.property] | specifFormat }}</span>           
                <span v-else> {{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
        </el-table>
          <!-- 分页 -->
            <div class="pagerow xycenter">
                <el-pagination
                background
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total">
                </el-pagination>
            </div>
    </el-form>
     <Dialog ref="dialog" :config="config" :beforeClose="beforeClose" @close="resetForm"  v-if="type==='in'">
        <AddGoodsForm ref="addGoods" @chooseForm="saveGoods"></AddGoodsForm>
    </Dialog>
 </div>
</template>

<script>
import Dialog from './Dialog.vue';
import AddGoodsForm from './addGoodsForm.vue';
const API=require('/src/assets/js/apilist.js');
export default {
  components:{
    Dialog,
    AddGoodsForm
  },
 filters: {
   specifFormat: function (val) {
    return val == 1 ? '箱' : val == 2 ? '托' : '';
  },
 },
 props:{
   commodityData:{
     type:Array,
     default:[]
   }, 
   goodsHeader:{
       type:Array,
       default:[]
    },
   type:{
      type:String,
      default:''
    },
   goodsDetailId:{
      type:Object,
      default:{}
    }
 },
  data() {
    return {
       config: {
        top: '20vh',
        width: '80%',
        title: '快速新增',
        center: true,
        btnTxt: ['取消', '保存'],
      },
      currentPage:1,
      pageSize:10,
      total:1,
      searchWords:'',
      commodityJson:[], 
      header:this.goodsHeader,
      goodsDetailsData:[]
    };
  },
  mounted(){
     const unwatch = this.$watch(
      'goodsHeader',
      // eslint-disable-next-line
      function (newValue, oldValue) {
        if (newValue && newValue.length > 0) {
          this.header = newValue;
          if (unwatch) {
            unwatch();
          }
        }
      },
      { deep: true },
    );
     this.getGoodList()
  },
  methods: {
    watchSearchWords(val){
      if(!val){
        this.currentPage = 1
        this.pageSize = 10
        this.total = 1
      }
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getGoodList()
    },
    handleCurrentChange(val){
      this.currentPage = val
      this.getGoodList()
    },
    onSearch(){
      this.getGoodList(this.searchWords)
    },
    handleSelectionChange(val){
      console.log(val,'?sss')
      this.commodityJson = val  
    },
    getRowKey(row){
      console.log(row,'ffff')
      return row.id
    },
    // 查询商品数据
    getGoodList(commodity){
      let params ={}
      let address = ''
     if(this.type==='in'){
       console.log(this.goodsDetailId,'dfdfddf')
       params = {
         commodity:commodity || '',
         corpId:this.goodsDetailId.corpId,
         size: this.pageSize,
         current: this.currentPage
      } 
      address = API.entryGoodList
      }else{
        params = {
         commodityName:commodity || '',
         depotId:this.goodsDetailId.depotId,
         size: this.pageSize,
         current: this.currentPage
      } 
       address = API.outGoodList
      }
      
      this.$axios('post',address,params).then((res)=>{
          console.log(res,'resList')
          this.goodsDetailsData = res.data.records
          this.total = res.data.total
          this.pageSize = res.data.size
          if(this.goodsDetailsData.length !== 0){
              this.currentPage = res.data.current
          }
          // this.$nextTick(() => {
          this.toggleSelection(this.goodsDetailsData)
          // })
     })
    },
     // 设置选中
    toggleSelection (_this) {
      if (_this) {
        _this.forEach((item) => {
          this.commodityData.forEach(item2=>{
            if(item.id===item2.id){
               // 设置该表格选框选中
              this.$refs.multipleTable.toggleRowSelection(item)
            }
          })
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    chooseGood(){
      if(this.commodityData.length!== 0 && this.commodityJson !== 0){
         this.commodityData.forEach((item,index)=>{
            this.commodityJson.forEach((item2,index2)=>{
              if(item.id === item2.id){
                if(this.type =='in'){
                  item2.enterNumber = item.enterNumber
                }else{
                  item2.outNumber = item.outNumber
                }
              }
            })
         })
      }
      this.$emit('choose',this.commodityJson)
    },
    addGoods(){
      // 弹窗确定按钮点击事件
      this.$refs.dialog.open(confirm => {
            const form1 = new Promise((resolve, reject) => {
          this.$refs.addGoods.$refs.addGoodsForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            this.$message({
              message: "商品新增填写校验未通过，请检查信息填写是否符合规范",
              type: "error",
            });
          }
        });
      });
       Promise.all([form1]).then((resolve, reject) => {
          console.log("通过验证 可以保存商品了");
          this.$refs.addGoods.chooseGood();
          this.$refs.dialog.cancel();
        });
        
      })
      .then(() => {
      
      });   //这里就充分利用了open方法中返回的nextTick
    },
    saveGoods(val){
      this.$axios('post',API.addGoods,val[0]).then((res)=>{
          console.log(res,'res')
          if(res.code =='200'){
            this.$message({
              message: "商品保存成功",
              type: "success",
            });
            this.getGoodList();
          }          
     })
    
    },

    beforeClose(){

    },
    resetForm(){

    },
  }
};
</script>

<style scoped>
.goodsDetailBtn{
  width: 180px;
  line-height: 42px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  padding: 0;
  background-color: #0A8DE4;
}
.search{
  margin-top:20px;
}
.goodsForm .el-form--inline .el-form-item{
  vertical-align: middle;
}
</style>