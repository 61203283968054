<template>
  <div id="app">
    <keep-alive :max="1">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不需要缓存数据的路由 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
  export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data(){
    return{
      // isRouterAlive:true,//配置更新vue页面缓存变量
    }
  },
  // provide () { return { reload: this.reload } },
  created(){
    // this.setlocalUser();
    
  },
  mounted(){

  },
  methods:{
    // reload () {
    //   this.isRouterAlive = false
    //   this.$nextTick(() => (this.isRouterAlive = true))
    // }
    // 设置默认用户数据
    
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

</style>
