<template>
  <div id="cargoLabelPC" v-if="isM">
    <CargoLabelM
      :reFresh="reFresh"
      :cargoLabelList="cargoLabelList"
      :tagLine="tagLine"
      :cargoRules="cargoRules"
      :isShowTagDetail="isShowTagDetail"
      @handleSelect="handleSelect"
      @handleCargoSubmit="handleCargoSubmit"
      @removeItem="removeItem"
      @changeIptVal="changeIptVal"
      @changeFaEdit="changeFaEdit"
    ></CargoLabelM>
  </div>
  <div id="cargoLabelM" v-else>
    <CargoLabelPC
      :reFresh="reFresh"
      :cargoLabelList="cargoLabelList"
      :tagLine="tagLine"
      :cargoRules="cargoRules"
      :isShowTagDetail="isShowTagDetail"
      @handleSelect="handleSelect"
      @handleCargoSubmit="handleCargoSubmit"
      @removeItem="removeItem"
      @changeIptVal="changeIptVal"
    ></CargoLabelPC>
  </div>
</template>
<script>
import CargoLabelPC from "@/components/cargoLabelCmp/CargoLabelPC.vue";
import CargoLabelM from "@/components/cargoLabelCmp/CargoLabelM.vue";
import { isMobile, $axios } from "@/assets/js/global";
import {
  regExp,
  multiply,
  getFloat,
  priceKG,
  priceT,
} from "@/assets/js/global";
export default {
  name: "CargoLabelCmp",
  components: {
    CargoLabelPC,
    CargoLabelM,
  },
  props: {
    newStepArr: {
      type: Object,
      required: true,
    },
    typeRef: {
      type: String,
      required: true,
    },
    sp: {
      type: String,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isM() {
      return isMobile();
    },
  },
  watch: {
    dialogVisible(isVisible) {
      if (isVisible) {
        this.reFresh = false;
        this.$nextTick(() => {
          this.reFresh = true;
          this.analysisData();
        });
      }
    },
    isEdit(newVal) {
      this.isShowTagDetail = newVal;
    },
  },
  data() {
    return {
      reFresh: true, //用于摧毁组件用
      cargoLabelList: JSON.parse(
        JSON.stringify(this.$store.state.cargoLabelList)
      ), //新建对象
      selectedTags: [], //已选规格
      tagLine: {
        tagLineDetail: [], //单个规格输入详情
      },
      isShowTagDetail: this.isEdit, //是否隐藏tag规格的详情
      cargoRules: {
        pakages: regExp("integer"),
        weight: regExp("oneDec"),
        volume: regExp("sixDec"),
      },
    };
  },
  created() {
    this.analysisData();
  },
  methods: {
    analysisData() {
      //解析父组件传来的数据
      let newDetailList = [];
      let dataList = JSON.parse(
        JSON.stringify(this.$store.state.cargoLabelList)
      );
      let newSpArr = this.sp ? this.sp.split("|") : [];
      if (newSpArr.length) {
        newSpArr.map((item) => {
          let arr = item.split(",");
          arr = {
            id: arr[0],
            t: priceT(arr[1]),
            v: arr[2],
            m: arr[3],
          };
          newDetailList.push(arr);
          dataList.map((ht, index) => {
            if (arr.id === ht.id) {
              dataList[index].checked = true;
            }
          });
        });
      }
      this.cargoLabelList = [...dataList];
      this.tagLine.tagLineDetail = [...newDetailList];
    },
    handleSelect(cargoId, index) {
      if (this.newStepArr.dzdState === "0") {
        return;
      }
      let isCheck = !this.cargoLabelList[index].checked;
      if (isCheck) {
        //不存在则选中
        // this.isShowTagDetail = false;
        this.changeTagModal(false);
        this.cargoLabelList[index].checked = isCheck;
        this.cargoLabelList = [...this.cargoLabelList];
        this.addItem(cargoId);
      } else {
        //存在则删除
        if (this.isShowTagDetail) {
          //已存在，但详情列表隐藏时，则显示详情列表
          //   this.isShowTagDetail = false;
          this.changeTagModal(false);
          return;
        }
        // this.isShowTagDetail = false;
        this.changeTagModal(false);
        this.removeItem(cargoId);
      }
    },
    addItem(cargoId) {
      let newList = [...this.tagLine.tagLineDetail];
      const addParams = { id: cargoId, t: "", v: "", m: "" };
      newList.push(addParams);
      this.tagLine.tagLineDetail = [...newList];
    },
    removeItem(cargoId) {
      let datas = JSON.parse(JSON.stringify(this.cargoLabelList));
      var ListCur = this.tagLine.tagLineDetail.findIndex(
        (item) => item.id === cargoId
      );
      let detailCur = datas.findIndex((item) => item.id === cargoId);
      this.tagLine.tagLineDetail.splice(ListCur, 1);
      datas[detailCur].checked = false;
      this.cargoLabelList = [...datas];
      if (!this.tagLine.tagLineDetail.length) {
        // this.isShowTagDetail = true;
        this.changeTagModal(true);
        return;
      }
    },
    showPriceT(val) {
      return priceT(val);
    },
    getName(cargoId) {
      let newName = "";
      this.cargoLabelList.map((tag, idx) => {
        if (cargoId == tag.id) {
          newName = tag.name;
        }
      });
      return newName;
    },
    changeIptVal(iptVal, line, cargoId) {
      let cargoDatas = [...this.tagLine.tagLineDetail];
      let curLine = {},
        t = 0,
        v = 0;
      if (cargoId != "0") {
        //点击的tag为‘其它’时，不用自动计算
        this.cargoLabelList.map((tag, idx) => {
          if (cargoId == tag.id) {
            curLine = tag;
          }
        });
        t = getFloat(multiply(iptVal, priceT(curLine.unitWeight)), 4);
        v = getFloat(multiply(iptVal, curLine.unitVolume), 6);
        cargoDatas[line].t = t > 100000 ? 100000 : t;
        cargoDatas[line].v = v > 10000 ? 10000 : v;
      }
      cargoDatas[line].m = iptVal > 10000 ? 10000 : iptVal;
      this.tagLine.tagLineDetail = [...cargoDatas];
    },
    handleCargoSubmit() {
      let datas = this.tagLine.tagLineDetail;
      let newSP = [],
        totalW = 0,
        totalV = 0,
        totalP = 0;
      datas.map((item) => {
        const arr =
          item.id + "," + priceKG(item.t) + "," + item.v + "," + item.m;
        totalW += Number(item.t);
        totalV += Number(item.v);
        totalP += Number(item.m);
        newSP.push(arr);
      });
      let vol = getFloat(totalV, 6);
      let wei = getFloat(totalW, 1);
      const params = {
        totalPakages: totalP > 10000 ? 10000 : totalP,
        totalVolume: vol > 10000 ? 10000 : vol,
        totalWeight: wei > 100000 ? 100000 : wei,
        sp: newSP.join("|"),
      };
      this.$emit("changeSp", params);
      this.changeTagModal(true);
      //   this.isShowTagDetail = true;
    },
    changeFaEdit(flag) {
      this.$emit("changeEdit", flag);
    },
    changeTagModal(flag) {
      if (flag === this.isShowTagDetail) return;
      this.isShowTagDetail = flag;
      if (this.isM) {
        this.changeFaEdit(flag);
      }
    },
  },
};
</script>
