<template>
   <div id="redpoint" class="xycenter" v-if="number>0">
        {{number}}
   </div>
</template>

<script>
export default {

    components: {

    },
    props:[
        'number'
    ],

    data() {
        return {
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
    }

}

</script>
<style scoped>
    #redpoint{
        min-width: 36px;
        min-height: 36px;
        background: #FF2F49;
        border-radius: 50%;
        font-size: 18px;
        color: white;
        position: absolute;
        top: -18px;
        right: -18px;
    }

</style>
