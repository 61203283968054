const API = require('/src/assets/js/apilist.js');
import { $axios } from '@/assets/js/global.js'

export default {
  // 调用注册验证客户信息方法
   getCorpId({commit}, name){
      $axios('post',API.createCustomer,name).then((res)=>{
        console.log("获取用户的客户信息",res);
        if(res.code==='200'){
          let userData = JSON.parse(window.localStorage.getItem('user'))
          userData.companyName = name.companyName
          userData.corpId = res.data.corpId
          window.localStorage.setItem('user',JSON.stringify(userData))
        }
      })
    }
};