<template>
    <div id="m_search" class="flex yrow">
        <div class="flex1 padding">
            <div id="searchForm">
                <!-- 单号表单 -->
                <div class="row b_border flex">
                    <!-- 输入单号,出入库单号 -->
                    <div class="label">{{$route.params.type==0?'输入单号':'出入库单号'}}</div>
                    <div>
                        <input class="ml30 input" v-model="form.orderNo" placeholder="请输入单号" maxlength="20">
                    </div>
                </div>
                <!-- 下单日期 -->
                <div class="row b_border flex">
                    <div class="label">下单日期</div>
                    <div class="flex1 xycenter">
                        <input class="ml30 input" :value="form.startTime" @click="showStartTime=true" placeholder="开始日期">
                    </div>
                    <i class="iconfont icon-rili" @click="showStartTime=true"></i>
                </div>
                <!-- 结束日期 -->
                <div class="row b_border flex ycenter">
                        <div class="label">至</div>
                        <div class="flex1 xycenter">
                            <input class="ml30 input" :value="form.endTime" @click="showEndTime=true" placeholder="结束日期">
                        </div>
                        <i class="iconfont icon-rili" @click="showEndTime=true"></i>
                </div>
                <!-- 发货区域 -->
                <div v-if="$route.params.type==0" class="row b_border flex ycenter">
                        <div class="label">发货区域</div>
                        <div class="flex1 xycenter">
                            <input class="ml30 input" :value="form.sendArea" placeholder="请选择"  @click="showSendArea=true">
                        </div>
                        <i class="iconfont icon-youjiantou1"></i>
                </div>
                <!-- 收货区域 -->
                <div v-if="$route.params.type==0" class="row b_border flex ycenter">
                    <div class="label">收货区域</div>
                    <div class="flex1 xycenter">
                        <input class="ml30 input" :value="form.acceptArea" placeholder="请选择" @click="showAcceptArea=true">
                    </div>
                    <i class="iconfont icon-youjiantou1"></i>
                </div>
                <!-- 支付状态 -->
                <div v-if="$route.params.type==0" class="row flex ycenter">
                    <div class="label">支付状态</div>
                    <div class="flex1 xycenter">
                        <input class="ml30 input" placeholder="请选择" :value="form.stateText" @click="selectState">
                    </div>
                    <i class="iconfont icon-xiajiantou"></i>
                </div>
                <!-- 开始时间 -->
                <van-popup v-model="showStartTime" position="bottom">
                    <van-datetime-picker
                    type="date"
                    title="选择年月日"
                    @confirm="confirmStartTime"
                    @cancel="showStartTime = false"
                    :min-date="minDate"
                    :max-date="maxDate"
                    />
                </van-popup>
                <!-- 结束时间 -->
                <van-popup v-model="showEndTime" position="bottom">
                    <van-datetime-picker
                    type="date"
                    title="选择年月日"
                    @confirm="confirmEndTime"
                    @cancel="showEndTime = false"
                    :min-date="minDate"
                    :max-date="maxDate"
                    />
                </van-popup>
                <!-- 发货区域 -->
                <van-popup v-model="showSendArea" round position="bottom">
                    <van-cascader
                    title="请选择所在地区"
                    :options="cityOption"
                    :fieldNames="fieldNames"
                    @close="showSendArea = false"
                    @finish="confirmSendArea"
                    />
                </van-popup>
                <!-- 收货区域 -->
                <van-popup v-model="showAcceptArea" position="bottom">
                    <van-cascader
                    title="请选择所在地区"
                    :options="cityOption"
                    :fieldNames="fieldNames"
                    @close="showAcceptArea = false"
                    @finish="confirmAcceptArea"
                    />
                </van-popup>
                <!-- 支付状态弹窗 -->
                <PayStateDialog :isShow="isShow" @hide="_hide"></PayStateDialog>
            </div>
            <div class="prompt">注:只填一项即可查询</div>
        </div>
        
        <div class="footer flex">
            <div class="flex1 xycenter">
                <div class="btn flex xycenter" @click="reset">重置</div>
            </div>
            <div class="flex1 xycenter">
                <div class="btn flex xycenter" @click="search">搜索</div>
            </div>
        </div>
    </div>
</template>

<script>
const city=require('../../utlis/chinaCity.js');
import PayStateDialog from './PayStateDialog.vue'
export default {

    components: {
        PayStateDialog
    },

    data() {
        return {
            cityOption:city.chinaCity,//城市列表
            fieldNames:{
                value:'value',
                text:'label',
                children:'children'
            },
            isShow:false,//是否显示支付弹窗
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: new Date(),
            showStartTime:false,//是否展示开始时间
            showEndTime:false,//是否展示结束时间
            showSendArea:false,//是否展示发货区域
            showAcceptArea:false,//是否展示收货区域
            form:{
                orderNo:"",
                startTime:"",//开始时间
                endTime:"",//结束时间
                sendArea:"",//发货区域
                acceptArea:"",//收货区域
                sendCity: "",
                sendDist: "",
                endCity: "",
                endDist: "",
                stateText:"",//支付状态文本
            }
            

            // stateArr:[
            //     {name:'未结清',state:0,active:true},
            //     {name:'已结清',state:1,active:false}
            // ],
            
        }
    },
    // 私有过滤器
    filters:{
        
    },

    created() {
        console.log("打印传来的type",this.$route.params.type)
    },


    mounted() {

    },

    methods: {
        // 重置
        reset:function(){
            const that=this;
            that.form={
                orderNo:"",
                startTime:"",//开始时间
                endTime:"",//结束时间
                sendArea:"",//发货区域
                acceptArea:"",//收货区域
                stateText:"",//支付状态文本
                sendCity:"",
                sendDist:"",
                endCity:"",
                endDist:""
            }
        },
        // 查询
        search:function(){
            this.$router.replace({
                name:'m_order',
                params:{
                    form:this.form,
                    type:this.$route.params.type
                }
            })
            this.$emit('formdata',this.form);
            console.log("查询时的form",this.form);

        },
        // 确认发货地址
        confirmSendArea:function(address){
            const that=this;
            that.showSendArea=false;
            console.log("打印这个发货地址",address);
            that.form.sendCity=address.selectedOptions[1].value;
            that.form.sendDist=address.selectedOptions[2].value;
            address.selectedOptions.forEach((item)=>{
                that.form.sendArea+=item.label
            })
        },
        // 确认收货地址
        confirmAcceptArea:function(address){
            const that=this;
            that.showAcceptArea=false;
            console.log("打印这个收货地址",address);
            that.form.endCity=address.selectedOptions[1].value;
            that.form.endDist=address.selectedOptions[2].value;
            address.selectedOptions.forEach((item)=>{
                that.form.acceptArea+=item.label
            })
        },
        // 确认开始时间
        confirmStartTime:function(time){//time
            const that=this;
            that.form.startTime=this.moment(time).format('YYYY-MM-DD')
            that.showStartTime=false;


        },
        // 确认结束时间
        confirmEndTime:function(time){
            const that=this;
            that.form.endTime=this.moment(time).format('YYYY-MM-DD')
            that.showEndTime=false;
        },
        onFinish2:function(){

        },
        onFinish:function(){

        },
        _hide:function(val){
            const that=this;
            that.isShow=val.isshow;
            that.form.stateText=val.stateName;
        },
        // 选择支付状态
        selectState:function(){
            const that=this;
            that.isShow=true;
        },
        // 更新数据
        updateData:function(val){
            console.log("获取子组件传回的数据",val)
        }
    }

}

</script>
<style scoped>
    #m_search{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /* width: 100%;
        min-height: 100vh; */
    }
    .prompt{
        font-size: 26px;
        color: #e61d3a;
        margin-top: 20px;
    }
    .footer{
        width: 100%;
        height: 130px;
        background-color: white;
    }
    .btn{
        width: 260px;
        height: 90px;
        border-radius: 45px;
        font-size: 36px;
        background-color: #007EC5;
        color: white;
    }
    .padding{
        padding: 148px 30px 20px 30px;
    }
    #searchForm{
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
        border-radius: 20px;
        background: #fff;
        padding-left: 30px;
        padding-right: 30px;
    }
    .icon-youjiantou1{
        font-size: 34px;
    }
    .icon-xiajiantou{
        font-size: 30px;
    }
    .icon-rili{
        font-size: 50px;
    }
    .input{
        border: none;
    }
    .ml30{
        margin-left: 30px;
    }
    .label{
        min-width: 120px;
        text-align: right;
    }
    .row{
        font-size: 30px;
        padding: 20px 0;
    }
    .b_border{
        border-bottom: 1px solid #dcdcdc;
    }
    .label{
        
    }

</style>
