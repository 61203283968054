<template>
  <div class="order_form">
    <el-steps :active="active">
      <el-step title="Step 1" description="出库信息填写"></el-step>
      <el-step title="Step 2" description="完成下单"></el-step>
    </el-steps>
    <OutWareOrderStepOne v-show="active === 1" :parmsJsonProps="parmsJsons" @getParmsJson="getParmsJsonFun"
      :outOptions="options"></OutWareOrderStepOne>
    <OutWareOrderStepTwo v-if="active === 2" :parmsJsonProps="parmsJsons" @getParmsJson="getParmsJsonFun" :type="'out'">
    </OutWareOrderStepTwo>
  </div>
</template>

<script>
  import OutWareOrderStepOne from "./outWareOrderStepOne.vue";
  import OutWareOrderStepTwo from "./wareOrderStepTwo.vue";
  import { outInitialParms } from "@/assets/js/warehouse.js";
  // 接口参数名称
  const API = require("/src/assets/js/apilist.js");
  //获取用户信息
  let userJson = JSON.parse(localStorage.getItem("user"));
  export default {
    components: {
      OutWareOrderStepOne,
      OutWareOrderStepTwo,
    },
    data() {
      return {
        active: 1,
        parmsJsons: outInitialParms, //保存的集合
        depotId: "", //仓库id
        options: [],
      };
    },
    mounted() {
      let id = this.$route.query.id || "";
      if (id) {
        this.getDetail(id);
      } else {
        this.getOutCityList();
      }
      //更新
      this.userMsgFun();
    },
    methods: {
      //编辑获取详情
      getDetail(id) {
        let self = this;
        this.$axios("get", API.outGetById + "?id=" + id).then((res) => {
          if (res && res.code == "200" && res.data) {
            let parmsJson = self.parmsJsons;
            let data = res.data;
            Object.keys(parmsJson).map(function (key) {
              data[key] && (parmsJson[key] = data[key]);
            });
            let depotId =
              data.province && data.city && data.district && data.depotId
                ? [data.province, data.city, data.district, data.depotId]
                : [];
            parmsJson.depotId = depotId;
            parmsJson.commodityJson = data.depotStock;
            parmsJson.valueAddedServices = data.valueAddedServices
              ? data.valueAddedServices.split(",")
              : "";
            self.parmsJsons = parmsJson;
            self.getDetailOutCityList(self.parmsJsons.depotId);
          } else {
            self.$message.error("获取详情出错了！");
          }
        });
      },
      getParmsJsonFun({ parmsJson, active }) {
        this.parmsJsons = parmsJson;
        this.active = active;
      },
      // 存储出库省市区编辑详情初始数据的方法
      getDetailOutCityList(value) {
        // 调用仓库接口的参数
        let params = {
          current: 1,
          size: 30,
          name: "",
          contractName: "",
          province: value[0],
          city: value[1],
          district: value[2],
        };
        let data = [];
        this.$axios("post", API.outCity, { corpId: userJson.corpId || "" })
          .then((res) => {
            data = res.data;
            if (res.code == "200") {
              this.$axios("post", API.outDepot, params)
                .then((res) => {
                  let result = res.data.records;
                  data.forEach((item) => {
                    if (item.value === value[0]) {
                      item.children.forEach((item2) => {
                        if (item2.value === value[1]) {
                          item2.children.forEach((item3) => {
                            if (item3.value === value[2]) {
                              item3.children = [];
                              result.map((item4) => {
                                if (item4.id === value[3]) {
                                  item3.children.push(
                                    Object.assign({
                                      value: item4.id,
                                      label: item4.name,
                                      leaf: true,
                                    })
                                  );
                                } else {
                                  item3.children.push(
                                    Object.assign({
                                      value: item4.id,
                                      label: item4.name,
                                      leaf: 4,
                                    })
                                  );
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                  this.options = data;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => { });
      },
      //存储出库省市区信息方法
      getOutCityList() {
        this.$axios("post", API.outCity, { corpId: userJson.corpId })
          .then((res) => {
            this.options = res.data;
          })
          .catch((error) => { });
      },
      //补充客户信息
      userMsgFun() {
        this.parmsJson = {
          ...this.parmsJsons,
          companyName: userJson.companyName || "", //公司名称
          corpId: userJson.corpId || "", //公司Id
          userId: userJson.id || "", //用户ID
          mobile: userJson.mobile || "", //手机号
        };
      },
    },
  };
</script>
<style scoped>
  .order_form>>>.el-step__head {
    text-align: left;
  }

  .order_form>>>.el-step__title.is-finish,
  .order_form>>>.el-step__description.is-finish {
    color: #0a8de4;
  }

  .order_form>>>.el-step__head.is-wait,
  .order_form>>>.el-step__head.is-process {
    color: #666;
    border-color: #bdbdbd;
  }

  .order_form>>>.el-step__title {
    font-size: 20px !important;
    line-height: 2em !important;
  }

  .order_form>>>.el-step__description {
    font-size: 18px !important;
    line-height: 2em !important;
  }

  .order_form>>>.el-step__title.is-wait,
  .order_form>>>.el-step__title.is-process {
    font-weight: normal;
    color: #666;
  }

  .order_form>>>.el-step__description.is-wait,
  .order_form>>>.el-step__description.is-process {
    color: #666;
  }

  .order_form>>>.el-step.is-horizontal {
    flex-basis: 50% !important;
  }

  .order_form>>>.el-step.is-horizontal .el-step__line {
    top: 15px;
  }

  .order_form>>>.el-step__icon {
    width: 30px;
    height: 30px;
  }

  .order_form>>>.el-step__icon.is-text {
    border-radius: 0;
    border: none;
    border-color: transparent;
    background: url("../../../assets/images/step_icon.png") no-repeat -30px 0;
    background-size: 60px;
  }

  .order_form>>>.is-finish .el-step__icon.is-text {
    background-position: 0 0;
  }

  .order_form>>>.el-step__icon-inner {
    font-size: 0;
  }
</style>