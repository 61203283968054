<template>
  <div class="cargo_box" v-if="reFresh">
    <div class="cargo_list mb20">
      <span
        class="cargo_li"
        v-for="(item, index) in cargoLabelList"
        :key="index"
        @click="handleSelect(item.id, index)"
        :class="item.checked ? 'active' : ''"
      >
        {{ item.name }}
      </span>
    </div>
    <div class="detail" v-show="!isShowTagDetail">
      <el-form
        :model="tagLine"
        :rules="cargoRules"
        ref="cargoForm"
        class="cargoForm"
      >
        <div
          class="flex"
          v-for="(item, index) in tagLine.tagLineDetail"
          :key="index"
        >
          <span class="name">{{ getName(item.id) }}</span>
          <el-form-item
            :prop="'tagLineDetail.' + index + '.m'"
            :rules="cargoRules.pakages"
          >
            <el-input
              v-model="item.m"
              placeholder="请输入件"
              @input="(val) => changeIptVal(val, index, item.id)"
            >
              <template slot="append">件</template>
            </el-input>
          </el-form-item>
          <el-form-item
            :prop="'tagLineDetail.' + index + '.t'"
            :rules="cargoRules.weight"
          >
            <el-input
              v-model="item.t"
              placeholder="请输入重量"
              :disabled="item.id != '0'"
            >
              <template slot="append">公斤</template>
            </el-input>
          </el-form-item>
          <el-form-item
            :prop="'tagLineDetail.' + index + '.v'"
            :rules="cargoRules.volume"
          >
            <el-input
              v-model="item.v"
              placeholder="请输入方"
              :disabled="item.id != '0'"
            >
              <template slot="append">方</template>
            </el-input>
          </el-form-item>
          <i
            class="delbox iconfont icon-delete1 hand"
            @click="removeItem(item.id, index)"
          ></i>
        </div>
        <div class="tc">
          <el-button class="w150" type="primary" @click="handleCargoSubmit"
            >确定</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "cargoLabelPC",
  props: {
    cargoLabelList: {
      type: Array,
      required: true,
    },
    tagLine: {
      type: Object,
      required: true,
    },
    reFresh: {
      type: Boolean,
      required: true,
    },
    cargoRules: {
      type: Object,
      required: true,
    },
    isShowTagDetail: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getName(cargoId) {
      let newName = "";
      this.cargoLabelList.map((tag, idx) => {
        if (cargoId == tag.id) {
          newName = tag.name;
        }
      });
      return newName;
    },
    handleSelect(id, index) {
      this.$emit("handleSelect", id, index);
    },
    handleCargoSubmit() {
      this.$refs["cargoForm"].validate((valid, event) => {
        if (valid) {
          this.$emit("handleCargoSubmit");
        }
      });
    },
    removeItem(id, index) {
      this.$emit("removeItem", id, index);
    },
    changeIptVal(val, index, id) {
      this.$emit("changeIptVal", val, index, id);
    },
  },
};
</script>
<style scoped>
.cargo_list .cargo_li {
  margin-top: 5px;
  padding: 8px 10px;
  line-height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #fff;
}
.cargo_list .cargo_li.active {
  background-color: #0383d9;
  color: #fff;
}
.name {
  min-width: 50px;
  line-height: 40px;
  margin-right: 20px;
}
.delbox {
  font-size: 30px;
  line-height: 40px;
  margin-left: 20px;
}
.cargo_box >>> .el-input-group__append {
  box-shadow: 3px 3px 5px #ddd;
  border: none;
}
.batch_form .cargo_box >>> .el-input-group__append {
  box-shadow: none;
  background-color: #e1e1e1;
}
.batch_form .cargo_box >>> .el-input .el-input__inner {
  line-height: 40px;
  height: 40px;
}
.batch_form .cargo_box >>> .el-input.is-disabled .el-input__inner {
  background-color: #e1e1e1;
  color: #909399;
}
.detail {
  display: inline-block;
}
</style>