<!-- 头部导航 -->
<template>
    <div class="header xycenter">
        <div class="flex box">
          <div class="flex ycenter mr80">
            <img class="login" :src="logo" @click="to_index">
          </div>
          <div class="tab flex">
            <div class="flex1 yrow"  v-for="(item,index) in tab" :key="item.title">
              <div class="flex1 xycenter pointer" @click="showNextNav(index)">
                {{item.title}}
              </div>
              <div class="height0 flex yrow relative">
                <div class="absolute" v-show="item.active">
                  <div class="flex xycenter">
                    <img :src="triangle">
                  </div>
                  <div class="bgblue white nav">
                    <div v-for="(item2,index2) in item.content" class="flex xycenter secondNav pointer" @click="skipPage(index,item2.path,item2.jumptype)">{{item2.title}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="phone ycenter">
            <i class="iconfont dh_icon">&#xe69a;</i>
            <span class="dh_icon">{{dianhua}}</span>
          </div>
          <div class="login ycenter">
            <div class="ycenter ml86 rel">
              <i class="iconfont usericon" @click="exitout">&#xe612;</i>
              <span v-if="mobile==''" class="white fz20 login pointer" @click="tologin">登录/注册</span>
              <div v-else>
                <span class="white fs22 login pointer" @click="exitout">{{mobile}}</span>
                <div v-if="isQuick" class="abs_down fz16 f999" @click="exitToLogin"><span class="abs_down_bg">退出</span></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <confirmDialog :isShow="show" :title="title" :content="content" @_close="close" @_confirm="confirm"></confirmDialog> -->
    </div>
  </template>
    
    <script>
    import {setCookie,getCookie,delCookie} from '../../assets/js/cookies.js'
    import confirmDialog from '../../components/pc/confirmDialog.vue'
    export default {
      name: 'webheader',
      components:{
        confirmDialog
      },
      data () {
        return {
          mobile:"",
          triangle:require('../../assets/images/triangle.png'),
          logo:require('../../assets/images/logo_white.png'),
          // 导航
          tab:[
                {
                  title:'首页',
                  content:[],
                  active:false,
                },
                {
                  title:'产品服务',
                  content:[
                    {title:'运输产品',path:'/html/product.html?tab=1',jumptype:0},
                    {title:'仓储产品',path:'/html/product.html?tab=2',jumptype:0},
                    {title:'行业解决方案',path:'/html/product.html?tab=3',jumptype:0},
                  ],
                  active:false
                },
                {
                  title:'寄件查询',
                  content:[
                    {title:'运输下单',path:'/pre_order',jumptype:1},
                    {title:'仓储下单',path:'/entry_order',jumptype:1},
                    {title:'快速询价',path:'/html/inquiry.html',jumptype:0},
                    {title:'货物追踪',path:'/html/trajectory.html',jumptype:0},
                    {title:'我的订单',path:'/p_myorder/p_transOrder',jumptype:1}
                  ],
                  active:false
                },
                {
                    title:'一站网动态',
                    content:[
                      {title:'新闻',path:'/dynamic?type=0&current=1',jumptype:0},
                      {title:'活动',path:'/dynamic?type=1&current=1',jumptype:0},
                      {title:'公告',path:'/dynamic?type=2&current=1',jumptype:0}
                    ],
                    active:false
                },
                {
                  title:'帮助支持',
                  content:[
                    {title:'常见问题',path:'/html/helpAndSupport01.html',jumptype:0},
                    {title:'运输和包装',path:'/html/helpAndSupport02.html',jumptype:0},
                    {title:'保价和理赔',path:'/html/helpAndSupport03.html',jumptype:0},
                    {title:'增值服务',path:'/html/helpAndSupport04.html',jumptype:0}
                  ],
                  active:false
                },
                {
                  title:'走进一站',
                  content:[
                    {title:'认识一站网',path:'/html/walkInto.html',jumptype:0},
                    {title:'一站招聘',path:'/joinList',jumptype:0},
                    {title:'联系我们',path:'/html/contactUs.html',jumptype:0}
                  ],
                  active:false
                },
              ],
          // 电话
          dianhua:'400-868-5656',
          show:false,
          title:'账号退出提示',
          content:'您确定要退出当前账号吗?',
          isQuick: false,//是否显示退出下拉
        }
      },
      created(){
        if(!!localStorage.getItem('user')){
          const user=JSON.parse(localStorage.getItem('user'));
          this.mobile=user.mobile;
        }
      },
      mounted(){
        const that=this;
      },
      methods:{
        close:function(val){
          console.log("取消打印回调",val)
          this.show=val;
        },
        confirm:function(val){
          this.show=val;
          console.log("确定打印回调",val)
          setCookie('mobile',null,0,'','.yizhan56.cn');//删除顶级域名cookies
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.envJump('/p_login','href',1);
        },
        // 退出登录
        exitout:function(){
          this.isQuick = !this.isQuick;
          let that = this;
          if(that.isQuick){
            setTimeout(()=>{
              that.isQuick = false
            },4000)
          }
        },
        exitToLogin(){
          this.isQuick = false;
          setCookie('mobile',null,0,'','.yizhan56.cn');//删除顶级域名cookies
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.envJump('/p_login','href',1);
        },
        // 点击Logo返回首页
        to_index:function(){
          this.envJump('','href',0)
        },
        // 点击二级导航跳转页面
        skipPage:function(idx,url,type){
          this.envJump(url,'href',type)
          console.log("11111111111",idx)
          this.tab[idx].active=false;
        },
        hideNextNav:function(){
          const that=this;
          console.log("鼠标隐藏")
        },
        // 展示二级菜单
        showNextNav:function(idx){
          const that=this;
          console.log("展示二级菜单")
          if(that.tab[idx].content.length>0){
            that.tab.forEach((item,index)=>{
              if(idx==index){
                item.active=!item.active;
              }else{
                item.active=false;
              }
            })
          }else{
            if(idx==0){
              this.envJump('','href',0);//跳转到首页
            }
          }
          
          console.log("打印当前菜单数组",that.tab)
        },
        // 初始化
        // 跳转到登录
        tologin:function(){
          const that=this;
          that.$router.push('/p_login')
        }
          
      }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
      .login{
        cursor:pointer
      }
      .fs22{
        font-size: 22px;
      }
      .secondNav{
        font-size: 18px;
        padding:18px 0;
      }
      .secondNav:hover{
        background-color: rgb(2, 119, 197);
      }
      .nav{
        /* padding: 20px 0; */
        border-radius: 14px;
      }
      .height0{
        height: 0px;
      }
      .relative{
        position: relative;
      }
      .bgblue{
        background-color: #0A8DE4;
      }
      .white{
        color: white;
      }
      .absolute{
        width: 160%;
        position: absolute;
        top: 14px;
        z-index: 99;
        transform: translateX(-18%);
        /* background-color: #0A8DE4; */
        /* color: white; */
      }
      .pointer{
        cursor:pointer
      }
      .mr80{
        margin-right: 80px;
      }
      .ml86{
        margin-left: 86px;
      }
      /* .fz20{
        font-size: 20px;
        color: white;
      } */
      .usericon{
        font-size: 22px;
        color: white;
      }
      .dh_icon{
        font-size: 24px;
        color: white;
      }
      .header{
          width: 100%;
          height: 90px;
          background-color: #0A8DE4;
      }
      .box{
        /* width: 1232px; */
      }
      .tab{
        width: 647px;
        font-size: 20px;
        color: white;
        font-family:"MicrosoftYaHei";
      }
      .abs_down{
          position: absolute;
          top: 36px;
          height: 50px;
          left: 0;
          width: 100%;
          padding-top: 18px;
          color: #fff;
          border-radius: 5px;
          box-sizing: border-box;
          background: url(../../assets/images/triangle_sp.png) no-repeat center 0;
      }
      .abs_down_bg{
        width: 100%;
        padding: 15px;
        text-align: center;
        box-sizing: border-box;
        background: #caeaff;
        color: #0277c5;
        border-radius: 8px;
      }
    </style>