<template>
  <div class="plr20">
    <van-tabs>
      <van-tab title="可用优惠券">
        <div class="mainbox pt30" :style="{ maxHeight: innerHeight + 'px' }">
          <div
            class="coupon_row"
            v-for="item in $store.state.couponList"
            :key="item.id"
            :class="item.couponType"
          >
            <div
              class="row_left c_red flex_c_m"
              v-if="item.couponType === 'YHQLX03'"
            >
              <div>
                <div class="top">
                  <span class="txt">￥</span>
                  <span class="font">{{ item.discountValue }}</span>
                </div>
                <div class="btm">满{{ item.discountValueAppend }}可用</div>
              </div>
            </div>
            <div
              class="row_left c_yellow flex_c_m"
              v-if="item.couponType === 'YHQLX02'"
            >
              <span class="txt">￥</span>
              <span class="font">{{ item.discountValue }}</span>
            </div>
            <div
              class="row_left c_blue flex_c_m"
              v-if="item.couponType === 'YHQLX01'"
            >
              <span class="font">{{ item.discountValue }}</span>
              <span class="txt">折</span>
            </div>
            <div class="row_center">
              <div class="name ellipsis">{{ item.couponName }}</div>
              <div class="sec">
                <span class="type">{{ item.couponTypeName }}</span>
                <span class="num">NO.{{ item.couponNum }}</span>
              </div>
              <div class="date">{{ item.beginTime }} - {{ item.endTime }}</div>
            </div>
            <div class="row_right flex_c_m">
              <div>
                <div class="c_color" v-if="item.isDiejia === '1'">可叠加</div>
                <input
                  class="check_ipt"
                  type="checkbox"
                  v-model="newCouponNum"
                  :value="item.couponNum"
                  @click="(e) => handleSelect(e, item)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="main_btn flex_lr_m">
          <span class="fz28">
            优惠金额：
            <span class="c_red"
              >{{
                newStepArr.lineSalePrice ? "- " + newStepArr.lineSalePrice : 0
              }}元</span
            >
          </span>
          <span class="btn_primary" @click="submitBtn">确定使用</span>
        </div>
      </van-tab>
      <van-tab title="录入优惠券">
        <div class="bind_m_box">
          <div class="flex_c">
            <input
              class="bind_m_ipt"
              placeholder="请输入优惠券码"
              v-model="couponIpt"
            />
          </div>
          <div class="flex_c">
            <button class="bind_m_btn btn_primary" @click="handleBind">
              绑定
            </button>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: "CouponListM",
  props: {
    newStepArr: {
      type: Object,
      required: true,
    },
    couponNum: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newCouponNum: this.couponNum,
      couponIpt: "",
      innerHeight: document.documentElement.clientHeight - 380,
    };
  },
  watch:{
    couponNum(newVal){
      this.newCouponNum = newVal
    }
  },
  methods: {
    handleSelect(e, item) {
      this.$emit("handleFaSelect", e, item);
    },
    submitBtn() {
      this.$emit("faComfirmBtn");
    },
    handleBind() {
      let val = this.couponIpt;
      if (!val) {
        this.$message.info("请填写要绑定的优惠券号");
        return;
      }
      this.$emit("handleFaBind", val);
    },
    handleHide() {
      this.$emit("changeVisible", false);
    },
  },
};
</script>
<style lang="less" scoped>
.mainbox {
  width: 90%;
  padding-right: 20px;
  //   max-height: 600px;
  overflow-y: auto;
  margin: 0 auto;
}
.coupon_row {
  width: 100%;
  height: 160px;
  border-radius: 15px;
  display: flex;
  margin-bottom: 20px;
}
.coupon_row .row_left {
  flex: 1.2;
}
.coupon_row .row_center {
  height: 100%;
  flex: 2.5;
  padding: 20px 10px;
  line-height: 30px;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 20px;
  box-sizing: border-box;
}
.coupon_row .row_right {
  flex: 1;
}
.coupon_row.YHQLX01 {
  background: #dff0fe;
}
.coupon_row.YHQLX01 .row_center {
  background-image: url("../../assets/images/coupons_blueline.png");
}
.coupon_row.YHQLX02 {
  background: #fff2de;
}
.coupon_row.YHQLX02 .row_center {
  background-image: url("../../assets/images/coupons_yellowline.png");
}
.coupon_row.YHQLX03 {
  background: #fce2e6;
}
.coupon_row.YHQLX03 .row_center {
  background-image: url("../../assets/images/coupons_redline.png");
}
.mainbox .check_ipt {
  width: 40px;
  height: 40px;
  border-color: #dbdbdb;
  background: #ebebeb;
}
.flex_c_m {
  /* 垂直水平居中 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.c_yellow,
.YHQLX02 .c_color {
  color: rgb(253, 158, 35);
}
.c_red,
.YHQLX03 .c_color {
  color: #b93a13;
}
.c_blue,
.YHQLX01 .c_color {
  color: rgb(37, 157, 246);
}
.sec {
  margin: 15px 0;
}
.type {
  padding: 5px;
  margin-right: 10px;
  color: #fff;
  border-radius: 10px;
}
.YHQLX02 .type {
  background-color: rgb(253, 158, 35);
}
.YHQLX03 .type {
  background-color: #b93a13;
}
.YHQLX01 .type {
  background-color: rgb(37, 157, 246);
}
.txt {
  font-size: 16px;
}
.font {
  font-size: 60px;
}
.main_btn {
  width: 90%;
  padding: 30px;
}
.bind_m_box {
  height: 300px;
  padding: 30px 0;
}
.bind_m_ipt {
  width: 500px;
  height: 90px;
  padding-left: 40px;
  padding-right: 35px;
  border: 1px solid #3a4360;
  border-radius: 45px;
  display: inline-block;
}
.bind_m_btn {
  width: 300px;
  margin-top: 100px;
  box-shadow: none;
  outline: none;
  border: none;
}
</style>