<template>
    <div id="p_transOrder" class="flex yrow">
        <!-- tab -->
        <div class="tabbox b_border flex">
             <div class="h84 plr30" v-for="(item,index) in tabArr">
                 <div :class="item.active?'activetab pointer':'_activetab pointer'" @click="changeTab(item.route)">{{item.name}}</div>
             </div>
        </div>
        <!-- 筛选条件 -->
        <div class="flex h74 ycenter">
            <div class="flex pl32">
                <div v-for="(item,index) in statusList" :key="item.name" @click="changeStatus(item,index)" :class="item.active==true?'blue mr50 fz18 pointer':'mr50 fz18 pointer'">
                    <span>{{item.name}}</span>
                    <!-- <span>({{item.number}})</span> -->
                </div>
            </div>
            <!-- 搜索条件 -->
            <div class="flex ycenter">
                <div class="l_inputrow ycenter">
                    <input placeholder="请输入单号" v-model="form.depotNo" class="input">
                </div>
                <div class="searchbtn xycenter pointer" @click="search">查询</div>
            </div>
            <div class="reset xycenter pointer" @click="reset">重置</div>
            <div class="fz18 blue">
                <span class="pl22 minw110 pointer" @click="moreSearch">更多查询条件</span>
            </div>
        </div>
        <!-- 查询条件 -->
        <div>
            <div class="flex searchrow pl32 fz16" v-show="show">
            <!-- 下单日期 -->
            <div class="flex yrow">
                <span class="padtb12">下单日期</span>
                <el-date-picker
                v-model="time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions2"
                @change="selectTime">
            </el-date-picker>
            </div>
            </div>
        </div>
        <div class="maincontent flex1 relative">
            <div class="orderRow flex xycenter">
                <div class="pt30 pb20 fs20 _width80">共<span class="blue">{{totalnumber}}</span>票货物</div>
            </div>
            <!-- 列表 -->
            <div class="orderRow flex xycenter" v-for="(item,index) in orderArr">
                <div class="orderdemo" @click="toDetail(item)">
                    <div class="t_order flex bg_lightblue">
                    <div class="flex1 ycenter">
                        订单号:{{item.depotNo}}
                        <div :class="item.type==0?'typebtn':'_typebtn'">{{item.type=='0'?'入库单':'出库单'}}</div>
                    </div>
                    <div class="flex1 ycenter xright">
                        <span class="mr30">下单时间:{{item.createTime}}</span>
                        <!-- <div :class="item.type==0?'typebtn':'_typebtn'">{{item.state}}</div> -->
                        <div v-if="item.state=='待处理'" class="symbol fs18 flex xycenter">待处理</div>
                        <div v-if="item.state=='待确认'" class="symbol2 fs18 flex xycenter">待确认</div>
                        <div v-if="item.state=='待出库'" class="symbol3 fs18 flex xycenter">待出库</div>
                        <div v-if="item.state=='待入库'" class="symbol3 fs18 flex xycenter">待入库</div>
                        <div v-if="item.state=='已入库'" class="symbol3 fs18 flex xycenter">已入库</div>
                        <div v-if="item.state=='已取消'" class="symbol4 fs18 flex xycenter">已取消</div>
                    </div>
                    </div>
                    <div class="b_order ptb30 fz18">
                        <div class="flex pb30 b_border">
                            <div class="flex1 xycenter">
                                <div class="xycenter yrow">
                                    <div class="xycenter">{{item.depotName}}</div>
                                    <div class="xycenter">{{item.companyName}}</div>
                                </div>
                            </div>
                            <div class="flex1 xycenter l_border money fs20">
                                ¥{{item.payment|empty}}
                            </div>
                        </div>
                        <div class="flex xright" v-if="item.state!='已取消'">
                            <div class="delete btn pointer" v-if="item.state=='待处理'" @click.stop="cancel(item)">撤销</div>
                            <!-- <div class="btn pointer" v-if="(item.state=='待处理'||item.state=='待确认')&&item.paidMoney!=item.payment&&item.payment" @click.stop="pay(item)">确定并支付</div> -->
                            <div class="edit btn pointer" v-if="item.state=='待处理'" @click.stop="changeOrder(item,'edit')">订单修改</div>
                        </div>
                    </div>                   
                </div>
            </div>
            <div v-show="orderArr.length==0" class="nodata">暂无数据~</div>
            
        </div>
        <!-- 分页 -->
        <div class="pagerow xycenter">
            <el-pagination
            v-show="orderArr.length>0"
            background
            layout="prev, pager, next"
            :total="totalnumber"
            :page-size="pageSize"
            :current-page.sync="form.current"
            @current-change="changePage">
            </el-pagination>
        </div>
        
    </div>
 </template>
 
 <script>
 const API=require('/src/assets/js/apilist.js');
 export default {
 
    components: {
        
    },

    data() {
        return {
            arrow:require('../../../assets/images/arrow.png'),
            tabArr:[
                {name:'运输订单',active:false,route:'p_transOrder'},
                {name:'仓储订单',active:true,route:'p_storeOrder'}
            ],
            show:false,
            tab:0,//0:运输订单,1:仓储订单
            statusList:[
                {name:'全部订单',active:true,outStateName:'',enterStateName:''},
                {name:'待处理',active:false,outStateName:1,enterStateName:1},
                {name:'待确认',active:false,outStateName:5,enterStateName:10},
                {name:'待入/出库',active:false,outStateName:3,enterStateName:2},
                {name:'已入/出库',active:false,outStateName:6,enterStateName:4},
                {name:'撤销',active:false,outStateName:0,enterStateName:0}
            ],
            orderArr:[],//订单列表
            time:"",
            totalnumber:0,//总行数
            pageSize:5,
            form:{
                outStateName: '',
                enterStateName: '',
                current: 1,
                size: 5,
                userId: '',
                mobile: '',
                depotNo: '',
                createTimeBegin: '',
                endTimeEnd: ''
            },
            payStateArr:[//支付状态选项
                {
                    value:'1,2',
                    label:'未结清'
                },
                {
                    value:'3',
                    label:'已结清'
                },
            ],
            pickerOptions2: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // userId:JSON.parse(localStorage.getItem('user').id) 
        }
    },
    filters:{
        // 金额为空时自动改为0,
        empty:function(data){
            if(data==''){
                return '0.00'
            }else{
                return data;
            }
        },
    },

    created() {
        const that=this;
        const params=this.filterParams2(this.form)
        that.warehouseOrder(params)
        
        
        

    },


    mounted() {
        const that=this;
        
    //  that.warehouseOrder();
    },

    methods: {
        // 重置
        reset:function(){
            this.time='';
            this.statusList.forEach((item)=>{
                item.active=false;
            })
            this.statusList[0].active=true;
            this.form={
                outStateName: '',
                enterStateName: '',
                current:1,
                size: 5,
                userId: '',
                mobile: '',
                depotNo: '',
                createTimeBegin: '',
                endTimeEnd: ''
            }
            const params=this.filterParams2(this.form)
            that.warehouseOrder(params)
        },
        // 订单修改
        changeOrder:function(obj,state){
            const that=this;
            if(obj.type==0){
                that.$router.push({
                    path:'/entry_order',
                    query:{
                        id:obj.id,
                        actType:state
                    }
                })
            }else{
                that.$router.push({
                    path:'/out_order',
                    query:{
                        id:obj.id,
                        actType:state
                    }
                })
            }
        },
        // 撤销
        // 撤销订单
        cancel:function(obj){
            const that=this;
            this.$dialog.confirm({
                title: '温馨提示',
                message: '确定要撤销该订单么？',
                confirmButtonColor:'#007EC5'
                })
                .then(() => {
                    if(obj.type==0){//如果是入库单
                        let params={
                            id:obj.id
                        }
                        that.$axios('get',API.inStoreCancel,params,'params','application/json')
                        .then((res)=>{
                            console.log("入库单撤销成功",res)
                            if(res.isSuccess){
                                that.$toast('撤销成功');
                                // this.form.current=1;
                                // that.orderArr=[];
                                const params=this.filterParams2(this.form)
                                setTimeout(function(){
                                    that.warehouseOrder(params);
                                },1000)
                                
                            }

                        })
                        .catch((error)=>{
                            console.log("入库单撤销失败",error)

                        })

                    }else{//如果是出库单
                        let params={
                            depotOutId:obj.id,
                            state:0
                        }
                        that.$axios('get',API.outStoreCancel,params,'params','application/json')
                        .then((res)=>{
                            console.log("出库单撤销成功",res)
                            if(res.isSuccess){
                                that.$toast('撤销成功');
                                // this.form.current=1;
                                // that.orderArr=[];
                                const params=this.filterParams2(this.form)
                                setTimeout(function(){
                                    that.warehouseOrder(params);
                                },1000)
                            }
                            
                        })
                        .catch((error)=>{
                            console.log("出库单撤销失败",error)

                        })

                    }
                })
                .catch(() => {

                });

        },
        // 跳转到详情
        toDetail:function(obj){
            const that=this;
            that.$router.push({
                path:'p_orderDetail',
                query:{
                    type:obj.type,
                    detail:2,
                    id:obj.id,
                    state:obj.state,
                    accessType:obj.accessType
                }
            })
        },
        // 查询
        search:function(){
            const that=this;
            that.form.current=1;
            console.log("打印form",that.form);
            const params=that.filterParams2(that.form);
            that.warehouseOrder(params);

        },
        // 翻页
        changePage:function(Page){
            const that=this;
            console.log("打印当前页数",Page)
            that.form.current=Page;
            let params=that.filterParams2(that.form);
            console.log("打印这个管道参数",params);
            that.warehouseOrder(params)
        },
        // 选择下单时间
        selectTime:function(time){
            const that=this;
            if(time!=null){
                that.form.createTimeBegin=that.moment(time[0]).format("YYYY-MM-DD");
                that.form.endTimeEnd=that.moment(time[1]).format("YYYY-MM-DD");
            }else{
                that.form.createTimeBegin="";
                that.form.endTimeEnd="";
            }
            // console.log("11111111111111",time)
            // that.form.createTimeBegin=that.moment(time[0]).format("YYYY-DD-MM");
            // that.form.endTimeEnd=that.moment(time[1]).format("YYYY-DD-MM");
        },
        // 切换状态
        changeStatus:function(item,idx){
            const that=this;
            that.statusList.forEach((item,index)=>{
                if(idx==index){
                    that.statusList[idx].active=true;
                }else{
                    that.statusList[index].active=false;
                }
            })
            that.form.outStateName=item.outStateName;
            that.form.enterStateName=item.enterStateName;
            that.form.current=1;
            console.log("打印当前form",that.form)
            const params=that.filterParams2(that.form);
            console.log("打印过滤后的参数",params);
            that.warehouseOrder(params);
            // that.updateTransOrder(params);

        },
        changeTab:function(route){
            this.$router.push(route)
        },
         // 更多查询条件
         moreSearch:function(){
             const that=this;
             that.show=!that.show
         },
         // 选择发货地址
         selectAddress:function(obj){
             const that=this;
             console.log("打印地址值",obj)
 
         },
         //获取仓储订单
         warehouseOrder:function(Params){
             const that=this;
             that.$loading({text:'加载数据中'})
             that.$axios('get',API.storagePage,Params,'params','application/json').then((res)=>{
                 if(res.isSuccess){
                    that.$loading().close();
                    console.log("获取仓库订单列表成功",res)
                    that.totalnumber=res.data.total
                    that.orderArr=res.data.records;
                 }else{
                    that.$loading().close();
                    that.$message.error(res.msg)
                 }
                 
             }).catch((error)=>{
                that.$loading().close();
 
             })
 
 
         },
     }
 
 }
 
 </script>
 <style scoped>
     #p_transOrder{
         width: 100%;
     }
     .fs18{
         font-size: 18px;
     }
     .fs20{
         font-size: 20px;
     }
     ._width80{
         width: 80%;
     }
     .relative{
         position: relative;
     }
     .nodata{
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
        font-size: 22px;
    }
     .reset{
        width: 80px;
        height: 38px;
        margin-left: 8px;
        border-radius: 6px;
        background-color: #d43e3e;
        color: white;
        font-size: 16px;
    }
    .add,
    .edit{
        background-color: #0A8DE4;
        color: white;
    }
    .delete{
        background-color: #d43e3e;
        color: white;
    }
     .delete{
        background-color: #d43e3e;
        color: white;
     }
     .mr30{
         margin-right: 30px;
     }
     .typebtn{
        padding: 10px 16px;
        margin-left: 12px;
        background-color: #0A8DE4;
        border-radius: 5px;
        color: white;
     }
     ._typebtn{
        padding: 10px 16px;
        margin-left: 12px;
        background-color: #76B040;
        border-radius: 5px;
        color: white;
     }
     /* 待处理 */
     .symbol{
        background-color: #F5B968;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
    }
    /* 待确认 */
    .symbol2{
        background-color: #2A97E8;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
        
    }
    /* 待入库,待出库 */
    .symbol3{
        background-color: #76B040;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
    }
    /* 撤销 */
    .symbol4{
        background-color: #D3D3D3;
        padding: 10px 16px;
        margin-left: 12px;
        border-radius: 5px;
        color: white;
    }
     .activetab{
        padding: 0 24px;
        height: 84px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 4px solid #0A8DE4;
        cursor: pointer;
     }
     ._activetab{
        padding: 0 24px;
        height: 84px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
     }
     .btn{
        padding: 10px 20px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
     }
     .pointer{
         cursor: pointer;
     }
     .padtb12{
         padding: 12px 0;
     }
     .searchrow{
         /* justify-content: space-around; */
     }
     >>>.el-input__inner{
         background-color: #F1F1F1 !important;
     }
     >>>.el-range-editor .el-range-input{
         background-color: #F1F1F1 !important;
     }
     >>>.el-date-editor .el-range-separator{
         display: flex;
         align-items: center;
     }
     .bggray{
         background-color: #F1F1F1;
     }
     .pagerow{
         padding-top: 50px;
         padding-bottom: 70px;
     }
     .stateText{
         padding-top: 12px;
         color: #0A8DE4;
     }
     .blue{
         color: #0A8DE4;
     }
     .minw110{
         min-width: 110px;
     }
     .pb30{
         padding-bottom: 30px;
     }
     .mt50{
         margin-top: 50px;
     }
     .orderdemo{
         width: 80%;
         border: 1px solid #CCCCCC;
         border-radius: 5px;
         margin-bottom: 30px;
     }
     .money{
         color:#E62019;
         font-family: Microsoft YaHei;
     }
     .l_border{
         border-left: 1px solid #CCCCCC;
     }
     .arrow{
         width: 90px;
         height: 10px;
     }
     .ptb30{
         padding-top: 30px;
     }
     .bg_lightblue{
         background-color: #e2eff6;
     }
     .t_order{
         padding: 10px 22px;
         font-size: 16px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #99A0A3;
     }
     .maincontent{
         width: 100%;
     }
     .pl22{
         padding-left: 22px;
     }
     .blue{
         color: #0A8DE4;
     }
     .l_inputrow{
         width: 252px;
         height: 40px;
         background-color: #F1F1F1;
         border-top-left-radius: 6px;
         border-bottom-left-radius: 6px;
     }
     .h74{
         height: 74px;
     }
     .searchbtn{
         width: 80px;
         height: 38px;
         border-top-right-radius: 6px;
         border-bottom-right-radius: 6px;
         background-color: #0A8DE4;
         color: white;
         font-size: 16px;
     }
     .input{
         width: 200px;
         font-size: 14px;
         background-color: #F1F1F1;
         border: none;
         padding-left: 16px;
         /* text-indent: 16px; */
         /* padding:14px 0; */
     }
     .pl32{
         padding-left: 32px;
     }
     .padtb30{
         padding:30px 0;
         /* padding-left: 32px; */
     }
     .mr50{
         margin-right: 50px;
     }
     .fz18{
         font-size: 18px;
     }
     .t_border{
        border-bottom: 1px solid #d9d9d9;
     }
     .b_border{
         border-bottom: 1px solid #d9d9d9;
     }
     .plr30{
         padding: 0 30px;
     }
     .plr24{
         padding: 0 24px;
     }
     .h84{
         height: 84px;
     }
 
 </style>
 