<template>
  <div class="order_form">
    <header1></header1>
    <div class="head_tab bg_fff pt20 mb50">
      <div class="wauto tab_changeline">
        <span
          class="tab_li"
          :class="
            fullPath.indexOf(orderNav[0].id) != -1 ||
            fullPath.indexOf(orderNav[1].id) != -1
              ? 'active'
              : ''
          "
          @click="changeRoute(orderNav[0].id)"
        >
          运输下单
        </span>
        <span
          class="tab_li"
          :class="
            fullPath.indexOf(wareNav[0].id) != -1 ||
            fullPath.indexOf(wareNav[1].id) != -1
              ? 'active'
              : ''
          "
          @click="changeRoute(wareNav[0].id)"
        >
          仓储下单
        </span>
      </div>
    </div>
    <div class="pay_order" label="运输下单" name="1">
      <div class="wauto">
        <div class="tab_changecolor mtb50">
          <span
            class="tab_li"
            v-for="(item, index) in fullPath === orderNav[0].id ||
            fullPath === orderNav[1].id
              ? orderNav
              : wareNav"
            :key="index"
            :class="fullPath.indexOf(item.id) != -1 ? 'active' : ''"
            @click="changeRoute(item.id, true)"
          >
            {{ item.name }}
          </span>
        </div>
        <OrderForm v-if="fullPath === orderNav[0].id"></OrderForm>
        <BatchOrderForm
          v-else-if="fullPath === orderNav[1].id"
        ></BatchOrderForm>
        <WareOrderForm
          v-else-if="fullPath.indexOf(wareNav[0].id) != -1"
          key="in"
        ></WareOrderForm>
        <OutWareOrder
          v-else-if="fullPath.indexOf(wareNav[1].id) != -1"
          key="out"
        ></OutWareOrder>
      </div>
    </div>
    <footer1></footer1>
  </div>
</template>
  
<script>
import header1 from "@/components/pc/header.vue";
import footer1 from "@/components/pc/footer.vue";
import OrderForm from "@/views/pc/order/orderForm.vue";
import BatchOrderForm from "@/views/pc/order/batchOrderForm.vue";
import TabItems from "@/components/pc/mytabs/mytabs.vue";
import WareOrderForm from "@/views/pc/warehouseOrder/wareOrderForm.vue";
import OutWareOrder from "@/views/pc/warehouseOrder/outWareOrder.vue";
import {
  getCargoLabelList,
  getZengZhiAll,
  getByCode,
  fetchPackingList,
} from "@/assets/js/order";
import { $axios } from "@/assets/js/global";
import { getUserStore } from "@/assets/js/storage";
const userData = getUserStore();
export default {
  data() {
    return {
      orderNav: [
        {
          id: "/pre_order",
          name: "极速发货",
        },
        {
          id: "/batch_order",
          name: "批量发货",
        },
      ],
      wareNav: [
        {
          id: "/entry_order",
          name: "入库单",
        },
        {
          id: "/out_order",
          name: "出库单",
        },
      ],
      fullPath: "",
      activeName: "1",
      activeNameArr: "a",
      active: 1,
    };
  },
  created() {
    getByCode(this);
    const path = this.$router.history.current.fullPath;
    this.fullPath = path;
    if (path === "/pre_order" || path === "/batch_order") {
      if (userData.corpId) {
        getCargoLabelList(userData.corpId); //获取货物规格列表
      }
      let byPCode = this.$store.state.byPCode;
      if (!byPCode.packingVO.length) {
        getZengZhiAll();
        fetchPackingList();
      }
    }
  },
  methods: {
    changeRoute(path, changeHeadTab) {
      if (this.$store.state.drawerType === "edit") {
        //修改订单和添加子订单时，不允许切换tab
        return;
      }
      if (this.$store.state.stepAction != 1) {
        //重置步骤条
        this.$store.state.stepAction = 1;
      }
      if (path === this.fullPath) return;
      this.fullPath = path;
      this.$router.push({ path: path });
    },
    next() {
      if (this.active++ >= 4) return;
    },
  },
  components: {
    header1,
    footer1,
    TabItems,
    OrderForm,
    BatchOrderForm,
    WareOrderForm,
    OutWareOrder,
  },
};
</script>
  
<style scoped>
.mb50 {
  margin-bottom: 50px;
}
.order_form {
  background-color: #f9f9f9;
}
.bg_gray {
  background-color: #f9f9f9;
}
</style>