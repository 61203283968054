<template>
<div>
   <el-cascader
      class="mycascader"
      width="100%"
      v-model="popValue"
      :options="popOptions"
      :props="props"
      :placeholder="holder"
      ref="cs"
      @change="(val) => getAreaLable(val)"
      :key="address"
      ></el-cascader>
      </div>
</template>
<script>
// 接口参数名称
const API=require('/src/assets/js/apilist.js');
let userJson = JSON.parse(localStorage.getItem('user'));
export default {
  props:{
    // 父组件的v-model值
    value:{
      type:Array,
      default:()=>[]
    },
    // 仓库接口地址
    address:{
      type:String,
      default:''
    },
    // 级联选择器的仓库数组
     options:{
      type:Array,
      default:()=>[]
    },
    // 更新sessionStorage内的数组名字
    storageName:{
      type:String,
      default:''
    },
    // placeholder默认值
    holder:{
      type:String,
      default:''
    }
  },
  data(){
   return{
     province:this.value[0], // 省id
     city:this.value[1], // 市id
     district:this.value[2], // 区id
     // 懒加载处理配置
     props: {     
        emitPath: true, //为true的时候value是数组，为false就只能拿到仓库的id的字符串   
        lazy: true,         
        lazyLoad: this.lazyLoad,
        value: 'value',
        label: 'label',  
        children:'children'            
     }
   } 
  },
  computed:{
    popOptions:{
       get() {
        return this.options;
       },
       set(newValue) {
       },
    },
    popValue:{
       get() {
        return this.value;
       },
       set(newValue) {
        this.$emit('update:value', newValue);
       },
    }
  },
   watch: {
     //  响应校验
     popValue:{
       handler(val) {
           this.validateTextarea();
       },
     },
  },
  mounted(){},
  methods:{
    // 级联面板选中事件
    getAreaLable(val) {
      // 传值给父组件
      this.$emit('input', val);
      this.$emit('change',val);
      this.$emit('changeDepotFun',val)  
    },
    // 动态懒加载方法
    lazyLoad(node, resolve) {       
      this.getData(node, resolve);     
    },   
    // 动态懒加载数据处理事件 
    getData(node, resolve) {
        // 判断层级       
        const level = node.level;
        let that = this
        // 如果初始有值就赋值
        if (node.data) {       
         resolve(node.data)  
        } 
         //获取省选择的id
        if(level === 1){
          that.province = node.data.value 
        }
        //获取市选择的id 
        if(level === 2){
           that.city = node.data.value 
        } 
        if (level === 3) {
           //获取区选择的id                
           that.district =  node.data.value;  
          // 调用查询仓库接口的参数        
          let params = {
            current: 1,
            size: 30,
            name: '',
            contractName: '',
            province: that.province,
            city: that.city,
            district:that.district,
          } 
      // 调用查询仓库接口方法
      that.$axios('post',API[that.address],params).then((res)=>{
        let result          
        result = res.data.records  
        const nodes = Array.from(result).map(item => ({
            value: item.id,
            label: `${item.name}`,
            leaf: level >= 3
          }));
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        resolve(nodes);
      }).catch((error)=>{
         console.log(error)
       })   
     }
    
    },
    // 传值给父组件，并响应父组件input事件
    validateTextarea() {
      try {
        this.$emit('input', this.value);
        this.$emit('change', this.value);
      } catch (e) {
      
      }
    },
  }
}
</script>
<style scoped>

</style>
