<template>
   <div id="m_coupon" class="flex yrow">
       <HeadNav></HeadNav>
       <!-- tab -->
       <div class="flex">
           <div class="flex1 xycenter" v-for="(item,index) in tabArr">
                <div :class="item.active==true?'option':'_option'" @click="changeTab(index)">{{item.name}}</div>
           </div>
       </div>
       <!-- 列表 -->
       <van-list
        class="vanlist"
        :offset="offset"
        v-model="loading"
        :finished="finished"
        :finished-text="finishText"
        @load="loadMore"
        >
            <div v-for="(item,index) in coupons" :class="item.couponType=='YHQLX01'?'demo mt28 flex b_bg':item.couponType=='YHQLX02'?'demo mt28 flex y_bg':'demo mt28 flex r_bg'">
                <div class="l_demo flex1 flex">
                    <!-- <div class="ycenter"> -->
                        <div class="l_l_demo flex1 xycenter padlr12">
                            <span v-if='item.couponType!="YHQLX01"'>¥</span>
                            <span :class="item.couponType=='YHQLX01'?'fs90 b_color':item.couponType=='YHQLX02'?'fs90 y_color':'fs90 r_color'">{{item.discountValue}}</span>
                            <span :class="item.couponType=='YHQLX01'?'fs32 b_color':item.couponType=='YHQLX02'?'fs32 y_color':'fs32 r_color'
                            " v-if='item.couponType="YHQLX01"'>折</span>
                        </div>
                        <div class="m_demo ycenter lh20">
                            <div class="aaa">
                                <div class="couponT">{{item.couponName}}</div>
                                <div>
                                    <span :class="item.couponType=='YHQLX01'?'pad4_6 fs26 b_bg2':item.couponType=='YHQLX02'?'pad4_6 fs26 y_bg2':'pad4_6 fs26 r_bg2'">{{item.couponTypeName}}</span>
                                    <span class="couponNo ml24">NO.{{item.couponNum}}</span>
                                </div>
                                <div class="couponTime">{{item.beginTime}}-{{item.endTime}}</div>
                            </div>
                        </div>
                    <!-- </div> -->
                </div>
                <img :src="item.couponType=='YHQLX01'?bl:item.couponType=='YHQLX02'?yl:rl" class="line">
                <div :class="
                item.couponType=='YHQLX01'?'r_demo xycenter b_color':item.couponType=='YHQLX02'?'r_demo xycenter y_color':'r_demo xycenter r_color'"
                @click="check(item)">
                    查看
                </div>
            </div>
            <div v-show="coupons.length==0" class="nodataDiv flex yrow xycenter">
                <img :src="nodata" class="nodata">
                <span class="nodataText">暂无数据</span>
            </div>
        </van-list>
        <!-- 优惠券明细弹窗 -->
        <couponPop :isShow="isShow" :coupon="coupon" @confirm="closeDialog"></couponPop>
   </div>
</template>

<script>
const API=require('/src/assets/js/apilist.js');
import HeadNav from '../../components/mobile/headNav.vue'
import couponPop from '../../components/mobile/couponPop.vue'
export default {
    components: {
        HeadNav,
        couponPop
    },

    data() {
        return {
            tabArr:[
                {name:'有效',active:true},
                {name:'未开始',active:false},
                {name:'已过期',active:false},
                {name:'已使用',active:false}
            ],
            isShow:false,//优惠券明细弹窗
            finishText:'没有更多了',
            offset:10,
            page:1,//页数
            coupon:{},
            coupons:[],
            loading: false,
            finished: false,
            nodata:require('../../assets/images/mobile/nodata.png'),
            bl:require('../../assets/images/mobile/coupon_bl.png'),
            gl:require('../../assets/images/mobile/coupon_gl.png'),
            rl:require('../../assets/images/mobile/coupon_rl.png'),
            yl:require('../../assets/images/mobile/coupon_yl.png'),

            
        }
    },

    created() {
        this.getCouponList('有效',this.page)

    },


    mounted() {

    },

    methods: {
        // 关闭优惠券弹窗
        closeDialog:function(val){
            const that=this;
            that.isShow=val;
        },
        // 查看
        check:function(obj){
            console.log("点击了查看")
            const that=this;
            that.coupon=obj;
            that.isShow=true;//打开弹窗
        },
        // 加载更多
        loadMore:function(){
            const that=this;
            that.page++;
            that.getCouponList(that.stateName,that.page)

        },
        // 切换tab选项
        changeTab:function(idx){
            console.log('触发了切换',idx)
            const that=this;
            that.tabArr.forEach((item,index)=>{
                if(idx==index){
                    that.tabArr[idx].active=true;
                    that.stateName=item.name;
                }else{
                    that.tabArr[index].active=false;
                }
            })
            that.page=1;
            that.coupons=[];
            that.getCouponList(that.stateName,that.page);
        },
        // 获取优惠券列表
        getCouponList:function(stateName,Page){
            const that=this;
            that.loading=true;
            that.finished=false;
            console.log("打印缓存user",JSON.parse(localStorage.getItem('user')))
            const user=JSON.parse(localStorage.getItem('user'));
            const params={
                couponState: stateName,
                pageSize: 15,
                pageIndex: Page,
            }
            that.$axios('get',API.getMyCoupons,params,'params','application/json').then((res)=>{
                if(res.isSuccess==true){
                    console.log("获取优惠券列表",res)
                    if(res.data.records.length>0){
                        that.coupons=that.coupons.concat(res.data.records);
                        that.loading=false;
                    }else{
                        if(that.coupons.length==0){
                            that.finishText="";
                        }
                        that.finished=true;
                        that.loading=false;
                    }
                    
                }
                
    
                
            }).catch((error)=>{

            })
        },
    }

}

</script>
<style scoped>
    #m_coupon{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    /* >>>.van-list__finished-text{
        display: none;
    } */
    .nodataDiv{
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        -ms-transform:translateX(-50%) translateY(-50%);
        -moz-transform:translateX(-50%) translateY(-50%);
        -webkit-transform:translateX(-50%) translateY(-50%);
        -o-transform:translateX(-50%) translateY(-50%);
    }
    .nodataText{
        font-size: 28px;
        color: #999999;
    }
    .nodata{
        width: 196px;
        height: 196px;
    }
    .fs32{
        font-size: 32px;
    }
    .aaa{
        width: 100%;
    }
    .b_bg{
        background-color: #E8F4FF;
    }
    .b_bg2{
        background-color: #2E9CF7;
    }
    .b_color{
        color: #2E9CF7;
    }
    .y_bg{
        background-color:#FFF2DE;
    }
    .y_bg2{
        background-color:#FC9F23;
    }
    .y_color{
        color:#FC9F23
    }
    .r_bg{
        background-color: #FFEFE8;
    }
    .r_bg2{
        background-color: #F55D5A;
    }
    .r_color{
        color:#F55D5A;
    }
    .lh20{
        line-height: 50px;
    }
    .ml24{
        margin-left: 24px;
    }
    .mt28{
        margin-top: 28px;
    }
    .couponTime{
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #807D7D;
    }
    .couponNo{
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #807D7D;
    }

    .couponT{
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .pad4_6{
        padding: 4px 6px;
        border-radius: 6px;
        color: white;
    }
    .fs26{
        font-size: 26px;
    }

    .l_demo{
        display: flex;
    }
    .l_l_demo{
        
    }
    .m_demo{
        width: 338px;
    }
    .padlr12{
        padding: 0 12px;
    }
    .r_demo{
        width: 150px;
        font-size: 32px;
    }
    .vanlist{
        padding: 0 20px;
        flex: 1;
        overflow-y: scroll;
        position: relative;
        /* overflow: hidden; */
    }
    .fs90{
        font-size: 90px;
    }
    .demo{
        /* width: 690px; */
        height: 178px;
        border-radius: 12px;
    }
    .option{
        padding: 12px;
        border-bottom: 4px solid #007EC5;
        color: #007EC5;
        font-size: 32px;
    }
    ._option{
        padding: 12px;
        font-size: 32px;
    }
    .line{
        width: auto;
        height: 178px;
    }

</style>
