<template>
    <div id="m_refund" class="flex yrow">
        <headNav></headNav>
        <!-- 列表 -->
        <van-list
         class="vanlist"
         :offset="offset"
         v-model="loading"
         :finished="finished"
         :finished-text="finishText"
         @load="loadMore"
         >
         <div class="demo mt28" v-for="(item,index) in orderArr" @click="selectOrder(item,index)">
             <div class="t_demo flex ycenter b_border">
                 <div class="flex1 fs26">单号:{{item.orderNo}}</div>
                 <div class="flex1 xright mr">
                    <span :class="item.stateName=='审核中'?'review':item.stateName=='退款成功'?'success':'fail'">{{item.stateName}}</span>
                 </div>

                 <!-- <span class="fs26 ml24">单号:{{item.orderNo}}</span> -->
             </div>
             <div class="b_demo">
                 <div class="flex fs38">
                     <div class="">{{item.sendersCityName+item.sendersDistrictName}}</div>
                     <div class="flex1 xycenter">
                         <img :src="arrow" class="arrow">
                     </div>
                     <div class="">
                         {{item.recipientCityName + item.recipientDistrictName}}
                     </div>
                 </div>
                 <!-- 结算方式,总费用,待付,已付 -->
                 <div class="flex orderdetail">
                     <div class="flex1 ycenter">结算方式:{{item.paymentTypeName}}</div>
                     <div class="flex1 xright ycenter">总费用:{{item.payment}}元</div>
                 </div>
                 <div class="flex orderdetail">
                     <div class="flex1 ycenter">待付:{{item.surplusMoney}}元</div>
                     <div class="flex1 xright ycenter">已付:{{item.paidMoney}}元</div>
                 </div>
             </div>
         </div>
         <div v-show="orderArr.length==0" class="nodataDiv flex yrow xycenter">
             <img :src="nodata" class="nodata">
             <span class="nodataText">暂无数据</span>
         </div>
         </van-list>
    </div>
 </template>
 
 <script>
 const API=require('/src/assets/js/apilist.js');
 import headNav from '../../components/mobile/headNav.vue'
 export default {
     components: {
         headNav
     },
 
     data() {
         return {
             sele:require('../../assets/images/mobile/select.png'),
             unsele:require('../../assets/images/mobile/unselect.png'),
             arrow:require('../../assets/images/mobile/arrow.png'),
             nodata:require('../../assets/images/mobile/nodata.png'),
             selectAll:false,
             orderArr:[],
             orderIdArr:[],//订单号数组
             page:1,
             offset:5,
             finishText:'没有更多了',
             loading: false,
             finished: false
 
             
         }
     },
 
     created() {
         this.getData(this.page)
 
     },
 
 
     mounted() {
 
     },
 
     methods: {
         // 查找存在元素的index下标
         existIndex:function(orderid,idArr){
             const that=this;
             idArr.findIndex((val) => {
             return val==orderid
             })
         },
         // 判断所选的元素id在id数组中是否存在
         isExistId:function(orderid,idArr){//orderid 为所选择的订单id,idArr为id的数组集合
             const that=this;
             const findData=idArr.find((val)=>{
                 console.log("打印这个val",val)
                 return val==orderid;
             })
             if(findData==undefined){//undefind表示数组集合idArr不存在orderid
                 return false;//如果不存在,返回false
             }else{//如果存在,返回true
                 return true;
             }
 
         },
         // 将选中的订单id插入到数组
         createIdArr:function(){
             const that=this;
 
 
         },
         // 立即支付
         payment:function(){
             const that=this;
             that.$router.push({
                 name:'m_payment',
                 params:{
                     orderIds:that.orderIdArr,
                     orderNum:that.orderIdArr.length
                 }
             })
             // const params={
             //     channelServiceName:'weiXinServiceImpl',
             //     orderIds:that.orderIdArr,
             //     parametersJson: {"appId":"wx2a60d1dc5fe4b730","tradeType":"NATIVE","body":"一站web商城"}
             // }
             // that.$axios('post',API.getPayOrderUrl,)
 
         },
         // 全选
         selectTotal:function(){
             const that=this;
             that.selectAll=!that.selectAll;
             that.orderIdArr=[];//清空数组
             if(that.selectAll){
                 that.orderArr.forEach(item=>{
                     item.isSelect=true;
                     that.orderIdArr.push(item.id)
                 })
             }else{
                 that.orderArr.forEach(item=>{
                     item.isSelect=false;
                 })
                 that.orderIdArr=[];//清空数组
             }
             console.log("打印所有被选择的id",that.orderIdArr)
         },
         // 选择订单
         selectOrder:function(obj,idx){
             const that=this;
             obj.isSelect=!obj.isSelect;
             let isSelect=obj.isSelect;
             const exist=that.isExistId(obj.id,that.orderIdArr);
             console.log("打印exist",exist)
             if(exist){
                     const i=that.existIndex(obj.id,that.orderIdArr);
                     console.log("i",i)
                     that.orderIdArr.splice(idx,1);
                 }else{
                     that.orderIdArr.push(obj.id)
                 }
 
             // if(isSelect){//如果是选中
             //     if(exist){
             //         return false
             //     }else{
             //         that.orderIdArr.push(obj.id)
             //     }
             // }else{//如果是取消勾选
             //     if(exist){
                     
             //     }else{
             //         that.orderIdArr.push(obj.id)
             //     }
             // }
             console.log("打印这个id数组",that.orderIdArr)
 
         },
         // 获取待支付订单列表
         getData:function(Page){
             const that=this;
             that.loading=true;
             that.finished=false;
             const params={
                 current: Page,
                 size: "10",
             }
             that.$axios('get',API.orderRefundPage,params,'params','application/json').then((res)=>{
                 console.log("获取待支付列表",res)
                 if(res.data.records.length>0){
                    //  res.data.records.forEach(item=>{
                    //      item.isSelect=false;
                    //  })
                 that.orderArr=that.orderArr.concat(res.data.records);
                 that.loading=false;
                 }else{
                     if(that.orderArr.length==0){
                         that.finishText="";
                     }
                     that.finished=true;
                     that.loading=false;
                 }
                 console.log('打印当前loading',that.loading)
             })
             that.loading=false;
             
         },
         //加载更多
         loadMore:function(){
             const that=this;
             that.page++;
             that.getData(that.page)
 
         },
     }
 
 }
 
 </script>
 <style scoped>
     #m_refund{
         background-color: rgb(244,244,244);
         min-height: 100vh;
         overflow: hidden;
         /* position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         background-color: #F5F5F5; */
     }
     .review{
        border-radius: 6px;
        padding: 2px 6px;
        color: #fff;
        background-color: #2A97E8;
     }
     .success{
        border-radius: 6px;
        padding: 2px 6px;
        color: #fff;
        background-color: #00C6AB;
     }
     .fail{
        border-radius: 6px;
        padding: 2px 6px;
        color: #fff;
        background-color: #93A1A7;
     }
     .nodataDiv{
         position: absolute;
         top:50%;
         left:50%;
         transform:translateX(-50%) translateY(-50%);
         -ms-transform:translateX(-50%) translateY(-50%);
         -moz-transform:translateX(-50%) translateY(-50%);
         -webkit-transform:translateX(-50%) translateY(-50%);
         -o-transform:translateX(-50%) translateY(-50%);
     }
     .nodataText{
         font-size: 28px;
         color: #999999;
     }
     .nodata{
         width: 196px;
         height: 196px;
     }
     .paybtn{
         width: 280px;
         height: 90px;
         background: linear-gradient(360deg, #FFCC84 0%, #FFF8EF 100%);
         border-radius: 45px;
         font-size: 34px;
         font-family: Source Han Sans CN;
         font-weight: 500;
         color: #B93A13;
     }
     .fs32{
         font-size: 32px;
     }
     .ml18{
         margin-left: 18px;
     }
     .pl36{
         padding-left: 36px;
     }
     .pr36{
         padding-right: 36px;
     }
     .mt28{
         margin-top: 28px;
     }
     .orderdetail{
         font-size: 28px;
         color: #666666;
         padding-top: 22px;
     }
     .b_demo{
         padding: 30px;
     }
     .pl30{
         padding-left: 30px;
     }
     .pr30{
         padding-right: 30px;
     }
     .arrow{
         width: 58px;
         height: 8px;
     }
     .b_border{
         border-bottom: 1px solid #DBDCDB;
     }
     .fs38{
         font-size: 38px;
     }
     .fs26{
         font-size: 26px;
     }
     .ml24{
         margin-left: 24px;
     }
     .unsele{
         width: 40px;
         height: 40px;
         background-size: 40px 40px;
         background-position: center;
         background-repeat: no-repeat;
     }
     .unsele2{
         width: 48px;
         height: 48px;
         background-size: 48px 48px;
         background-position: center;
         background-repeat: no-repeat;
     }
     .t_demo{
         padding: 28px 22px 28px 22px;
     }
     .payDiv{
         /* width: 100%; */
         height: 130px;
         box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
         background-color: white;
     }
     .vanlist{
         padding: 0 20px;
         flex: 1;
         overflow-y: scroll;
     }
     .demo{
         background-color: white;
         box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
         border-radius: 12px;
 
     }
     
 
 </style>
 