<template>
  <div class="form_area">
    <el-form :model="newStepArr" :rules="rules" ref="ruleForm" class="ruleForm">
      <div class="xlr_m">
        <h3 class="h3">发货信息</h3>
        <div class="iconbox hand" @click="showAddressM('deliver')" v-if="!newStepArr.orderSn">
          <span><i class="iconfont icon-diliweizhi mr5"></i>地址簿选择</span>
        </div>
      </div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="发货人" prop="sendersName">
            <el-input
              v-model="newStepArr.sendersName"
              prefix-icon="iconfont icon-yonghu"
              placeholder="请输入姓名"
              :disabled="drawerType === 'detail'"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发货人联系电话" prop="sendersPhone">
            <el-input
              v-model="newStepArr.sendersPhone"
              prefix-icon="iconfont icon-dianhua"
              placeholder="请输入联系人电话/座机"
              :disabled="drawerType === 'detail'"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="我的企业名称" prop="companyName">
            <el-input
              v-model="newStepArr.companyName"
              prefix-icon="iconfont icon-qiye"
              placeholder="请输入公司名称"
              :disabled="userStore.companyName != '' || drawerType !== 'add' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发货省市区" prop="sendersAddress">
            <el-cascader
              class="mycascader"
              width="100%"
              v-model="newStepArr.sendersAddress"
              placeholder="请选择省/市/区"
              ref="sendersAddressName"
              :options="ldStartList"
              :disabled="drawerType !== 'add' || isLock"
              @change="val => getAreaLable(val, 'sendersAddressName')"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发货详情地址" prop="sendersDetailaddress">
            <el-input
              v-model="newStepArr.sendersDetailaddress"
              prefix-icon="iconfont icon-diliweizhi"
              placeholder="请输入详细地址"
              :disabled="drawerType !== 'add' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="智能录入">
            <div class="rel auto_target">
              <input
                class="auto_ipt el-input__inner"
                placeholder="复制发货信息，快速识别"
                :disabled="drawerType !== 'add' || isLock"
                ref="deliver"
              />
              <span class="auto_btn" @click="() => getAddress('deliver')">识别</span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-col :span="12">
            <el-form-item label="预计发货时间" prop="sendersDate" class="mypicker">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="newStepArr.sendersDate"
                style="width: 100%"
                :picker-options="pickerOptions"
                @change="changeDate"
                value-format="yyyy-MM-dd"
                :clearable="false"
                :disabled="isLock"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="rec_date" label="预计发货时间段" prop="sendersTime">
              <el-select
                v-model="newStepArr.sendersTime"
                @visible-change="handleRecDate($event)"
                class="myselect"
                @change="changeTimes"
                :disabled="isLock"
              >
                <el-option v-for="item in sendersTimeOtp" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <div class="xlr_m">
        <h3 class="h3">收货信息</h3>
        <div class="iconbox hand" @click="showAddressM('receive')" v-if="!newStepArr.orderSn">
          <span><i class="iconfont icon-diliweizhi mr5"></i>地址簿选择</span>
        </div>
      </div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="收货人" prop="recipientName">
            <el-input
              v-model="newStepArr.recipientName"
              prefix-icon="iconfont icon-yonghu"
              placeholder="请输入姓名"
              :disabled="drawerType === 'detail'"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-col :span="12">
            <el-form-item label="收货人联系电话" prop="recipientPhone">
              <el-input
                v-model="newStepArr.recipientPhone"
                prefix-icon="iconfont icon-dianhua"
                placeholder="请输入联系人电话/座机"
                :disabled="drawerType === 'detail'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收货省市区" prop="recipientAddress">
            <el-cascader
              class="mycascader"
              width="100%"
              v-model="newStepArr.recipientAddress"
              placeholder="请选择省/市/区"
              ref="recipientAddressName"
              :options="ldEndList"
              :disabled="drawerType !== 'add' || isLock"
              @change="val => getAreaLable(val, 'recipientAddressName')"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收货详情地址" prop="recipientDetailaddress">
            <el-input
              v-model="newStepArr.recipientDetailaddress"
              prefix-icon="iconfont icon-diliweizhi"
              placeholder="请输入详细地址"
              :disabled="drawerType !== 'add' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="智能录入">
            <div class="rel auto_target">
              <input
                class="auto_ipt el-input__inner"
                placeholder="复制收货信息，快速识别"
                :disabled="drawerType !== 'add' || isLock"
                ref="receive"
              />
              <span class="auto_btn" @click="() => getAddress('receive')">
                识别
              </span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <h3 class="h3">货物信息</h3>
      <el-row class="mb20">
        <CargoLabelCmp
          v-if="this.$store.state.cargoLabelList.length"
          typeRef="order"
          :newStepArr="newStepArr"
          :sp="newStepArr.sp || ''"
          @changeSp="changeSp"
        ></CargoLabelCmp>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="货物类型" prop="cargoType">
            <el-select v-model="newStepArr.cargoType">
              <el-option
                v-for="item in this.$store.state.cargoTypeVO"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                @click.native="optionClick(item.name, 'cargoTypeName')"
                :disabled="drawerType == 'detail'"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="包装方式" prop="packing" style="position:relative">
            <el-select v-model="newStepArr.packing">
              <el-option
                v-for="item in packingVO"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                @click.native="optionClick(item.name, 'packingName')"
                :disabled="drawerType == 'detail'"
              >
              </el-option>
            </el-select>
            <div
              v-if="newStepArr.packing == 'LZ'"
              style="position:absolute;top:32px;left: 0;font-size: 12px;color: #0081EB;"
            >
              注：小票零担产品无法承运裸装
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="件数（件）" prop="totalPakages">
            <el-input
              required
              v-model="newStepArr.totalPakages"
              placeholder="请输入件数"
              :disabled="drawerType == 'detail' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="重量（kg）" prop="goodsWeight">
            <el-input
              required
              v-model="newStepArr.goodsWeight"
              placeholder="请输入重量"
              :disabled="drawerType == 'detail' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="体积（方）" prop="goodsVolume">
            <el-input
              required
              v-model="newStepArr.goodsVolume"
              placeholder="请输入体积"
              :disabled="drawerType == 'detail' || isLock"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="xright">
        <el-button type="primary" class="main_btn" @click="next">下一步</el-button>
      </el-row>
    </el-form>
    <AddressListCmp
      ref="addressOneRef"
      :isRadio="isRadio"
      :activeName="activeName"
      :xhrParams="xhrData"
      :isBatch="isBatch"
      :isShowAddrModal="isShowAddrModal"
      :selectedAddr="selectedAddr"
      @changeAddrModal="changeAddrModal"
      @confirm="confirm"
    ></AddressListCmp>
  </div>
</template>

<script>
import { regExp, dateRec } from '@/assets/js/global'
import { setOrderForm, getOrderForm, getUserStore, getAreaFilterList } from '@/assets/js/storage'
import {
  submitForm,
  getAddressDetail,
  handleSelectAddress,
  areaFilterVersion
} from '@/assets/js/order'
import moment from 'moment'
import AddressListCmp from '@/components/addressListCmp/AddressListCmp.vue' //引入选择产品弹窗
import CargoLabelCmp from '@/components/cargoLabelCmp/CargoLabelCmp.vue' //引入货物规格
const userData = getUserStore()
export default {
  props: {
    stepOneArr: {
      type: Object,
      required: true
    },
    isBatch: {
      type: Boolean
    },
    faIdx: {
      type: Number,
      required: true
    },
    isLock: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AddressListCmp,
    CargoLabelCmp
  },
  data() {
    return {
      drawerType: this.$store.state.drawerType,
      userStore: userData,
      newStepArr: this.stepOneArr,
      sendersTimeOtp: [],
      activeName: 'deliver',
      ldStartList: getAreaFilterList('AREA_LD_START'),
      ldEndList: getAreaFilterList('AREA_LD_END'),
      xhrData: {
        corpId: userData.corpId || '',
        userId: userData.id || ''
      },
      selectedAddr: [],
      isRadio: true, //true为地址簿选择弹窗，false为添加多发货人弹窗
      isShowAddrModal: false, //是否显示地址簿弹窗
      cargoTypeOtp: [
        {
          label: '颜料',
          value: '2111'
        }
      ],
      packingOtp: [
        {
          label: '件',
          value: '111'
        }
      ],
      pickerOptions: {
        disabledDate(time) {
          let h = Number(moment().format('HH')) //当前小时
          if (h >= 18) {
            return time.getTime() < Date.now() //18：00后只能选明天之后
          } else {
            return time.getTime() < Date.now() - 8.64e7
          }
        }
      },
      rules: {
        sendersName: regExp('other'),
        sendersPhone: regExp('mobilePhone'),
        companyName: regExp('other'),
        sendersAddress: regExp('other', true, '请选择'),
        sendersDetailaddress: regExp('other'),
        recipientName: regExp('other'),
        recipientPhone: regExp('mobilePhone'),
        recipientAddress: regExp('other', true, '请选择'),
        recipientDetailaddress: regExp('other'),
        cargoType: regExp('other', true, '请选择'),
        packing: regExp('other', true, '请选择'),
        goodsWeight: regExp('oneDec'),
        goodsVolume: regExp('sixDec'),
        totalPakages: regExp('integer'),
        sendersDate: regExp('other', true, '请选择'),
        sendersTime: regExp('other', true, '请选择')
      }
    }
  },
  computed: {
    packingVO() {
      //包装方式列表
      return this.$store.state.byPCode.packingVO
    }
  },
  watch: {
    stepOneArr(newVal) {
      this.newStepArr = { ...newVal }
    }
  },
  created() {
    areaFilterVersion(this) //查询地址版本
  },
  methods: {
    changeSp(item) {
      this.newStepArr = {
        ...this.newStepArr,
        ...item
      }
      this.$emit('changeStepArr', this.newStepArr)
    },
    changeAddrModal(flag) {
      this.isShowAddrModal = flag || false
    },
    confirm(res) {
      const addr = res.obj
      this.newStepArr = {
        ...this.newStepArr,
        ...addr
      }
      setOrderForm(this.newStepArr)
    },
    showAddressM(type) {
      this.activeName = type
      ;(this.isRadio = true), (this.isShowAddrModal = true)
      // if (this.$refs.addressOneRef) {
      //   this.$refs.addressOneRef.show();
      // }
    },
    changeTimes(val) {
      if (
        val === '18:00-18:30' ||
        val === '18:30-19:00' ||
        val === '19:00-19:30' ||
        val === '19:30-20:00'
      ) {
        let txt =
          '亲，您的提货时间为18:00之后，此时间段为提货高峰期，可能无法保障提货及时，建议选择明早提货。如需要今天发走，请在确定下单后保持电话畅通，稍后会有客服小姐姐联系您，请耐心等候！'
        this.$message.warning(txt, 5000)
      }
    },
    //地址识别
    getAddress(type) {
      let text = this.$refs[type].value
      if (!text) {
        this.$message.info('请填写要识别' + (type === 'deliver' ? '发货' : '收货') + '信息')
        return
      }
      getAddressDetail(this, type, text, this.callbackReco)
    },
    callbackReco(data) {
      this.newStepArr = {
        ...this.newStepArr,
        ...data.addr
      }
      setOrderForm(this.newStepArr)
    },
    getAreaLable(val, type) {
      const self = this
      setTimeout(() => {
        let labelVal = this.$refs[type].inputValue,
          arr = {}
        if (!labelVal) return
        labelVal = labelVal.split(' / ')
        if (type === 'sendersAddressName') {
          arr = {
            sendersProvinceName: labelVal[0] || '',
            sendersCityName: labelVal[1] || '',
            sendersDistrictName: labelVal[2] || ''
          }
        } else {
          arr = {
            recipientProvinceName: labelVal[0] || '',
            recipientCityName: labelVal[1] || '',
            recipientDistrictName: labelVal[2] || ''
          }
        }
        const newForm = {
          ...self.newStepArr,
          ...arr
        }
        self.newStepArr = newForm
        getOrderForm(newForm)
      }, 100)
    },
    optionClick(name, field) {
      if (this.newStepArr[field] === name) return
      this.newStepArr[field] = name
    },
    changeDate(date) {
      if (this.newStepArr.sendersTime) {
        //已选有时间段则清空
        this.newStepArr.sendersTime = ''
      }
    },
    handleRecDate(isShow) {
      if (isShow) {
        if (this.newStepArr.sendersDate) {
          this.sendersTimeOtp = dateRec(this.newStepArr.sendersDate)
        } else {
          this.$message.error('请先选择发货时间')
          return
        }
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    next() {
      submitForm('ruleForm', this)
    }
  }
}
</script>

<style scoped>
.rec_date >>> .el-form-item__label {
  font-size: 0;
}
.iconbox,
.iconbox .iconfont {
  font-size: 16px;
  color: #666;
}
</style>
