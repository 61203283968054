<template>
   <div id="m_search" class="flex yrow">
       <headNav class="headNav"></headNav>
        <div class="flex1 relative">
            <SearchForm></SearchForm>
        </div>
   </div>
</template>

<script>
import headNav from '../../components/mobile/headNav.vue';
import SearchForm from '../../components/mobile/SearchForm.vue'
// import PayStateDialog from '../../components/mobile/PayStateDialog.vue'
export default {

    components: {
        SearchForm,
        headNav
        // PayStateDialog
    },
    data() {
        return {
            showdialog:false
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
        // 查询
        search:function(){
            const that=this;

        },
        _showDialog:function(val){
            const that=this;
            console.log("2222222",val)
            that.showdialog=val;
        }
    }

}

</script>
<style scoped>
    #m_search{
        width: 100%;
        min-height: 100vh;
    }
    .relative{
        position: relative;
    }
    .headNav{
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 999;
    }
    

</style>
