export default {
  //出库仓库
  SET_OUTDEPOTOPITONS(state, outDepotOptions) {
    state = Object.assign(state, outDepotOptions);
    sessionStorage.setItem('outDepotOptions', JSON.stringify(outDepotOptions))
  },
  //向state 里面设置数据
  SET_DEPOTOPITONS(state, depotOptions) {
    state = Object.assign(state, depotOptions);
    sessionStorage.setItem('depotOptions', JSON.stringify(depotOptions))
  },
  SET_CORPID(state, corpId) {
    state.corpId = corpId
  }
};