<template>
  <div class="step_four form_area">
    <el-form :model="newStepArr" :rules="rulesFour" ref="ruleFormFour" class="ruleForm">
      <h3 class="h3">优惠服务</h3>
      <div>
        <span class="blue_btn" @click="handleModal">我的优惠券</span>
        <span class="ml20 fz18" v-if="newStepArr.couponNum">{{ newStepArr.couponName }}</span>
      </div>
      <div></div>
      <h3 class="h3">付款方式</h3>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="paymentType">
            <el-radio-group v-model="newStepArr.paymentType" :disabled="isLock">
              <span
                class="mr20"
                v-for="item in $store.state.byPCode.paymentTypeVO"
                :key="item.code"
              >
                <el-radio
                  :label="item.code"
                  :key="item.code"
                  v-if="!(item.code === 'ZL1605223' && $store.monthClosing != 1)"
                  @click.native="optionClick(item.code, item.name, 'paymentType')"
                >
                  {{ item.name }}
                </el-radio>
              </span>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <h3 class="h3">费用明细</h3>
        <el-table
          class="tborder"
          :data="$store.state.feeList"
          ref="feeRef"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column prop="feeName" label="费用类型" align="center"> </el-table-column>
          <el-table-column prop="cost" label="金额" align="center"> </el-table-column>
        </el-table>
      </el-row>
      <el-row class="xright mt30">
        <el-button type="primary" class="main_btn sec_btn" @click="prev">上一步</el-button>
        <span
          class="ml10"
          v-if="!isBatch && $store.state.feeList.length && newStepArr.paymentType === 'ZL1605220'"
        >
          <el-button type="primary" class="main_btn" @click="() => next('toList')"
            >提货支付</el-button
          >
          <el-button type="primary" class="main_btn" @click="() => next('toPay')"
            >立即支付</el-button
          >
        </span>
        <el-button
          type="primary"
          class="main_btn"
          @click="() => next('toList')"
          v-if="!isBatch && $store.state.feeList.length && newStepArr.paymentType !== 'ZL1605220'"
        >
          {{ newStepArr.orderSn ? '修改订单' : '确认下单' }}
        </el-button>
        <el-button type="primary" class="main_btn" @click="() => next('toList')" v-if="isBatch"
          >确定
        </el-button>
      </el-row>
    </el-form>
    <CouponModal
      :showCouponModal="showCouponModal"
      ref="refCoupon"
      :stepFourArr="newStepArr"
      @changeFaCoupon="changeFaCoupon"
    ></CouponModal>
    <CodeDialog
      :isShow="ishowPayModal"
      :amount="newStepArr.totalPrice || 0"
      :orderId="payOrderId"
      @close="_close"
    ></CodeDialog>
  </div>
</template>

<script>
import { regExp } from '@/assets/js/global'
import { getBatchOrderForm } from '@/assets/js/storage'
import CodeDialog from '@/components/pc/payQrCodeDialog.vue'
import { calcTotal, submitOrderForm, saveStorage, returnOneObj } from '@/assets/js/order'
import CouponModal from '@/components/couponsCmp/CouponModal.vue'
export default {
  props: {
    stepFourArr: {
      type: Object,
      required: true
    },
    isBatch: {
      type: Boolean
    },
    faIdx: {
      type: Number
    },
    isLock: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      drawerType: this.$store.state.drawerType,
      newStepArr: this.stepFourArr,
      showCouponModal: false, //是否展示优惠券弹窗
      ishowPayModal: false, //是否显示支付弹窗
      payOrderId: '', //需要支付的订单号
      rulesFour: {
        paymentType: regExp('other')
      }
    }
  },
  watch: {
    stepFourArr(newVal, oldVal) {
      this.newStepArr = newVal
    }
  },
  components: {
    CouponModal,
    CodeDialog
  },
  methods: {
    _close(res) {
      this.ishowPayModal = false
      this.$router.push({
        path: '/p_myorder/p_transOrder' //跳转到付款页
      })
      this.$store.state.stepAction = 1 //极速下单步骤路由重置
    },
    optionClick(code, name, field) {
      if (this.newStepArr[field + 'Name'] === name) return
      this.newStepArr[field + 'Name'] = name
      this.newStepArr[field] = code
      calcTotal(this, this.newStepArr, this.isBatch, this.faIdx, true)
    },
    handleModal() {
      if (this.isLock) {
        this.$message('锁定订单不能修改优惠方式')
        return
      }
      this.showCouponModal = true
      this.$refs.refCoupon && this.$refs.refCoupon.changeVisible(true, this.newStepArr)
    },
    changeFaCoupon(couponNumArr, couponNameArr) {
      this.newStepArr.couponNum = couponNumArr.join(',')
      this.newStepArr.couponName = couponNameArr.join(',')
      this.showCouponModal = false
      this.$emit('changeStepArr', this.newStepArr)
    },
    prev() {
      if (!this.isBatch) {
        this.$store.state.stepAction--
      } else {
        this.$emit('faFunc', 3)
      }
    },
    next(goToPageType) {
      let type = goToPageType || 'toList'
      if (!this.isBatch) {
        submitOrderForm(this, this.newStepArr, type, this.isBatch, this.faIdx)
      } else {
        let batchForm = getBatchOrderForm()
        const oneDatas = {
          ...batchForm.fhArr,
          ...this.newStepArr
        }
        saveStorage(this, oneDatas, this.isBatch, this.faIdx)
        submitOrderForm(this, oneDatas, type, this.isBatch, this.faIdx)
        this.$emit('chagneDialogVisible', false)
      }
    }
  }
}
</script>
<style scoped></style>
