export const warehouseRules = {
  depotId: [{ required: true, message: '请选择省/市/区/仓库名称', trigger: ['blur','change' ]}],
  storageDays: [{ required: true, message: '请输入储存天数'}],
  warehousingType: [{ required: true, message: '请选择入库需求', trigger: ['blur','change' ]}],
  warehousingDate: [{ required: true, message: '请选择入库日期', trigger: ['blur','change' ] }],
  warehousingTimes: [{ required: true, message: '请选择入库时间', trigger: ['blur','change' ] }],
  totalPakages: [{ required: true, message: '请输入入库数量（总计）', trigger: 'blur' }],
  totalVolume: [{ required: true, message: '请输入重量', trigger: 'blur' }],
  totalWeight: [{ required: true, message: '请输入体积', trigger: 'blur' }],
};
