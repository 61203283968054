<!-- 时间轴组件 @author Zerson -->
<template>
  <div id="StepLine" class="flex xycenter">
    <div class="flex xycenter box">
      <div v-for="(item,index) in stateArr" :class="index==stateArr.length-1?'final_option':state>item.state?'b_option':'option'">
        <i :class="state>item.state?'iconfont icon-liuchengjiedian3':state==item.state?'iconfont icon-liuchengjiedian2':'iconfont icon-liuchengjiedian'"></i>
        <span class="text">{{item.name}}</span>
      </div>
    </div>
      
  </div>
</template>

<script>
export default {
    components: {

    },
    props:[
      'state',
      'width'
    ],

    data() {
        return {
          stateArr:[
            {name:"下单成功",state:3},
            {name:"已提货",state:6},
            {name:"运输中",state:7},
            {name:"已签收",state:9}
          ]
            
        }
    },

    created() {

    },


    mounted() {

    },

    methods: {
      // 根据当前订单状态,动态渲染时间轴
      renderStyle:function(State){

      }
    }

}

</script>
<style scoped>
  .box{
    width: 500px;
  }
  /* 未成功的icon */
  .icon-liuchengjiedian{
    font-size: 18px;
    position: absolute;
    top:0%;
    left:0%;
    transform: translate(-50%, -50%);
    color: #AAA;
  }
  /* 进行中的icon */
  .icon-liuchengjiedian2{
    background-color: white;
    color: #007EC5;
    font-size: 34px;
    position: absolute;
    top:0%;
    left:0%;
    transform: translate(-50%, -50%);
  }
  /* 成功时的icon */
  .icon-liuchengjiedian3{
    background-color: white;
    color: #007EC5;
    font-size: 40px;
    position: absolute;
    top:0%;
    left:0%;
    transform: translate(-50%, -50%);
  }
  .firstOption{
    position: relative;
    width: 0;
    height: 60px;
    display: block;
  }
  .option{
    flex:1;
    height: 60px;
    border-top: 1px solid #AAAAAA;
    position: relative;
  }
  .b_option{
    flex:1;
    height: 60px;
    border-top: 1px solid #007EC5;
    position: relative;
  }
  .final_option{
    width: 0;
    height: 60px;
    position: relative;
  }
  .text{
    position: absolute;
    bottom:0%;
    width: 100px;
    text-align: center;
    left:0%;
    transform: translate(-50%,0);
  }
  .b_text{
    color: #007EC5;
    position: absolute;
    bottom:0%;
    width: 100px;
    text-align: center;
    left:0%;
    transform: translate(-50%,0);
  }
  .point{
    position: absolute;
    top:0%;
    left:0%;
    transform: translate(-50%, -50%);
  }
  .flexspace{
    justify-content: space-between;
  }
  
   

</style>
