<template>
  <div id="p_orderDetail">
    <div class="maincontent">
      <!-- 头部 -->
      <div class="flex ycenter ptb40">
        <div class="flex1">
          <span class="fz18 pointer" @click="goback"><<返回订单列表</span>
        </div>
        <div class="flex1 xright">
          <div class="bluebtn xycenter mr30 pointer" @click="to_wuliu($route.query.orderSn)">
            物流信息
          </div>
          <div class="bluebtn xycenter pointer" @click="to_eOrder($route.query.orderSn)">
            电子运单
          </div>
        </div>
      </div>
      <!-- 新建 -->
      <div class="pad22 card">
        <div class="flex">
          <div class="flex1 card_t">{{ detail.stateName }}</div>
          <div class="flex1 xright ycenter orderno">订单号:{{ detail.orderNo }}</div>
        </div>
        <div class="mt30">用户下单时间:{{ detail.createTime }}</div>
      </div>
      <!-- 收费明细 -->
      <div class="pad22 card">
        <div class="flex1 card_t">收费明细</div>
        <div class="flex ycenter fz18 pt20 wrap">
          <div class="_width50 pt10" v-for="(item, index) in detail.feeMergeList">
            {{ item.name }}:{{ item.cost | pricefilter }}
          </div>
          <div class="_width50 pt10">
            总运费(元):{{ detail.payment ? detail.payment : 0.0 | pricefilter }}
          </div>

          <!-- <div class="flex1 yrow">
                         <div class="mt20">预计支付:{{detail.payment}}</div>
                         <div class="mt20">主运费:{{detail.mainPrice}}</div>
                         <div class="mt20">保价费:{{detail.insurancePrice}}</div>
                         <div class="mt20">优惠金额:{{detail.lineSalePrice}}</div>
                     </div>
                     <div class="line l_border"></div>
                     <div class="flex1 ycenter">
                         <div class="pl22">
                             <div class="mt20">送货费:{{detail.shfPrice}}</div>
                             <div class="mt20">回单费:{{detail.receiptPrice}}</div>
                         </div>
                     </div> -->
        </div>
      </div>
      <!-- 发收货信息 -->
      <div class="card">
        <div class="flex1 card_t padlrt22">发/收货信息</div>
        <div class="flex plr22 ycenter">
          <div class="flex1">
            <div class="xycenter">
              <div class="bg_blue xycenter circle">发</div>
            </div>
            <div class="pl18 fz18">
              <p>{{ detail.sendersName }}/{{ detail.sendersPhone }}</p>
              <p>
                {{ detail.sendersProvinceName }}{{ detail.sendersCityName
                }}{{ detail.sendersDistrictName }}{{ detail.sendersDetailaddress }}
              </p>
            </div>
          </div>
          <div class="line l_border"></div>
          <div class="flex1 pl22">
            <div class="xycenter">
              <div class="bg_green xycenter circle">收</div>
            </div>
            <div class="pl18 fz18">
              <p>{{ detail.recipientName }}/{{ detail.recipientPhone }}</p>
              <p>
                {{ detail.recipientProvinceName }}{{ detail.recipientCityName
                }}{{ detail.recipientDistrictName }}{{ detail.recipientDetailaddress }}
              </p>
            </div>
          </div>
        </div>
        <div class="t_border pad22 fz18">
          <span>货物信息</span>
          <span class="ml82"
            >{{ detail.cargoTypeName }},{{ detail.packingName }},{{ detail.totalWeight }}kg,{{
              detail.totalVolume
            }}方,{{ detail.totalPakages }}{{ detail.packingName }}</span
          >
        </div>
      </div>
      <!-- 服务信息 -->
      <div class="pad22 card">
        <div class="flex1 card_t">服务信息</div>
        <div class="flex ycenter fz18">
          <div class="flex1 yrow">
            <div class="mt20">回单服务:{{ detail.receiptTypeName }}</div>
            <div class="mt20">付款方式:{{ detail.paymentTypeName }}</div>
            <div class="mt20">备注:{{ detail.remark }}</div>
          </div>
          <div class="line l_border"></div>
          <div class="flex1 ycenter">
            <div class="pl22">
              <div class="mt20">
                送货服务:{{ detail.deliveryService == true ? '送货上门' : '自提' }}
              </div>
              <div class="mt20">预计发货时间:{{ detail.sendersDate }} {{ detail.sendersTime }}</div>
              <div class="mt20">时效:{{ detail.lineHour }}</div>
              <div class="mt20">预计送达时间:{{ detail.recipientDate | cancelHour }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const API = require('/src/assets/js/apilist.js')
export default {
  components: {},

  data() {
    return {
      detail: {}
    }
  },
  filters: {
    // 金额过滤器,保留小数点后2位,没有小数则自动补零
    pricefilter: function(data) {
      return parseFloat(data).toFixed(2)
    },
    // 去掉送达时间的时分秒
    cancelHour: function(data) {
      return data ? data.split(' ')[0] : ''
    }
  },

  created() {
    this.getOrderDetail()
  },

  mounted() {},

  methods: {
    // 物流信息
    to_wuliu: function(orderSn) {
      this.envJump('/html/trajectory.html?orderSn=' + orderSn, 'href', 0)
    },
    // 去电子面单
    to_eOrder: function(orderSn) {
      this.envJump(
        '/business/faceSheet?waybillNo=' + orderSn + '-G1&type=3&time=' + new Date().getTime(),
        'href',
        0
      )
    },
    getOrderDetail: function() {
      const that = this
      const params = {
        orderSn: that.$route.query.orderSn
      }
      that
        .$axios('post', API.orderDetail, params, 'data', 'application/json')
        .then(res => {
          console.log('获取订单详情成功', res)
          that.detail = res.data
        })
        .catch(error => {
          console.log('获取订单详情成功', error)
        })
    },
    goback: function() {
      const that = this
      that.$router.go(-1)
    }
  }
}
</script>
<style scoped>
#p_orderDetail {
  padding: 0 36px;
}
.pt20 {
  padding-top: 20px;
}
.pt10 {
  padding-top: 10px;
}
._width50 {
  width: 50%;
}
.pointer {
  cursor: pointer;
}
.padlrt22 {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 22px;
}
.mt20 {
  margin-top: 20px;
}
.line {
  width: 1px;
  height: 42px;
}
.ml82 {
  margin-left: 82px;
}
.plr22 {
  padding: 0 22px;
}
.t_border {
  border: 1px solid #d9d9d9;
}
.fz18 {
  font-size: 18px;
  color: #666666;
}
.pl18 {
  padding-left: 18px;
}
.circle {
  width: 44px;
  height: 44px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border-radius: 50%;
}
.bg_blue {
  background-color: #0a8de4;
}
.bg_green {
  background-color: #76b042;
}
.l_border {
  border-left: 1px solid #cccccc;
}
.mt30 {
  margin-top: 30px;
  font-size: 18px;
  color: #666666;
}
.orderno {
  font-size: 16px;
  color: #666666;
}
.card {
  background-color: #f7f7f7;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 30px;
}
.card_t {
  color: #666666;
  font-size: 20px;
  font-weight: bold;
}
.pad22 {
  padding: 22px;
}
.pl22 {
  padding-left: 22px;
}
.blue {
  color: #0a8de4;
}
.ptb40 {
  padding: 40px 0;
}
.mr30 {
  margin-right: 30px;
}
.maincontent {
  width: 1150px;
}
.bluebtn {
  width: 155px;
  height: 38px;
  background: #0a8de4;
  border-radius: 5px;
  color: white;
  font-size: 16px;
}
</style>
