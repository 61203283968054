let userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
const FAHUOARR = {
  sendersName: '',
  sendersPhone: '',
  companyName: userData.companyName || '',
  sendersAddress: [],
  sendersDetailaddress: '',
  sendersDate: '',
  sendersTime: '',
  phoneNumber: userData.mobile || ''
}
const SHOUHUOARR = {
  orderSn: '', //211109001
  monthClosing: false,
  recipientName: '',
  recipientPhone: '',
  recipientAddress: [],
  recipientDetailaddress: '',
  cargoType: '',
  packing: '',
  totalPakages: '',
  totalWeight: '',
  totalVolume: '',
  lineId: '',
  paymentType: 'ZL1605220',
  paymentTypeName: '现付',
  declaredValue: '',
  insurancePrice: '', //保价金额
  pickService: 'shsm', //送货服务
  receiptType: 'ZL1605215',
  receiptTypeName: '无需回单',
  addedService: ['0'], //包装服务
  mtp: '', //木托盘数
  services: [], //装卸服务
  remark: '',
  dzdState: '', //是否已对账，'0'为已对账
  drawerType: 'add', //订单类型： add新增、edit修改、again再来一单。
  nature: '' //客户性质
}
const ORDERSTORE = {
  //极速下单原始数据
  ...FAHUOARR,
  ...SHOUHUOARR
}
const BATCHORDERSTORE = {
  //批量下单原始数据
  fhArr: FAHUOARR,
  shList: [SHOUHUOARR]
}
const cargoTypeVO = [
  //货物类型
  // { code: 'ZL1605323', name: '颜料油漆', usedTimes: 0 },
  { code: 'ZL1807094', name: '化工用品', usedTimes: 0 },
  { code: 'ZL1605324', name: '日化用品', usedTimes: 0 },
  { code: 'ZL1605326', name: '医药', usedTimes: 57 },
  { code: 'ZL1605328', name: '机械设备', usedTimes: 4 },
  { code: 'ZL1807089', name: '家具家居', usedTimes: 0 },
  { code: 'ZL1807093', name: '家用家电', usedTimes: 0 },
  { code: 'ZL1605340', name: '建筑材料', usedTimes: 0 },
  { code: 'ZL1807087', name: '五金建材', usedTimes: 0 },
  { code: 'ZL1807085', name: '食品饮料', usedTimes: 0 },
  { code: 'ZL1807091', name: '3C电子', usedTimes: 0 },
  { code: 'ZL1605338', name: '橡胶制品', usedTimes: 0 },
  { code: 'ZL1605339', name: '皮革皮具', usedTimes: 0 }
]
const addedServiceList = [
  { id: 'mj', name: '木架' },
  { id: 'mtp', name: '木托盘' },
  { id: 'bkml', name: '薄款木箱' },
  { id: 'hkml', name: '厚款木箱' },
  { id: '0', name: '暂不需要' }
]
const servicesList = [
  { id: 'zh', name: '装货' },
  { id: 'xh', name: '卸货' }
]
const tagsDat = [
  { id: 'mr', name: '默认' },
  { id: 'jg', name: '价格' },
  { id: 'sx', name: '时效' }
]
module.exports = {
  FAHUOARR,
  SHOUHUOARR,
  ORDERSTORE,
  cargoTypeVO,
  BATCHORDERSTORE,
  addedServiceList,
  servicesList,
  tagsDat
}
