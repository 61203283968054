<template>
  <div class="m_index">
    {{ msg }}
    <i class="iconfont icon-weixin"></i>
    <div class="iconfont icon-weixin"></div>
  </div>
</template>
  
<script>
  const API=require('/src/assets/js/apilist.js');
export default {
  name: "index",
  components: {},
  data() {
    return {
      msg: "手机页面",
    };
  },
  created () {
    const that = this;
    console.log('1111111')
    that.getData();
    // that.getData2();
  },
  mounted() {
    const that = this;
    console.log(that.print);
    // that.print()
  },
  methods: {
    getData:function(){
      var obj={
        current: 1,
        size: 4,
      }
      this.$axios('get',API.bigTicketExpress,obj)
    },
    getData2:function(){
      const that=this;
      localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ7XCJidXNpbmVzc0xpY2Vuc2VcIjpcIlwiLFwiYnVzaW5lc3NMaWNlbnNlTnVtXCI6XCJcIixcImNsaWVudENvZGVcIjpcIlNDS0gyMDIxMDUwMjNcIixcImNvbXBhbnlBZGRyZXNzXCI6XCJcIixcImNvbXBhbnlOYW1lXCI6XCIxMTExXCIsXCJjb3JwSWRcIjoxNDQ4OTQxNTc2NDE4NDEwNDk3LFwiaWRcIjpcIjE0NDg5NDE1Njg1MTYzNDE3NjFcIixcImxhc3RMb2dpbklwXCI6XCIxMC4xLjQuMTA5XCIsXCJsYXN0TG9naW5UaW1lXCI6MTYzNDI4OTU2NTAwMCxcImxhc3RNb2RpZnlUaW1lXCI6MTYzNTIzNTc0NTg1NSxcImxlZ2FsUGVyc29uXCI6XCJcIixcImxvZ2luTnVtXCI6MSxcIm1vYmlsZVwiOlwiMTg2NjU1MDQ5MzZcIixcIm5ld1VzZXJGbGFnXCI6ZmFsc2UsXCJyZWdpc3RlclBhdGhcIjpcIjFcIixcInJlZ2lzdGVyVGltZVwiOjE2MzQyODk1NjUwMDAsXCJyb2xlQ29kZVwiOlwiYWRtaW5cIixcInNldHRsZUN5Y2xlXCI6XCJcIixcInNldHRsZVR5cGVcIjpcIlwiLFwidXNlclN0YXRlXCI6MSxcInVzZXJUeXBlXCI6XCIxXCIsXCJ4Y3hJbmZvXCI6XCJ7XFxcInBob25lTnVtYmVyXFxcIjpcXFwiMTg2NjU1MDQ5MzZcXFwiLFxcXCJ1bmlvbklkXFxcIjpcXFwib3ZlNk8wcExmQ2VPZXZhdU90VEZ0LXJTcnhsY1xcXCIsXFxcInNlc3Npb25LZXlcXFwiOlxcXCJkWFd3Rk4xaXFpc1pneTVRd29pRy9nPT1cXFwiLFxcXCJwdXJlUGhvbmVOdW1iZXJcXFwiOlxcXCIxODY2NTUwNDkzNlxcXCIsXFxcImNvdW50cnlDb2RlXFxcIjpcXFwiODZcXFwiLFxcXCJvcGVuSWRcXFwiOlxcXCJvTmJHOTR0dDFEYklnSGo3NXFIYWFwRkl1RHFjXFxcIixcXFwiYXBwaWRcXFwiOlxcXCJ3eDJhNjBkMWRjNWZlNGI3MzBcXFwiLFxcXCJ0aW1lc3RhbXBcXFwiOjE2MzQyODk3MTB9XCIsXCJ4Y3hPcGVuaWRcIjpcIm9OYkc5NHR0MURiSWdIajc1cUhhYXBGSXVEcWNcIn0iLCJjdXJyZW50VXNlciI6MTQ0ODk0MTU2ODUxNjM0MTc2MSwiZXhwIjoxNjM3ODI3NzQ1LCJpYXQiOjE2MzUyMzU3NDV9.7Zsp-zO051kz_6n9tt-t6HPRpvIpoW1emiaDdwxggf8');
      that.axios({
        method: 'get',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded',
          'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ7XCJidXNpbmVzc0xpY2Vuc2VcIjpcIlwiLFwiYnVzaW5lc3NMaWNlbnNlTnVtXCI6XCJcIixcImNsaWVudENvZGVcIjpcIlNDS0gyMDIxMDUwMjNcIixcImNvbXBhbnlBZGRyZXNzXCI6XCJcIixcImNvbXBhbnlOYW1lXCI6XCIxMTExXCIsXCJjb3JwSWRcIjoxNDQ4OTQxNTc2NDE4NDEwNDk3LFwiaWRcIjpcIjE0NDg5NDE1Njg1MTYzNDE3NjFcIixcImxhc3RMb2dpbklwXCI6XCIxMC4xLjQuMTA5XCIsXCJsYXN0TG9naW5UaW1lXCI6MTYzNDI4OTU2NTAwMCxcImxhc3RNb2RpZnlUaW1lXCI6MTYzNTIzMjIwMzUzNSxcImxlZ2FsUGVyc29uXCI6XCJcIixcImxvZ2luTnVtXCI6MSxcIm1vYmlsZVwiOlwiMTg2NjU1MDQ5MzZcIixcIm5ld1VzZXJGbGFnXCI6ZmFsc2UsXCJyZWdpc3RlclBhdGhcIjpcIjFcIixcInJlZ2lzdGVyVGltZVwiOjE2MzQyODk1NjUwMDAsXCJyb2xlQ29kZVwiOlwiYWRtaW5cIixcInNldHRsZUN5Y2xlXCI6XCJcIixcInNldHRsZVR5cGVcIjpcIlwiLFwidXNlclN0YXRlXCI6MSxcInVzZXJUeXBlXCI6XCIxXCIsXCJ4Y3hJbmZvXCI6XCJ7XFxcInBob25lTnVtYmVyXFxcIjpcXFwiMTg2NjU1MDQ5MzZcXFwiLFxcXCJ1bmlvbklkXFxcIjpcXFwib3ZlNk8wcExmQ2VPZXZhdU90VEZ0LXJTcnhsY1xcXCIsXFxcInNlc3Npb25LZXlcXFwiOlxcXCJkWFd3Rk4xaXFpc1pneTVRd29pRy9nPT1cXFwiLFxcXCJwdXJlUGhvbmVOdW1iZXJcXFwiOlxcXCIxODY2NTUwNDkzNlxcXCIsXFxcImNvdW50cnlDb2RlXFxcIjpcXFwiODZcXFwiLFxcXCJvcGVuSWRcXFwiOlxcXCJvTmJHOTR0dDFEYklnSGo3NXFIYWFwRkl1RHFjXFxcIixcXFwiYXBwaWRcXFwiOlxcXCJ3eDJhNjBkMWRjNWZlNGI3MzBcXFwiLFxcXCJ0aW1lc3RhbXBcXFwiOjE2MzQyODk3MTB9XCIsXCJ4Y3hPcGVuaWRcIjpcIm9OYkc5NHR0MURiSWdIajc1cUhhYXBGSXVEcWNcIn0iLCJjdXJyZW50VXNlciI6MTQ0ODk0MTU2ODUxNjM0MTc2MSwiZXhwIjoxNjM3ODI0MjAzLCJpYXQiOjE2MzUyMzIyMDN9.IAXN-kOtsG1f_MfP4O65x3R8gssR3Ii0Az03UT30jNM',
        },
        url: '/api/portal/homePage/bigTicketExpress',
        params:{
        current: 1,
        size: 4,
        }
      })
      .then(function (res) {
        console.log('请求成功',res);
      })
      .catch(function (e) {
        console.log('请求失败',e);
      });
      
    }
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.m_index {
  width: 600px;
  height: 400px;
  font-size: 28px;
}
.icon-weixin {
  font-size: 100px;
}
</style>