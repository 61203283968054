<template>
  <div class="m_index">
    {{ msg }}
    <i class="iconfont icon-weixin"></i>
    <div class="iconfont icon-weixin"></div>
    <el-button @click="pay">发起支付</el-button>
    <codeDialog ref="dialog" :isShow="show" :amount="amount" :orderId="orderId" @close="_close"></codeDialog>
  </div>
</template>
  
<script>
  const API=require('../../assets/js/apilist.js');
  import codeDialog from '../../components/pc/payQrCodeDialog.vue'
export default {
  name: "index",
  components:{
    codeDialog
  },
  data() {
    return {
      msg: "PC页面",
      address: {},
      show:false,
      amount:100,
      orderId:'1475724737034752001'
    };
  },

  created: function () {
    const that = this;
    // that.print()
  },
  mounted: function () {
    const that = this;
    // that.print()
  },
  methods: {
    pay:function(){
      // this.$refs.dialog.payment();
      this.show=true;
    },
    _close:function(val){
      console.log("打印这个回调的val",val)
      this.show=val;
    }
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.m_index {
  width: 600px;
  height: 400px;
  font-size: 28px;
}
.icon-weixin {
  font-size: 100px;
}
</style>