import Vue from 'vue'
import axios from 'axios'
import 'lib-flexible'
import App from './App.vue'
import router from './router/m_index.js'
import store from './store'
import moment from 'moment'//moment模块
import './registerServiceWorker'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
import Vant from 'vant'
import 'vant/lib/index.css';
Vue.use(Vant)
import {isMobile} from './assets/js/global.js'
import {print} from './assets/js/mobile.js'
import {print2} from './assets/js/pc.js'
import toast from './plugins/Toast'

import {message} from './utlis/resetMessage'
// import cookie from 'vue-cookie'
Vue.use(toast);
Vue.prototype.axios = axios;
Vue.prototype.moment = moment;
Vue.prototype.$message=message;
// Vue.use(cookie) 
// 路由拦截
// router.beforeEach((to,from,next)=>{
//   // 从移动端访问PC端
//   if(isMobile()&&to.meta.type!='mobile'){
//     next('/m_index')
//   }
//   // 从PC端访问移动端
//   if(!isMobile()&&to.meta.type=='mobile'){
//     next('/p_index')
//   }
//   next();
// })
if(isMobile()){//如果为移动端
  require(['./assets/css/mobile/mobile.css'])//加载移动css样式
  console.log("加载移动样式成功")
  // Vue.prototype.print = print;
}else{
  require(['./assets/css/pc/pc.css'])//加载PC端css样式
  console.log("加载PC样式成功")
  // Vue.prototype.print2 = print2;
}
Vue.config.productionTip = false
// 封装函数
import{$axios,getcodeAPI,filterParams,filterParams2,_filterParams,envJump,wxBrower,throttle}from './assets/js/global.js'
Vue.prototype.$axios = $axios
Vue.prototype.getcodeAPI = getcodeAPI;//获取验证码接口
Vue.prototype.filterParams=filterParams;
Vue.prototype.filterParams2=filterParams2;
Vue.prototype._filterParams=_filterParams;
Vue.prototype.envJump=envJump;//根据不同环境跳转不同环境的1.0页面
Vue.prototype.wxBrower=wxBrower;
Vue.prototype.throttle=throttle;//节流函数
var vue=new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vue

